import React from "react";
import "./index.css";
import {
  ArrowLeft01Icon,
  Copy01Icon,
  Share08Icon,
  InformationCircleIcon,
} from "hugeicons-react";
import { QRCode } from "react-qrcode-logo";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";

const WalletReceive = ({ selectedToken, back }) => {
  const { wallet_address: address } = useSelector((state) => state.user);
  const { activeProvider: provider } = useSelector((state) => state.assets);

  const copyText = () => {
    const textToCopy = address;
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          toast.success("Address copied", {
            style: { fontSize: "12px" },
          });
        })
        .catch((err) => {
          toast.error("Failed to copy link: ", err, {
            style: { fontSize: "12px" },
          });
        });
    } else {
      // Fallback method for older browsers
      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        toast.success("Address copied", {
          style: { fontSize: "12px" },
        });
      } catch (err) {
        toast.error("Failed to copy link: ", err, {
          style: { fontSize: "12px" },
        });
      }
      document.body.removeChild(textArea);
    }
  };

  return (
    <div className="WalletDetailPageDiv">
      <div className="WalletDetailPageDiv_header">
        <div className="WalletDetailPageDiv_header_back" onClick={back}>
          <ArrowLeft01Icon size={24} />
        </div>

        <div className="WalletDetailPageDiv_header_info">
          <InformationCircleIcon size={20} />
        </div>
      </div>
      <div className="TokenListDetailBody">
        <div className="TokenListDetailBody_qrCode_div">
          <QRCode
            value={address}
            quietZone={5}
            eyeColor="#000"
            bgColor="#fff"
            fgColor="#000"
            logoImage={
              selectedToken?.tokenType === "native"
                ? provider?.image
                : selectedToken?.img || selectedToken?.image || "/img/art.png"
            }
            eyeRadius={[
              [5, 5, 0, 5],
              [5, 5, 5, 0],
              [5, 0, 5, 5],
            ]}
            removeQrCodeBehindLogo={true}
            logoPaddingStyle="circle"
            className="depositMoneyDiv_cont_body_qr_div_qr"
          />
        </div>
        <div className="TokenListDetailBody_qrCode_div_txt">
          Send only{" "}
          <span className="TokenListDetailBody_qrCode_div_txt_span">
            {provider?.network}
          </span>{" "}
          network assets to this address.
        </div>
        <div className="TokenListDetailBody_copy_div">
          <div className="TokenListDetailBody_copy_div_cont1">
            <div className="TokenListDetailBody_copy_div_cont1_title">
              {selectedToken?.tokenType === "native"
                ? provider?.baseToken
                : selectedToken?.tokenSymbol}
            </div>
            <div className="TokenListDetailBody_copy_div_cont1_addr">
              {address}
            </div>
          </div>
          <button className="WalletDiv_cont1_div2_icon1" onClick={copyText}>
            Copy
          </button>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default WalletReceive;
