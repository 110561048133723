import React from "react";

export const ProductSlideThumb = ({ selected, image, onClick }) => {
  return (
    <div
      className={`embla-thumbs__slide ${
        selected ? "embla-thumbs__slide--selected" : ""
      }`}
      onClick={onClick}
    >
      <img className="embla-thumbs__img" src={image} alt="Thumbnail" />
    </div>
  );
};
