import { ethers } from "ethers";
import { parseEther } from "ethers/lib/utils";
import { v4 as uuid } from "uuid";
import { store } from "../../../Redux/app/store";
import { decrypt } from "../../../helper/encryption";
import routerAbi from "../ExchangeTrade/Liquidity/dhiveRouterAbi.json";
import { getParsedEthersError } from "@enzoferey/ethers-error-parser";
import { decodeErrorResult } from "viem";
const state = store.getState();

export function parseViemError(error, abi = routerAbi) {
  try {
    if (error?.data) {
      const decoded = decodeErrorResult({ abi, data: error.data });
      return decoded
        ? `${decoded.errorName}: ${decoded.args.join(", ")}`
        : "Unknown contract error";
    }
  } catch (e) {
    console.error("Error parsing Solidity error:", e);
  }
  return error?.message || "Unknown error";
}

// Example usage:
// const parsedViemError = parseViemError(errorObject, contractAbi);
// console.log(parsedViemError);

export const getBalanceFromAddress = async ({ address }) => {
  const result = state.assets.Assets.find((ass) => ass?.tokenB === address);
  return result?.balance;
};

export const getAmountsOut = async ({ path, amount }) => {
  const slippageTolerance = ethers.BigNumber.from("100");
  // const decrypted = decrypt(privateKey);
  const provider = new ethers.providers.JsonRpcProvider(
    "https://mainnet.egochain.org"
  );
  // const signer = new ethers.Wallet(decrypted, provider);
  const routerAddress = "0x1641aDe952320BEC5330B7e54D5A032FB5006de3"; // Replace with the actual DEX router address
  const PANCAKESWAP_ROUTER_ABI = [
    "function getAmountsOut(uint amountIn, address[] memory path) public view returns (uint[] memory amounts)",
  ];

  const router = new ethers.Contract(
    routerAddress,
    PANCAKESWAP_ROUTER_ABI,
    provider
  );

  // Fetch estimated output amount
  const amountsOut = await router.callStatic.getAmountsOut(
    ethers.utils.parseEther(amount.toString()),
    path
  );

  // Calculate amountOutMin with slippage tolerance
  const estimatedOut = amountsOut[1]; // The second element is the output amount

  // Apply slippage tolerance
  const slippageAmount = estimatedOut.mul(slippageTolerance).div(10000);
  const amountOutMin = estimatedOut.sub(slippageAmount);

  return amountOutMin;
};
export const _priceChangeStyling = ({ pair }) => {
  //destructure thr pair

  if (parseFloat(pair?.open24h) > parseFloat(pair?.close24h)) {
    return "#ff445d";
  }

  if (parseFloat(pair?.open24h) < parseFloat(pair?.close24h)) {
    return "#12b66f";
  }
  return "#fff";
};

export const _symbolChecker = ({ pair }) => {
  if (parseFloat(pair?.open24h) > parseFloat(pair?.close24h)) {
    return "";
  }

  if (parseFloat(pair?.open24h) < parseFloat(pair?.close24h)) {
    return "+";
  }
  return "";
};

export const _highestSellOrder = ({ orders = [], ticker }) => {
  const sellOrders = orders
    .filter(
      (order) =>
        order.orderType === "SELL" &&
        order.state === "OPEN" &&
        order.ticker === ticker
    )
    .sort((a, b) => parseFloat(a.amount) - parseFloat(b.amount));
  if (sellOrders.length === 0)
    return {
      price: null,
    };

  const highestSellOrder = sellOrders.reduce((maxOrder, currentOrder) => {
    return parseFloat(currentOrder.amount) < parseFloat(maxOrder.amount)
      ? currentOrder
      : maxOrder;
  }, sellOrders[0]);
  if (highestSellOrder === undefined)
    return {
      price: null,
    };

  return highestSellOrder;
};
export const _lowestBuyOrder = ({ orders = [], ticker }) => {
  const buyOrders = orders.filter(
    (order) =>
      order.orderType === "BUY" &&
      order.state === "OPEN" &&
      order.ticker === ticker
  );
  if (buyOrders.length === 0) {
    return {
      price: null,
    };
  }
  const lowBuy = buyOrders.reduce((max, order) => {
    return parseFloat(order.amount) > parseFloat(max.amount) ? order : max;
  });

  if (lowBuy === undefined)
    return {
      price: null,
    };

  return lowBuy;
};

export const _buyManager = ({ market, ticker, orders }) => {
  if (market === "BUY") {
    return _highestSellOrder({ orders, ticker });
  }
  if (market === "SELL") {
    return _lowestBuyOrder({ orders, ticker });
  }
};
export const _highestBuyOrder = ({ orders = [], ticker }) => {
  const buyOrders = orders.filter(
    (order) =>
      order.orderType === "BUY" &&
      order.state === "OPEN" &&
      order.ticker === ticker
  );
  const highestBuyOrder = buyOrders.reduce((maxOrder, currentOrder) => {
    return parseFloat(currentOrder.amount) < parseFloat(maxOrder.amount)
      ? currentOrder
      : maxOrder;
  }, buyOrders[0]);
  if (highestBuyOrder === undefined) return 0;

  return highestBuyOrder;
};
export const _all_prices = ({
  orders = [],
  ticker,
  marketType,
  targetAmount = 0,
}) => {
  let accumulatedAmount = 0.0;
  const resultArr = [];

  if (marketType === "BUY") {
    const sortedArray = orders
      .filter(
        (order) =>
          order.orderType === "BUY" &&
          order.state === "OPEN" &&
          order.ticker === ticker
      )
      .sort((a, b) => parseFloat(b.amount) - parseFloat(a.amount));
    for (const order of sortedArray) {
      if (accumulatedAmount >= targetAmount) break;

      resultArr.push(order);
      accumulatedAmount += parseFloat(order?.numberOfShares);
    }

    if (resultArr.length == 0) return [];

    return resultArr?.map((data) => {
      return parseEther(data?.amount);
    });
  }
  if (marketType === "SELL") {
    // alert("somomo");

    const sortedArray = orders
      .filter(
        (order) =>
          order.orderType === "SELL" &&
          order.state === "OPEN" &&
          order.ticker === ticker
      )
      .sort((a, b) => parseFloat(a.amount) - parseFloat(b.amount));

    for (const order of sortedArray) {
      if (accumulatedAmount >= targetAmount) break;

      resultArr.push(order);
      accumulatedAmount += order?.numberOfShares;
    }

    if (resultArr.length == 0) return [];

    return resultArr?.map((data) => {
      return ethers.utils.parseEther(data?.amount, "wei");
    });
  }
};
export const _all_amount = ({
  orders = [],
  ticker,
  marketType,
  targetAmount = 0,
}) => {
  let accumulatedAmount = 0.0;
  const resultArr = [];

  if (marketType === "BUY") {
    const sortedArray = orders
      .filter(
        (order) =>
          order.orderType === "BUY" &&
          order.state === "OPEN" &&
          order.ticker === ticker
      )
      .sort((a, b) => parseFloat(b.amount) - parseFloat(a.amount));
    for (const order of sortedArray) {
      if (accumulatedAmount >= targetAmount) break;

      resultArr.push(order);
      accumulatedAmount += parseFloat(order?.numberOfShares);
    }

    if (resultArr.length == 0) return [];

    return resultArr?.map((data) => {
      return parseEther(data?.amount);
    });
  }
  if (marketType === "SELL") {
    let newOrder = orders
      .filter(
        (data) =>
          data.ticker === ticker &&
          data.status === "OPEN" &&
          data.type === "SELL"
      )
      .sort((a, b) => parseFloat(a.amount) - parseFloat(b.amount));

    let budget = targetAmount; // EGOD
    let totalShares = 0; // To track how many shares you can afford

    // newOrder.forEach((order) => {
    for (const order of newOrder) {
      const pricePerShare =
        parseFloat(order.amount) *
        (parseFloat(order.amount) - parseFloat(order?.filled));

      const remainingShares =
        parseFloat(order.amount) - parseFloat(order.filled);

      if (budget === 0) {
        break;
      }
      if (budget >= pricePerShare) {
        // Buy all remaining shares in this order
        totalShares += remainingShares;
        budget -= pricePerShare;
      } else {
        // Buy as many shares as budget allows
        const affordableShares = budget / parseFloat(order.amount);
        totalShares += affordableShares;
        budget = 0; // Budget is exhausted
      }
    }

    //console.log(totalShares, "lokoko");
    return ethers.utils.parseEther(parseFloat(totalShares)?.toFixed(4), "wei");
  }
};
export const _all_prices2 = ({
  orders = [],
  ticker,
  marketType,
  targetAmount = 0,
  threshold = 0,
}) => {
  let accumulatedAmount = 0.0;
  const resultArr = [];

  if (marketType === "BUY") {
    const sortedArray = orders
      .filter(
        (order) =>
          order.orderType === "BUY" &&
          order.state === "OPEN" &&
          order.ticker === ticker &&
          parseFloat(order.amount) >= threshold
        // &&
        // order.amount >= threshold
      )
      .sort((a, b) => parseFloat(b.amount) - parseFloat(a.amount));
    for (const order of sortedArray) {
      if (accumulatedAmount >= targetAmount) break;

      resultArr.push(order);
      accumulatedAmount += parseFloat(order?.numberOfShares);
    }

    if (resultArr.length == 0) return [];

    return resultArr?.map((data) => {
      return parseEther(data?.amount);
    });
  }
  if (marketType === "SELL") {
    // alert("somomo");

    const sortedArray = orders
      .filter(
        (order) =>
          order.orderType === "SELL" &&
          order.state === "OPEN" &&
          order.ticker === ticker &&
          parseFloat(order.amount) <= threshold
      )
      .sort((a, b) => parseFloat(a.amount) - parseFloat(b.amount));

    for (const order of sortedArray) {
      if (accumulatedAmount >= targetAmount) break;

      resultArr.push(order);
      accumulatedAmount += order?.numberOfShares;
    }

    if (resultArr.length == 0) return [];

    return resultArr?.map((data) => {
      return parseEther(data?.amount);
    });
  }
};

export const uuidFromUuidV4 = () => {
  const newUuid = uuid();

  return newUuid;
};

export const extractErrorMessage = (error) => {
  console.error("Transaction Error:", error);

  let errorMessage = "An unknown error occurred";

  if (error.reason) {
    errorMessage = error.reason;
  } else if (error.error && error.error.message) {
    errorMessage = error.error.message;
  } else if (error.body) {
    try {
      const errorBody = JSON.parse(error.body);
      if (errorBody.error && errorBody.error.message) {
        errorMessage = errorBody.error.message;
      }
    } catch (parseError) {
      console.error("Failed to parse error body:", parseError);
    }
  }

  return errorMessage;
};

// Helper function to clean RPC errors
