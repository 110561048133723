import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  transactions: [],
  block: null,
};

const WallTransSlice = createSlice({
  name: "wallTrans",
  initialState,
  reducers: {
    allTransactions: (state, action) => {
      state.transactions = action.payload;
    },

    pushTransaction: (state, action) => {
      state.transactions.unshift(action.payload);
    },
    setNewBlock: (state, action) => {
      state.block = action.payload;
    },
  },
});

export const { allTransactions, pushTransaction, setNewBlock } =
  WallTransSlice.actions;
export default WallTransSlice.reducer;
