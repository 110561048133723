import { ethers } from "ethers";
import dhiveFactoryAbi from "../abis/dhiveFactoryAbi.json";
import pairAbi from "../pairAbi.json"; // Pair contract ABI
import multicallAbi from "../abis/multicallAbi.json"; // Multicall3 ABI

const provider = new ethers.providers.JsonRpcProvider(
  "https://mainnet.egochain.org"
);
const providerBase = new ethers.providers.JsonRpcProvider(
  "https://base-rpc.publicnode.com"
);

const factoryAddress = "0x4dd41600986a45197d78effd04e16e700dd5b840";
const factoryAddressBase = "0x04C9f118d21e8B767D2e50C946f0cC9F6C367300";
const tokenB = "0x7e38B6d9c6074F259F213BBA2Bbf6B74d96fcE6A"; // EGAX Token Address
const tokenBBase = "0x6Db1e1b97e6727D93FdfEbE68CB9Dab094fA8e52"; // BASE Token Address
const multicallAddress = "0x03Bbcb153cb5D9fD420214bEe7cdEaFC4f51F54a"; // Multicall3 address EGOCHAIN
const multicallAddressBase = "0xf679dC562B5262248f82aEbb5Ca8BA108F4b90C9"; // Multicall3 address BASE

const erc20Abi = [
  {
    constant: true,
    inputs: [{ internalType: "address", name: "owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
];

export const liquidityDetail = async (
  tokenAddress,
  walletAddress,
  baseToken
) => {
  console.log(baseToken, "baseToken");

  try {
    const multicallContract = new ethers.Contract(
      baseToken == "EGAX" ? multicallAddress : multicallAddressBase,
      multicallAbi,
      baseToken == "EGAX" ? provider : providerBase
    );
    const factoryContract = new ethers.Contract(
      baseToken == "EGAX" ? factoryAddress : factoryAddressBase,
      dhiveFactoryAbi,
      baseToken == "EGAX" ? provider : providerBase
    );

    // Get LP Pair Address
    const pairAddress = await factoryContract.getPair(
      tokenAddress,
      baseToken == "EGAX" ? tokenB : tokenBBase
    );
    if (!pairAddress || pairAddress === ethers.constants.AddressZero) {
      throw new Error("Pair not found");
    }

    // Initialize contract interfaces
    const pairInterface = new ethers.utils.Interface(pairAbi);
    const erc20Interface = new ethers.utils.Interface(erc20Abi);

    // Prepare multicall data for batch requests
    const calls = [
      {
        target: pairAddress,
        callData: pairInterface.encodeFunctionData("getReserves"),
      },
      {
        target: pairAddress,
        callData: pairInterface.encodeFunctionData("token0"),
      },
      {
        target: pairAddress,
        callData: pairInterface.encodeFunctionData("token1"),
      },
      {
        target: pairAddress,
        callData: pairInterface.encodeFunctionData("totalSupply"),
      },
      {
        target: tokenAddress,
        callData: erc20Interface.encodeFunctionData("totalSupply"),
      },
    ];

    if (walletAddress) {
      calls.push({
        target: pairAddress,
        callData: erc20Interface.encodeFunctionData("balanceOf", [
          walletAddress,
        ]),
      });
    }

    // Execute batch call
    const response = await multicallContract.callStatic.aggregate(calls);

    // Decode responses
    const decodedReserves = pairInterface.decodeFunctionResult(
      "getReserves",
      response.returnData[0]
    );
    const token0 = pairInterface.decodeFunctionResult(
      "token0",
      response.returnData[1]
    )[0];
    const token1 = pairInterface.decodeFunctionResult(
      "token1",
      response.returnData[2]
    )[0];
    const lpTotalSupply = pairInterface.decodeFunctionResult(
      "totalSupply",
      response.returnData[3]
    )[0];
    const tokenTotalSupply = erc20Interface.decodeFunctionResult(
      "totalSupply",
      response.returnData[4]
    )[0];
    const userLPBalance = walletAddress
      ? erc20Interface.decodeFunctionResult(
          "balanceOf",
          response.returnData[5]
        )[0]
      : ethers.BigNumber.from(0);

    // Convert values to readable format
    const reserve0 = Number(ethers.utils.formatUnits(decodedReserves[0], 18));
    const reserve1 = Number(ethers.utils.formatUnits(decodedReserves[1], 18));
    const lpTotalSupplyFormatted = Number(
      ethers.utils.formatUnits(lpTotalSupply, 18)
    );
    const tokenTotalSupplyFormatted = Number(
      ethers.utils.formatUnits(tokenTotalSupply, 18)
    );
    const userLPBalanceFormatted = Number(
      ethers.utils.formatUnits(userLPBalance, 18)
    );

    // Determine token reserves and token price in EGAX
    let tokenReserve, egaxReserve;
    if (token0.toLowerCase() === tokenAddress.toLowerCase()) {
      tokenReserve = reserve0;
      egaxReserve = reserve1;
    } else {
      tokenReserve = reserve1;
      egaxReserve = reserve0;
    }

    const tokenPriceInEgax = tokenReserve > 0 ? egaxReserve / tokenReserve : 0;
    const marketCapInEgax = tokenTotalSupplyFormatted * tokenPriceInEgax;

    // Calculate TVL (Total Value Locked)
    const tvlInEgax = egaxReserve * 2; // Both tokens contribute equally in value

    // Calculate user share
    const shareOfPool =
      lpTotalSupplyFormatted > 0
        ? (userLPBalanceFormatted / lpTotalSupplyFormatted) * 100
        : 0;
    const userShareToken =
      (userLPBalanceFormatted / lpTotalSupplyFormatted) * tokenReserve || 0;
    const userShareEgax =
      (userLPBalanceFormatted / lpTotalSupplyFormatted) * egaxReserve || 0;

    return {
      loading: false,
      pairAddress,
      userLPBalance: userLPBalanceFormatted,
      userShareToken: userShareToken.toFixed(6),
      userShareEgax: userShareEgax.toFixed(6),
      totalEgaxPoolVal: egaxReserve,
      totalTokenPoolVal: tokenReserve,
      shareOfPool: shareOfPool.toFixed(4) + "%",
      lpProvided: userShareToken > 0 || userShareEgax > 0,
      tokenPriceInEgax: tokenPriceInEgax.toFixed(6),
      marketCapInEgax: marketCapInEgax.toFixed(6),
      tvlInEgax: tvlInEgax.toFixed(6), // 🚀 Added TVL calculation here!
    };
  } catch (error) {
    console.error("Error fetching liquidity details:", error);
    return { loading: false, error: error.message };
  }
};
