import { http, createConfig } from "@wagmi/core";
import { mainnet, sepolia } from "@wagmi/core/chains";

const metadata = {
  name: "EgochainFarming",
  description: "EgochainFarming",
  url: "https://www.fronttest.egomart.org", // origin must match your domain & subdomain
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};
const egochain = {
  id: 5439,
  name: "Egochain",
  network: "egochain",
  nativeCurrency: {
    name: "Egochain",
    symbol: "EGAX",
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ["https://mainnet.egochain.org"],
    },
  },
  blockExplorers: {
    default: {
      name: "EgoScan",
      url: "https://egoscan.io/",
    },
  },
  iconUrls: ["https://www.egochain.org/img/egax_logo.png"], // Replace with actual icon URL
};
export const config = createConfig({
  chains: [egochain],
  transports: {
    [egochain.id]: http(),
  },
});
