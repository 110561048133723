import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GET_BUSINESS_WITH_PRODUCT } from "../../../services/market";

// Async thunk to fetch trending businesses
export const fetchTrendingBusinesses = createAsyncThunk(
  "trendingBusinesses/fetchTrendingBusinesses",
  async (_, { rejectWithValue }) => {
    try {
      const res = await GET_BUSINESS_WITH_PRODUCT();
      return res?.newBusiness || [];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  trending: [],
  loading: false,
  error: null,
};

const trendingBusinessSlice = createSlice({
  name: "trendingBusinesses",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrendingBusinesses.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTrendingBusinesses.fulfilled, (state, action) => {
        state.loading = false;
        state.trending = action.payload;
      })
      .addCase(fetchTrendingBusinesses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default trendingBusinessSlice.reducer;
