// SocketContext.js
import React, { useMemo, useContext, createContext } from "react";
import { io } from "socket.io-client";
import { BASE_URL } from "../../services/routes";

const SocketContext = createContext(null);

export const SocketProvider = ({ children }) => {
  // Initialize the socket once
  const socket = useMemo(() => {
    const instance = io(BASE_URL); // Replace with your server URL
    // const instance = io(`ws://${BASE_URL.split("//")[1]}`); // Replace with your server URL
    instance.on("connect", () => {
      // console.log("Socket connected:", instance.id);
    });
    instance.on("disconnect", () => {
      // console.log("Socket disconnected");
    });
    return instance;
  }, []);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export const useSocket = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error("useSocket must be used within a SocketProvider");
  }
  return context;
};
