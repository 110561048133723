import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  wallet_address: null,
  mnemonic: null,
  privateKey: null,
  password: null,
  business_created: false,
  business_info: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    setWalletDetails: (state, action) => {
      (state.wallet_address = action.payload.wallet),
        (state.privateKey = action.payload.privateKey),
        (state.mnemonic = action.payload.mnemonic);
    },

    updateBusinessState: (state) => {
      state.business_created = true;
    },
    resetBusiness: (state) => {
      state.business_created = false;
    },

    setBusinessInfo: (state, action) => {
      state.business_info = action.payload;
    },
  },
});

export const {
  setPassword,
  setWalletDetails,
  updateBusinessState,
  resetBusiness,
  setBusinessInfo,
} = userSlice.actions;
export default userSlice.reducer;
