import React, { useState, useEffect, useCallback } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { ProductSlideThumb } from "./ProductSlideThumb";
import "./productDetailSlider.css";

const ProductSlide = ({ images }) => {
  const options = {};
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [emblaMainRef, emblaMainApi] = useEmblaCarousel(options);
  const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel({
    containScroll: "keepSnaps",
    dragFree: true,
  });

  const onThumbClick = useCallback(
    (index) => {
      if (!emblaMainApi) return;
      emblaMainApi.scrollTo(index);
    },
    [emblaMainApi]
  );

  const onSelect = useCallback(() => {
    if (!emblaMainApi) return;
    setSelectedIndex(emblaMainApi.selectedScrollSnap());
    emblaThumbsApi?.scrollTo(emblaMainApi.selectedScrollSnap());
  }, [emblaMainApi, emblaThumbsApi]);

  useEffect(() => {
    if (!emblaMainApi) return;
    onSelect();
    emblaMainApi.on("select", onSelect).on("reInit", onSelect);
  }, [emblaMainApi, onSelect]);

  const scrollPrev = () => emblaMainApi && emblaMainApi.scrollPrev();
  const scrollNext = () => emblaMainApi && emblaMainApi.scrollNext();

  return (
    <div className="embla">
      {/* Main Slider */}
      <div className="embla__viewport" ref={emblaMainRef}>
        <div className="embla__container">
          {images.map((image, index) => (
            <div className="embla__slide" key={index}>
              <img
                className="embla__slide__img"
                src={image}
                alt={`Slide ${index}`}
              />
            </div>
          ))}
        </div>
        {/* Navigation Buttons */}
        <button className="embla__prev" onClick={scrollPrev}>
          ❮
        </button>
        <button className="embla__next" onClick={scrollNext}>
          ❯
        </button>
      </div>

      {/* Thumbnail Slider */}
      <div className="embla-thumbs">
        <div className="embla-thumbs__viewport" ref={emblaThumbsRef}>
          <div className="embla-thumbs__container">
            {images.map((image, index) => (
              <ProductSlideThumb
                key={index}
                onClick={() => onThumbClick(index)}
                selected={index === selectedIndex}
                image={image} // Pass image instead of index
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductSlide;
