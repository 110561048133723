import React, { useEffect, useState } from "react";
import "./index.css";
import Blockies from "react-blockies";
import { useDispatch, useSelector } from "react-redux";
import OrderReceipt from "./OrderReceipt";
import { GET_USER_ORDERS } from "../../../../services/product";
import ReactTimeAgo from "react-time-ago";
import Nodata from "../../../../Components/Nodata/Nodata";
import ClipLoader from "react-spinners/ClipLoader";

const ExchangeOrder = () => {
  const { wallet_address } = useSelector((state) => state.user);
  const [receipt, setReceipt] = useState(null);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);

  const toggleReceipt = (data) => {
    setReceipt(data);
  };

  const fetchOrders = async () => {
    setLoading(true);
    const res = await GET_USER_ORDERS();
    //console.log("====================================");
    console.log(res);
    //console.log("====================================");
    if (res?.success) {
      setOrders(res?.userOrders);
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <div className="ExchangeOrder_div">
      <div className="container" style={{ maxWidth: "1250px" }}>
        <div className="ExchangeOrder_div_area">
          <div className="rwaBusinessDiv_area_div1_area1">
            <div className="rwaBusinessDiv_area_div1_cont1">
              Account{" "}
              <div className="rwaBusinessDiv_area_div1_cont1_area">
                <Blockies
                  seed={wallet_address ? wallet_address : "0xXXXX"}
                  size={8}
                  scale={4}
                  className="blockies_icon"
                />{" "}
                {wallet_address ? (
                  <>
                    {" "}
                    {`${wallet_address?.slice(0, 4)}...${wallet_address?.slice(
                      37,
                      42
                    )}`}
                  </>
                ) : (
                  <>0xXXXX</>
                )}
              </div>
            </div>
            <div className="ExchangeOrder_div_area_cont_area2">
              <div className="ExchangeOrder_div_area_cont_area2_title">
                My Orders
              </div>
              <div className="ExchangeOrder_div_area_cont_area2_value">
                {orders?.length || 0}
              </div>
            </div>
          </div>
          <div className="ExchangeOrder_div_area2">
            <div className="ExchangeOrder_div_area2_title">
              My Orders{" "}
              <div className="ExchangeOrder_div_area2_title_filter_div"></div>
            </div>
            <div className="ExchangeOrder_div_area2_body">
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <ClipLoader color="#6059fd" size={42} />
                </div>
              ) : (
                <>
                  {orders?.length === 0 ? (
                    <Nodata message={"No order found"} />
                  ) : (
                    <table className="TableCompWithDiv_cont_table">
                      <thead className="TableCompWithDiv_cont_head">
                        <tr className="TableCompWithDiv_cont_head_titles">
                          <th className="TableCompWithDiv_cont_head_titles_div TableCompWithDiv_cont_head_titles_div_first">
                            Product
                          </th>
                          <th className="TableCompWithDiv_cont_head_titles_div">
                            Price
                          </th>
                          <th className="TableCompWithDiv_cont_head_titles_div">
                            Quantity
                          </th>
                          <th className="TableCompWithDiv_cont_head_titles_div">
                            Total
                          </th>
                          <th className="TableCompWithDiv_cont_head_titles_div">
                            Status
                          </th>
                          <th className="TableCompWithDiv_cont_head_titles_div">
                            Time
                          </th>
                          <th className="TableCompWithDiv_cont_head_titles_div ">
                            Order Id
                          </th>
                          <th className="TableCompWithDiv_cont_head_titles_div  TableCompWithDiv_cont_head_titles_div_last"></th>
                        </tr>
                      </thead>
                      <tbody className="tableBody">
                        {orders?.map((data) => (
                          <tr className="tableBody_row ">
                            <td className="tableBody_row_data tableBody_row_data_first  ">
                              <div className="tableBody_row_data_product_div">
                                <img
                                  src={data.Get_product_image}
                                  alt=""
                                  className="tableBody_row_data_product_div_img"
                                />
                                <div className="tableBody_row_data_product_div_cont">
                                  <div className="tableBody_row_data_product_div_cont_1">
                                    {data?.item_name}
                                  </div>
                                  <div className="tableBody_row_data_product_div_cont_2">
                                    <span className="tableBody_row_data_product_div_cont_2_span">
                                      {" "}
                                      Business:
                                    </span>{" "}
                                    _ _
                                  </div>
                                </div>
                              </div>
                            </td>

                            <td className="tableBody_row_data">
                              <div className="tableBody_row_data_product_price">
                                <div className="tableBody_row_data_product_price_div1">
                                  {parseFloat(data?.amount)?.toFixed(2)} USDC
                                </div>
                                <span className="tableBody_row_data_product_price_span">
                                  =$ {parseFloat(data?.amount)?.toFixed(2)}
                                </span>
                              </div>
                            </td>
                            <td className="tableBody_row_data">{data?.qty}</td>
                            <td className="tableBody_row_data">
                              {" "}
                              <div className="tableBody_row_data_product_price">
                                <div className="tableBody_row_data_product_price_div1">
                                  {parseFloat(
                                    parseFloat(data?.qty) *
                                      parseFloat(data?.amount)
                                  )?.toFixed(2)}{" "}
                                  USDC
                                </div>
                                <span className="tableBody_row_data_product_price_span">
                                  =${" "}
                                  {parseFloat(
                                    parseFloat(data?.qty) *
                                      parseFloat(data?.amount)
                                  )?.toFixed(2)}
                                </span>
                              </div>
                            </td>
                            <td className="tableBody_row_data">
                              <div
                                className={
                                  data?.status === "INIT"
                                    ? "tableBody_row_data_product_Status"
                                    : "tableBody_row_data_product_Status_delivered"
                                }
                              >
                                {data?.status === "INIT"
                                  ? "Processing"
                                  : data?.status}{" "}
                              </div>
                            </td>
                            <td className="tableBody_row_data">
                              {" "}
                              <ReactTimeAgo
                                date={data?.createdAt}
                                locale="en-US"
                              />
                            </td>
                            <td className="tableBody_row_data ">
                              {data?.order_id}
                            </td>
                            <td className="tableBody_row_data tableBody_row_data_last">
                              <button
                                className="viewReceiptButton"
                                onClick={() => {
                                  toggleReceipt(data);
                                }}
                              >
                                View Receipt
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {receipt && (
        <OrderReceipt back={() => setReceipt(null)} payload={receipt} />
      )}
    </div>
  );
};

export default ExchangeOrder;
