import { ethers } from "ethers";
import { parseEther } from "ethers/lib/utils";
import { store } from "../Redux/app/store";
const sendTransactionWithPrivateKey = async ({
  privateKey,
  to,
  amount,
  rpcUrl,
}) => {
  try {
    // Connect to the blockchain using a provider
    const state = store.getState();

    // const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
    const provider = new ethers.providers.JsonRpcProvider(
      store.getState().assets.activeProvider.rpc
    );
    // Create a signer (wallet) using the private key
    const wallet = new ethers.Wallet(privateKey, provider);
    // Create and send the transaction
    const tx = await wallet.sendTransaction({
      to: to,
      value: parseEther(amount.toString()),
    });
    //console.log("Transaction Sent:", tx.hash);
    // Wait for confirmation
    await tx.wait();
    //console.log("Transaction Confirmed:", tx.hash);
    return {
      hash: tx.hash,
      success: true,
    };
  } catch (error) {
    console.error("Transaction Failed:", error);
    return { success: false, error: error.message };
  }
};

export default sendTransactionWithPrivateKey;
