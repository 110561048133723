import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GET_ALL_PRODUCT_BY_CATEGORY } from "../../../services/market";

const categories = [
  "Electronics",
  "Furniture",
  "Mobile Phones & Tablets",
  "Machinery",
  "Gadgets",
  "Real Estate",
  "Agriculture and Food",
  "Fashion",
  "Household Appliances",
  "Health & Beauty",
  "Automobile",
];

// Async thunk to fetch products by category, page, and limit
export const fetchProductsByCategory = createAsyncThunk(
  "products/fetchProductsByCategory",
  async ({ category, page, limit }, { getState, rejectWithValue }) => {
    try {
      const state = getState().products.categoryProducts;

      // Check if data already exists
      const key = `${category || "all"}_page${page}_limit${limit}`;
      if (state[key] && state[key].data.length) {
        return { key, data: state[key].data };
      }

      const fetchPromises = categories.map((cat) =>
        GET_ALL_PRODUCT_BY_CATEGORY(cat, page, limit).then((res) => ({
          category: cat,
          data: res?.data || [],
        }))
      );

      const results = await Promise.all(fetchPromises);

      // Convert results into an object with key-based storage
      return results.reduce((acc, { category, data }) => {
        const key = `${category}_page${page}_limit${limit}`;
        acc[key] = { success: true, data };
        return acc;
      }, {});
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  categoryProducts: {}, // Stores data based on page+limit
  loading: false,
  error: null,
};

const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductsByCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProductsByCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.categoryProducts = {
          ...state.categoryProducts,
          ...action.payload,
        };
      })
      .addCase(fetchProductsByCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default productSlice.reducer;
