import { liquidityDetail } from "./Utils/liquidtiyDetail";
import { decrypt } from "../../../../helper/encryption";
import { ethers } from "ethers";
import dhiveRouterAbi from "./dhiveRouterAbi.json";

const egaxUsdPrice = 0.45;

export const getAmountOutFn = async (
  amountIn,
  path,
  routerAddress,
  provider
) => {
  // const provider = new ethers.providers.JsonRpcProvider(
  //   "https://mainnet.egochain.org"
  // );
  // const decrypted = decrypt(privateKey);
  // const signer = new ethers.Wallet(decrypted, provider);

  const contract = new ethers.Contract(routerAddress, dhiveRouterAbi, provider);

  try {
    const res = await contract.callStatic.getAmountsOut(amountIn, path);
    // console.log(res, "getAmountOut");
    return res;
  } catch (error) {
    // console.log(error);
    return error;
  }
};
export const getPrice = async (tokenAddress, walletAddress, data) => {
  console.log(data);
  const BASE_TOKEN =
    data?.network === "EGOCHAIN"
      ? "EGAX"
      : data?.network === "BASE"
      ? "DHV"
      : "EGAX";
  try {
    const res = await liquidityDetail(tokenAddress, walletAddress, BASE_TOKEN);

    if (res.error) {
      throw new Error(res.error);
    }

    const { totalEgaxPoolVal, totalTokenPoolVal } = res;

    if (parseFloat(totalTokenPoolVal) === 0) {
      throw new Error("Insufficient liquidity");
    }

    // Calculate token price in Egax
    const tokenPriceInEgax =
      parseFloat(totalEgaxPoolVal) / parseFloat(totalTokenPoolVal);

    // Calculate token price in USD
    const tokenPriceInUsd =
      tokenPriceInEgax * (data?.tokenSymbol === "DHV" ? 0.1 : egaxUsdPrice);

    return { tokenPriceInEgax, tokenPriceInUsd };
  } catch (error) {
    console.error("Error fetching token price:", error);
    return { error: error.message };
  }
};
