import React, { useState, useEffect } from "react";
import "./index.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import ProductDisplayCarousel from "./ProductDisplay/ProductDisplayCarousel";
import {
  GET_ALL_PRODUCT_BY_CATEGORY,
  GET_BUSINESS_WITH_PRODUCT,
} from "../../services/market";
import CustomLoader from "../../Components/CustomLoader/CustomLoader";
import FormatNumber from "../../helper/formatNumber";
import { FETCH_PRODUCTS } from "../../services/product";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
// import { fetchProductsByCategory } from "../../Redux/feautures/products/productSlice";
import { fetchProductsByCategory } from "../../Redux/feautures/products/productSlice";

import { fetchTrendingBusinesses } from "../../Redux/feautures/products/trendingBusinessSlice";

const OPTIONS = { slidesToScroll: "auto" };

const categories = [
  "Electronics",
  "Furniture",
  "Mobile Phones & Tablets",
  "Machinery",
  "Gadgets",
  "Real Estate",
  "Agriculture and Food",
  "Fashion",
  "Household Appliances",
  "Health & Beauty",
  "Automobile",
];

const MarketPlace = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { categoryProducts, loading } = useSelector((state) => state.products);
  const { trending, loading: lajd } = useSelector(
    (state) => state.trendingBusinesses
  );
  const [welcomeModal, setWelcomeModal] = useState(false);
  useEffect(() => {
    if (!trending.length) {
      dispatch(fetchTrendingBusinesses());
    }
  }, [dispatch, trending.length]);

  const limit = 10;
  const page = 1;

  useEffect(() => {
    const key = `all_page${page}_limit${limit}`;

    if (!categoryProducts[key]) {
      dispatch(fetchProductsByCategory({ category: null, page, limit }));
    }
  }, [dispatch, categoryProducts]);

  // Check if there's any product data
  const hasData = Object.keys(categoryProducts).some(
    (key) => categoryProducts[key]?.data?.length > 0
  );

  useEffect(() => {
    const welcome = localStorage.getItem("welcomeMarket");

    if (welcome == "true") {
      setWelcomeModal(true);
      setTimeout(() => {
        localStorage.setItem("welcomeMarket", "false");
        setWelcomeModal(false);
      }, 2000);
    }
  }, []);

  return (
    <>
      {welcomeModal ? (
        <div className={`WelcomeModalDiv ${!welcomeModal ? "fade-out" : ""}`}>
          <div className="container">
            <div className="WelcomeModalDiv_area">
              <img
                src="/White Dhive.svg"
                alt=""
                className="WelcomeModalDiv_area_img"
              />
              <div className="WelcomeModalDiv_area_txt">Market</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="marketPlace">
          <div className="container">
            <div className="marketPlace_area">
              <div className="marketPlace_area_1">
                <Swiper
                  pagination={true}
                  loop={true}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay, Pagination]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    {" "}
                    <img
                      src="/img/marketBanner1.jpg"
                      alt=""
                      className="marketPlace_area_1_img"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <img
                      src="/img/marketBanner2.jpeg"
                      alt=""
                      className="marketPlace_area_1_img"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <img
                      src="/img/marketBanner3.jpeg"
                      alt=""
                      className="marketPlace_area_1_img"
                    />
                  </SwiperSlide>
                </Swiper>
              </div>
              {/* ==== */}
              {/* ==== */}
              {/* ==== */}
              {/* ==== */}
              <div className="marketPlace_area_2">
                <div className="marketPlace_area_2_title">
                  Trending Businesses
                </div>
                <div className="marketPlace_area_2_body">
                  <div className="marketPlace_area_2_area1">
                    {lajd ? (
                      <div
                        className="loadMoreBusinessDiv"
                        style={{
                          padding: "2em 10px",
                          height: "250px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CustomLoader
                          borderSize={"3px"}
                          height={"35px"}
                          width={"35px"}
                          containerHeight="max-content"
                        />
                        {/* <p className="loadMoreBusinessDiv_txt">Loading...</p> */}
                      </div>
                    ) : (
                      <table className="prTable">
                        <thead className="prTable_head">
                          <tr className="prTable_head_titles">
                            <th className="prTable_head_titles_data">Rank</th>
                            <th className="prTable_head_titles_data">
                              Business
                            </th>
                            <th className="prTable_head_titles_data">
                              Market Cap
                            </th>
                            <th className="prTable_head_titles_data">
                              Products
                            </th>
                          </tr>
                        </thead>
                        <tbody className="prTableBody">
                          {trending?.slice(0, 3)?.map((data, index) => {
                            return (
                              <tr
                                className="prTableRow"
                                onClick={() =>
                                  navigate(
                                    `/market/business/all/products/${data.businessName}`
                                  )
                                }
                              >
                                <td className="prTableRow_data ">
                                  {index + 1}
                                </td>
                                <td className="prTableRow_data ">
                                  <div className="tpBusDiv">
                                    <img
                                      src={data.image}
                                      alt=""
                                      className="tpBusDiv_img"
                                    />
                                    <div className="tpBusDiv_name_div">
                                      {data.businessName}
                                    </div>
                                  </div>
                                </td>
                                <td className="prTableRow_data ">
                                  $
                                  {FormatNumber(
                                    parseFloat(data.marketCap) * 0.45
                                  )}
                                </td>
                                <td className="prTableRow_data ">
                                  {data.productCount}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                  </div>
                  <div className="marketPlace_area_2_area2">
                    {lajd ? (
                      <div
                        className="loadMoreBusinessDiv"
                        style={{
                          padding: "2em 10px",
                          height: "250px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CustomLoader
                          borderSize={"3px"}
                          height={"35px"}
                          width={"35px"}
                          containerHeight="max-content"
                        />
                        {/* <p className="loadMoreBusinessDiv_txt">Loading...</p> */}
                      </div>
                    ) : (
                      <table className="prTable">
                        <thead className="prTable_head">
                          <tr className="prTable_head_titles">
                            <th className="prTable_head_titles_data">Rank</th>
                            <th className="prTable_head_titles_data">
                              Business
                            </th>
                            <th className="prTable_head_titles_data">
                              Market Cap
                            </th>
                            <th className="prTable_head_titles_data">
                              Products
                            </th>
                          </tr>
                        </thead>
                        <tbody className="prTableBody">
                          {trending?.slice(4, 7)?.map((data, index) => {
                            return (
                              <tr
                                className="prTableRow"
                                onClick={() =>
                                  navigate(
                                    `/market/business/all/products/${data.businessName}`
                                  )
                                }
                              >
                                <td className="prTableRow_data ">
                                  {index + 4}
                                </td>
                                <td className="prTableRow_data ">
                                  <div className="tpBusDiv">
                                    <img
                                      src={data.image}
                                      alt=""
                                      className="tpBusDiv_img"
                                    />
                                    <div className="tpBusDiv_name_div">
                                      {data.businessName}
                                    </div>
                                  </div>
                                </td>
                                <td className="prTableRow_data ">
                                  $
                                  {FormatNumber(
                                    parseFloat(data.marketCap) * 0.45
                                  )}
                                </td>
                                <td className="prTableRow_data ">
                                  {data.productCount}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
              {/* ==== */}
              {/* ==== */}
              {/* ==== */}
              {/* ==== */}
              {/* ==== */}
              <div className="marketPlace_area_3">
                {!hasData && loading ? (
                  <div
                    className="loadMoreBusinessDiv"
                    style={{ padding: "2em 10px" }}
                  >
                    <CustomLoader
                      borderSize={"3px"}
                      height={"35px"}
                      width={"35px"}
                    />
                    <p className="loadMoreBusinessDiv_txt">
                      Loading products...
                    </p>
                  </div>
                ) : (
                  categories?.map((category) => {
                    const key = `${category}_page${page}_limit${limit}`;
                    const products = categoryProducts[key]?.data || [];
                    return products?.length > 0 ? (
                      <div
                        id={`#${category}`}
                        key={category}
                        className="marketPlace_area_3_div1"
                      >
                        <ProductDisplayCarousel
                          slides={products}
                          options={OPTIONS}
                          title={category}
                          seeAll={`/market/category/all/products/${category}`}
                        />
                      </div>
                    ) : null;
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* === */}
      {/* === */}
      {/* === */}
    </>
  );
};

export default MarketPlace;
