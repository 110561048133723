// redux/store.js
import { configureStore } from "@reduxjs/toolkit";
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import createIndexedDBStorage from "redux-persist-indexeddb-storage";
import userReducer from "../feautures/user/userSlice";
import userBalanceReducer from "../feautures/user/userBalance";
import assetReducer from "../feautures/assets/assetSlice";
import businessReducer from "../feautures/businesses/businessSlice";
import TradeReducer from "../feautures/trades/tradeSlice";
import TransactionReducer from "../feautures/walletTransactions/wallTransSlice";
import OrderReducer from "../feautures/orders/OrderSlice";
import ProductReducer from "../feautures/products/productSlice";
import trendingBusinessReducer from "../feautures/products/trendingBusinessSlice";
import chartReducer from "../feautures/Chart/chartSlice";
import tradesDataReducer from "../feautures/trades/tradeDataSlice";
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

const DB_NAME = "fairlaunch";
const storage = createIndexedDBStorage(DB_NAME);

const persistConfig = (key) => ({
  key,
  storage,
});

const isProduction = import.meta.env.MODE == "production";

export const store = configureStore({
  reducer: {
    products: persistReducer(persistConfig("products"), ProductReducer),
    user: persistReducer(persistConfig("userInfo"), userReducer),
    userBalance: persistReducer(
      persistConfig("userBalance"),
      userBalanceReducer
    ),
    assets: persistReducer(persistConfig("assets"), assetReducer),
    businesses: persistReducer(persistConfig("businesses"), businessReducer),
    trendingBusinesses: persistReducer(
      persistConfig("trendingBusinesses"),
      trendingBusinessReducer
    ),
    trades: persistReducer(persistConfig("trades"), TradeReducer),
    orders: persistReducer(persistConfig("orders"), OrderReducer),
    wallTrans: persistReducer(
      persistConfig("wallTransactions"),
      TransactionReducer
    ),
    chart: persistReducer(persistConfig("chart"), chartReducer),
    tradesData: persistReducer(persistConfig("tradesData"), tradesDataReducer),
  },
  devTools: !isProduction && {
    maxAge: 10, // Reduce history from default 50 to 10
    actionsDenylist: [
      "tradesData/fetchTradesData/fulfilled", // Filter heavy actions
      "chart/updateData",
    ],
    serialize: {
      options: {
        circular: "ignore",
        limit: 50, // Limit serialized properties
      },
    },
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        ignoredPaths: [
          "chart.data", // Skip serialization checks for heavy data
          "tradesData.tradesData",
        ],
      },
      immutableCheck: false, // Disable for performance
    }),
});

if (isProduction && typeof window !== "undefined") {
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ = undefined;
}

export const persistor = persistStore(store);
