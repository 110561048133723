import React, { useEffect, useState } from "react";
import { AddCircleIcon, ArrowLeft01Icon } from "hugeicons-react";
import { decrypt } from "../../../../helper/encryption";
import { useDispatch, useSelector } from "react-redux";
import { ethers } from "ethers";
import dhiveRouterAbi from "./dhiveRouterAbi.json";
import ClipLoader from "react-spinners/ClipLoader";
import { getParsedEthersError } from "@enzoferey/ethers-error-parser";
import { checkApproval, setApproval } from "../../../../services/etherUtils";
import toast, { Toaster } from "react-hot-toast";
import WalletPop from "../../../../OnchainWallet/WalletPop/WalletPop";
import { Wallet01Icon } from "hugeicons-react";
import { Cancel01Icon } from "hugeicons-react";
import CustomLoader from "../../../../Components/CustomLoader/CustomLoader";
import { extractErrorMessage } from "../../BuySell/helper";
import {
  triggerBalanceChange,
  updateBalancesAfterTrade,
} from "../../../../Redux/feautures/assets/assetSlice";
import { numberWithCommas } from "../../../../assets/js/numberWithCommas";

const AddLiquidity = ({
  back,
  data,
  tokenPriceInUSD,
  tokenPriceInEgax,
  totalEgaxPoolVal,
  totalTokenPoolVal,
  fetchLiquid,
  baseToken,
}) => {
  const dispatch = useDispatch();
  const { privateKey, wallet_address } = useSelector((state) => state.user);
  const { activeProvider, balanceState: balancer } = useSelector(
    (state) => state.assets
  );
  const Assets = useSelector(
    (state) => state.assets.tokensByNetwork[activeProvider?.network] || []
  );
  const provider = new ethers.providers.JsonRpcProvider(
    "https://mainnet.egochain.org"
  );
  const providerBase = new ethers.providers.JsonRpcProvider(
    "https://base-rpc.publicnode.com"
  );
  const decrypted = decrypt(privateKey);
  const signer = new ethers.Wallet(
    decrypted,
    baseToken == "EGAX" ? provider : providerBase
  );
  const routerAddress = "0x1641aDe952320BEC5330B7e54D5A032FB5006de3";
  const routerAddressBase = "0xaaa3b1F1bd7BCc97fD1917c18ADE665C5D31F066";
  const contract = new ethers.Contract(
    baseToken == "EGAX" ? routerAddress : routerAddressBase,
    dhiveRouterAbi,
    signer
  );
  const paths = [
    "0x6Db1e1b97e6727D93FdfEbE68CB9Dab094fA8e52",
    data?.contractAddress,
  ];

  const [hasAllowance, setHasAllowance] = useState(false);
  const [liquidityAmount, setLiquidityAmount] = useState("");
  const [amountOut, setAmountOut] = useState(0);
  const [checkAllowanceLoading, setCheckAllowanceLoading] = useState(false);
  const [slippage, setSlippage] = useState(0.5);
  const [priceImpact, setPriceImpact] = useState(0);

  const [amountOutLoading, setAmountOutLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmBuyTransactionModal, setConfirmBuyTransactionModal] =
    useState(false);
  const [transactionType, setTransactionType] = useState(null);
  const [openWallet, setOpenWallet] = useState(false);

  // const checkAllowance = async () => {
  //   setCheckAllowanceLoading(true);
  //   const res = await checkApproval({
  //     tokenAddress: data?.contractAddress,
  //     owner: wallet_address,
  //     spender: routerAddress,
  //   });
  //   // console.log("====================================");
  //   // console.log(res);
  //   // console.log("====================================");
  //   setHasAllowance(parseFloat(res) > parseFloat(liquidityAmount));
  //   setCheckAllowanceLoading(false);
  // };
  console.log(baseToken);

  const checkAllowance = async () => {
    setCheckAllowanceLoading(true);
    let allApproved = true; // Assume all are approved
    if (baseToken == "DHV") {
      for (const tokenAddress of paths) {
        const response = await checkApproval({
          owner: wallet_address,
          spender: routerAddressBase,
          tokenAddress: tokenAddress,
          prov: providerBase,
        });

        console.log(`Allowance for ${data?.contractAddress}:`, response);

        if (parseFloat(response) == 0) {
          allApproved = false; // If any token has zero allowance, set to false
          setHasAllowance(false);
          setCheckAllowanceLoading(false);
          return;
        }
        setHasAllowance(true);
        setCheckAllowanceLoading(false);
      }
      return;
    }

    if (baseToken == "EGAX") {
      const response = await checkApproval({
        owner: wallet_address,
        spender: routerAddress,
        tokenAddress: data?.contractAddress,
        prov: provider,
      });

      console.log(`Allowance for ${data?.contractAddress}:`, response);

      if (parseFloat(response) == 0) {
        setHasAllowance(false);
        setCheckAllowanceLoading(false);
        return;
      }
      setHasAllowance(true);
      setCheckAllowanceLoading(false);
      return;
    }
  };

  useEffect(() => {
    checkAllowance();
  }, [liquidityAmount]);

  const setAllowance = async () => {
    setOpenWallet(false);
    setConfirmBuyTransactionModal(true);
    setLoading(true);
    try {
      if (baseToken == "DHV") {
        for (const tokenAddress of paths) {
          const tx = await setApproval({
            tokenAddress,
            spender: routerAddressBase,
            privateKey,
            prov: providerBase,
          });

          console.log(`Setting allowance for ${tokenAddress}:`, tx);
          const receipt = await tx.wait();
          console.log(`Transaction receipt for ${tokenAddress}:`, receipt);
          setLoading(false);

          toast.success(
            <div className="toast_success_div">
              <div className="toast_success_div_title">Allowance Success!</div>
              <div className="toast_success_div_para">
                {" "}
                Allowance enabled successfully
              </div>
            </div>,
            {
              duration: 5000,
              className: "toast_success",
            }
          );
          setHasAllowance(true);
          setOpenWallet(false);
          // console.log("Liquidity added successfully");
        }

        return;
      }

      if (baseToken == "EGAX") {
        const tx = await setApproval({
          tokenAddress: data?.contractAddress,
          spender: routerAddress,
          privateKey,
          prov: provider,
        });
        // console.log("phase1");
        const receipt = await tx.wait();
        // console.log("phase2");
        setLoading(false);

        toast.success(
          <div className="toast_success_div">
            <div className="toast_success_div_title">Allowance Success!</div>
            <div className="toast_success_div_para">
              {" "}
              Allowance enabled successfully
            </div>
          </div>,
          {
            duration: 5000,
            className: "toast_success",
          }
        );
        setHasAllowance(true);
        setOpenWallet(false);
        // console.log("Liquidity added successfully");
        return;
      }
    } catch (error) {
      setLoading(false);
      // console.log("Transaction Error:", error);
      // console.log("Error in Transaction: ", error.message);
      // console.log("Transaction Error:", extractErrorMessage(error));
      toast.error(
        <div className="toast_success_div">
          <div className="toast_error_div_title">Error!!</div>
          <div className="toast_success_div_para">
            {extractErrorMessage(error) ==
            "insufficient funds for intrinsic transaction cost"
              ? "insufficient funds for gas fee please top up"
              : extractErrorMessage(error)}{" "}
          </div>
        </div>,
        {
          duration: 5000,
          className: "toast_error",
        }
      );
    }
  };

  const addLiquidityFn = async () => {
    setOpenWallet(false);
    setConfirmBuyTransactionModal(true);
    setLoading(true);

    try {
      const tokenAddress = data?.contractAddress;

      // Compute desired token amount
      const calcAmountOut = parseFloat(liquidityAmount) * parseFloat(amountOut);
      const amountTokenDesired = ethers.utils.parseUnits(
        calcAmountOut.toString(),
        18
      );

      // Ensure reserves are correctly formatted
      const reservA = ethers.utils.parseUnits(totalTokenPoolVal.toString(), 18);
      const reservB = ethers.utils.parseUnits(totalEgaxPoolVal.toString(), 18);

      // Compute optimal ETH amount based on reserves
      const amountETHOptimal = amountTokenDesired.mul(reservB).div(reservA);
      const ethAmount = ethers.utils.parseUnits(liquidityAmount.toString(), 18);
      const finalEthAmount = amountETHOptimal.lte(ethAmount)
        ? amountETHOptimal
        : ethAmount;

      // Adjust slippage
      const adjustedSlippage = Math.max(slippage * 1.5, 0.5); // Increase slippage by 50% or set min 0.5%
      const slippageBN = ethers.BigNumber.from(10000).sub(
        Math.floor(adjustedSlippage * 100)
      );

      // Calculate minimum amounts for slippage protection
      const amountTokenMin = amountTokenDesired.mul(slippageBN).div(10000);
      const amountETHMin = ethAmount.mul(slippageBN).div(10000);

      // Set deadline (10 minutes from now)
      const deadline = Math.floor(Date.now() / 1000) + 60 * 10;

      const tokenB = "0x6db1e1b97e6727d93fdfebe68cb9dab094fa8e52";

      // Debugging logs
      // console.log("========== Liquidity Debug Info ==========");
      // console.log({
      //   tokenAddress,
      //   amountTokenDesired: amountTokenDesired.toString(),
      //   reservA: reservA.toString(),
      //   reservB: reservB.toString(),
      //   amountETHOptimal: amountETHOptimal.toString(),
      //   ethAmount: ethAmount.toString(),
      //   finalEthAmount: finalEthAmount.toString(),
      //   amountTokenMin: amountTokenMin.toString(),
      //   amountETHMin: amountETHMin.toString(),
      //   deadline,
      // });

      // Execute the liquidity transaction

      if (baseToken == "DHV") {
        const tx = await contract.addLiquidity(
          tokenAddress,
          tokenB,
          amountTokenDesired,
          ethAmount,
          amountTokenMin,
          amountETHMin,
          wallet_address,
          deadline,
          {
            gasLimit: 300000,
          }
        );
        console.log(tx, "rtx");

        const receipt = await tx.wait();
        dispatch(
          updateBalancesAfterTrade({
            networkName: `BASE NETWORK`,
            walletAddress: wallet_address,
            tokenAddresses: [paths[0], paths[1]], // Addresses of tokens involved in trade
          })
        );
        setLoading(false);
        setOpenWallet(false);
        back();
        fetchLiquid();
        // Success toast
        toast.success(
          <div className="toast_success_div">
            <div className="toast_success_div_title">Liquidity Success!</div>
            <div className="toast_success_div_para">
              Liquidity added successfully
            </div>
          </div>,
          { duration: 5000, className: "toast_success" }
        );

        setConfirmBuyTransactionModal(false);
        // console.log("Liquidity added successfully");

        return;
      }
      if (baseToken == "EGAX") {
        const tx = await contract.addLiquidityETH(
          tokenAddress,
          amountTokenDesired,
          amountTokenMin,
          amountETHMin,
          wallet_address,
          deadline,
          { value: ethAmount } // Pass `ethAmount` instead of `finalEthAmount`
        );

        const receipt = await tx.wait();
        dispatch(
          updateBalancesAfterTrade({
            networkName: `EGOCHAIN NETWORK`,
            walletAddress: wallet_address,
            tokenAddresses: [paths[1]], // Addresses of tokens involved in trade
          })
        );
        setLoading(false);
        setOpenWallet(false);
        back();
        fetchLiquid();
        // Success toast
        toast.success(
          <div className="toast_success_div">
            <div className="toast_success_div_title">Liquidity Success!</div>
            <div className="toast_success_div_para">
              Liquidity added successfully
            </div>
          </div>,
          { duration: 5000, className: "toast_success" }
        );

        setConfirmBuyTransactionModal(false);
        // console.log("Liquidity added successfully");

        return;
      }
    } catch (error) {
      setLoading(false);
      // console.log("Transaction Error:", error);
      const errorMessage = extractErrorMessage(error);

      // Handle error messages properly
      const displayMessage =
        errorMessage === "insufficient funds for intrinsic transaction cost"
          ? "Insufficient funds for gas fees, please top up."
          : errorMessage;

      toast.error(
        <div className="toast_success_div">
          <div className="toast_error_div_title">Error!!</div>
          <div className="toast_success_div_para">{displayMessage}</div>
        </div>,
        { duration: 5000, className: "toast_error" }
      );
    }
  };

  const onChangeAmount = (e) => {
    const value = e.target.value;
    setLiquidityAmount(value);
  };
  // console.log(totalTokenPoolVal, totalEgaxPoolVal);

  const getAmountOutFunc = () => {
    if (parseFloat(totalTokenPoolVal) > 0 && parseFloat(totalEgaxPoolVal) > 0) {
      // console.log("====================================");
      // console.log("there is amount");
      // console.log("====================================");
      // Convert string reserves to BigNumber with 18 decimals
      const totalTokenBN = ethers.utils.parseUnits(
        totalTokenPoolVal.toString(),
        18
      );
      const totalEgaxBN = ethers.utils.parseUnits(
        totalEgaxPoolVal.toString(),
        18
      );

      const inputAmount = ethers.utils.parseEther("1"); // 1 ETH input
      const amountOutRaw = inputAmount.mul(totalTokenBN).div(totalEgaxBN);

      // console.log("Reserves:", totalTokenBN.toString(), totalEgaxBN.toString());
      // console.log(
      //   "Computed amountOut:",
      //   ethers.utils.formatEther(amountOutRaw)
      // );

      setAmountOut(ethers.utils.formatEther(amountOutRaw));
    } else {
      setAmountOut("0"); // No liquidity available
      // console.log("there is no amount");
    }
  };

  useEffect(() => {
    getAmountOutFunc();
  }, [totalTokenPoolVal, totalEgaxPoolVal]);

  const initAddLiquidity = () => {
    setConfirmBuyTransactionModal(true);
  };

  console.log(data);
  console.log(baseToken);

  const baseBalance =
    data?.network === "BASE"
      ? balancer.byNetwork[`${data?.network} NETWORK`]?.tokens[
          "0x6Db1e1b97e6727D93FdfEbE68CB9Dab094fA8e52".toLowerCase()
        ]?.balance
      : balancer?.byNetwork[`${data?.network} NETWORK`]?.native?.balance;

  const tokenBalance =
    balancer.byNetwork[`${data?.network} NETWORK`]?.tokens[
      data?.contractAddress.toLowerCase()
    ]?.balance;

  return (
    <div className="LiquidityDiv_cont_cont2">
      <div className="LiquidityDiv_cont_cont2_back_div" onClick={back}>
        <ArrowLeft01Icon /> Back
      </div>
      <div className="LiquidityDiv_cont_cont2_title">
        Deposit Tokens
        <div className="LiquidityDiv_cont_cont2_para">
          Specify the token amounts for your liquidity contribution.
        </div>
      </div>
      <div className="LiquidityDiv_cont_cont2_areab">
        <div className="LiquidityDiv_cont_cont2_area_1">
          <div className="LiquidityDiv_cont_cont2_area_1_cont1">
            <input
              type="number"
              className="LiquidityDiv_cont_cont2_area_1_cont1_input"
              onChange={onChangeAmount}
              value={liquidityAmount}
            />
            <div className="LiquidityDiv_cont_cont2_area_1_cont1_span">
              ${parseFloat(liquidityAmount) * 0.45 || 0}
            </div>
          </div>
          <div className="LiquidityDiv_cont_cont2_area_1_cont2">
            <div className="LiquidityDiv_cont_cont2_area_1_cont2_div1">
              <div className="div_bal_div">
                <Wallet01Icon size={14} className="div_bal_div_icon" />

                {parseFloat(baseBalance).toFixed(4)}
              </div>
              <div className="LiquidityDiv_cont_cont2_area_1_cont2_div1_content">
                <img
                  src={
                    baseToken === "EGAX"
                      ? "/img/egax_logo.png"
                      : "/img/dhiveCoinIcon.png"
                  }
                  alt=""
                  className="LiquidityDiv_cont_cont2_area_1_cont2_div1_img"
                />{" "}
                {baseToken}
              </div>
            </div>
            <div className="LiquidityDiv_cont_cont2_area_1_cont2_div2">
              <button className="LiquidityDiv_cont_cont2_area_1_cont2_div2_max">
                Max
              </button>
            </div>
          </div>
        </div>

        <div className="LiquidityDiv_cont_cont2_area_1">
          <div className="LiquidityDiv_cont_cont2_area_1_cont1">
            <input
              type="number"
              className="LiquidityDiv_cont_cont2_area_1_cont1_input"
              value={parseFloat(liquidityAmount) * parseFloat(amountOut)}
              readOnly
            />
            <div className="LiquidityDiv_cont_cont2_area_1_cont1_span">_ _</div>
          </div>
          <div className="LiquidityDiv_cont_cont2_area_1_cont2">
            <div className="LiquidityDiv_cont_cont2_area_1_cont2_div1">
              <div className="div_bal_div">
                {" "}
                <Wallet01Icon size={14} className="div_bal_div_icon" />
                {parseFloat(tokenBalance).toFixed(4)}
              </div>
              <div className="LiquidityDiv_cont_cont2_area_1_cont2_div1_content">
                <img
                  src={data?.image || "/img/art.png"}
                  alt=""
                  className="LiquidityDiv_cont_cont2_area_1_cont2_div1_img"
                />{" "}
                {data?.tokenSymbol}
              </div>
            </div>
            <div className="LiquidityDiv_cont_cont2_area_1_cont2_div2">
              {/* <button className="LiquidityDiv_cont_cont2_area_1_cont2_div2_max">
                Max
              </button> */}
            </div>
          </div>
        </div>
      </div>
      <div className="pricesSpan">
        1 {baseToken} ={" "}
        {amountOutLoading ? (
          <>
            {" "}
            <CustomLoader
              width="15px"
              height="15px"
              padding="0px"
              borderSize="2px"
              containerWidth={"max-content"}
              margin={"0px 5px 0px 10px"}
              borderColor="#6059fd #c7c6d5 #c7c6d5"
            />
          </>
        ) : (
          <> {parseFloat(1) * parseFloat(amountOut) || 0} </>
        )}{" "}
        {data?.tokenSymbol}
      </div>
      {/* Slippage Selection */}
      <div className="slippage-container">
        <label className="slippage-container_label">Slippage Tolerance:</label>
        <select
          onChange={(e) => setSlippage(parseFloat(e.target.value))}
          value={slippage}
          className="slippage-container_select"
        >
          <option value="0.1">0.1%</option>
          <option value="0.5">0.5%</option>
          <option value="1">1%</option>
          <option value="2">2%</option>
          <option value="3">3%</option>
          <option value="5">5%</option>
        </select>
      </div>
      {/* Slippage Warnings */}
      {slippage > 2 && (
        <div className="high-slippage-warning">
          ⚠️ High slippage ({slippage}%) may cause large price changes!
        </div>
      )}
      {slippage < 0.5 && (
        <div className="low-slippage-warning">
          ⚠️ Low slippage ({slippage}%) might cause transaction failures.
        </div>
      )}
      <div style={{ width: "100%", marginTop: "0em" }}>
        {amountOutLoading ? (
          <>
            {" "}
            <button
              className="LiquidityDiv_cont_cont2_btn"
              disabled
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ClipLoader color="#ffffff" size={18} /> Fetching price...
            </button>
          </>
        ) : (
          <>
            {liquidityAmount <= 0 || liquidityAmount == "" ? (
              <div className="remove_liquidity_btn_divs">
                <button className="LiquidityDiv_cont_cont2_btn" disabled>
                  Input amount
                </button>
              </div>
            ) : (
              <>
                {parseFloat(baseBalance) < parseFloat(liquidityAmount) ? (
                  <button className="LiquidityDiv_cont_cont2_btn" disabled>
                    Insufficient {baseToken} balance
                  </button>
                ) : parseFloat(tokenBalance) <
                  parseFloat(
                    parseFloat(liquidityAmount) * parseFloat(amountOut)
                  ) ? (
                  <button className="LiquidityDiv_cont_cont2_btn" disabled>
                    Insufficient {data?.tokenSymbol} balance
                  </button>
                ) : (
                  <button
                    className="LiquidityDiv_cont_cont2_btn"
                    onClick={initAddLiquidity}
                  >
                    {loading ? (
                      <>
                        <ClipLoader color="#ffffff" size={18} /> Supplying...
                      </>
                    ) : (
                      " Supply"
                    )}
                  </button>
                )}
              </>
            )}
          </>
        )}
      </div>
      <Toaster />
      {confirmBuyTransactionModal && (
        <div className="confrimBuySellDiv">
          <div className="confrimBuySellDiv_container">
            <div className="confrimBuySellDiv_title">
              Confirm Add liquidity
              <Cancel01Icon
                size={20}
                className="confrimBuySellDiv_title_icon"
                onClick={() => {
                  setConfirmBuyTransactionModal(false);
                }}
              />
            </div>
            <div className="confrimBuySellDiv_body">
              <div className="confrimBuySellDiv_body1">
                <div className="confrimBuySellDiv_body1_cont1">
                  <div className="confrimBuySellDiv_body1_cont1_area1">
                    {parseFloat(liquidityAmount)?.toFixed(8)}
                  </div>
                  <div className="confrimBuySellDiv_body1_cont1_area2">
                    {baseToken}
                  </div>
                </div>
                <div className="confrimBuySellDiv_body1_cont1">
                  <div className="confrimBuySellDiv_body1_cont1_area1">
                    {parseFloat(
                      parseFloat(liquidityAmount) * parseFloat(amountOut)
                    )?.toFixed(8)}
                  </div>

                  <div className="confrimBuySellDiv_body1_cont1_area2">
                    {" "}
                    {data?.tokenSymbol}{" "}
                  </div>
                </div>
              </div>
              <div className="confrimBuySellDiv_body2">
                <div className="swap_details_div_body">
                  <div className="swap_details_div_body_cont1">
                    <div className="swap_details_div_body_cont1_div1">
                      Price
                    </div>
                    <div className="swap_details_div_body_cont1_div2">
                      {parseFloat(1) * parseFloat(amountOut) || 0}{" "}
                      {data?.tokenSymbol} / {baseToken}
                    </div>
                  </div>

                  <div className="swap_details_div_body_cont1">
                    <div className="swap_details_div_body_cont1_div1">
                      Slippage Tolerance
                    </div>
                    <div className="swap_details_div_body_cont1_div2">
                      0.50%
                    </div>
                  </div>
                  {/* <div className="swap_details_div_body_cont1">
                    <div className="swap_details_div_body_cont1_div1">
                      Trading fee
                    </div>
                    <div className="swap_details_div_body_cont1_div2">
                      0.30%
                    </div>
                  </div> */}
                </div>
              </div>
              {hasAllowance ? (
                <button
                  className="product_detail_cont_div5_7_btn"
                  onClick={() => {
                    setOpenWallet(true);
                    setConfirmBuyTransactionModal(false);
                    setTransactionType("supply");
                  }}
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <ClipLoader color="#ffffff" size={18} /> Supplying...
                    </>
                  ) : (
                    "Confirm Supply"
                  )}
                </button>
              ) : (
                <button
                  className="product_detail_cont_div5_7_btn"
                  onClick={() => {
                    setOpenWallet(true);
                    setConfirmBuyTransactionModal(false);
                    setTransactionType("approve");
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      {" "}
                      <ClipLoader color="#fff" size={24} /> Approving...
                    </>
                  ) : (
                    "   Approve Spending"
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      {openWallet && transactionType === "approve" && (
        <WalletPop
          closePop={() => {
            setOpenWallet(false);
            setConfirmBuyTransactionModal(true);
          }}
          transaction={true}
          transactionType={transactionType}
          transact={setAllowance}
          loading={loading}
          tokenName={data?.tokenName}
        />
      )}
      {openWallet && transactionType === "supply" && (
        <WalletPop
          closePop={() => {
            setOpenWallet(false);
            setConfirmBuyTransactionModal(true);
          }}
          transaction={true}
          transactionType={transactionType}
          transact={addLiquidityFn}
          loading={loading}
          tokenName={data?.tokenName}
        />
      )}
    </div>
  );
};

export default AddLiquidity;
