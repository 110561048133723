export const formatDecimal = (value) => {
  if (value === 0 || Math.abs(value) < 1e-10) {
    return "0.0000"; // Handle zero values
  }

  const fullValue = value?.toFixed(10); // Convert to full decimal with 10 places
  const valueStr = parseFloat(fullValue)?.toFixed(4); // Format to 4 decimal places
  const match = fullValue.match(/^0\.(0+)(\d+)/);

  if (match) {
    const zeroCount = match[1].length; // Count leading zeros
    const significantDigits = match[2]; // Extract significant digits

    // Apply {X} notation if leading zeros are more than 4
    if (zeroCount > 4) {
      return `0.0{${zeroCount}}${significantDigits.slice(0, 4)}`;
    }
  }

  return valueStr; // Return as is with 4 decimal places
};
