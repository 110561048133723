import React from "react";
import { ArrowLeft01Icon, InformationCircleIcon } from "hugeicons-react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveProvider } from "../../../Redux/feautures/assets/assetSlice";
const WalletChains = ({ toggleChainModal, chains, setSelectedChain }) => {
  const dispatch = useDispatch();
  return (
    <div className="SendModal_div">
      <div className="WalletDetailPageDiv_header">
        <div
          className="WalletDetailPageDiv_header_back"
          onClick={toggleChainModal}
        >
          <ArrowLeft01Icon size={24} />
        </div>
        Select Chain
        <div className="WalletDetailPageDiv_header_info">
          <InformationCircleIcon size={20} />
        </div>
      </div>
      <div className="SendModal_div_body">
        {chains?.map((data) => {
          return (
            <div
              className="WalletPop_div_cont_walletHome_div2_body_asset"
              onClick={() => {
                // setSelectedChain(data);
                dispatch(setActiveProvider(data?.network || "Egochain"));

                toggleChainModal();
              }}
            >
              <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont1">
                <img
                  src={data?.img || data?.image || "/img/art.png"}
                  alt=""
                  className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_img"
                />
                <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_area">
                  <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_area_title">
                    {data?.network}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WalletChains;
