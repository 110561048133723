import React from "react";
import "./index.css";

const HowItWorks = ({ close }) => {
  return (
    <div className="WalletPop_div">
      <div className="WalletPop_div_close_div" onClick={close}></div>
      <div className="howItworksCont">
        <div className="howItworksArea">
          <div className="howItworksArea_title">How It Works</div>
          <div className="howItworksArea_title_val">
            Tokenization of Real-World Businesses
          </div>
          <div className="howItworksArea_div">
            <div className="howItworksArea_div_cont1">
              Step1: Easily create and launch no-code loyalty tokens to enhance
              customer engagement and reward loyalty within your business
              ecosystem.
            </div>{" "}
            <div className="howItworksArea_div_cont1">
              Step2: Convert physical products into redeemable NFTs (rNFTs),
              bridging the gap between traditional inventory and blockchain
              technology.
            </div>{" "}
            <div className="howItworksArea_div_cont1">
              Step3: List your tokenized products as NFTs on Dhive’s platform
              and use blockchain for transparent, traceable inventory
              management.
            </div>{" "}
            <div className="howItworksArea_div_cont1">
              Step4: Leverage loyalty tokens and rNFTs to create innovative
              revenue streams and unique customer experiences.
            </div>{" "}
            Seamlessly integrate your business into the Web3 ecosystem, driving
            growth and value through Dhive’s accessible blockchain solutions.
          </div>
          <button
            className="ExchangeHeader_div_area_2_links_div_btn"
            onClick={close}
          >
            Get started
          </button>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
