import React, { useState, useEffect } from "react";
import { AddCircleIcon, ArrowLeft01Icon } from "hugeicons-react";
import AnimatedNumber from "react-awesome-animated-number";
import { decrypt } from "../../../../helper/encryption";
import { useDispatch, useSelector } from "react-redux";
import { ethers } from "ethers";
import dhiveRouterAbi from "./dhiveRouterAbi.json";
import { checkApproval, setApproval } from "../../../../services/etherUtils";
import { extractErrorMessage } from "../../BuySell/helper";
import WalletPop from "../../../../OnchainWallet/WalletPop/WalletPop";
import toast, { Toaster } from "react-hot-toast";
import { Cancel01Icon } from "hugeicons-react";
import ClipLoader from "react-spinners/ClipLoader";
import {
  triggerBalanceChange,
  updateBalancesAfterTrade,
} from "../../../../Redux/feautures/assets/assetSlice";

const RemoveLiquidity = ({
  back,
  data,
  tokenPriceInEgax,
  egaxPoolVal,
  tokenPoolVal,
  userLpBalance,
  pairAddress,
  userShareOfPool,
  fetchLiquid,
  baseToken,
}) => {
  const dispatch = useDispatch();
  const { privateKey, wallet_address } = useSelector((state) => state.user);
  const provider = new ethers.providers.JsonRpcProvider(
    "https://mainnet.egochain.org"
  );
  const providerBase = new ethers.providers.JsonRpcProvider(
    "https://base-rpc.publicnode.com"
  );
  const decrypted = decrypt(privateKey);
  const signer = new ethers.Wallet(
    decrypted,
    baseToken == "EGAX" ? provider : providerBase
  );
  const routerAddress = "0x1641aDe952320BEC5330B7e54D5A032FB5006de3";
  const routerAddressBase = "0xaaa3b1F1bd7BCc97fD1917c18ADE665C5D31F066";
  const routerAbi = dhiveRouterAbi;
  const contract = new ethers.Contract(
    baseToken == "EGAX" ? routerAddress : routerAddressBase,
    routerAbi,
    signer
  );
  const [sliderValue, setSliderValue] = useState(0);
  const [hasAllowance, setHasAllowance] = useState(false);
  const [checkAllowanceLoading, setCheckAllowanceLoading] = useState(false);
  const [confirmBuyTransactionModal, setConfirmBuyTransactionModal] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [transactionType, setTransactionType] = useState(null);
  const [openWallet, setOpenWallet] = useState(false);
  const slippagePercentage = ethers.BigNumber.from(Math.floor(0.5 * 100));
  const paths = [
    "0x6Db1e1b97e6727D93FdfEbE68CB9Dab094fA8e52",
    data?.contractAddress,
  ];
  const handleSliderChange = (e) => {
    setSliderValue(e.target.value);
  };

  const checkAllowance = async () => {
    setCheckAllowanceLoading(true);

    if (baseToken == "EGAX") {
      const res = await checkApproval({
        tokenAddress: pairAddress,
        owner: wallet_address,
        spender: routerAddress,
        prov: provider,
      });
      // console.log("====================================");
      // console.log(res);
      // console.log("====================================");
      if (parseFloat(res) > parseFloat(1000000000000000)) {
        setHasAllowance(true);
        setCheckAllowanceLoading(false);
      }

      return;
    }
    if (baseToken == "DHV") {
      const res = await checkApproval({
        tokenAddress: pairAddress,
        owner: wallet_address,
        spender: routerAddressBase,
        prov: providerBase,
      });
      console.log("====================================");
      console.log(res);
      console.log("====================================");
      if (parseFloat(res) > parseFloat(1000000000000000)) {
        setHasAllowance(true);
        setCheckAllowanceLoading(false);
      }

      return;
    }
  };
  useEffect(() => {
    checkAllowance();
  }, []);

  const setAllowance = async () => {
    setOpenWallet(false);
    setConfirmBuyTransactionModal(true);
    setLoading(true);
    try {
      if (baseToken == "DHV") {
        const tx = await setApproval({
          tokenAddress: pairAddress,
          spender: routerAddressBase,
          privateKey,
          prov: providerBase,
        });
        // console.log("phase1");
        const receipt = await tx.wait();
        // console.log("phase2");
        setLoading(false);
        toast.success(
          <div className="toast_success_div">
            <div className="toast_success_div_title">Allowance Success!</div>
            <div className="toast_success_div_para">
              {" "}
              Allowance enabled successfully
            </div>
          </div>,
          {
            duration: 5000,
            className: "toast_success",
          }
        );
        setHasAllowance(true);
        setOpenWallet(false);
        return;
      }
      if (baseToken == "EGAX") {
        const tx = await setApproval({
          tokenAddress: pairAddress,
          spender: routerAddress,
          privateKey,
          prov: provider,
        });
        // console.log("phase1");
        const receipt = await tx.wait();
        // console.log("phase2");
        setLoading(false);
        toast.success(
          <div className="toast_success_div">
            <div className="toast_success_div_title">Allowance Success!</div>
            <div className="toast_success_div_para">
              {" "}
              Allowance enabled successfully
            </div>
          </div>,
          {
            duration: 5000,
            className: "toast_success",
          }
        );
        setHasAllowance(true);
        setOpenWallet(false);
        return;
      }
      // console.log("Liquidity added successfully");
    } catch (error) {
      setLoading(false);
      // console.log("Transaction Error:", error);
      // console.log("Error in Transaction: ", error.message);
      // console.log("Transaction Error:", extractErrorMessage(error));
      toast.error(
        <div className="toast_success_div">
          <div className="toast_error_div_title">Error!!</div>
          <div className="toast_success_div_para">
            {extractErrorMessage(error) ==
            "insufficient funds for intrinsic transaction cost"
              ? "insufficient funds for gas fee please top up"
              : extractErrorMessage(error)}{" "}
          </div>
        </div>,
        {
          duration: 5000,
          className: "toast_error",
        }
      );
    }
  };
  // console.log(baseToken, data?.contractAddress, userLpBalance, pairAddress);
  console.log(
    userLpBalance,
    userLpBalance * (sliderValue / 100),
    parseFloat(sliderValue)
  );
  const removeLiquidity = async () => {
    setOpenWallet(false);
    setConfirmBuyTransactionModal(true);
    setLoading(true);
    try {
      const tokenAddress = data?.contractAddress;
      const liquidityAmount =
        parseFloat(sliderValue) === 100
          ? userLpBalance
          : userLpBalance * (parseFloat(sliderValue) / 100);

      const liquidity = ethers.utils.parseEther(liquidityAmount.toString());
      const tokenAmount = tokenPoolVal * (sliderValue / 100);
      console.log(tokenAmount, "tokenAmount");

      const amountTokenDesired = ethers.utils.parseEther(
        tokenAmount.toString()
      );
      console.log(
        ethers.utils.formatEther(amountTokenDesired),
        "amountTokenDesired"
      );

      const amountTokenMin = amountTokenDesired
        .mul(10000 - slippagePercentage)
        .div(10000);

      console.log(ethers.utils.formatEther(amountTokenMin), "amountTokenMin");

      const Amounteth = egaxPoolVal * (sliderValue / 100);
      const ethAmount = ethers.utils.parseEther(Amounteth.toString());
      const amountETHMin = ethAmount.mul(10000 - slippagePercentage).div(10000);
      const address = wallet_address;
      const deadline = Math.floor(Date.now() / 1000) + 60 * 20;
      const tokenB = "0x6db1e1b97e6727d93fdfebe68cb9dab094fa8e52";

      console.log(
        tokenAddress,
        tokenB,
        ethers.utils.formatEther(liquidity),
        ethers.utils.formatEther(amountTokenMin),
        ethers.utils.formatEther(amountETHMin),
        address,
        deadline
      );

      if (baseToken == "DHV") {
        const tx = await contract.removeLiquidity(
          tokenAddress,
          tokenB,
          liquidity,
          amountTokenMin,
          amountETHMin,
          address,
          deadline.toString(),
          {
            gasLimit: 300000,
          }
        );

        console.log(tx, "tctxt");
        console.log(
          tokenAddress,
          tokenB,
          ethers.utils.formatEther(liquidity),
          ethers.utils.formatEther(amountTokenMin),
          ethers.utils.formatEther(amountETHMin),
          address,
          deadline
        );
        const receipt = await tx.wait();
        dispatch(
          updateBalancesAfterTrade({
            networkName: `BASE NETWORK`,
            walletAddress: wallet_address,
            tokenAddresses: [paths[0], paths[1]], // Addresses of tokens involved in trade
          })
        );
        console.log(receipt);

        setLoading(false);
        setOpenWallet(false);
        back();
        fetchLiquid();
        toast.success(
          <div className="toast_success_div">
            <div className="toast_success_div_title">Removal Success!</div>
            <div className="toast_success_div_para">
              {" "}
              Liquidity removed successfully
            </div>
          </div>,
          {
            duration: 5000,
            className: "toast_success",
          }
        );
        setConfirmBuyTransactionModal(false);
        return;
      }
      if (baseToken == "EGAX") {
        const tx = await contract.removeLiquidityETH(
          tokenAddress,
          liquidity,
          amountTokenMin,
          amountETHMin,
          address,
          deadline,
          { gasLimit: 300000 }
        );

        // console.log(tx);
        const receipt = await tx.wait();
        dispatch(
          updateBalancesAfterTrade({
            networkName: `EGOCHAIN NETWORK`,
            walletAddress: wallet_address,
            tokenAddresses: [paths[1]], // Addresses of tokens involved in trade
          })
        );
        // console.log(receipt);

        setLoading(false);
        setOpenWallet(false);
        back();
        fetchLiquid();
        toast.success(
          <div className="toast_success_div">
            <div className="toast_success_div_title">Removal Success!</div>
            <div className="toast_success_div_para">
              {" "}
              Liquidity removed successfully
            </div>
          </div>,
          {
            duration: 5000,
            className: "toast_success",
          }
        );
        setConfirmBuyTransactionModal(false);
        // console.log("Liquidity added successfully");

        return;
      }
    } catch (error) {
      setLoading(false);
      console.log("Transaction Error:", error);
      // console.log("Error in Transaction: ", error.message);
      // console.log("Transaction Error:", extractErrorMessage(error));
      toast.error(
        <div className="toast_success_div">
          <div className="toast_error_div_title">Error!!</div>
          <div className="toast_success_div_para">
            {extractErrorMessage(error) ==
            "insufficient funds for intrinsic transaction cost"
              ? "insufficient funds for gas fee please top up"
              : extractErrorMessage(error)}{" "}
          </div>
        </div>,
        {
          duration: 5000,
          className: "toast_error",
        }
      );
    }
  };

  const initRemoveLiquidity = () => {
    setConfirmBuyTransactionModal(true);
  };

  // console.log(data);
  return (
    <div className="LiquidityDiv_cont_cont2">
      <div className="LiquidityDiv_cont_cont2_back_div" onClick={back}>
        <ArrowLeft01Icon /> Back
      </div>
      <div className="LiquidityDiv_cont_cont2_title">
        Remove liquidity
        <div className="LiquidityDiv_cont_cont2_para">
          Remove your {data?.tokenSymbol} / {baseToken} liquidity to collect
          your tokens back
        </div>{" "}
      </div>
      <div className="removeLiquidityBody">
        <div className="removeLiquidityBody_cont1">
          <div className="removeLiquidityBody_cont1_div1">
            <div className="removeLiquidityBody_cont1_div1_title">Amount</div>
            <div className="removeLiquidityBody_cont1_div1_value">
              <AnimatedNumber
                value={sliderValue}
                hasComma={true}
                size={48}
                duration={500}
              />
              %
            </div>
          </div>
          <div className="removeLiquidityBody_cont1_div2">
            <input
              type="range"
              min="0"
              max="100"
              step="1"
              value={sliderValue}
              onChange={handleSliderChange}
              className="removeLiquidityBody_cont1_div2_range"
              style={{
                background: `linear-gradient(to right, #605afd ${sliderValue}%, #fff ${sliderValue}%)`,
              }}
            />
          </div>
          <div className="removeLiquidityBody_cont1_div2_max_btns">
            <button
              className="removeLiquidityBody_cont1_div2_max_btns_1"
              onClick={() => setSliderValue(20)}
            >
              20%
            </button>
            <button
              className="removeLiquidityBody_cont1_div2_max_btns_1"
              onClick={() => setSliderValue(50)}
            >
              50%
            </button>
            <button
              className="removeLiquidityBody_cont1_div2_max_btns_1"
              onClick={() => setSliderValue(75)}
            >
              75%
            </button>
            <button
              className="removeLiquidityBody_cont1_div2_max_btns_1"
              onClick={() => setSliderValue(100)}
            >
              Max
            </button>
          </div>
        </div>
        <div className="removeLiquidityBody_cont2">
          <div className="removeLiquidityBody_cont2_title">
            You will receive
          </div>
          <div className="removeLiquidityBody_cont2_div1">
            <div className="removeLiquidityBody_cont2_div1_val">
              {data?.tokenSymbol}
            </div>
            <div className="removeLiquidityBody_cont2_div1_val">
              {tokenPoolVal * (sliderValue / 100)}
            </div>
          </div>
          <div className="removeLiquidityBody_cont2_div1">
            <div className="removeLiquidityBody_cont2_div1_val">
              {baseToken}
            </div>
            <div className="removeLiquidityBody_cont2_div1_val">
              {" "}
              {egaxPoolVal * (sliderValue / 100)}
            </div>
          </div>
        </div>
        <div className="removeLiquidityBody_cont2">
          <div className="removeLiquidityBody_cont2_div1">
            <div className="removeLiquidityBody_cont2_div1_val">
              Pooled {data?.tokenSymbol}
            </div>
            <div className="removeLiquidityBody_cont2_div1_val">
              {tokenPoolVal}
            </div>
          </div>
          <div className="removeLiquidityBody_cont2_div1">
            <div className="removeLiquidityBody_cont2_div1_val">
              Pooled {baseToken}
            </div>
            <div className="removeLiquidityBody_cont2_div1_val">
              {egaxPoolVal}
            </div>
          </div>
          <div className="removeLiquidityBody_cont2_div1">
            <div className="removeLiquidityBody_cont2_div1_val">
              Share of pool
            </div>
            <div className="removeLiquidityBody_cont2_div1_val">
              {userShareOfPool}
            </div>
          </div>
        </div>
        <div className="removeLiquidityBody_collect">
          Collect as WEGAX{" "}
          <label class="switch">
            <input type="checkbox" />
            <span class="slider"></span>
          </label>
        </div>
      </div>
      <div className="remove_liquidity_btn_divs">
        {sliderValue == 0 ? (
          <button className="LiquidityDiv_cont_cont2_btn" disabled>
            Input value
          </button>
        ) : (
          <button
            className="LiquidityDiv_cont_cont2_btn"
            onClick={initRemoveLiquidity}
          >
            Remove
          </button>
        )}
      </div>

      {confirmBuyTransactionModal && (
        <div className="confrimBuySellDiv">
          <div className="confrimBuySellDiv_container">
            <div className="confrimBuySellDiv_title">
              Confirm Remove liquidity
              <Cancel01Icon
                size={20}
                className="confrimBuySellDiv_title_icon"
                onClick={() => {
                  setConfirmBuyTransactionModal(false);
                }}
              />
            </div>
            <div className="confrimBuySellDiv_body">
              <div className="confrimBuySellDiv_body1">
                <div className="confrimBuySellDiv_body1_cont1">
                  <div className="confrimBuySellDiv_body1_cont1_area1">
                    {tokenPoolVal * (sliderValue / 100)}
                  </div>
                  <div className="confrimBuySellDiv_body1_cont1_area2">
                    {data?.tokenSymbol}{" "}
                  </div>
                </div>
                <div className="confrimBuySellDiv_body1_cont1">
                  <div className="confrimBuySellDiv_body1_cont1_area1">
                    {egaxPoolVal * (sliderValue / 100)}
                  </div>

                  <div className="confrimBuySellDiv_body1_cont1_area2">
                    {" "}
                    {baseToken}
                  </div>
                </div>
              </div>
              <div className="confrimBuySellDiv_body2">
                <div className="swap_details_div_body">
                  <div className="swap_details_div_body_cont1">
                    <div className="swap_details_div_body_cont1_div1">
                      Pooled {data?.tokenSymbol}
                    </div>
                    <div className="swap_details_div_body_cont1_div2">
                      {tokenPoolVal}
                    </div>
                  </div>
                  <div className="swap_details_div_body_cont1">
                    <div className="swap_details_div_body_cont1_div1">
                      Pooled {baseToken}
                    </div>
                    <div className="swap_details_div_body_cont1_div2">
                      {egaxPoolVal}
                    </div>
                  </div>
                  <div className="swap_details_div_body_cont1">
                    <div className="swap_details_div_body_cont1_div1">
                      Share of pool
                    </div>
                    <div className="swap_details_div_body_cont1_div2">
                      {userShareOfPool}
                    </div>
                  </div>

                  <div className="swap_details_div_body_cont1">
                    <div className="swap_details_div_body_cont1_div1">
                      Slippage Tolerance
                    </div>
                    <div className="swap_details_div_body_cont1_div2">
                      0.50%
                    </div>
                  </div>
                  {/* <div className="swap_details_div_body_cont1">
                    <div className="swap_details_div_body_cont1_div1">
                      Trading fee
                    </div>
                    <div className="swap_details_div_body_cont1_div2">
                      0.30%
                    </div>
                  </div> */}
                </div>
              </div>
              {hasAllowance ? (
                <button
                  className="product_detail_cont_div5_7_btn"
                  onClick={() => {
                    setOpenWallet(true);
                    setConfirmBuyTransactionModal(false);
                    setTransactionType("remove");
                  }}
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <ClipLoader color="#ffffff" size={18} /> Removing...
                    </>
                  ) : (
                    "Remove liquidity"
                  )}
                </button>
              ) : (
                <button
                  className="product_detail_cont_div5_7_btn"
                  onClick={() => {
                    setOpenWallet(true);
                    setConfirmBuyTransactionModal(false);
                    setTransactionType("approve");
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      {" "}
                      <ClipLoader color="#fff" size={24} /> Approving...
                    </>
                  ) : (
                    "   Approve Spending"
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      {openWallet && transactionType === "approve" && (
        <WalletPop
          closePop={() => {
            setOpenWallet(false);
            setConfirmBuyTransactionModal(true);
          }}
          transaction={true}
          transactionType={transactionType}
          transact={setAllowance}
          loading={loading}
          tokenName={data?.tokenName}
        />
      )}
      {openWallet && transactionType === "remove" && (
        <WalletPop
          closePop={() => {
            setOpenWallet(false);
            setConfirmBuyTransactionModal(true);
          }}
          transaction={true}
          transactionType={transactionType}
          transact={removeLiquidity}
          loading={loading}
          tokenName={data?.tokenName}
        />
      )}
      <Toaster />
    </div>
  );
};

export default RemoveLiquidity;
