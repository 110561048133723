import CryptoJS from "crypto-js";
const secretKey = import.meta.env.VITE_secretKey;

export const encrypt = (text) => {
  try {
    if (!text) {
      throw new Error("Text to encrypt is empty or undefined.");
    }

    const encryptedText = CryptoJS.AES.encrypt(text, secretKey).toString();
    //console.log("Encrypted Text:", encryptedText);
    return encryptedText;
  } catch (error) {
    console.error("Encryption Error:", error.message);
    return null;
  }
};

export const decrypt = (encryptedText) => {
  try {
    // Validate input
    if (!encryptedText) {
      throw new Error("Encrypted text is empty or undefined.");
    }
    // Decrypt the ciphertext
    const bytes = CryptoJS.AES.decrypt(encryptedText, secretKey);
    // Convert the decrypted bytes into a UTF-8 string
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    // Validate the output

    if (!originalText) {
      throw new Error(
        "Decryption failed. Malformed data or incorrect secret key."
      );
    }
    return originalText;
  } catch (error) {
    console.error("Decryption Error:", error.message);
    return null; // Return null or handle the error appropriately
  }
};
