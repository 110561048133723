import { message } from "antd";
import { ethers } from "ethers";
import { parseUnits } from "ethers/lib/utils";
import { store } from "../Redux/app/store";
const sendTokenWithPrivateKey = async ({
  privateKey,
  tokenAddress,
  to,
  amount,
  decimals = 18,
  rpcUrl,
}) => {
  try {
    const state = store.getState();
    // Connect to the blockchain using a provider

    const provider = new ethers.providers.JsonRpcProvider(
      state.assets.activeProvider.rpc
    );

    // Create a signer (wallet) using the private key
    const wallet = new ethers.Wallet(privateKey, provider);

    // Connect to the token contract
    const tokenContract = new ethers.Contract(
      tokenAddress,
      ["function transfer(address to, uint256 amount) public returns (bool)"],
      wallet
    );
    // Convert amount to the correct decimal places
    const amountInWei = parseUnits(amount.toString(), decimals);
    // Send the token transaction
    const tx = await tokenContract.transfer(to, amountInWei);
    //console.log("Transaction Sent:", tx.hash);
    // Wait for confirmation
    await tx.wait();
    //console.log("Transaction Confirmed:", tx.hash);
    return { hash: tx.hash, success: true };
  } catch (error) {
    console.error("Token Transfer Failed:", error);
    return { success: false, message: error.message };
  }
};

export default sendTokenWithPrivateKey;
