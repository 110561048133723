import apiInstance from "../utils/axiosInstance";
import {
  GET_ALL_PRODUCT_BY_CATEGORY_ROUTE,
  GET_PRODUCT_DETAIL_BY_ID_ROUTE,
  GET_BUSINESS_WITH_PRODUCT_ROUTE,
} from "./routes";

export const GET_ALL_PRODUCT_BY_CATEGORY = async (category, page, limit) => {
  try {
    const res = await apiInstance.get(
      `${GET_ALL_PRODUCT_BY_CATEGORY_ROUTE}category=${category}&page${page}&limit=${limit}`
    );
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};

export const GET_PRODUCT_DETAIL_BY_ID = async (id) => {
  try {
    const res = await apiInstance.get(
      `${GET_PRODUCT_DETAIL_BY_ID_ROUTE}/${id}`
    );
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
export const GET_BUSINESS_WITH_PRODUCT = async (id) => {
  try {
    const res = await apiInstance.get(`${GET_BUSINESS_WITH_PRODUCT_ROUTE}`);
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
