import React, { useEffect, useState } from "react";
import "./index.css";
import { Camera02Icon, ArrowLeft01Icon } from "hugeicons-react";
import { AddressShortener } from "../../utils/AddressShortener";
import {
  InformationCircleIcon,
  CheckmarkBadge01Icon,
  ShoppingCart01Icon,
} from "hugeicons-react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";

import Blockies from "react-blockies";
import {
  YoutubeIcon,
  NewTwitterIcon,
  Facebook01Icon,
  InstagramIcon,
  TiktokIcon,
} from "hugeicons-react";
import { useSelector } from "react-redux";
import {
  LIST_ON_BOT_MARKETPLACE,
  EDIT_LISTED_PRODUCT,
} from "../../services/business";
import toast, { Toaster } from "react-hot-toast";
import { socketEvents } from "../../Sockets/events";
import { useSocket } from "../../Sockets/contextProvider/SocketContext";
import Nodata from "../../Components/Nodata/Nodata";
import ReactTimeAgo from "react-time-ago";
import ClipLoader from "react-spinners/ClipLoader";
import UploadWidget from "../Cloudinary/UploadWidget";

export const categories = [
  { value: "Electronics", label: "Electronics" },
  { value: "Automobile", label: "Automobile" },
  { value: "Mobile Phones & Tablets", label: "Mobile Phones & Tablets" },
  { value: "Machinery", label: "Machinery" },
  { value: "Gadgets", label: "Gadgets" },
  { value: "Real Estate", label: "Real Estate" },
  { value: "Agriculture and Food", label: "Agriculture and Food" },
  { value: "Fashion", label: "Fashion" },
  { value: "Household Appliances ", label: "Household Appliances" },
  { value: "Health & Beauty", label: "Health & Beauty" },
];
const ListProduct = ({
  toggleListModal,
  type,
  productId,
  businessProducts,
}) => {
  const navigate = useNavigate();
  const { wallet_address, business_info } = useSelector((state) => state.user);
  const { Assets } = useSelector((state) => state.assets);
  const [processing, setProcessing] = useState(false);
  const [productPayload, setProductPayload] = useState({
    walletAddress: wallet_address,
    name: "",
    brand: "",
    specifications: "",
    details: "",
    category: "",
    product_image: "",
    userAmount: "",
  });

  const [specifications, setSpecifications] = useState([
    { name: "", value: "" },
  ]);
  const socket = useSocket();

  const handleCategoryChange = (selectedOption) => {
    setProductPayload((prev) => ({
      ...prev,
      category: selectedOption.value,
    }));
  };

  const handleDataChange = (e) => {
    const { id, value } = e.target;
    setProductPayload({ ...productPayload, [id]: value });
  };
  const listProduct = async () => {
    setProcessing(true);
    // console.log(productPayload, "sss");
    const res = await LIST_ON_BOT_MARKETPLACE(productPayload);
    // console.log(res, "list product response ");

    if (!res.success) {
      setProcessing(false);
      toast.error(
        <div className="toast_success_div">
          <div className="toast_error_div_title">Error!!</div>
          <div className="toast_success_div_para">
            Product Listing Failed!!!
          </div>
        </div>,
        {
          duration: 5000,
          className: "toast_error",
        }
      );
      return;
    }
  };
  const editProduct = async () => {
    setProcessing(true);
    const body = {
      walletAddress: wallet_address,
      name: productPayload.name,
      specifications: productPayload.specifications,
      details: productPayload.details,
      category: productPayload.category,
      images: productPayload.product_image,
      productId: productId,
      businessID: business_info?.businesses?.businessID,
    };
    // console.log(body, "sss");
    const res = await EDIT_LISTED_PRODUCT(body);
    // console.log(res, "list product response ");
    if (res.success !== true) {
      setProcessing(false);
      toast.error(
        <div className="toast_success_div">
          <div className="toast_error_div_title">Error!!</div>
          <div className="toast_success_div_para">
            Product Listing Failed!!!
          </div>
        </div>,
        {
          duration: 5000,
          className: "toast_error",
        }
      );
      return;
    }

    setProcessing(false);
    toast.success(
      <div className="toast_success_div">
        <div className="toast_success_div_title">Success!</div>
        <div className="toast_success_div_para">
          editing of product successful!!!
        </div>
      </div>,
      {
        duration: 5000,
        className: "toast_success",
      }
    );
    setTimeout(() => {
      navigate("/business/products");
    }, 3000);
  };

  useEffect(() => {
    // socket.on(socketEvents.TOKEN_CREATION, (data) => {
    //   //// console.log("Token ceation event received:", data);
    // });
    socket.on(`${wallet_address}${socketEvents.LIST_TOKEN_ON_BOT}`, (data) => {
      // console.log("Token ceation event ended:", data);
      if (!data?.success) {
        setProcessing(false);
        toast.error(
          <div className="toast_success_div">
            <div className="toast_error_div_title">Error!!</div>
            <div className="toast_success_div_para">
              Product Listing Failed!!!
            </div>
          </div>,
          {
            duration: 5000,
            className: "toast_error",
          }
        );
        return;
      }
      setProcessing(false);
      toast.success(
        <div className="toast_success_div">
          <div className="toast_success_div_title">Success!</div>
          <div className="toast_success_div_para">
            Listing of product successful!!!
          </div>
        </div>,
        {
          duration: 5000,
          className: "toast_success",
        }
      );
      setTimeout(() => {
        navigate("/business/products");
      }, 3000);
    });

    return () => {
      socket.off(`${wallet_address}${socketEvents.LIST_TOKEN_ON_BOT}`); // Cleanup listener
    };
  }, [socket]);

  const handleEvent = (err, result) => {
    //// console.log(result, "ama");
    if (result?.event == "success") {
      const image = result?.info?.url;
      setProductPayload((prev) => ({
        ...prev,
        product_image: image,
      }));
      return;
    }
  };

  const handleChange = (e) => {
    if (e.target) {
      // Handle standard input fields
      const { name, value } = e.target;
      if (name == "initialBuy") {
        setPayload((prevState) => ({
          ...prevState,
          [name]: parseFloat(value),
        }));
      }
      setPayload((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      // Handle react-select fields
      const { name, value } = e;
      setPayload((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const handleInputChange = (index, field, event) => {
    const updatedSpecs = [...specifications];
    updatedSpecs[index][field] = event.target.value;
    setSpecifications(updatedSpecs);

    // Update productPayload
    const concatenatedValues = updatedSpecs
      ?.map((spec) => `${spec?.name}:${spec?.value}`)
      ?.join(",");
    setProductPayload((prev) => ({
      ...prev,
      specifications: concatenatedValues,
    }));
  };

  const AddInputCount = () => {
    setSpecifications([...specifications, { name: "", value: "" }]);
  };

  useEffect(() => {
    if (type === "edit") {
      const parsedSpecifications = businessProducts?.specifications
        ?.split(",")
        ?.map((item) => {
          const [name, value] = item?.split(":");
          return { name, value };
        });

      const concatenatedValues = parsedSpecifications
        ?.map((spec) => `${spec?.name}:${spec?.value}`)
        ?.join(",");

      setSpecifications(parsedSpecifications);

      setProductPayload({
        ...productPayload,
        name: businessProducts?.name,
        specifications: concatenatedValues,
        details: businessProducts?.details,
        category: businessProducts?.category,
        product_image: businessProducts?.images,
      });
    }
  }, [type, businessProducts]);
  // console.log("====================================");
  // console.log(businessProducts);
  // console.log("====================================");
  return (
    <div className="ListModal">
      <div className="ListModal_hide_bg" onClick={toggleListModal}></div>
      <div className="ListModal_cont">
        <div className="ListModal_cont_area_back_button">
          {" "}
          <div
            className="ListModal_cont_area_back_button_div1"
            onClick={toggleListModal}
          >
            <ArrowLeft01Icon />
            Back
          </div>
        </div>
        <div className="ListModal_cont_area">
          <div className="ListModal_cont_area_title">
            {type === "edit"
              ? "  Edit Your Product"
              : "  List A Product On dhive"}
          </div>
          <div className="ListModal_cont_area_body">
            <div className="ListModal_cont_area_body_div1">
              <div className="ListModal_cont_area_body_div1_title">
                Product Image<span className="asteriks">*</span>
              </div>
              <div className="ListModal_cont_area_body_div1_area">
                <div className="ListModal_cont_area_body_div1_div1">
                  {" "}
                  <UploadWidget
                    event={handleEvent}
                    img={productPayload.product_image}
                  />
                </div>
                <div className="ListModal_cont_area_body_div1_div2">
                  Accepted file formats jpeg,png,svg.
                  <br /> File must be less than 2mb.
                </div>
              </div>
            </div>
            <div className="ListModal_cont_area_body_div2">
              <div className="ListModal_cont_area_body_div2_title">
                Product Name<span className="asteriks">*</span>
              </div>
              <input
                type="text"
                placeholder="Name"
                className="ListModal_cont_area_body_div2_input"
                id="name"
                value={productPayload.name}
                onChange={handleDataChange}
              />
            </div>

            {type === "edit" ? null : (
              <div className="ListModal_cont_area_body_div2">
                <div className="ListModal_cont_area_body_div2_title">
                  Product Brand<span className="asteriks">*</span>
                </div>
                <input
                  type="text"
                  placeholder="Brand"
                  className="ListModal_cont_area_body_div2_input"
                  id="brand"
                  value={productPayload.brand}
                  onChange={handleDataChange}
                />
              </div>
            )}

            {/* redacted --- */}
            {/* <div className="ListModal_cont_area_body_div2">
                  <div className="ListModal_cont_area_body_div2_title">
                    Product Quantity<span className="asteriks">*</span>
                  </div>
                  <input
                    type="number"
                    placeholder="Count"
                    className="ListModal_cont_area_body_div2_input"
                    id="quantity"
                    value={productPayload.quantity}
                    onChange={handleDataChange}
                  />
                </div> */}

            {type === "edit" ? null : (
              <div className="ListModal_cont_area_body_div2">
                <div className="ListModal_cont_area_body_div2_title">
                  Product Price<span className="asteriks">*</span>
                </div>
                <input
                  type="number"
                  placeholder="0.00 USDC"
                  className="ListModal_cont_area_body_div2_input"
                  id="userAmount"
                  value={productPayload.userAmount}
                  onChange={handleDataChange}
                />
              </div>
            )}

            <div className="ListModal_cont_area_body_div2">
              <div className="ListModal_cont_area_body_div2_title">
                Product Category<span className="asteriks">*</span>
              </div>

              <Select
                options={categories}
                className="ListModal_cont_area_body_div2_input"
                value={categories.find(
                  (cat) => cat.value === productPayload.category
                )}
                onChange={handleCategoryChange}
                placeholder="Select Category"
              />
              {/* <input
                type="text"
                placeholder="category"
                className="ListModal_cont_area_body_div2_input"
                id="category"
                value={productPayload.category}
                onChange={handleDataChange}
              /> */}
            </div>
            {/* <input
                    type="txt"
                    placeholder="specs"
                    className="ListModal_cont_area_body_div2_input"
                    id="specifications"
                    value={productPayload.specifications}
                    onChange={handleDataChange}
                  /> */}
            <div className="ListModal_cont_area_body_div2">
              <div className="ListModal_cont_area_body_div2_title">
                Product Specifications eg ( color : blue )
              </div>
              <div className="sell_container_prod_spec_input_div_area_title_area">
                <div className="sell_container_prod_spec_input_div_area_title_area1">
                  Title
                </div>
                <div className="sell_container_prod_spec_input_div_area_title_area1">
                  Spec
                </div>
              </div>

              {specifications?.map((spec, index) => (
                <div
                  className="sell_container_prod_spec_input_divs"
                  key={index}
                >
                  <input
                    type="text"
                    className="sell_container_prod_spec_input_div1"
                    value={spec?.name}
                    onChange={(event) =>
                      handleInputChange(index, "name", event)
                    }
                    style={{ marginRight: "10px" }}
                  />
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    _
                  </span>
                  <input
                    type="text"
                    className="sell_container_prod_spec_input_div1"
                    value={spec?.value}
                    onChange={(event) =>
                      handleInputChange(index, "value", event)
                    }
                    style={{ marginLeft: "10px" }}
                  />
                </div>
              ))}

              <button
                className="sell_container_prod_spec_input_div_btn"
                onClick={AddInputCount}
              >
                Add Input +
              </button>
            </div>
            <div className="ListModal_cont_area_body_div2">
              <div className="ListModal_cont_area_body_div2_title">
                Product Details <span className="asteriks">*</span>
              </div>
              <textarea
                name=""
                cols="30"
                rows="10"
                className="ListModal_cont_area_body_div2_textArea"
                id="details"
                value={productPayload.details}
                onChange={handleDataChange}
                // onChange={(e) =>
                //   setProductPayload({ ...productPayload, details: "llll" })
                // }
              ></textarea>
            </div>
            <div className="ListModal_cont_area_body_div_btndiv">
              {type === "edit" ? (
                <button
                  onClick={editProduct}
                  disabled={processing}
                  className="product_detail_cont_div5_7_btn"
                  style={{ maxWidth: "100%" }}
                >
                  {processing && <ClipLoader color="#fff" size={18} />}{" "}
                  {processing ? "editing..." : "  Edit your product"}
                </button>
              ) : (
                <button
                  onClick={listProduct}
                  disabled={processing}
                  className="product_detail_cont_div5_7_btn"
                  style={{ maxWidth: "100%" }}
                >
                  {processing && <ClipLoader color="#fff" size={18} />}{" "}
                  {processing ? "Listing..." : "  List Product On Dhive"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default ListProduct;
