import React, { useEffect, useState } from "react";
import "./index.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Blockies from "react-blockies";
import { useDispatch, useSelector } from "react-redux";
import { GET_BUSINESS_INFO } from "../../services/business";
import { setBusinessInfo } from "../../Redux/feautures/user/userSlice";
import Nodata from "../../Components/Nodata/Nodata";
import { app } from "../../utils/config";
import ReactTimeAgo from "react-time-ago";
import FormatNumber from "../../helper/formatNumber";
import MarketCap, {
  Price,
} from "../../Exchange/ExchangePages/ExchangeTrade/Liquidity/MarketCap";
import TVL from "../../Exchange/ExchangePages/ExchangeTrade/Liquidity/TVL";
import { GET_TRADE_DATA } from "../../services/exchange";
import CustomLoader from "../../Components/CustomLoader/CustomLoader";
import { numberWithCommas } from "../../assets/js/numberWithCommas";
import { formatDecimal } from "../../assets/js/formatDecimal";

const RwaHome = () => {
  const { Assets } = useSelector((state) => state.assets);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { wallet_address } = useSelector((state) => state.user);
  const { business_info } = useSelector((state) => state.user);
  const [tradesLoading, setTradesLoading] = useState(false);
  const [trades, setTrades] = useState([]);

  //// console.log("====================================");
  // // console.log(business_info);
  //// console.log("====================================");
  //// console.log("====================================");
  //// console.log(wallet_address);
  //// console.log("====================================");
  const getTrades = async () => {
    setTradesLoading(true);
    const res = await GET_TRADE_DATA({
      ticker: `${app?.BASE_TOKEN}-${business_info?.tokens?.tokenSymbol}`,
    });
    // console.log("====================================");
    // console.log(res, "TRADESSSSSSSSSSSSSSSSSSSSS");
    if (res?.success === true) {
      setTradesLoading(false);
      setTrades(res?.trades);
      return;
    }
    setTradesLoading(false);
    // console.log("====================================");
  };
  useEffect(() => {
    getTrades();
  }, [business_info]);

  // console.log("====================================");
  // console.log(business_info);
  // console.log("====================================");
  return (
    <div className="rwaBusinessDiv_area_div1">
      <div className="rwaBusinessDiv_area_div1_area1">
        <div className="rwaBusinessDiv_area_div1_cont1">
          Account{" "}
          <div className="rwaBusinessDiv_area_div1_cont1_area">
            <Blockies
              seed={wallet_address ? wallet_address : "0xXXXX"}
              size={8}
              scale={4}
              className="blockies_icon"
            />{" "}
            {wallet_address ? (
              <>
                {" "}
                {`${wallet_address?.slice(0, 4)}...${wallet_address?.slice(
                  37,
                  42
                )}`}
              </>
            ) : (
              <>0xXXXX</>
            )}
          </div>
        </div>
        {wallet_address ? (
          <>
            {business_info ? (
              <Link
                to="/business/details"
                className="rwaBusinessDiv_area_div1_cont2"
              >
                <button className="rwaBusinessDiv_area_div1_cont2_btn2">
                  Manage business
                </button>
              </Link>
            ) : (
              <Link
                to="/business/create"
                className="rwaBusinessDiv_area_div1_cont2"
              >
                <button className="rwaBusinessDiv_area_div1_cont2_btn2">
                  Tokenize Business
                </button>
              </Link>
            )}
          </>
        ) : (
          <Link to="/wallet" className="rwaBusinessDiv_area_div1_cont2">
            <button className="rwaBusinessDiv_area_div1_cont2_btn2">
              Create Account
            </button>
          </Link>
        )}
      </div>
      {/* <div className="createdBusiness_div_title">Business Stats</div> */}
      <div className="createdBusiness_div">
        {business_info === null ? (
          <Nodata message={"No business created"} />
        ) : (
          <>
            {" "}
            <div className="createdBusiness_div_cont1">
              <div className="tableBody_row_data_first_div">
                <img
                  // src="/img/egoras_logo_icon.svg"
                  src={business_info?.tokens?.image}
                  alt=""
                  className="tableBody_row_data_first_div_img"
                />
                <div className="tableBody_row_data_first_div_cont1">
                  <div className="tableBody_row_data_first_div_cont1_title">
                    {business_info?.businesses?.businessName}
                    <span className="tableBody_row_data_first_div_cont1_title_Span">
                      ${business_info?.tokens?.tokenSymbol}
                    </span>
                  </div>
                  <div className="RwaBusinessDetails_div_area_1_cont1_title_addr">
                    <div className="tableBody_row_data_first_div_cont1_addr_div1">
                      {wallet_address ? (
                        <>
                          {" "}
                          {`${wallet_address?.slice(
                            0,
                            4
                          )}...${wallet_address?.slice(37, 42)}`}
                        </>
                      ) : (
                        <>0xXXXX</>
                      )}
                    </div>{" "}
                    <div className="tableBody_row_data_first_div_cont1_addr_div2">
                      {business_info?.businesses?.businessType}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="createdBusiness_div_cont2">
              <div className="createdBusiness_div_cont2_area1">Market Cap</div>
              <div className="createdBusiness_div_cont2_area2">
                $
                <MarketCap
                  tokenAddress={business_info?.tokens?.contractAddress}
                  data={business_info}
                  totalSupply={business_info?.tokens?.initialSupply}
                />
              </div>
            </div>
            {/* <div className="createdBusiness_div_cont2">
              <div className="createdBusiness_div_cont2_area1">TVL</div>
              <div className="createdBusiness_div_cont2_area2">$108.8M</div>
            </div> */}
            <div className="createdBusiness_div_cont2">
              <div className="createdBusiness_div_cont2_area1"> TVL</div>
              <div className="createdBusiness_div_cont2_area2">
                $
                <TVL
                  tokenAddress={business_info?.tokens?.contractAddress}
                  data={business_info}
                />
              </div>
            </div>
            <div className="createdBusiness_div_cont2">
              <div className="createdBusiness_div_cont2_area1"> Price </div>
              <div className="createdBusiness_div_cont2_area2">
                <Price
                  tokenAddress={business_info?.tokens?.contractAddress}
                  data={business_info}
                  totalSupply={business_info?.tokens?.initialSupply}
                />{" "}
                egax
              </div>
            </div>
            {/* <div className="createdBusiness_div_cont2">
              <div className="createdBusiness_div_cont2_area1">Holders</div>
              <div className="createdBusiness_div_cont2_area2">10,000</div>
            </div> */}
            <div className="createdBusiness_div_cont2">
              <div className="createdBusiness_div_cont2_area1">Status</div>
              <div className="createdBusiness_div_cont2_area2">
                {" "}
                <div className="verified_div">
                  {business_info?.businesses?.businessStatus}
                </div>
              </div>
            </div>
            <Link
              to="/business/details"
              style={{
                margin: "auto",
                width: "fit-content",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <button className="rwaBusinessDiv_area_view_button">
                Manage Business
              </button>
            </Link>
          </>
        )}
      </div>
      {/* ===== */}
      {/* ===== */}
      <div style={{ marginTop: "1em" }}>
        <div className="trades_title">Exchange Trades</div>
        <div className="ExchangeTrade_area_2_cont1_body">
          {tradesLoading ? (
            <div style={{ background: "#fff", height: "300px" }}>
              <CustomLoader containerWidth={"100%"} margin={"10px 0px "} />
            </div>
          ) : (
            <>
              {trades.length === 0 ? (
                <Nodata message={"No trades data"} />
              ) : (
                <table className="TableCompWithDiv_cont_table">
                  <thead className="TableCompWithDiv_cont_head">
                    <tr className="TableCompWithDiv_cont_head_titles">
                      <th className="TableCompWithDiv_cont_head_titles_div TableCompWithDiv_cont_head_titles_div_first">
                        Account
                      </th>
                      <th className="TableCompWithDiv_cont_head_titles_div">
                        Type
                      </th>
                      <th className="TableCompWithDiv_cont_head_titles_div">
                        $EGAX
                      </th>
                      <th className="TableCompWithDiv_cont_head_titles_div">
                        ${business_info?.tokens?.tokenSymbol}
                      </th>
                      <th className="TableCompWithDiv_cont_head_titles_div">
                        Ticker
                      </th>
                      <th className="TableCompWithDiv_cont_head_titles_div">
                        Date
                      </th>
                      <th className="TableCompWithDiv_cont_head_titles_div  TableCompWithDiv_cont_head_titles_div_last">
                        Transaction
                      </th>
                    </tr>
                  </thead>
                  <tbody className="tableBody">
                    {trades
                      .sort(
                        (b, a) => new Date(a.createdAt) - new Date(b.createdAt)
                      )
                      ?.map((data) => {
                        return (
                          <tr className="tableBody_row ">
                            <td className="tableBody_row_data tableBody_row_data_first  ">
                              <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_value">
                                <Blockies
                                  seed={data?.user}
                                  size={5}
                                  scale={4}
                                  className="blockies_icon"
                                />{" "}
                                {data?.user ? (
                                  <>
                                    {" "}
                                    {`${data?.user?.slice(
                                      0,
                                      4
                                    )}...${data?.user?.slice(37, 42)}`}
                                  </>
                                ) : (
                                  <>0xXXXX</>
                                )}
                              </div>
                            </td>

                            <td className="tableBody_row_data">
                              <div
                                className="typeBuy"
                                style={{
                                  color:
                                    data?.swapType == "SELL"
                                      ? "#ff4b4b"
                                      : "#00c055",
                                }}
                              >
                                {data?.swapType}
                              </div>
                            </td>
                            <td className="tableBody_row_data">
                              {numberWithCommas(
                                formatDecimal(
                                  parseFloat(
                                    data?.swapType == "SELL"
                                      ? data?.amountOut
                                      : data?.amountIn
                                  )
                                )
                              )}
                            </td>
                            <td className="tableBody_row_data">
                              {numberWithCommas(
                                formatDecimal(
                                  parseFloat(
                                    data?.swapType == "SELL"
                                      ? data?.amountIn
                                      : data?.amountOut
                                  )
                                )
                              )}
                            </td>
                            <td className="tableBody_row_data">
                              {data?.ticker}
                            </td>
                            <td className="tableBody_row_data">
                              <ReactTimeAgo
                                date={data?.createdAt}
                                locale="en-US"
                              />
                            </td>
                            <td className="tableBody_row_data tableBody_row_data_last">
                              {" "}
                              {data?.txHash ? (
                                <>
                                  {" "}
                                  {`${data?.txHash?.slice(
                                    0,
                                    4
                                  )}...${data?.txHash?.slice(37, 42)}`}
                                </>
                              ) : (
                                <>0xXXXX</>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RwaHome;
