import React, { useState } from "react";
import "./index.css";
import WalletHome from "./WalletHome/WalletHome";
import TransactionModal from "../Components/TransactionModal/TransactionModal";
const WalletPop = ({
  closePop,
  transaction,
  transactionType,
  transact,
  loading,
  tokenName,
}) => {
  return (
    <div className="WalletPop_div">
      <div className="WalletPop_div_close_div" onClick={closePop}></div>
      <div className="WalletPop_div_cont">
        {transaction ? (
          <TransactionModal
            closePop={closePop}
            transactionType={transactionType}
            transact={transact}
            loading={loading}
            tokenName={tokenName}
          />
        ) : (
          <WalletHome closePop={closePop} />
        )}
      </div>
    </div>
  );
};

export default WalletPop;
