import React, { useState, useEffect } from "react";
import "./index.css";
import AddLiquidity from "./AddLiquidity";
import RemoveLiquidity from "./RemoveLiquidity";
import PoolInfo from "./PoolInfo/PoolInfo";
import { useNavigate } from "react-router-dom";
import {
  AddCircleIcon,
  ArrowLeft01Icon,
  Cancel01Icon,
  RemoveCircleIcon,
} from "hugeicons-react";
import { useSelector } from "react-redux";
import { RefreshIcon } from "./RefreshIcon";
import { liquidityDetail } from "./Utils/liquidtiyDetail";
const EGAX_USD_Price = 0.45;
const Liquidity = ({ cancel, ticker, data, baseToken }) => {
  const navigate = useNavigate();
  const { privateKey, wallet_address } = useSelector((state) => state.user);
  // ========
  // ========
  // ========
  const [lpProvided, setLpProvided] = useState(false); // Replace with real data check
  const [showAddLiquidity, setShowAddLiquidity] = useState(false);
  const [showRemoveLiquidity, setShowRemoveLiquidity] = useState(false);
  const [egaxPoolVal, setEgaxPoolVal] = useState(0);
  const [tokenPoolVal, setTokenPoolVal] = useState(0);
  const [toTalegaxPoolVal, setToTalegaxPoolVal] = useState(0);
  const [toTaltokenPoolVal, setToTaltokenPoolVal] = useState(0);
  const [liquidityLoading, setLiquidityLoading] = useState(true);
  const [tokenPriceInEgax, setTokenPriceInEgax] = useState(0);
  const [tokenPriceInUSD, setTokenPriceInUSD] = useState(0);
  const [userLpBalance, setUserLpBalance] = useState(0);
  const [pairAddress, setPairAddress] = useState("");
  const [userShareOfPool, setUserShareOfPool] = useState("0%");
  const [tvl, setTvl] = useState(0);

  const fetchLiquid = async () => {
    setLiquidityLoading(true);
    const res = await liquidityDetail(
      data?.contractAddress,
      wallet_address,
      baseToken
    );

    // console.log("====================================");
    // console.log(res);
    // console.log("====================================");

    if (res.error) {
      console.error("Error fetching liquidity details:", res.error);
    } else {
      setToTalegaxPoolVal(res?.totalEgaxPoolVal);
      setToTaltokenPoolVal(res?.totalTokenPoolVal);
      setEgaxPoolVal(res?.userShareEgax);
      setTokenPoolVal(res?.userShareToken);
      setLpProvided(res?.lpProvided);
      setUserLpBalance(res?.userLPBalance);
      setPairAddress(res?.pairAddress);
      setUserShareOfPool(res?.shareOfPool);
      setTokenPriceInEgax(res?.tokenPriceInEgax);
      setTokenPriceInUSD(parseFloat(res?.tokenPriceInEgax) * EGAX_USD_Price);
      setTvl(parseFloat(res?.tvlInEgax));
    }
    setLiquidityLoading(false);
  };

  useEffect(() => {
    fetchLiquid();
  }, []);

  //// console.log("====================================");
  //// console.log(data);
  //// console.log("====================================");

  return (
    <div className="LiquidityModal">
      <Cancel01Icon className="LiquidityModal_close" onClick={cancel} />
      <div className="LiquidityDiv_cont">
        <div className="LiquidityDiv_cont_area">
          <LiquidityHeader
            callGetAmountsOut={null}
            loading={liquidityLoading}
            data={data}
            baseToken={baseToken}
          />
          {!wallet_address ? (
            <>
              <div className="noLiquidityDiv">
                <div className="noLiquidityDiv_title">
                  No account found
                  <div className="noLiquidityDiv_para">
                    You have no dhive web3 wallet created / connected, create a
                    wallet to continue.
                  </div>
                </div>
                <button
                  className="product_detail_cont_div5_7_btn"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => navigate("/wallet")}
                >
                  Create wallet
                </button>
              </div>
            </>
          ) : (
            <>
              {liquidityLoading ? (
                <div className="liquidty_spinner_cont">
                  <div className="spinner"></div>
                  <div className="liquidty_spinner_cont_txt">
                    Fetching liquidity details...
                  </div>
                </div>
              ) : showAddLiquidity ? (
                <AddLiquidity
                  back={() => setShowAddLiquidity(false)}
                  data={data}
                  tokenPriceInUSD={tokenPriceInUSD}
                  tokenPriceInEgax={tokenPriceInEgax}
                  totalEgaxPoolVal={toTalegaxPoolVal}
                  totalTokenPoolVal={toTaltokenPoolVal}
                  fetchLiquid={fetchLiquid}
                  baseToken={baseToken}
                />
              ) : showRemoveLiquidity ? (
                <RemoveLiquidity
                  back={() => setShowRemoveLiquidity(false)}
                  data={data}
                  tokenPriceInEgax={tokenPriceInEgax}
                  egaxPoolVal={egaxPoolVal}
                  tokenPoolVal={tokenPoolVal}
                  userLpBalance={userLpBalance}
                  pairAddress={pairAddress}
                  userShareOfPool={userShareOfPool}
                  fetchLiquid={fetchLiquid}
                  baseToken={baseToken}
                />
              ) : lpProvided ? (
                <div>
                  <RemoveLiquidityInfo
                    onRemove={() => setShowRemoveLiquidity(true)}
                    egaxPoolVal={egaxPoolVal}
                    tokenPoolVal={tokenPoolVal}
                    userShareOfPool={userShareOfPool}
                    data={data}
                    addLiquid={() => setShowAddLiquidity(true)}
                    baseToken={baseToken}
                  />
                </div>
              ) : (
                <NoLiquidity onAddLiquidity={() => setShowAddLiquidity(true)} />
              )}
            </>
          )}

          <PoolInfo
            egaxPoolVal={egaxPoolVal}
            tokenPoolVal={tokenPoolVal}
            toTalegaxPoolVal={toTalegaxPoolVal}
            toTaltokenPoolVal={toTaltokenPoolVal}
            loading={liquidityLoading}
            data={data}
            userShareOfPool={userShareOfPool}
            TVL={tvl}
            baseToken={baseToken}
          />
        </div>
      </div>
    </div>
  );
};

const LiquidityHeader = ({ callGetAmountsOut, loading, data, baseToken }) => (
  <div className="LiquidityDiv_cont_cont1">
    <div className="LiquidityDiv_cont_cont1_div1">
      <img
        src={data?.image || "/img/art.png"}
        alt=""
        className="LiquidityDiv_cont_cont1_div1_img1"
      />
      <img
        src={
          baseToken === "EGAX"
            ? "/img/egax_logo.png"
            : baseToken === "DHV"
            ? "/img/dhiveCoinIcon.png"
            : "/img/art.png"
        }
        alt=""
        className="LiquidityDiv_cont_cont1_div1_img2"
      />
    </div>
    <div className="LiquidityDiv_cont_cont1_div2">
      {data?.tokenSymbol || "_ _"} / {baseToken}{" "}
      <div className="LiquidityDiv_cont_cont1_div2_tag">Fee 0.2%</div>
    </div>

    <>
      <RefreshIcon callGetAmountsOut={callGetAmountsOut} loading={loading} />
      {/* <span className="resfresh_icon_div_span">Upadting...</span> */}
    </>
  </div>
);

const NoLiquidity = ({ onAddLiquidity }) => (
  <div className="noLiquidityDiv">
    <div className="noLiquidityDiv_title">
      No liquidity provided
      <div className="noLiquidityDiv_para">
        You have no liquidity provided, add liquidity to earn fees on this pool.
      </div>
    </div>
    <button className="noLiquidityDiv_btn" onClick={onAddLiquidity}>
      <AddCircleIcon className="noLiquidityDiv_btn_icon" /> Add liquidity
    </button>
  </div>
);

const RemoveLiquidityInfo = ({
  onRemove,
  tokenPoolVal,
  egaxPoolVal,
  data,
  userShareOfPool,
  addLiquid,
  baseToken,
}) => (
  <div className="removeLiquidityDivHome">
    <div className="removeLiquidityDivHome_title">
      Your liquidity
      <div className="removeLiquidityDivHome_para">
        Remove liquidity to receive tokens back.
      </div>
    </div>
    <div className="removeLiquidityDivHome_area">
      <LiquidityDetails
        label={`Pooled ${data?.tokenSymbol || "_ _"}`}
        value={parseFloat(tokenPoolVal)?.toFixed(4)}
      />
      <LiquidityDetails
        label={`Pooled ${baseToken}`}
        value={parseFloat(egaxPoolVal)?.toFixed(4)}
      />
      <LiquidityDetails label="Share of pool" value={userShareOfPool} />
      <button className="noLiquidityDiv_btn" onClick={onRemove}>
        <RemoveCircleIcon className="noLiquidityDiv_btn_icon" /> Remove
        liquidity
      </button>

      <button className="add_liquid_btn_optionB" onClick={addLiquid}>
        Add liquidity instead
      </button>
    </div>
  </div>
);

const LiquidityDetails = ({ label, value }) => (
  <div className="removeLiquidityDivHome_area_2">
    <div className="removeLiquidityDivHome_area_2_cont1">{label}</div>
    <div className="removeLiquidityDivHome_area_2_cont2">{value}</div>
  </div>
);

export default Liquidity;
