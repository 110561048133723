import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userBal: 100000,
};

const userBalance = createSlice({
  name: "balance",
  initialState,
  reducers: {
    setUserBalance: (state, action) => {
      state.userBal = action.payload;
    },
  },
});

export const { setUserBalance } = userBalance.actions;
export default userBalance.reducer;
