import React from "react";
import { useSelector } from "react-redux";
import TokenList2 from "./TokenList2";

const WalletModel = ({
  setSelectedAsset,
  selectedAsset,
  handleOnClick,
  hideLowBal = false,
  cb,
}) => {
  const {
    Assets,
    providers,
    activeProvider: selectedChain,
  } = useSelector((state) => state.assets);

  return (
    <div className="WalletPop_div_cont_walletHome_div2_body">
      <TokenList2
        hideLowBal={false}
        // selectedChain={}
      />
      {selectedChain.network != "EGOCHAIN NETWORK"
        ? Assets.filter(
            (dd) => dd.tokenType === "native" || dd.tokenBName === "USDC"
          )?.map((data) => {
            let assImg = data?.img || data?.image || "/img/art.png";

            if (data?.tokenType === "native") {
              assImg = selectedChain?.img || selectedChain?.image;
            }

            return (
              <div
                key={data?.id}
                className="WalletPop_div_cont_walletHome_div2_body_asset"
                onClick={() => {
                  //console.log("plais", data);
                  setSelectedAsset(data);
                  handleOnClick();
                }}
              >
                <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont1">
                  <img
                    // src={data?.img || data?.image || "/img/art.png"}
                    src={assImg}
                    alt=""
                    className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_img"
                  />
                  <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_area">
                    <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_area_title">
                      {data?.tokenType === "native"
                        ? selectedChain?.baseToken
                        : data?.tokenBName}
                    </div>
                    <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_area_price">
                      {data?.tokenType != "native" &&
                        parseFloat(data?.currentPrice || 0)?.toFixed(4)}
                    </div>
                  </div>
                </div>
                <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont2">
                  <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_area_title">
                    {parseFloat(data?.balance || 0)?.toFixed(4)}
                  </div>
                  <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_area_price">
                    {parseFloat(
                      (data?.balance || 0) * (data?.currentPrice || 0)
                    )?.toFixed(4)}
                  </div>
                </div>
              </div>
            );
          })
        : Assets?.filter(
            (data) => !hideLowBal || parseFloat(data?.balance) > 0
          )?.map((data) => {
            // if (data?.tokenType === "native")
            let assImg = data?.img || data?.image || "/img/art.png";

            if (data?.tokenType === "native") {
              assImg = selectedChain?.img || selectedChain?.image;
            }

            return (
              <div
                key={data?.id}
                className="WalletPop_div_cont_walletHome_div2_body_asset"
                onClick={() => {
                  setSelectedAsset(data);
                }}
              >
                <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont1">
                  <img
                    // src={data?.img || data?.image || "/img/art.png"}
                    src={assImg}
                    alt=""
                    className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_img"
                  />
                  <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_area">
                    <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_area_title">
                      {data?.tokenType === "native"
                        ? selectedChain?.baseToken
                        : data?.tokenBName}
                    </div>
                    <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_area_price">
                      {data?.tokenType != "native" &&
                        parseFloat(data?.currentPrice || 0)?.toFixed(4)}
                    </div>
                  </div>
                </div>
                <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont2">
                  <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_area_title">
                    {parseFloat(data?.balance || 0)?.toFixed(4)}
                  </div>
                  <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_area_price">
                    {parseFloat(
                      (data?.balance || 0) * (data?.currentPrice || 0)
                    )?.toFixed(4)}
                  </div>
                </div>
              </div>
            );
          })}
    </div>
  );
};

export default WalletModel;
