import React from "react";
import "./index.css";
const CustomLoader = ({
  padding,
  borderSize,
  width,
  height,
  containerWidth,
  margin,
  borderColor,
  justifyContent,
  containerHeight = "100%",
}) => {
  return (
    <div
      className="spinner_container"
      style={{
        padding: padding,
        width: containerWidth,
        margin: margin,
        justifyContent: justifyContent,
        height: containerHeight,
      }}
    >
      <div
        className="spinner"
        style={{
          width: width,
          height: height,
          border: `${borderSize} solid #c7c6d5`,
          borderTop: `${borderSize} solid #6059fd`,
          borderColor: borderColor,
        }}
      ></div>
    </div>
  );
};

export default CustomLoader;
