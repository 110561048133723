import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { getPrice } from "./getPrice";
import { formatDecimal } from "../../../../assets/js/formatDecimal";

const MarketCap = ({ tokenAddress, data, totalSupply }) => {
  const { privateKey, wallet_address } = useSelector((state) => state.user);

  const [tokenPriceInUSD, setTokenPriceInUSD] = useState(0);
  const [marketCapLoader, setMarketCapLoader] = useState(false);

  const fetchTokenPrice = async () => {
    setMarketCapLoader(true);
    const res = await getPrice(tokenAddress, wallet_address);

    if (res.error) {
      console.error("Error fetching token price:", res.error);
      setMarketCapLoader(false);
    } else {
      setTokenPriceInUSD(res?.tokenPriceInUsd);
    }
    setMarketCapLoader(false);
  };

  useEffect(() => {
    if (data) {
      fetchTokenPrice();
    }
  }, [data]);

  if (marketCapLoader) {
    return <ClipLoader color="#605afd" size={18} />;
  }

  const marketCap = parseFloat(tokenPriceInUSD * totalSupply);

  return formatDecimal(marketCap);
};
export const Price = ({ tokenAddress, data }) => {
  const { privateKey, wallet_address } = useSelector((state) => state.user);
  const [tokenPriceInEgax, setTokenPriceInEgax] = useState(0);
  const [marketCapLoader, setMarketCapLoader] = useState(false);

  const fetchTokenPrice = async () => {
    setMarketCapLoader(true);
    const res = await getPrice(tokenAddress, wallet_address);

    if (res.error) {
      console.error("Error fetching token price:", res.error);
      setMarketCapLoader(false);
    } else {
      setTokenPriceInEgax(res?.tokenPriceInEgax);
    }
    setMarketCapLoader(false);
  };

  useEffect(() => {
    if (data) {
      fetchTokenPrice();
    }
  }, [data]);

  if (marketCapLoader) {
    return <ClipLoader color="#605afd" size={18} />;
  }

  const price = tokenPriceInEgax;

  return formatDecimal(price);
};

export default MarketCap;
