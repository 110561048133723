import React, { useState, useEffect } from "react";
import ExchangeTrade from "./ExchangeTrade";
import {
  Briefcase01Icon,
  ChartLineData02Icon,
  UnavailableIcon,
} from "hugeicons-react";
import "./index.css";
import { Camera02Icon, ArrowLeft01Icon } from "hugeicons-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Blockies from "react-blockies";
import {
  InformationCircleIcon,
  CheckmarkBadge01Icon,
  ShoppingCart01Icon,
} from "hugeicons-react";
import "../../../BusinessCreation/RWABusiness/index.css";
import {
  YoutubeIcon,
  NewTwitterIcon,
  Facebook01Icon,
  InstagramIcon,
  TiktokIcon,
} from "hugeicons-react";
import ExchangeProductDetail from "./ExchangeProductDetail";
import {
  GET_BUSINESS_INFO,
  PUBLIC_BUSINESS_INFO,
} from "../../../services/business";
import ComponentLoader from "../../../Components/ComponentLoader/ComponentLoader";
import ClipLoader from "react-spinners/ClipLoader";
import Nodata from "../../../Components/Nodata/Nodata";
import { Store03Icon } from "hugeicons-react";

const ExchangeTradeHome = () => {
  const { businessName } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("trade");
  const [imagePreview, setImagePreview] = useState(null);
  const [productDetail, setProductDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const previewLength = 350; // Adjust the preview length as needed

  const [businessInfo, setBusinessInfo] = useState(null);
  const fetchBusinessInfo = async () => {
    setLoading(true);
    const res = await PUBLIC_BUSINESS_INFO({ id: businessName });
    console.log(res, "business ini");
    setLoading(false);
    if (!res.success) {
      return;
    }
    setBusinessInfo(res);
  };

  const toggleProductDetail = (data) => {
    setProductDetail(data);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      //   setSelectedFile(file);
      setImagePreview(URL.createObjectURL(file)); // For previewing the selected image
    }
    //// console.log("====================================");
    //// console.log(imagePreview);
    //// console.log("====================================");
  };

  useEffect(() => {
    //// console.log(businessName);
    fetchBusinessInfo();
  }, [businessName]);

  //// console.log(businessInfo?.products?.length);
  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  // console.log("====================================");
  // console.log(businessInfo);
  // console.log("====================================");

  console.log("====================================");
  console.log(businessInfo);
  console.log("====================================");
  return (
    <>
      <div className="container">
        <div className="RwaBusinessDetails_div_area">
          <div className="ListModal_cont_area_back_button">
            {" "}
            <div
              className="ListModal_cont_area_back_button_div1"
              onClick={() => {
                navigate("/");
              }}
            >
              <ArrowLeft01Icon />
              Back
            </div>
          </div>
          <div className="coverPhototDiv">
            <div className="coverPhototDiv_dark_bg1"></div>

            {loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <ClipLoader color="#6059fd" size={42} />
              </div>
            ) : (
              <img
                src="/img/coverphoto.jpg"
                alt=""
                className="coverPhototDiv_img"
              />
            )}

            <div className="coverPhototDiv_dark_bg2"></div>
          </div>
          <div className="RwaBusinessDetails_div_area_1">
            <div className="RwaBusinessDetails_div_area_1_cont1">
              <div className="tableBody_row_data_first_div">
                <div className="topGeeBusiness_business_div_cont1_img_div">
                  {loading ? (
                    <div className="RwaBusinessDetails_div_area_1_img_loader">
                      <ClipLoader color="#6059fd" size={42} />
                    </div>
                  ) : (
                    <img
                      src={businessInfo?.businesses?.image}
                      alt=""
                      className="topGeeBusiness_business_div_cont1_img"
                    />
                  )}
                  <div className="origin_div">
                    {businessInfo?.businesses?.network === "EGOCHAIN" ? (
                      <img
                        src="/img/egax_logo.png"
                        alt=""
                        className="origin_div_img"
                      />
                    ) : businessInfo?.businesses?.network === "BASE" ? (
                      <img
                        src="/img/baseLogo.png"
                        alt=""
                        className="origin_div_img"
                      />
                    ) : null}
                  </div>
                </div>
                <div className="tableBody_row_data_first_div_cont1">
                  <div className="RwaBusinessDetails_div_area_1_cont1_title">
                    {loading ? (
                      <>--</>
                    ) : (
                      // </div>
                      <> {businessInfo?.businesses?.businessName}</>
                    )}
                    {businessInfo?.businesses?.businessStatus ==
                    "UNVERIFIED" ? (
                      <UnavailableIcon className="tableBody_row_data_stats_div_title_verifyBadge_warn" />
                    ) : (
                      <CheckmarkBadge01Icon className="tableBody_row_data_stats_div_title_verifyBadge" />
                    )}
                    <span className="tableBody_row_data_stats_div_title_span">
                      {" "}
                      $
                      {loading ? (
                        <>--</>
                      ) : (
                        // </div>
                        <> {businessInfo?.businesses?.tokenSymbol}</>
                      )}
                      {!businessInfo ? null : (
                        <img
                          alt={businessInfo?.businesses?.country}
                          src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${businessInfo?.businesses?.country}.svg`}
                          className="tableBody_row_data_stats_div_title_span_country"
                        />
                      )}
                    </span>
                  </div>
                  <div className="cashbackDiv">
                    <div className="cashbackDiv_title">
                      Product cash back
                      <InformationCircleIcon className="cashbackDiv_title_icon" />
                      :
                    </div>
                    <div className="cashbackDiv_value">
                      {loading ? (
                        <>--</>
                      ) : (
                        // </div>
                        <> {businessInfo?.businesses?.burnPercentage}</>
                      )}
                      %
                    </div>
                  </div>
                  <div className="RwaBusinessDetails_div_area_1_cont1_title_addr">
                    <div className="tableBody_row_data_first_div_cont1_addr_div1">
                      {businessInfo?.businesses?.createdBy ? (
                        <>
                          {" "}
                          {`${businessInfo?.businesses?.createdBy?.slice(
                            0,
                            4
                          )}...${businessInfo?.businesses?.createdBy?.slice(
                            37,
                            42
                          )}`}
                        </>
                      ) : (
                        <>0xXXXX</>
                      )}
                    </div>{" "}
                    <div className="tableBody_row_data_first_div_cont1_addr_div2">
                      {loading ? (
                        <>--</>
                      ) : (
                        // </div>
                        <> {businessInfo?.businesses?.businessType}</>
                      )}{" "}
                    </div>
                  </div>
                  <div className="tableBody_row_data_first_div_cont1_addr_time">
                    {loading ? (
                      <>--</>
                    ) : (
                      // </div>
                      <> {businessInfo?.createdAt}</>
                    )}{" "}
                  </div>
                </div>
              </div>
              <div className="RwaBusinessDetails_div_area_1_cont1_para">
                {isExpanded
                  ? businessInfo?.businesses?.businessDescription
                  : `${businessInfo?.businesses?.businessDescription?.slice(
                      0,
                      previewLength
                    )} ... `}
                <span
                  onClick={toggleExpanded}
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  {isExpanded ? " See less" : "See more"}
                </span>
              </div>
              <div className="RwaBusinessDetails_div_area_1_cont1_div3">
                <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1">
                  <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_title">
                    Business Owner
                  </div>
                  <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_value">
                    {loading ? (
                      <>__</>
                    ) : (
                      // </div>
                      <>
                        {" "}
                        <Blockies
                          seed={
                            businessInfo?.businesses?.createdBy
                              ? businessInfo?.businesses?.createdBy
                              : "0xXXXX"
                          }
                          size={5}
                          scale={4}
                          className="blockies_icon"
                        />{" "}
                        {businessInfo?.businesses?.createdBy ? (
                          <>
                            {" "}
                            {`${businessInfo?.businesses?.createdBy?.slice(
                              0,
                              4
                            )}...${businessInfo?.businesses?.createdBy?.slice(
                              37,
                              42
                            )}`}
                          </>
                        ) : (
                          <>0xXXXX</>
                        )}
                      </>
                    )}{" "}
                  </div>
                </div>
                <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1">
                  <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_title">
                    Business Status
                  </div>
                  <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_value">
                    {loading ? (
                      <>__</>
                    ) : (
                      // </div>
                      <> {businessInfo?.businesses?.businessStatus}</>
                    )}{" "}
                  </div>
                </div>
                {/* <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1">
                  <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_title">
                    All Time Volume
                  </div>
                  <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_value">
                    $
                    {loading ? (
                      <>__</>
                    ) : (
                      // </div>
                      <>12.2B</>
                    )}{" "}
                  </div>
                </div> */}
                <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1">
                  <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_title">
                    Yearly Revenue
                  </div>
                  <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_value">
                    {loading ? (
                      <>__</>
                    ) : (
                      // </div>
                      <> {businessInfo?.businesses?.businessRevenue}</>
                    )}{" "}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="socialsDiv">
              <div className="socialsDiv_title">Socials</div>
              <div className="socialsDiv_area">
                <div className="socialsDiv_area_cont1">
                  <YoutubeIcon size={24} />
                </div>
                <div className="socialsDiv_area_cont1">
                  <NewTwitterIcon size={24} />
                </div>
                <div className="socialsDiv_area_cont1">
                  <TiktokIcon size={24} />
                </div>
                <div className="socialsDiv_area_cont1">
                  <Facebook01Icon size={24} />
                </div>
                <div className="socialsDiv_area_cont1">
                  <InstagramIcon size={24} />
                </div>
              </div>
            </div> */}

            {!businessInfo ? null : (
              <>
                {businessInfo?.products?.length === 0 ? null : (
                  <Link
                    to={`/market/business/all/products/${businessInfo?.businesses?.businessName}`}
                  >
                    <button className="visitStoreBtn">
                      <Store03Icon className="visitStoreBtn_icon" /> Visit Store
                    </button>
                  </Link>
                )}
              </>
            )}
          </div>

          <ExchangeTrade
            data={businessInfo?.tokens}
            businessStatus={businessInfo?.businesses?.businessStatus}
          />
        </div>
      </div>
    </>
  );
};

export default ExchangeTradeHome;
