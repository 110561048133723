import React, { useState, useEffect } from "react";
import "./index.css";
import FlipCountdown from "@rumess/react-flip-countdown";
import Blockies from "react-blockies";
import { AddressShortener } from "../../../utils/AddressShortener";
import { numberWithCommas } from "../../../assets/js/numberWithCommas";
import CustomLoader from "../../../Components/CustomLoader/CustomLoader";
import { GET_TRADE_LEADERBOARD } from "../../../services/exchange";
import Nodata from "../../../Components/Nodata/Nodata";

const TradingCompetition = () => {
  const [visibleItems, setVisibleItems] = useState(10);
  const [loading, setLoading] = useState(false);
  const [dataloading, setDataLoading] = useState(false);
  const [leaderBoard, setLeaderBoard] = useState([]);

  const fetchLeaderBoard = async () => {
    setDataLoading(true);
    const res = await GET_TRADE_LEADERBOARD();
    // console.log(res, "fetchLeaderBoard");
    if (!res?.success) {
      setDataLoading(false);
      setLeaderBoard([]);
      return;
    }
    setDataLoading(false);
    setLeaderBoard(res?.data);
  };
  useEffect(() => {
    fetchLeaderBoard();
  }, []);

  const loadMoreArray = () => {
    setLoading(true);

    setTimeout(() => {
      setVisibleItems((prev) => Math.min(prev + 10, leaderBoard.length));
      setLoading(false);
    }, 1000); // Simulating API delay
  };
  //

  // Sort leaderboard by volume (highest first)
  const sortedLeaderboard = [...leaderBoard].sort(
    (a, b) => b.tradeVolume - a.tradeVolume
  );

  // Extract top 3 positions
  const topThree = sortedLeaderboard.slice(0, 3);
  const others = sortedLeaderboard.slice(3);
  return (
    <div className="tradingCompetitionDiv">
      <div className="container">
        <div className="tradingCompetitionDiv_Area">
          <div className="tradingCompetitionDiv_Area_cont1">
            <div className="tradingCompetitionDiv_Area_cont1_area1">
              <div className="tradingCompetitionDiv_Area_cont1_title">
                Dhive Trading Event
              </div>
              <div className="tradingCompetitionDiv_Area_cont1_para">
                Trade businesses listed on dhive to win a share of
                <br />
                <span className="prizeAmount">$50,000</span>
              </div>
              {/* <div className="tradingCompetitionDiv_Area_cont1_countDown">
                <div className="tradingCompetitionDiv_Area_cont1_countDown_title">
                  Event Countdown
                </div>{" "}
                <div className="tradingCompetitionDiv_Area_cont1_countDown_div">
                  <FlipCountdown
                    titlePosition="bottom"
                    theme="light"
                    size="extra-small"
                    hideYear
                    endAtZero
                    endAt={"2025-5-30 00:00:00"} // Date/Time
                    onTimeUp={() => // console.log("Time's up ⏳")}
                  />
                </div>
              </div> */}
            </div>
            <div className="tradingCompetitionDiv_Area_cont1_area2">
              <img
                src="/img/blueGift.avif"
                alt=""
                className="tradingCompetitionDiv_Area_cont1_area2_img"
              />
            </div>
          </div>
          <div className="tradingCompetitionDiv_Area_cont2">
            <div className="tradingCompetitionDiv_Area_cont2_area1">
              <div className="tradingCompetitionDiv_Area_cont2_area1_cont1">
                <div className="tradingCompetitionDiv_Area_cont2_area1_cont1_div1">
                  <img
                    src="/img/leaderBoardTitleImg2.svg"
                    alt=""
                    className="tradingCompetitionDiv_Area_cont2_area1_cont1_div1_img"
                  />
                  <div className="tradingCompetitionDiv_Area_cont2_area1_cont1_div1_userAddr_div">
                    <Blockies
                      seed={topThree[1]?.user || 0}
                      size={10}
                      scale={4}
                      className="tradingCompetitionDiv_Area_cont2_area1_cont1_div1_userAddr"
                    />
                  </div>
                  <span className="tradingCompetitionDiv_Area_cont2_area1_cont1_div1_address">
                    {AddressShortener(topThree[1]?.user || "0xxxxxxx")}
                  </span>
                </div>
                <div className="tradingCompetitionDiv_Area_cont2_area1_cont1_div2">
                  <div className="tradingCompetitionDiv_Area_cont2_area1_cont1_div2_title">
                    Volume
                  </div>
                  <div className="tradingCompetitionDiv_Area_cont2_area1_cont1_div2_value">
                    <img
                      src="https://res.cloudinary.com/itechsuite/image/upload/c_thumb,w_200,g_face/v1738169483/a5fckqt8vkjjdfbnb737.png"
                      alt=""
                      className="tradingCompetitionDiv_Area_cont2_area1_cont1_div2_value_img"
                    />{" "}
                    {numberWithCommas(
                      parseFloat(topThree[1]?.tradeVolume || 0).toFixed(2)
                    )}
                  </div>
                  <div className="tradingCompetitionDiv_Area_cont2_area1_cont1_div2_position">
                    2
                  </div>
                  <div className="tradingCompetitionDiv_Area_cont2_area1_cont1_div2_fadeDiv"></div>
                </div>
              </div>
              {/* === */}
              {/* === */}
              {/* === */}
              {/* === */}
              {/* === */}
              <div className="tradingCompetitionDiv_Area_cont2_area1_cont1">
                <div className="tradingCompetitionDiv_Area_cont2_area1_cont1_div1">
                  <img
                    src="/img/leaderBoardTitleImg.svg"
                    alt=""
                    className="tradingCompetitionDiv_Area_cont2_area1_cont1_div1_img"
                  />
                  <div className="tradingCompetitionDiv_Area_cont2_area1_cont1_div1_userAddr_div">
                    <Blockies
                      seed={topThree[0]?.user || "0xxxxxxx"}
                      size={10}
                      scale={4}
                      className="tradingCompetitionDiv_Area_cont2_area1_cont1_div1_userAddr"
                    />
                  </div>
                  <span className="tradingCompetitionDiv_Area_cont2_area1_cont1_div1_address">
                    {AddressShortener(topThree[0]?.user || "0xxxxxxx")}
                  </span>
                </div>
                <div className="tradingCompetitionDiv_Area_cont2_area1_cont1_div2">
                  <div className="tradingCompetitionDiv_Area_cont2_area1_cont1_div2_title">
                    Volume
                  </div>
                  <div className="tradingCompetitionDiv_Area_cont2_area1_cont1_div2_value">
                    <img
                      src="https://res.cloudinary.com/itechsuite/image/upload/c_thumb,w_200,g_face/v1738169483/a5fckqt8vkjjdfbnb737.png"
                      alt=""
                      className="tradingCompetitionDiv_Area_cont2_area1_cont1_div2_value_img"
                    />{" "}
                    {numberWithCommas(
                      parseFloat(topThree[0]?.tradeVolume || 0).toFixed(2)
                    )}
                  </div>
                  <div className="tradingCompetitionDiv_Area_cont2_area1_cont1_div2_position">
                    1
                  </div>
                  <div className="tradingCompetitionDiv_Area_cont2_area1_cont1_div2_fadeDiv"></div>
                </div>
              </div>
              {/* ==== */}
              {/* ==== */}
              {/* ==== */}
              {/* ==== */}
              {/* ==== */}
              <div className="tradingCompetitionDiv_Area_cont2_area1_cont1">
                <div className="tradingCompetitionDiv_Area_cont2_area1_cont1_div1">
                  <img
                    src="/img/leaderBoardTitleImg3.svg"
                    alt=""
                    className="tradingCompetitionDiv_Area_cont2_area1_cont1_div1_img"
                  />
                  <div className="tradingCompetitionDiv_Area_cont2_area1_cont1_div1_userAddr_div">
                    <Blockies
                      seed={topThree[2]?.user || "0xxxxxxx"}
                      size={10}
                      scale={4}
                      className="tradingCompetitionDiv_Area_cont2_area1_cont1_div1_userAddr"
                    />
                  </div>
                  <span className="tradingCompetitionDiv_Area_cont2_area1_cont1_div1_address">
                    {AddressShortener(topThree[2]?.user || "0xxxxxxx")}
                  </span>
                </div>
                <div className="tradingCompetitionDiv_Area_cont2_area1_cont1_div2">
                  <div className="tradingCompetitionDiv_Area_cont2_area1_cont1_div2_title">
                    Volume
                  </div>
                  <div className="tradingCompetitionDiv_Area_cont2_area1_cont1_div2_value">
                    <img
                      src="https://res.cloudinary.com/itechsuite/image/upload/c_thumb,w_200,g_face/v1738169483/a5fckqt8vkjjdfbnb737.png"
                      alt=""
                      className="tradingCompetitionDiv_Area_cont2_area1_cont1_div2_value_img"
                    />{" "}
                    {numberWithCommas(
                      parseFloat(topThree[2]?.tradeVolume || 0).toFixed(2)
                    )}
                  </div>
                  <div className="tradingCompetitionDiv_Area_cont2_area1_cont1_div2_position">
                    3
                  </div>
                  <div className="tradingCompetitionDiv_Area_cont2_area1_cont1_div2_fadeDiv"></div>
                </div>
              </div>
            </div>
            <div className="tradingCompetitionDiv_Area_cont2_area2">
              {dataloading ? (
                <div
                  style={{ background: "#fff", height: "300px", width: "100%" }}
                >
                  <CustomLoader containerWidth={"100%"} margin={"10px 0px "} />
                </div>
              ) : (
                <>
                  {others?.length === 0 ? (
                    <div
                      style={{
                        background: "#fff",
                        height: "300px",
                        width: "100%",
                      }}
                    >
                      <Nodata message={"No leaderboard yet"} />
                    </div>
                  ) : (
                    <>
                      <table className="TableCompWithDiv_cont_table">
                        <thead className="TableCompWithDiv_cont_head">
                          <tr className="TableCompWithDiv_cont_head_titles">
                            <th className="TableCompWithDiv_cont_head_titles_div TableCompWithDiv_cont_head_titles_div_first">
                              Rank
                            </th>
                            <th className="TableCompWithDiv_cont_head_titles_div ">
                              Trader
                            </th>
                            <th className="TableCompWithDiv_cont_head_titles_div  TableCompWithDiv_cont_head_titles_div_last">
                              Volume
                            </th>
                          </tr>
                        </thead>
                        <tbody className="tableBody">
                          {others.slice(0, visibleItems)?.map((data, index) => (
                            <tr className="tableBody_row" key={index + 3}>
                              <td className="tableBody_row_data tableBody_row_data_first">
                                <div className="leaderBoardRankDiv">
                                  {index + 4}{" "}
                                  <img
                                    src="/img/rank_icon.svg"
                                    alt=""
                                    className="leaderBoardRankDiv_img"
                                  />
                                </div>
                              </td>
                              <td className="tableBody_row_data">
                                <div className="leaderBoardAddressDiv">
                                  <Blockies
                                    seed={data?.user}
                                    size={6}
                                    scale={4}
                                    className="leaderBoardAddressDiv_bllockies"
                                  />{" "}
                                  {AddressShortener(data?.user)}
                                </div>
                              </td>
                              <td className="tableBody_row_data tableBody_row_data_last">
                                <div className="leaderBoardTableVolume">
                                  <img
                                    src="https://res.cloudinary.com/itechsuite/image/upload/c_thumb,w_200,g_face/v1738169483/a5fckqt8vkjjdfbnb737.png"
                                    alt=""
                                    className="tradingCompetitionDiv_Area_cont2_area1_cont1_div2_value_img"
                                  />{" "}
                                  {numberWithCommas(
                                    parseFloat(data?.tradeVolume).toFixed(2)
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {visibleItems < leaderBoard.length && (
                        <button
                          onClick={loadMoreArray}
                          disabled={loading}
                          className="tradingCompetitionDiv_Area_cont2_area2_btn"
                        >
                          {loading ? (
                            <>
                              <CustomLoader
                                width="18px"
                                height="18px"
                                padding="0px"
                                margin={"0px 5px 0px 0px"}
                                borderSize="2px"
                                borderColor="#fff #c2c0ff #c2c0ff"
                                containerWidth={"max-content"}
                              />
                              Loading...
                            </>
                          ) : (
                            "Load more"
                          )}
                        </button>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradingCompetition;
