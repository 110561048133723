import React, { useEffect, useState } from "react";
import "./index.css";
import Blockies from "react-blockies";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PUBLIC_BUSINESS_INFO } from "../../../services/business";
import Nodata from "../../../Components/Nodata/Nodata";
import { AddressShortener } from "../../../utils/AddressShortener";
import ReactTimeAgo from "react-time-ago";
import CustomLoader from "../../../Components/CustomLoader/CustomLoader";

const RwaProducts = () => {
  const navigate = useNavigate();
  const { wallet_address, business_info } = useSelector((state) => state.user);
  const [businessProducts, setBusinessProducts] = useState([]);
  const [businessProductsLoading, setBusinessProductsLoading] = useState(false);

  const fetchBusinessProducts = async () => {
    setBusinessProductsLoading(true);
    const res = await PUBLIC_BUSINESS_INFO({
      id: business_info?.businesses?.businessName,
    });
    // console.log(res);
    if (res?.success === true) {
      setBusinessProductsLoading(false);
      setBusinessProducts(res?.products);
      return;
    }
    setBusinessProductsLoading(false);
  };
  useEffect(() => {
    fetchBusinessProducts();
  }, [business_info]);

  //// console.log("====================================");
  //// console.log(businessProducts);
  //// console.log("====================================");

  //// console.log("====================================");
  //// console.log(businessProducts?.length);
  //// console.log("====================================");

  return (
    <div className="RwaProducts_div">
      <div className="ExchangeOrder_div_area">
        <div className="rwaBusinessDiv_area_div1_area1">
          <div className="rwaBusinessDiv_area_div1_cont1">
            Business
            <div
              className="tableBody_row_data_first_div"
              style={{ marginTop: "1em" }}
            >
              <img
                // src="/img/egoras_logo_icon.svg"
                src={business_info?.tokens?.image || "/img/emptyImg.png"}
                alt=""
                className="tableBody_row_data_first_div_img"
              />
              <div className="tableBody_row_data_first_div_cont1">
                <div className="tableBody_row_data_first_div_cont1_title">
                  {business_info?.businesses?.businessName}
                  <span className="tableBody_row_data_first_div_cont1_title_Span">
                    ${business_info?.tokens?.tokenSymbol || "__"}
                  </span>
                </div>
                <div className="RwaBusinessDetails_div_area_1_cont1_title_addr">
                  <div className="tableBody_row_data_first_div_cont1_addr_div1">
                    {wallet_address ? (
                      <>
                        {" "}
                        {`${wallet_address?.slice(
                          0,
                          4
                        )}...${wallet_address?.slice(37, 42)}`}
                      </>
                    ) : (
                      <>0xXXXX</>
                    )}
                  </div>{" "}
                  <div className="tableBody_row_data_first_div_cont1_addr_div2">
                    {business_info?.businesses?.businessType || "__"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ExchangeOrder_div_area_cont_area2">
            <div className="ExchangeOrder_div_area_cont_area2_title">
              Products
            </div>
            <div className="ExchangeOrder_div_area_cont_area2_value">
              {businessProductsLoading ? (
                <CustomLoader
                  containerWidth={"max-content"}
                  margin={"10px 0px "}
                  width={"25px"}
                  height={"25px"}
                />
              ) : (
                <> {businessProducts?.length || 0}</>
              )}
            </div>
          </div>
        </div>
        {/* === */}
        {/* === */}
        {/* === */}
        <div className="ExchangeOrder_div_area2">
          <div className="ExchangeOrder_div_area2_title">
            Products
            <div className="ExchangeOrder_div_area2_title_filter_div"></div>
          </div>

          {businessProductsLoading ? (
            <div style={{ background: "#fff", height: "300px" }}>
              <CustomLoader containerWidth={"100%"} margin={"10px 0px "} />
            </div>
          ) : (
            <>
              {businessProducts?.length === 0 ||
              businessProducts === undefined ? (
                <div className="businessNoProductDiv">
                  <Nodata message={"No products found"} />
                </div>
              ) : (
                <div className="ExchangeOrder_div_area2_body">
                  <table className="TableCompWithDiv_cont_table">
                    <thead className="TableCompWithDiv_cont_head">
                      <tr className="TableCompWithDiv_cont_head_titles">
                        <th className="TableCompWithDiv_cont_head_titles_div TableCompWithDiv_cont_head_titles_div_first">
                          Product
                        </th>
                        <th className="TableCompWithDiv_cont_head_titles_div">
                          Price
                        </th>
                        <th className="TableCompWithDiv_cont_head_titles_div">
                          Quantity
                        </th>
                        <th className="TableCompWithDiv_cont_head_titles_div">
                          Total Orders
                        </th>
                        <th className="TableCompWithDiv_cont_head_titles_div">
                          Pending Orders
                        </th>
                        <th className="TableCompWithDiv_cont_head_titles_div">
                          Status
                        </th>
                        <th className="TableCompWithDiv_cont_head_titles_div">
                          Created by
                        </th>
                        <th className="TableCompWithDiv_cont_head_titles_div">
                          Created
                        </th>

                        <th className="TableCompWithDiv_cont_head_titles_div  TableCompWithDiv_cont_head_titles_div_last"></th>
                      </tr>
                    </thead>
                    <tbody className="tableBody">
                      {businessProducts
                        ?.sort(
                          (b, a) =>
                            new Date(a.createdAt) - new Date(b.createdAt)
                        )
                        ?.map((data) => (
                          <tr
                            className="tableBody_row "
                            onClick={() => {
                              navigate(
                                `/business/products/details/${data?.productId}/${data?.businessID}`
                              );
                            }}
                          >
                            <td className="tableBody_row_data tableBody_row_data_first  ">
                              <div className="tableBody_row_data_product_div">
                                <img
                                  src={data?.images}
                                  alt=""
                                  className="tableBody_row_data_product_div_img"
                                />
                                <div className="tableBody_row_data_product_div_cont">
                                  <div className="tableBody_row_data_product_div_cont_1">
                                    {data?.name}
                                  </div>
                                  <div className="tableBody_row_data_product_div_cont_2">
                                    <span className="tableBody_row_data_product_div_cont_2_span">
                                      {" "}
                                      Brand:
                                    </span>{" "}
                                    {data?.brand}
                                  </div>
                                </div>
                              </div>
                            </td>

                            <td className="tableBody_row_data">
                              <div className="tableBody_row_data_product_price">
                                <div className="tableBody_row_data_product_price_div1">
                                  {parseFloat(data?.userAmount)?.toFixed(2)}{" "}
                                  USDC
                                </div>
                                <span className="tableBody_row_data_product_price_span">
                                  =${parseFloat(data?.userAmount)?.toFixed(2)}
                                </span>
                              </div>
                            </td>
                            <td className="tableBody_row_data">
                              {data?.quantity}
                            </td>
                            <td className="tableBody_row_data">
                              {data?.order_count}
                            </td>
                            <td className="tableBody_row_data">
                              {" "}
                              {data?.pending}
                            </td>
                            <td className="tableBody_row_data">IN STOCK</td>
                            <td className="tableBody_row_data ">
                              {" "}
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Blockies
                                  seed={
                                    data?.personnel ? data?.personnel : "0xXXXX"
                                  }
                                  size={4}
                                  scale={4}
                                  className="blockies_icon"
                                />{" "}
                                {AddressShortener(data?.personnel)}
                              </div>
                            </td>
                            <td className="tableBody_row_data">
                              <ReactTimeAgo
                                date={data?.createdAt}
                                locale="en-US"
                              />
                            </td>

                            <td className="tableBody_row_data tableBody_row_data_last">
                              . . .
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RwaProducts;
