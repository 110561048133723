import React, { useEffect, useState } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { ArrowLeft02Icon } from "hugeicons-react";
import { useDispatch, useSelector } from "react-redux";
import { decrypt, encrypt } from "../../../helper/encryption";
import { IMPORT_WALLET } from "../../../services/auth";
import toast, { Toaster } from "react-hot-toast";
import { setWalletDetails } from "../../../Redux/feautures/user/userSlice";
import ClipLoader from "react-spinners/ClipLoader";

const ImporttWalletPage3 = ({ prevStep, nextStep }) => {
  const dispatch = useDispatch();
  const { mnemonic } = useSelector((state) => state.user);
  const [tempMnemonic, setTempMnemonic] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoading(true);
    const payload = {
      key: encrypt(tempMnemonic),
    };

    //console.log(payload);
    const res = await IMPORT_WALLET(payload);
    //console.log(res, "check import");
    setLoading(false);

    if (!res.success) {
      toast.error(
        <div className="toast_success_div">
          <div className="toast_error_div_title">Error!!</div>
          <div className="toast_success_div_para">
            Failed to proceed, wallet import failed!!!
          </div>
        </div>,
        {
          duration: 5000,
          className: "toast_error",
        }
      );

      return;
    }

    const toSAve = {
      wallet: decrypt(res?.credential?.wallet),
      mnemonic: res?.credential.mnemonic,
      privateKey: res?.credential.privateKey,
    };

    //save the wallet data
    dispatch(setWalletDetails(toSAve));
    setTimeout(() => {
      nextStep();
    }, 2000);

    toast.success(
      <div className="toast_success_div">
        <div className="toast_success_div_title"> Success!</div>
        <div className="toast_success_div_para">
          {" "}
          You have successfully Imported your wallet.
        </div>
      </div>,
      {
        duration: 5000,
        className: "toast_success",
      }
    );
  };

  return (
    <div className="onchainWalletDiv_container">
      <div className="CreateWalletPage1_area_div1">
        <div className="CreateWalletPage1_area_div1_cont1" onClick={prevStep}>
          <ArrowLeft02Icon />
        </div>
        <div className="CreateWalletPage1_area_div1_cont2">
          <div className="CreateWalletPage1_area_div1_cont2_circle1 active"></div>
          <div className="CreateWalletPage1_area_div1_cont2_circle1 active"></div>
          <div className="CreateWalletPage1_area_div1_cont2_circle1 active"></div>
        </div>
      </div>
      <div className="CreateWalletPage3_area">
        <div className="CreateWalletPage3_area_title">
          Secret Recovery Phrase
        </div>
        <div className="CreateWalletPage3_area_para">
          This phrase is the ONLY way to recover your wallet. Do NOT share it
          with anyone!
        </div>

        <textarea
          className="CreateWalletPage2_area_input"
          value={tempMnemonic}
          onChange={(e) => setTempMnemonic(e.target.value)}
        />

        <div className="radioDiv">The phrases are seperated by space</div>

        <button
          className="onchainWalletDiv_container_div2_btn1"
          onClick={handleLogin}
          // onClick={() => {
          //   navigate("/");
          // }}
        >
          {loading ? (
            <>
              {" "}
              <ClipLoader color="#fff" size={24} /> Importing...
            </>
          ) : (
            " Import"
          )}
        </button>
      </div>
      <Toaster />
    </div>
  );
};

export default ImporttWalletPage3;
