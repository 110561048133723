import React, { useEffect, useState } from "react";
import "./index.css";
import {
  Camera02Icon,
  ArrowLeft01Icon,
  UnavailableIcon,
} from "hugeicons-react";
import { AddressShortener } from "../../utils/AddressShortener";
import {
  InformationCircleIcon,
  CheckmarkBadge01Icon,
  ShoppingCart01Icon,
} from "hugeicons-react";
import { Link, useNavigate } from "react-router-dom";
import Blockies from "react-blockies";
import {
  YoutubeIcon,
  NewTwitterIcon,
  Facebook01Icon,
  InstagramIcon,
  TiktokIcon,
} from "hugeicons-react";
import { useSelector } from "react-redux";
import { LIST_ON_BOT_MARKETPLACE } from "../../services/business";
import toast, { Toaster } from "react-hot-toast";
import { socketEvents } from "../../Sockets/events";
import { useSocket } from "../../Sockets/contextProvider/SocketContext";
import Nodata from "../../Components/Nodata/Nodata";
import ReactTimeAgo from "react-time-ago";
import ClipLoader from "react-spinners/ClipLoader";
import UploadWidget from "../Cloudinary/UploadWidget";
import ListProduct from "./ListProduct";
import { PUBLIC_BUSINESS_INFO } from "../../services/business";
import CustomLoader from "../../Components/CustomLoader/CustomLoader";

const RwaBusinessDetails = () => {
  const navigate = useNavigate();
  const { wallet_address, business_info } = useSelector((state) => state.user);
  const { Assets } = useSelector((state) => state.assets);

  // const wallet_address = "0x0E7F93dba708d2ec5d6Eb91612f873598393e252";
  const [listModal, setListModal] = useState(false);
  const [businessProducts, setBusinessProducts] = useState([]);
  const [businessProductsLoading, setBusinessProductsLoading] = useState(false);
  const toggleListModal = () => {
    setListModal(!listModal);
  };
  // console.log("====================================");
  // console.log(business_info);
  // console.log("====================================");
  const fetchBusinessProducts = async () => {
    setBusinessProductsLoading(true);
    const res = await PUBLIC_BUSINESS_INFO({
      id: business_info?.businesses?.businessName,
    });
    // console.log(res);
    if (res?.success === true) {
      setBusinessProductsLoading(false);
      setBusinessProducts(res?.products);
      return;
    }
    setBusinessProductsLoading(false);
  };
  useEffect(() => {
    fetchBusinessProducts();
  }, [business_info]);
  return (
    <>
      <div className="RwaBusinessDetails_div_area2">
        <div className="ListModal_cont_area_back_button">
          {" "}
          <div
            className="ListModal_cont_area_back_button_div1"
            onClick={() => {
              navigate("/business");
            }}
          >
            <ArrowLeft01Icon />
            Back
          </div>
        </div>

        <div className="coverPhototDiv">
          <div className="coverPhototDiv_dark_bg1"></div>
          <img
            src="/img/coverphoto.jpg"
            alt=""
            className="coverPhototDiv_img"
          />
          <div className="coverPhototDiv_dark_bg2"></div>
        </div>
        <div className="RwaBusinessDetails_div_area_1">
          <div className="RwaBusinessDetails_div_area_1_cont1">
            <div className="tableBody_row_data_first_div">
              <img
                // src="/img/dummy_pic.jpeg"
                src={business_info?.tokens?.image}
                alt=""
                className="topGeeBusiness_business_div_cont1_img"
              />
              <div className="tableBody_row_data_first_div_cont1">
                <div className="RwaBusinessDetails_div_area_1_cont1_title">
                  {business_info?.businesses?.businessName}
                  {business_info?.businesses?.businessStatus == "UNVERIFIED" ? (
                    <UnavailableIcon className="tableBody_row_data_stats_div_title_verifyBadge_warn" />
                  ) : (
                    <CheckmarkBadge01Icon className="tableBody_row_data_stats_div_title_verifyBadge" />
                  )}{" "}
                  <span className="tableBody_row_data_first_div_cont1_title_Span">
                    {" "}
                    ${business_info?.tokens?.tokenSymbol}
                  </span>
                </div>
                <div className="cashbackDiv">
                  <div className="cashbackDiv_title">
                    Product cash back
                    <InformationCircleIcon className="cashbackDiv_title_icon" />
                    :
                  </div>
                  <div className="cashbackDiv_value">
                    {business_info?.businesses?.burnPercentage}%
                  </div>
                </div>
                <div className="RwaBusinessDetails_div_area_1_cont1_title_addr">
                  <div className="tableBody_row_data_first_div_cont1_addr_div1">
                    {wallet_address ? (
                      <>
                        {" "}
                        {`${wallet_address?.slice(
                          0,
                          4
                        )}...${wallet_address?.slice(37, 42)}`}
                      </>
                    ) : (
                      <>0xXXXX</>
                    )}
                  </div>{" "}
                  <div className="tableBody_row_data_first_div_cont1_addr_div2">
                    {business_info?.businesses?.businessType}
                  </div>
                </div>
                <div className="tableBody_row_data_first_div_cont1_addr_time">
                  Created: {business_info?.businesses?.createdAt}
                </div>
              </div>
            </div>
            <div className="RwaBusinessDetails_div_area_1_cont1_para">
              {business_info?.businesses?.businessDescription}
            </div>
            <div className="RwaBusinessDetails_div_area_1_cont1_div3">
              <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1">
                <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_title">
                  Business Owner
                </div>
                <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_value">
                  <Blockies
                    seed={wallet_address ? wallet_address : "0xXXXX"}
                    size={5}
                    scale={4}
                    className="blockies_icon"
                  />{" "}
                  {wallet_address ? (
                    <>
                      {" "}
                      {`${wallet_address?.slice(
                        0,
                        4
                      )}...${wallet_address?.slice(37, 42)}`}
                    </>
                  ) : (
                    <>0xXXXX</>
                  )}
                </div>
              </div>
              <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1">
                <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_title">
                  Business Status
                </div>
                <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_value">
                  Verified
                </div>
              </div>
              {/* <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1">
                <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_title">
                  All Time Volume
                </div>
                <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_value">
                  $12.2B
                </div>
              </div> */}
              <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1">
                <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_title">
                  Yearly Revenue
                </div>
                <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_value">
                  {business_info?.businesses?.businessRevenue}
                </div>
              </div>
            </div>
            <div className="RwaBusinessDetails_div_area_1_cont1_div4">
              <Link
                to={`/business/trade/${business_info?.businesses?.businessName}`}
                className="RwaBusinessDetails_div_area_1_cont1_div4_body"
              >
                <div className="RwaBusinessDetails_div_area_1_cont1_div4_body_cont1">
                  {business_info?.tokens?.tokenSymbol}-EGAX
                </div>
                <div className="RwaBusinessDetails_div_area_1_cont1_div4_body_cont2">
                  <span className="RwaBusinessDetails_div_area_1_cont1_div4_body_cont2_span1">
                    {/* {parseFloat(20)?.toFixed(2)} */}
                    {parseFloat(
                      Assets.find(
                        (dd) =>
                          dd.tokenSymbol === business_info?.tokens?.tokenSymbol
                      )?.currentPrice || 0
                    )?.toFixed(3)}
                  </span>
                  <span
                    className="RwaBusinessDetails_div_area_1_cont1_div4_body_cont2_span2"
                    style={{ color: "#ff445d" }}
                  >
                    {parseFloat(
                      Assets.find(
                        (ass) =>
                          ass.tokenAName === business_info?.tokens?.tokenSymbol
                      )?.change24h || 0
                    )?.toFixed(2)}
                    %
                  </span>
                </div>
              </Link>
            </div>
          </div>
          {/* <div className="socialsDiv">
            <div className="socialsDiv_title">Socials</div>
            <div className="socialsDiv_area">
              <div className="socialsDiv_area_cont1">
                <YoutubeIcon size={24} />
              </div>
              <div className="socialsDiv_area_cont1">
                <NewTwitterIcon size={24} />
              </div>
              <div className="socialsDiv_area_cont1">
                <TiktokIcon size={24} />
              </div>
              <div className="socialsDiv_area_cont1">
                <Facebook01Icon size={24} />
              </div>
              <div className="socialsDiv_area_cont1">
                <InstagramIcon size={24} />
              </div>
            </div>
          </div> */}
        </div>

        {/* ======= */}
        {/* ======= */}
        {/* ======= */}
        <div className="ExchangeOrder_div_area2">
          <div className="ExchangeOrder_div_area2_title">
            Products
            <div className="ExchangeOrder_div_area2_title_filter_div">
              {" "}
              <button
                className="rwaBusinessDiv_area_div1_cont2_btn"
                onClick={toggleListModal}
              >
                List A Product
              </button>
            </div>
          </div>

          {businessProductsLoading ? (
            <div style={{ background: "#fff", height: "300px" }}>
              <CustomLoader containerWidth={"100%"} margin={"10px 0px "} />
            </div>
          ) : (
            <>
              {businessProducts?.length === 0 ||
              businessProducts === undefined ? (
                <div className="businessNoProductDiv">
                  <Nodata message={"No products found"} />
                </div>
              ) : (
                <div className="ExchangeOrder_div_area2_body">
                  <table className="TableCompWithDiv_cont_table">
                    <thead className="TableCompWithDiv_cont_head">
                      <tr className="TableCompWithDiv_cont_head_titles">
                        <th className="TableCompWithDiv_cont_head_titles_div TableCompWithDiv_cont_head_titles_div_first">
                          Product
                        </th>
                        <th className="TableCompWithDiv_cont_head_titles_div">
                          Price
                        </th>
                        <th className="TableCompWithDiv_cont_head_titles_div">
                          Quantity
                        </th>
                        <th className="TableCompWithDiv_cont_head_titles_div">
                          Total Orders
                        </th>
                        <th className="TableCompWithDiv_cont_head_titles_div">
                          Pending Orders
                        </th>
                        <th className="TableCompWithDiv_cont_head_titles_div">
                          Status
                        </th>
                        <th className="TableCompWithDiv_cont_head_titles_div">
                          Created by
                        </th>
                        <th className="TableCompWithDiv_cont_head_titles_div">
                          Created
                        </th>

                        <th className="TableCompWithDiv_cont_head_titles_div  TableCompWithDiv_cont_head_titles_div_last"></th>
                      </tr>
                    </thead>
                    <tbody className="tableBody">
                      {businessProducts
                        ?.sort(
                          (b, a) =>
                            new Date(a.createdAt) - new Date(b.createdAt)
                        )
                        ?.map((data) => (
                          <tr
                            className="tableBody_row "
                            onClick={() => {
                              navigate(
                                `/business/products/details/${data?.productId}/${data?.businessID}`
                              );
                            }}
                          >
                            <td className="tableBody_row_data tableBody_row_data_first  ">
                              <div className="tableBody_row_data_product_div">
                                <img
                                  src={data?.images}
                                  alt=""
                                  className="tableBody_row_data_product_div_img"
                                />
                                <div className="tableBody_row_data_product_div_cont">
                                  <div className="tableBody_row_data_product_div_cont_1">
                                    {data?.name}
                                  </div>
                                  <div className="tableBody_row_data_product_div_cont_2">
                                    <span className="tableBody_row_data_product_div_cont_2_span">
                                      {" "}
                                      Brand:
                                    </span>{" "}
                                    {data?.brand}
                                  </div>
                                </div>
                              </div>
                            </td>

                            <td className="tableBody_row_data">
                              <div className="tableBody_row_data_product_price">
                                <div className="tableBody_row_data_product_price_div1">
                                  {parseFloat(data?.userAmount)?.toFixed(2)}{" "}
                                  USDC
                                </div>
                                <span className="tableBody_row_data_product_price_span">
                                  =${parseFloat(data?.userAmount)?.toFixed(2)}
                                </span>
                              </div>
                            </td>
                            <td className="tableBody_row_data">
                              {data?.quantity}
                            </td>
                            <td className="tableBody_row_data">
                              {data?.order_count}
                            </td>
                            <td className="tableBody_row_data">
                              {" "}
                              {data?.pending}
                            </td>
                            <td className="tableBody_row_data">IN STOCK</td>
                            <td className="tableBody_row_data ">
                              {" "}
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Blockies
                                  seed={
                                    data?.personnel ? data?.personnel : "0xXXXX"
                                  }
                                  size={4}
                                  scale={4}
                                  className="blockies_icon"
                                />{" "}
                                {AddressShortener(data?.personnel)}
                              </div>
                            </td>
                            <td className="tableBody_row_data">
                              <ReactTimeAgo
                                date={data?.createdAt}
                                locale="en-US"
                              />
                            </td>

                            <td className="tableBody_row_data tableBody_row_data_last">
                              . . .
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
        </div>
        {/* ======= */}
        {/* ======= */}
        {/* ======= */}
      </div>
      {listModal && <ListProduct toggleListModal={toggleListModal} />}

      <Toaster />
    </>
  );
};

export default RwaBusinessDetails;
