import React, { useState, useEffect } from "react";
import { Briefcase01Icon, UnavailableIcon } from "hugeicons-react";
import "./index.css";
import { Camera02Icon, ArrowLeft01Icon } from "hugeicons-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Blockies from "react-blockies";
import {
  InformationCircleIcon,
  CheckmarkBadge01Icon,
  ShoppingCart01Icon,
} from "hugeicons-react";

import { PUBLIC_BUSINESS_INFO } from "../../../../services/business";
import ClipLoader from "react-spinners/ClipLoader";
import Nodata from "../../../../Components/Nodata/Nodata";
import { Store03Icon } from "hugeicons-react";
import { ChartLineData02Icon } from "hugeicons-react";

const BusinessProducts = () => {
  const { businessName } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const previewLength = 350;
  const [businessInfo, setBusinessInfo] = useState(null);

  const fetchBusinessInfo = async () => {
    setLoading(true);
    const res = await PUBLIC_BUSINESS_INFO({ id: businessName });
    setLoading(false);
    if (!res.success) {
      return;
    }
    setBusinessInfo(res);
  };

  useEffect(() => {
    fetchBusinessInfo();
  }, [businessName]);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  // console.log("====================================");
  // console.log(businessInfo?.products);
  // console.log("====================================");
  return (
    <div className="BusinessProducts_div">
      <div className="container">
        <div className="RwaBusinessDetails_div_area">
          <div className="ListModal_cont_area_back_button">
            {" "}
            <div
              className="ListModal_cont_area_back_button_div1"
              onClick={() => {
                navigate("/market");
              }}
            >
              <ArrowLeft01Icon />
              Back
            </div>
          </div>
          <div className="coverPhototDiv">
            <div className="coverPhototDiv_dark_bg1"></div>

            {loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <ClipLoader color="#6059fd" size={42} />
              </div>
            ) : (
              <img
                src="/img/coverphoto.jpg"
                alt=""
                className="coverPhototDiv_img"
              />
            )}

            <div className="coverPhototDiv_dark_bg2"></div>
          </div>
          <div className="RwaBusinessDetails_div_area_1">
            <div className="RwaBusinessDetails_div_area_1_cont1">
              <div className="tableBody_row_data_first_div">
                {loading ? (
                  <div className="RwaBusinessDetails_div_area_1_img_loader">
                    <ClipLoader color="#6059fd" size={42} />
                  </div>
                ) : (
                  <img
                    src={businessInfo?.businesses?.image}
                    alt=""
                    className="topGeeBusiness_business_div_cont1_img"
                  />
                )}

                <div className="tableBody_row_data_first_div_cont1">
                  <div className="RwaBusinessDetails_div_area_1_cont1_title">
                    {loading ? (
                      <>--</>
                    ) : (
                      // </div>
                      <> {businessInfo?.businesses?.businessName}</>
                    )}
                    {businessInfo?.businesses?.businessStatus ==
                    "UNVERIFIED" ? (
                      <UnavailableIcon className="tableBody_row_data_stats_div_title_verifyBadge_warn" />
                    ) : (
                      <CheckmarkBadge01Icon className="tableBody_row_data_stats_div_title_verifyBadge" />
                    )}
                    <span className="tableBody_row_data_first_div_cont1_title_Span">
                      {" "}
                      $
                      {loading ? (
                        <>--</>
                      ) : (
                        // </div>
                        <> {businessInfo?.businesses?.tokenSymbol}</>
                      )}
                    </span>
                  </div>
                  <div className="cashbackDiv">
                    <div className="cashbackDiv_title">
                      Product cash back
                      <InformationCircleIcon className="cashbackDiv_title_icon" />
                      :
                    </div>
                    <div className="cashbackDiv_value">
                      {loading ? (
                        <>--</>
                      ) : (
                        // </div>
                        <> {businessInfo?.businesses?.burnPercentage}</>
                      )}
                      %
                    </div>
                  </div>
                  <div className="RwaBusinessDetails_div_area_1_cont1_title_addr">
                    <div className="tableBody_row_data_first_div_cont1_addr_div1">
                      {businessInfo?.businesses?.createdBy ? (
                        <>
                          {" "}
                          {`${businessInfo?.businesses?.createdBy?.slice(
                            0,
                            4
                          )}...${businessInfo?.businesses?.createdBy?.slice(
                            37,
                            42
                          )}`}
                        </>
                      ) : (
                        <>0xXXXX</>
                      )}
                    </div>{" "}
                    <div className="tableBody_row_data_first_div_cont1_addr_div2">
                      {loading ? (
                        <>--</>
                      ) : (
                        // </div>
                        <> {businessInfo?.businesses?.businessType}</>
                      )}{" "}
                    </div>
                  </div>
                  <div className="tableBody_row_data_first_div_cont1_addr_time">
                    {loading ? (
                      <>--</>
                    ) : (
                      // </div>
                      <> {businessInfo?.createdAt}</>
                    )}{" "}
                  </div>
                </div>
              </div>
              <div className="RwaBusinessDetails_div_area_1_cont1_para">
                {isExpanded
                  ? businessInfo?.businesses?.businessDescription
                  : `${businessInfo?.businesses?.businessDescription?.slice(
                      0,
                      previewLength
                    )} ... `}
                <span
                  onClick={toggleExpanded}
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  {isExpanded ? " See less" : "See more"}
                </span>
              </div>
              <div className="RwaBusinessDetails_div_area_1_cont1_div3">
                <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1">
                  <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_title">
                    Business Owner
                  </div>
                  <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_value">
                    {loading ? (
                      <>__</>
                    ) : (
                      // </div>
                      <>
                        {" "}
                        <Blockies
                          seed={
                            businessInfo?.businesses?.createdBy
                              ? businessInfo?.businesses?.createdBy
                              : "0xXXXX"
                          }
                          size={5}
                          scale={4}
                          className="blockies_icon"
                        />{" "}
                        {businessInfo?.businesses?.createdBy ? (
                          <>
                            {" "}
                            {`${businessInfo?.businesses?.createdBy?.slice(
                              0,
                              4
                            )}...${businessInfo?.businesses?.createdBy?.slice(
                              37,
                              42
                            )}`}
                          </>
                        ) : (
                          <>0xXXXX</>
                        )}
                      </>
                    )}{" "}
                  </div>
                </div>
                <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1">
                  <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_title">
                    Business Status
                  </div>
                  <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_value">
                    {loading ? (
                      <>__</>
                    ) : (
                      // </div>
                      <> {businessInfo?.businesses?.businessStatus}</>
                    )}{" "}
                  </div>
                </div>

                <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1">
                  <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_title">
                    Yearly Revenue
                  </div>
                  <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_value">
                    {loading ? (
                      <>__</>
                    ) : (
                      // </div>
                      <> {businessInfo?.businesses?.businessRevenue}</>
                    )}{" "}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="socialsDiv">
              <div className="socialsDiv_title">Socials</div>
              <div className="socialsDiv_area">
                <div className="socialsDiv_area_cont1">
                  <YoutubeIcon size={24} />
                </div>
                <div className="socialsDiv_area_cont1">
                  <NewTwitterIcon size={24} />
                </div>
                <div className="socialsDiv_area_cont1">
                  <TiktokIcon size={24} />
                </div>
                <div className="socialsDiv_area_cont1">
                  <Facebook01Icon size={24} />
                </div>
                <div className="socialsDiv_area_cont1">
                  <InstagramIcon size={24} />
                </div>
              </div>
            </div> */}
            <Link
              to={`/business/trade/${businessInfo?.businesses?.businessName}`}
            >
              <button className="visitStoreBtn">
                <ChartLineData02Icon className="visitStoreBtn_icon" /> Trade
              </button>
            </Link>
          </div>
          <div className="businessProd_body">
            <div className="businessProd_body_1">
              <div className="marketPlace_area_2_title">Products</div>
            </div>
            <div className="businessProd_body_2">
              <div className="businessProd_body_2_cont1">
                <div className="businessProd_body_2_cont1_div1">
                  <div className="businessProd_body_2_cont1_div1_title">
                    Price
                  </div>
                  <div className="businessProd_body_2_cont1_div1_body">
                    <input type="radio" name="" id="" />{" "}
                    <span className="businessProd_body_2_cont1_div1_body_span">
                      {" "}
                      High - Low
                    </span>{" "}
                  </div>
                  <div className="businessProd_body_2_cont1_div1_body">
                    <input type="radio" name="" id="" />{" "}
                    <span className="businessProd_body_2_cont1_div1_body_span">
                      {" "}
                      Low - High
                    </span>{" "}
                  </div>
                </div>
                <div className="businessProd_body_2_cont1_div1">
                  <div className="businessProd_body_2_cont1_div1_title">
                    Status
                  </div>
                  <div className="businessProd_body_2_cont1_div1_body">
                    <input type="radio" name="" id="" />{" "}
                    <span className="businessProd_body_2_cont1_div1_body_span">
                      {" "}
                      Sold Out
                    </span>{" "}
                  </div>
                  <div className="businessProd_body_2_cont1_div1_body">
                    <input type="radio" name="" id="" />{" "}
                    <span className="businessProd_body_2_cont1_div1_body_span">
                      {" "}
                      In Stock
                    </span>{" "}
                  </div>
                </div>
                <div className="businessProd_body_2_cont1_div1">
                  <div className="businessProd_body_2_cont1_div1_title">
                    Time
                  </div>
                  <div className="businessProd_body_2_cont1_div1_body">
                    <input type="radio" name="" id="" />{" "}
                    <span className="businessProd_body_2_cont1_div1_body_span">
                      {" "}
                      New
                    </span>{" "}
                  </div>
                </div>
              </div>{" "}
              <div className="businessProd_body_2_cont2">
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "400px",
                      width: "100%",
                    }}
                  >
                    <ClipLoader color="#6059fd" size={42} />
                  </div>
                ) : (
                  <>
                    {businessInfo?.products?.length === 0 ||
                    businessInfo?.products?.length === undefined ? (
                      <Nodata message="No products found" />
                    ) : (
                      <div className="businessProd_body_2_cont2_body">
                        {businessInfo?.products?.map((data, index) => {
                          // console.log(data);

                          return (
                            <div
                              className="businessProd_body_2_cont2_body_card"
                              key={index}
                              onClick={() =>
                                navigate(
                                  `/market/detail/${data.productId}/${data.category}`
                                )
                              }
                            >
                              <img
                                src={data.images}
                                alt=""
                                className="marketPlace_area_3_div1_body_cont_img"
                              />
                              <div className="marketPlace_area_3_div1_body_cont_stats_body">
                                <div className="marketPlace_area_3_div1_body_cont_stats_body_title">
                                  {data.name}
                                </div>
                                <div className="marketPlace_area_3_div1_body_cont_stats_body_amount">
                                  <div className="marketPlace_area_3_div1_body_cont_stats_body_amount_cont">
                                    {parseFloat(data.userAmount).toFixed(2)}
                                    <span className="marketPlace_area_3_div1_body_cont_stats_body_amount_cont_span">
                                      USDC
                                    </span>{" "}
                                  </div>
                                  <span className="marketPlace_area_3_div1_body_cont_stats_body_amount_span">
                                    =$
                                    {parseFloat(data.userAmount).toFixed(2)}
                                  </span>
                                </div>
                                <div className="marketPlace_area_3_div1_body_cont_stats_body_quantity">
                                  <img
                                    src={businessInfo?.businesses?.image}
                                    alt=""
                                    className="marketPlace_area_3_div1_body_cont_stats_body_quantity_img"
                                  />
                                  <div className="marketPlace_area_3_div1_body_cont_stats_body_quantity_div2">
                                    <div className="marketPlace_area_3_div1_body_cont_stats_body_quantity_title">
                                      Quantity
                                    </div>
                                    <div className="marketPlace_area_3_div1_body_cont_stats_body_quantity_amount">
                                      {data.quantity}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </>
                )}
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessProducts;
