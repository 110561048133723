import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  businesses: [],
  businessChain: "all",
};

const businessSlice = createSlice({
  name: "businesses",
  initialState,
  reducers: {
    populateBusinesses: (state, action) => {
      state.businesses = action.payload;
    },
    updateBusinessChain: (state, action) => {
      state.businessChain = action.payload;
    },
  },
});

export const { populateBusinesses, updateBusinessChain } =
  businessSlice.actions;
export default businessSlice.reducer;
