import React, { useEffect } from "react";
import {
  PrevButton,
  NextButton,
  usePrevNextButtons,
} from "./ProductCarouselArrowButons";
import useEmblaCarousel from "embla-carousel-react";
import { useNavigate } from "react-router-dom";

import "./productCarousel.css";
import { Link } from "react-router-dom";

const ProductDisplayCarousel = (props) => {
  const navigate = useNavigate();
  const { slides, options, title, seeAll } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi || null);

  useEffect(() => {
    if (!emblaApi) return;
    emblaApi.reInit(); // Ensures proper re-initialization when needed
  }, [emblaApi]);
  // console.log("====================================");
  // console.log(slides);
  // console.log("====================================");
  return (
    <section className="prodCarouselEmbla">
      <div className="prodCarouselEmbla__controls">
        <div className="marketPlace_area_2_title">{title}</div>

        {slides?.length === 10 && (
          <Link to={seeAll} className="marketPlace_area_2_span">
            See all
          </Link>
        )}
      </div>

      <div className="slides_desktop">
        <div className="prodCarouselEmbla__buttons">
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </div>
        <div className="prodCarouselEmbla__viewport" ref={emblaRef}>
          <div className="prodCarouselEmbla__container">
            {slides.map((data, index) => (
              <div
                className="marketPlace_area_3_div1_body_cont"
                key={index}
                onClick={() =>
                  navigate(`/market/detail/${data.productId}/${data.category}`)
                }
              >
                <img
                  src={data?.images}
                  alt=""
                  className="marketPlace_area_3_div1_body_cont_img"
                />
                <div className="marketPlace_area_3_div1_body_cont_stats_body">
                  <div className="marketPlace_area_3_div1_body_cont_stats_body_title">
                    {data.name}
                  </div>
                  <div className="marketPlace_area_3_div1_body_cont_stats_body_amount">
                    <div className="marketPlace_area_3_div1_body_cont_stats_body_amount_cont">
                      {parseFloat(data.userAmount).toFixed(2)}
                      <span className="marketPlace_area_3_div1_body_cont_stats_body_amount_cont_span">
                        USDC
                      </span>{" "}
                    </div>
                    <span className="marketPlace_area_3_div1_body_cont_stats_body_amount_span">
                      =$
                      {parseFloat(data.userAmount).toFixed(2)}
                    </span>
                  </div>
                  <div className="marketPlace_area_3_div1_body_cont_stats_body_quantity">
                    <img
                      src={data.busImg}
                      alt=""
                      className="marketPlace_area_3_div1_body_cont_stats_body_quantity_img"
                    />
                    <div className="marketPlace_area_3_div1_body_cont_stats_body_quantity_div2">
                      <div className="marketPlace_area_3_div1_body_cont_stats_body_quantity_title">
                        Quantity
                      </div>
                      <div className="marketPlace_area_3_div1_body_cont_stats_body_quantity_amount">
                        {data.quantity}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="slides_mobile">
        <div className="slides_mobile_conts">
          {slides.map((data, index) => (
            <div
              className="marketPlace_area_3_div1_body_cont"
              key={index}
              onClick={() =>
                navigate(`/market/detail/${data.productId}/${data.category}`)
              }
            >
              <img
                src={data?.images}
                alt=""
                className="marketPlace_area_3_div1_body_cont_img"
              />
              <div className="marketPlace_area_3_div1_body_cont_stats_body">
                <div className="marketPlace_area_3_div1_body_cont_stats_body_title">
                  {data.name}
                </div>
                <div className="marketPlace_area_3_div1_body_cont_stats_body_amount">
                  <div className="marketPlace_area_3_div1_body_cont_stats_body_amount_cont">
                    {parseFloat(data.userAmount).toFixed(2)}
                    <span className="marketPlace_area_3_div1_body_cont_stats_body_amount_cont_span">
                      USDC
                    </span>{" "}
                  </div>
                  <span className="marketPlace_area_3_div1_body_cont_stats_body_amount_span">
                    =$
                    {parseFloat(data.userAmount).toFixed(2)}
                  </span>
                </div>
                <div className="marketPlace_area_3_div1_body_cont_stats_body_quantity">
                  <img
                    src={data.busImg}
                    alt=""
                    className="marketPlace_area_3_div1_body_cont_stats_body_quantity_img"
                  />
                  <div className="marketPlace_area_3_div1_body_cont_stats_body_quantity_div2">
                    <div className="marketPlace_area_3_div1_body_cont_stats_body_quantity_title">
                      Quantity
                    </div>
                    <div className="marketPlace_area_3_div1_body_cont_stats_body_quantity_amount">
                      {data.quantity}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProductDisplayCarousel;
