import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { liquidityDetail } from "./Utils/liquidtiyDetail";
import { formatDecimal } from "../../../../assets/js/formatDecimal";

const TVL = ({ tokenAddress, data, baseToken }) => {
  const { privateKey, wallet_address } = useSelector((state) => state.user);

  const [totalLiquidity, setTotalLiquidity] = useState(0);
  const [liquidityLoading, setLiquidityLoading] = useState(false);
  const egaxUsdPrice = 0.45;

  const fetchLiquid = async () => {
    setLiquidityLoading(true);
    const res = await liquidityDetail(tokenAddress, wallet_address, baseToken);

    // console.log("====================================");
    // console.log(res);
    // console.log("====================================");

    if (res.error) {
      console.error("Error fetching liquidity details:", res.error);
    } else {
      const tokenValueInEgax =
        parseFloat(res.totalEgaxPoolVal) / parseFloat(res.totalTokenPoolVal);
      const tokenValueInDollar =
        tokenValueInEgax * parseFloat(res.totalTokenPoolVal) * egaxUsdPrice;
      const egaxValueInDollar = parseFloat(res.totalEgaxPoolVal) * egaxUsdPrice;
      setTotalLiquidity(tokenValueInDollar + egaxValueInDollar);
    }
    setLiquidityLoading(false);
  };

  useEffect(() => {
    fetchLiquid();
  }, [data]);

  if (liquidityLoading) {
    return <ClipLoader color="#605afd" size={18} />;
  }
  return formatDecimal(totalLiquidity);
};

export default TVL;
