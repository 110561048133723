// redux/features/chart/chartSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GET_CHART_DATA } from "../../../services/business";

// Async thunk for fetching chart data
export const fetchChartData = createAsyncThunk(
  "chart/fetchChartData",
  async (ticker, { getState, rejectWithValue }) => {
    try {
      const state = getState().chart;

      // Use stored data if it exists
      if (state.charts[ticker]) {
        return state.charts[ticker]; // Return cached data
      }

      // Fetch fresh data only if it's missing
      const data = await GET_CHART_DATA({
        ticker,
        timeframe: 365,
        page: "1",
        limit: "100",
      });
      return { ticker, data };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
  //
);

const chartSlice = createSlice({
  name: "chart",
  initialState: {
    charts: {}, // Stores data per ticker
    loading: {}, // Track loading per ticker
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchChartData.pending, (state, action) => {
        const ticker = action.meta.arg;
        if (!state.charts[ticker]) {
          state.loading[ticker] = true;
        }
        state.error = null;
      })
      .addCase(fetchChartData.fulfilled, (state, action) => {
        const { ticker, data } = action.payload;
        state.charts[ticker] = data; // Store data per ticker
        state.loading[ticker] = false;
      })
      .addCase(fetchChartData.rejected, (state, action) => {
        const ticker = action.meta.arg;
        state.loading[ticker] = false;
        state.error = action.payload;
      });
  },
});

export default chartSlice.reducer;
