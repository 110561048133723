export const socketEvents = {
  TOKEN_CREATION: "/token-creation",
  CREATE_PAIR: "/create-pair",
  ADD_INITIAL_LIQUIDITY: "/add-initial-liquidity",
  TOKEN: "/token",
  LIST_TOKEN_ON_BOT: "/list-product",
  BRIDGE_LISTENER: `/trigger-bridge`,
  USDC_PURCHASE: `/purchase-response`,
  TRADE_EVENT: `/emit-trade`,
  ALL_TRADE_EVENT: `/emit-general-trade`,
  NEW_BUSINESS: `/emit/new-business`,
  NEW_TRADE_BUSINESS: `/emit-traded-business`,
};
