import React, { useState } from "react";
import "./index.css";
import HomeContainer from "./OnchainWalletPages/HomeContainer/HomeContainer";
import CreateWalletPage1 from "./OnchainWalletPages/CreateWalletPage1/CreateWalletPage1";
import CreateWalletPage2 from "./OnchainWalletPages/CreateWalletPage2/CreateWalletPage2";
import CreateWalletPage3 from "./OnchainWalletPages/CreateWalletPage3/CreateWalletPage3";

const OnchainWallet = () => {
  const [nextStep, setNextStep] = useState(1);

  return (
    <div className="onchainWalletDiv">
      {nextStep === 1 && <HomeContainer nextStep={() => setNextStep(2)} />}
      {nextStep === 2 && (
        <CreateWalletPage1
          nextStep={() => setNextStep(3)}
          prevStep={() => setNextStep(1)}
        />
      )}
      {nextStep === 3 && (
        <CreateWalletPage2
          nextStep={() => setNextStep(4)}
          prevStep={() => setNextStep(2)}
        />
      )}
      {nextStep === 4 && <CreateWalletPage3 prevStep={() => setNextStep(3)} />}
    </div>
  );
};

export default OnchainWallet;
