import apiInstance from "../utils/axiosInstance";
import { createWalletRoute, importWalletRoute, userExistRoute } from "./routes";
export const CREATE_ACCOUNT = async () => {
  try {
    const res = await apiInstance.get(createWalletRoute);
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
export const IMPORT_WALLET = async (payload) => {
  try {
    const res = await apiInstance.post(importWalletRoute, payload);
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
export const USER_EXIST = async () => {
  try {
    const res = await apiInstance.get(userExistRoute);

    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
