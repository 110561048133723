import React, { useState, useEffect } from "react";
import "./index.css";
import { Camera02Icon, ArrowLeft01Icon } from "hugeicons-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import Nodata from "../../../../Components/Nodata/Nodata";
import { fetchProductsByCategory } from "../../../../Redux/feautures/products/productSlice";

const CategoryProductsDisplay = () => {
  const navigate = useNavigate();
  const { category } = useParams();
  const dispatch = useDispatch();
  const { categoryProducts, loading } = useSelector((state) => state.products);
  const page = 1;
  const limit = 100;
  const key = `${category}_page${page}_limit${limit}`;
  const products = categoryProducts[key]?.data || [];

  useEffect(() => {
    if (!products.length) {
      dispatch(fetchProductsByCategory({ category, page, limit }));
    }
  }, [dispatch, category, page, limit, products.length]);

  return (
    <div className="BusinessProducts_div">
      <div className="container">
        <div className="RwaBusinessDetails_div_area">
          <div className="ListModal_cont_area_back_button">
            {" "}
            <div
              className="ListModal_cont_area_back_button_div1"
              onClick={() => {
                navigate("/market");
              }}
              style={{ marginBottom: "2em" }}
            >
              <ArrowLeft01Icon />
              Back
            </div>
          </div>

          <div className="businessProd_body">
            <div className="businessProd_body_1">
              <div className="marketPlace_area_2_title">{category}</div>
            </div>
            <div className="businessProd_body_2">
              <div className="businessProd_body_2_cont1">
                <div className="businessProd_body_2_cont1_div1">
                  <div className="businessProd_body_2_cont1_div1_title">
                    Price
                  </div>
                  <div className="businessProd_body_2_cont1_div1_body">
                    <input type="radio" name="" id="" />{" "}
                    <span className="businessProd_body_2_cont1_div1_body_span">
                      {" "}
                      High - Low
                    </span>{" "}
                  </div>
                  <div className="businessProd_body_2_cont1_div1_body">
                    <input type="radio" name="" id="" />{" "}
                    <span className="businessProd_body_2_cont1_div1_body_span">
                      {" "}
                      Low - High
                    </span>{" "}
                  </div>
                </div>
                <div className="businessProd_body_2_cont1_div1">
                  <div className="businessProd_body_2_cont1_div1_title">
                    Status
                  </div>
                  <div className="businessProd_body_2_cont1_div1_body">
                    <input type="radio" name="" id="" />{" "}
                    <span className="businessProd_body_2_cont1_div1_body_span">
                      {" "}
                      Sold Out
                    </span>{" "}
                  </div>
                  <div className="businessProd_body_2_cont1_div1_body">
                    <input type="radio" name="" id="" />{" "}
                    <span className="businessProd_body_2_cont1_div1_body_span">
                      {" "}
                      In Stock
                    </span>{" "}
                  </div>
                </div>
                <div className="businessProd_body_2_cont1_div1">
                  <div className="businessProd_body_2_cont1_div1_title">
                    Time
                  </div>
                  <div className="businessProd_body_2_cont1_div1_body">
                    <input type="radio" name="" id="" />{" "}
                    <span className="businessProd_body_2_cont1_div1_body_span">
                      {" "}
                      New
                    </span>{" "}
                  </div>
                </div>
              </div>{" "}
              <div className="businessProd_body_2_cont2">
                {loading && !products?.length ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "400px",
                      width: "100%",
                    }}
                  >
                    <ClipLoader color="#6059fd" size={42} />
                  </div>
                ) : (
                  <>
                    {products?.length === 0 ||
                    products?.length === undefined ? (
                      <Nodata message="No products found" />
                    ) : (
                      <div className="businessProd_body_2_cont2_body">
                        {products?.map((data, index) => {
                          // console.log(data);

                          return (
                            <div
                              className="businessProd_body_2_cont2_body_card"
                              key={index}
                              onClick={() =>
                                navigate(
                                  `/market/detail/${data.productId}/${category}`
                                )
                              }
                            >
                              <img
                                src={data.images}
                                alt=""
                                className="marketPlace_area_3_div1_body_cont_img"
                              />
                              <div className="marketPlace_area_3_div1_body_cont_stats_body">
                                <div className="marketPlace_area_3_div1_body_cont_stats_body_title">
                                  {data.name}
                                </div>
                                <div className="marketPlace_area_3_div1_body_cont_stats_body_amount">
                                  <div className="marketPlace_area_3_div1_body_cont_stats_body_amount_cont">
                                    {parseFloat(data.userAmount).toFixed(2)}
                                    <span className="marketPlace_area_3_div1_body_cont_stats_body_amount_cont_span">
                                      USDC
                                    </span>{" "}
                                  </div>
                                  <span className="marketPlace_area_3_div1_body_cont_stats_body_amount_span">
                                    =$
                                    {parseFloat(data.userAmount).toFixed(2)}
                                  </span>
                                </div>
                                <div className="marketPlace_area_3_div1_body_cont_stats_body_quantity">
                                  <img
                                    src={data?.busImg}
                                    alt=""
                                    className="marketPlace_area_3_div1_body_cont_stats_body_quantity_img"
                                  />
                                  <div className="marketPlace_area_3_div1_body_cont_stats_body_quantity_div2">
                                    <div className="marketPlace_area_3_div1_body_cont_stats_body_quantity_title">
                                      Quantity
                                    </div>
                                    <div className="marketPlace_area_3_div1_body_cont_stats_body_quantity_amount">
                                      {data.quantity}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </>
                )}
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryProductsDisplay;
