import React, { useState, useEffect } from "react";
import {
  ArrowLeft01Icon,
  InformationCircleIcon,
  Recycle03Icon,
} from "hugeicons-react";
import {
  Add01Icon,
  ArrowUp02Icon,
  ArrowDown02Icon,
  File01Icon,
  Link03Icon,
  CreditCardAddIcon,
  Recycle01Icon,
} from "hugeicons-react";
import "./index.css";
import WalletSend from "../WalletSend/WalletSend";
import WalletReceive from "../WalletReceive/WalletReceive";
import { useSelector } from "react-redux";
import Nodata from "../../../Components/Nodata/Nodata";
import Convert from "../Convert/Convert";
const WalletAssetDetail = ({ selectedAsset, back }) => {
  const { transactions } = useSelector((state) => state.wallTrans);
  const { wallet_address: userAddress } = useSelector((state) => state.user);
  const { activeProvider: provider } = useSelector((state) => state.assets);
  const [receiveModal, setReceiveModal] = useState(false);
  const [convertModal, setConvertModal] = useState(false);
  const [sendModal, setSendModal] = useState(false);
  const [transaction, setTransaction] = useState([
    // {
    //   id: 1,
    //   amount: 10000,
    //   to: "0x0E7F93dba708d2ec5d6Eb91612f873598393e252",
    //   from: "0x6f24c8AdE0fA9e348cF75D0ffE82A958D8ea859A",
    //   status: "Completed",
    //   Symbol: "EGAX",
    //   createdAt: "2024-07-02T10:40:13.000Z",
    // },
    // {
    //   id: 2,
    //   amount: 2300,
    //   to: "0x6f24c8AdE0fA9e348cF75D0ffE82A958D8ea859A",
    //   from: "0x0E7F93dba708d2ec5d6Eb91612f873598393e252",
    //   status: "Completed",
    //   Symbol: "EGAX",
    //   createdAt: "2024-07-02T10:40:13.000Z",
    // },
    // {
    //   id: 1,
    //   amount: 5000,
    //   to: "0x0E7F93dba708d2ec5d6Eb91612f873598393e252",
    //   from: "0x6f24c8AdE0fA9e348cF75D0ffE82A958D8ea859A",
    //   status: "Completed",
    //   Symbol: "EGAX",
    //   createdAt: "2024-07-04T10:40:13.000Z",
    // },
    // {
    //   id: 2,
    //   amount: 1300,
    //   to: "0x6f24c8AdE0fA9e348cF75D0ffE82A958D8ea859A",
    //   from: "0x0E7F93dba708d2ec5d6Eb91612f873598393e252",
    //   status: "Completed",
    //   Symbol: "EGAX",
    //   createdAt: "2024-07-04T10:40:13.000Z",
    // },
  ]);

  const isNative = selectedAsset?.tokenType == "native";
  //   useEffect(() => {
  //     const token = Assets.find((a) => a.symbol === symbol);
  //     //console.log("change occured", symbol, token);
  //     setToken(token);
  //   }, [symbol]);

  const groups = transactions.reduce((groups, data) => {
    const date = data.createdAt.split("T")[0];
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(data);
    return groups;
  }, {});

  //   Edit: to add it in the array format instead
  const groupArrays = Object.keys(groups)?.map((date) => {
    return {
      date,
      data: groups[date],
    };
  });

  // const token = Assets.find((token) => token.id == id);

  const OpenReceiveModal = () => {
    setReceiveModal(true);
  };
  const OpenConvertModal = () => {
    setConvertModal(true);
  };
  const CloseReceiveModal = () => {
    setReceiveModal(false);
  };
  const OpenSendModal = () => {
    setSendModal(true);
  };
  const CloseSendModal = () => {
    setSendModal(false);
  };
  if (sendModal) {
    return (
      <WalletSend
        selectedToken={selectedAsset}
        back={() => setSendModal(false)}
      />
    );
  }
  if (receiveModal) {
    return (
      <WalletReceive
        selectedToken={selectedAsset}
        back={() => setReceiveModal(false)}
      />
    );
  }
  if (convertModal) {
    return <Convert back={() => setConvertModal(false)} />;
  }

  return (
    <div className="WalletDetailPageDiv">
      <div className="WalletDetailPageDiv_header">
        <div className="WalletDetailPageDiv_header_back" onClick={back}>
          <ArrowLeft01Icon size={24} />
        </div>

        <div className="WalletDetailPageDiv_header_info">
          <InformationCircleIcon size={20} />
        </div>
      </div>
      <div className="WalletDetailPageDiv_body1">
        {isNative ? (
          <div className="WalletDetailPageDiv_body1_img_div">
            <img
              src={provider?.image || "/img/egax_logo.png"}
              alt=""
              className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_img"
            />

            {provider?.baseToken}
          </div>
        ) : (
          <div className="WalletDetailPageDiv_body1_img_div">
            <img
              // src={provider?.image || "/img/egax_logo.png"}
              src={selectedAsset?.img || selectedAsset?.image || "/img/art.png"}
              alt=""
              className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_img"
            />
            {selectedAsset?.tokenBName}

            {/* {provider?.baseToken} */}
          </div>
        )}
        <div className="WalletDetailPageDiv_body1_amount">
          <div className="WalletDetailPageDiv_body1_amount_div1">
            {parseFloat(selectedAsset?.balance)?.toFixed(4)}
          </div>
          <div className="WalletDetailPageDiv_body1_amount_div1_val">
            ≈ ${parseFloat(selectedAsset?.currentPrice)?.toFixed(2)}
          </div>
        </div>
        <div className="WalletDiv_cont1_div2b">
          {/* <div></div> */}

          <div
            className="WalletPop_div_cont_walletHome_div1_cont2_area2_cont1"
            onClick={OpenSendModal}
          >
            <div className="WalletPop_div_cont_walletHome_div1_cont2_area2_cont1_icon_div">
              <ArrowUp02Icon className="WalletPop_div_cont_walletHome_div1_cont2_area2_cont1_icon" />
            </div>
            Send
          </div>
          <div
            className="WalletPop_div_cont_walletHome_div1_cont2_area2_cont1"
            onClick={OpenReceiveModal}
          >
            <div className="WalletPop_div_cont_walletHome_div1_cont2_area2_cont1_icon_div">
              <ArrowDown02Icon className="WalletPop_div_cont_walletHome_div1_cont2_area2_cont1_icon" />
            </div>
            Receive
          </div>

          {(selectedAsset?.ticker === "EGAX-USDC" ||
            selectedAsset?.ticker === "EGAX-EGAX") && (
            <div
              className="WalletPop_div_cont_walletHome_div1_cont2_area2_cont1"
              onClick={OpenConvertModal}
            >
              <div className="WalletPop_div_cont_walletHome_div1_cont2_area2_cont1_icon_div">
                <Recycle03Icon className="WalletPop_div_cont_walletHome_div1_cont2_area2_cont1_icon" />
              </div>
              Convert
            </div>
          )}
        </div>
      </div>

      <div className="WalletDetailPageDiv_body2">
        {groupArrays.length < 1 ? (
          <>
            <Nodata message={"No transaction found"} />
          </>
        ) : (
          <>
            {groupArrays.slice(0, 3)?.map((data) => (
              <>
                <div className="WalletDetailPageDiv_body2_date">
                  {data.date}
                </div>
                {data.data?.map((data) => (
                  <div className="WalletDetailPageDiv_body2_div1">
                    <div className="WalletDetailPageDiv_body2_div1_cont1">
                      <div className="WalletDetailPageDiv_body2_div1_cont1_icon_div">
                        {data.to === userAddress ? (
                          <ArrowDown02Icon size={16} />
                        ) : (
                          <ArrowUp02Icon size={16} />
                        )}
                      </div>
                      <div className="WalletDetailPageDiv_body2_div1_cont1_trans_div">
                        <div className="WalletDetailPageDiv_body2_div1_cont1_trans_div_title">
                          {data.to === userAddress ? "Receive" : "Send"}
                        </div>
                        <div className="WalletDetailPageDiv_body2_div1_cont1_trans_div_para">
                          {data.to === userAddress ? (
                            <>
                              From:
                              {`${data.from.slice(0, 6)}...${data.from.slice(
                                39,
                                42
                              )}`}
                            </>
                          ) : (
                            <>
                              To:
                              {`${data.to.slice(0, 6)}...${data.to.slice(
                                39,
                                42
                              )}`}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="WalletDetailPageDiv_body2_div1_cont2">
                      {data.amount}
                      {data.Symbol}
                    </div>
                  </div>
                ))}
              </>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default WalletAssetDetail;
