import apiInstance from "../utils/axiosInstance";
import {
  LIST_ALL_ASSET_ROUTE,
  NATIVE_TOKEN_BALANCE_ROUTE,
  TRANSFER_TOKEN_ROUTE,
  BRIDGE_ROUTE,
  FETCH_NETWORK_PROVIDER_ROUTES,
  BRIDGE2_ROUTE,
} from "./routes";

export const LIST_ALL_ASSET = async () => {
  try {
    const res = await apiInstance.get(LIST_ALL_ASSET_ROUTE);
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
export const FETCH_NETWORK_PROVIDERS = async () => {
  try {
    const res = await apiInstance.get(FETCH_NETWORK_PROVIDER_ROUTES);
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
export const NATIVE_TOKEN_BALANCE = async ({ wallet_address }) => {
  try {
    const res = await apiInstance.get(
      `${NATIVE_TOKEN_BALANCE_ROUTE}/${wallet_address}`
    );
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};

export const TRANSFER_TOKEN = async ({ key, amount, receiver, symbol }) => {
  try {
    const res = await apiInstance.post(TRANSFER_TOKEN_ROUTE, {
      key,
      amount,
      receiver,
      symbol,
    });
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
export const BRIDGE = async (payload) => {
  try {
    const res = await apiInstance.post(BRIDGE_ROUTE, payload);
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
export const BRIDGE2 = async (payload) => {
  try {
    const res = await apiInstance.post(BRIDGE2_ROUTE, payload);
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
