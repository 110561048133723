import React, { useState, useEffect } from "react";
import "./index.css";
import { CiReceipt } from "react-icons/ci";
import { LuSettings2 } from "react-icons/lu";
import { TbArrowsRightLeft } from "react-icons/tb";
import { socketEvents } from "../../../Sockets/events";
import { useSocket } from "../../../Sockets/contextProvider/SocketContext";
import { useDispatch, useSelector } from "react-redux";
import { ArrowDown01Icon, Cancel01Icon } from "hugeicons-react";
import { ArrowLeft01Icon, InformationCircleIcon } from "hugeicons-react";
import ClipLoader from "react-spinners/ClipLoader";
import toast, { Toaster } from "react-hot-toast";
import { getAmountOutFn } from "../../../Exchange/ExchangePages/ExchangeTrade/Liquidity/getPrice";
import { ethers } from "ethers";
import CustomLoader from "../../../Components/CustomLoader/CustomLoader";
import { decrypt } from "../../../helper/encryption";
import { INITIATE_SWAP } from "../../../services/business";
import WalletPop from "../WalletPop";
import {
  extractErrorMessage,
  getAmountsOut,
} from "../../../Exchange/ExchangePages/BuySell/helper";
import { triggerBalanceChange } from "../../../Redux/feautures/assets/assetSlice";
import { checkApproval, setApproval } from "../../../services/etherUtils";

const Convert = ({ back }) => {
  const provider = new ethers.providers.JsonRpcProvider(
    "https://mainnet.egochain.org"
  );
  const dispatch = useDispatch();
  const { privateKey, wallet_address } = useSelector((state) => state.user);
  const { Assets } = useSelector((state) => state.assets);
  const [selectedFrom, setSelectedFrom] = useState(null);
  const [selectedTo, setSelectedTo] = useState(null);
  const [isFromDropdownOpen, setIsFromDropdownOpen] = useState(false);
  const [isToDropdownOpen, setIsToDropdownOpen] = useState(false);
  const [convertAmount, setconvertAmount] = useState("");
  const [fromAmount, setFromAmount] = useState("");
  const [toAmount, setToAmount] = useState(0);
  const [amountOutLoading, setAmountOutLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transactionType, setTransactionType] = useState(null);
  const [openWallet, setOpenWallet] = useState(false);
  const [hasAllowance, setHasAllowance] = useState(null);

  const [confirmBuyTransactionModal, setConfirmBuyTransactionModal] =
    useState(false);
  const [writeState, setWriteState] = useState({
    loading: false,
    error: null,
    success: false,
  });
  const convertFees = 0;
  const options = [
    {
      value: "EGAX",
      label: "EGAX",
      img: "/img/egax_logo.png",
      tokenAddress: "0x7e38B6d9c6074F259F213BBA2Bbf6B74d96fcE6A",
    },
  ];
  const options2 = [
    {
      value: "USDC",
      label: "USDC",
      img: "/img/usdcicon.png",
      tokenAddress: "0xF5121E85b239F650063637B8Df45DcEd2c88976c",
    },
  ];

  const handleSelectFrom = (option) => {
    setSelectedFrom(option);
    setIsFromDropdownOpen(false);
    setSelectedTo(options2[0]);
  };

  const handleSelectTo = (option) => {
    setSelectedTo(option);
    setIsToDropdownOpen(false);
    setSelectedFrom(options2[0]);
  };
  const toggle = () => {
    setSelectedFrom(selectedTo);
    setSelectedTo(selectedFrom);
  };

  const handleFromChange = (e) => {
    // setconvertAmount(e.target.value);
    setFromAmount(e.target.value);
  };
  const handleToChange = (e) => {
    // setconvertAmount(e.target.value);
    setToAmount(e.target.value);
  };
  const setAlllowanceFunc = async () => {
    setOpenWallet(false);
    setConfirmBuyTransactionModal(true);
    setLoading(true);
    try {
      const response = await setApproval({
        privateKey,
        tokenAddress: selectedFrom?.tokenAddress,
        spender: "0x1641aDe952320BEC5330B7e54D5A032FB5006de3",
      });
      const receipt = await response.wait();
      // console.log("====================================");
      // console.log(receipt);
      // console.log("====================================");

      toast.success(
        <div className="toast_success_div">
          <div className="toast_success_div_title">Allowance Success!</div>
          <div className="toast_success_div_para">
            You have successfully set your allowance.
          </div>
        </div>,
        {
          duration: 5000,
          className: "toast_success",
        }
      );
      setTransactionType(null);
      setOpenWallet(false);
      setHasAllowance(true);
      setLoading(false);
    } catch (error) {
      toast.error(
        <div className="toast_success_div">
          <div className="toast_error_div_title">
            {response.message === "Insufficient funds for intrinsic transaction"
              ? "   Insufficient balance for fees!!"
              : "Error!!"}
          </div>
          <div className="toast_success_div_para">
            {response.message === "Insufficient funds for intrinsic transaction"
              ? "Insufficient USDC balance, top up your USDC in your wallet to continue"
              : response.message}
          </div>
        </div>,
        {
          duration: 5000,
          className: "toast_error",
        }
      );
    }
  };
  const checkAllowanceFunction = async () => {
    const response = await checkApproval({
      owner: wallet_address,
      spender: "0x1641aDe952320BEC5330B7e54D5A032FB5006de3",
      // tokenAddress: selectedFrom?.tokenAddress,
      tokenAddress: options2[0].tokenAddress,
    });
    // console.log(response, "mikki");
    if (response === 0) {
      setHasAllowance(false);
      return;
    }
    if (response > 0) {
      setHasAllowance(true);
      return;
    }
  };

  useEffect(() => {
    checkAllowanceFunction();
  }, [fromAmount]);

  useEffect(() => {
    if (setFromAmount === "" || selectedFrom?.tokenAddress == undefined) {
      setToAmount("");
      return;
    } else {
      getAmountOutFunc();
    }
  }, [fromAmount, selectedFrom]);

  const getAmountOutFunc = async () => {
    if (fromAmount === "") {
      setToAmount("");
      return;
    }
    setAmountOutLoading(true);
    const paths = [selectedFrom?.tokenAddress, selectedTo?.tokenAddress];
    // console.log(paths, "paths");
    const res = await getAmountOutFn(
      ethers.utils.parseEther(fromAmount.toString()),
      paths
    );
    // console.log(res, "exactly");
    if (res) {
      setAmountOutLoading(false);
      // console.log(res.toString(), "exactly");
      const splitValue = res.toString().split(",");
      // console.log(ethers.utils.formatEther(splitValue[1]));
      setToAmount(ethers.utils.formatEther(splitValue[1]));

      return;
    }
    setAmountOutLoading(false);
    setFromAmount("");
  };

  const co_ordinatePurchase = async () => {
    if (selectedFrom?.value === "EGAX") {
      await processBuy();
    } else {
      await processSell();
    }
  };
  const processBuy = async () => {
    //do basic validation

    //console.log();

    try {
      if (!fromAmount) {
        toast.error("You must provide an amount to purchase");
        return;
      }
      setOpenWallet(false);
      setConfirmBuyTransactionModal(true);
      setWriteState({
        loading: true,
      });
      const decrypted = decrypt(privateKey);
      const signer = new ethers.Wallet(decrypted, provider);
      const routerAddress = "0x1641aDe952320BEC5330B7e54D5A032FB5006de3"; // Replace with the actual DEX router address
      const routerAbi = [
        {
          inputs: [
            { internalType: "uint256", name: "amountOutMin", type: "uint256" },
            { internalType: "address[]", name: "path", type: "address[]" },
            { internalType: "address", name: "to", type: "address" },
            { internalType: "uint256", name: "deadline", type: "uint256" },
          ],
          name: "swapExactETHForTokens",
          outputs: [
            { internalType: "uint256[]", name: "amounts", type: "uint256[]" },
          ],
          stateMutability: "payable",
          type: "function",
        },
      ];
      const contract = new ethers.Contract(routerAddress, routerAbi, signer);
      // Swap Parameters
      const amountOutMin = ethers.utils.parseEther("0.01"); // Minimum tokens expected (adjust accordingly)
      const path = [
        "0x7e38B6d9c6074F259F213BBA2Bbf6B74d96fcE6A",
        selectedTo?.tokenAddress,
      ]; // Replace with actual token addresses
      const recipient = wallet_address; // Address to receive tokens
      const deadline = Math.floor(Date.now() / 1000) + 60 * 20; // 20 minutes from now
      const ethAmount = ethers.utils.parseEther(fromAmount.toString()); // Amount of ETH to swap

      const tx = await contract.swapExactETHForTokens(
        amountOutMin,
        path,
        recipient,
        deadline,
        { value: ethAmount, gasLimit: 300000 }
      );

      const receipt = await tx.wait();
      dispatch(triggerBalanceChange());
      setWriteState({
        loading: false,
        success: true,
      });
      toast.success(
        <div className="toast_success_div">
          <div className="toast_success_div_title">Success!</div>
          <div className="toast_success_div_para">Buy Successful!!!</div>
        </div>,
        {
          duration: 5000,
          className: "toast_success",
        }
      );
      setConfirmBuyTransactionModal(false);
      // console.log(receipt, tx.hash, "receipt");
      const hash = tx?.hash;
      const res = await INITIATE_SWAP({ txHash: hash, pair: "EGAX-USDC" });
      // console.log(res, "sender spree");
    } catch (error) {
      setWriteState({
        loading: false,
        error: error.message,
      });
      // console.log("Transaction Error:", error);
      // console.log("Error in Transaction: ", error.message);
      // console.log("Transaction Error:", extractErrorMessage(error));

      toast.error(
        <div className="toast_success_div">
          <div className="toast_error_div_title">Error!!</div>
          <div className="toast_success_div_para">
            {extractErrorMessage(error) ==
            "insufficient funds for intrinsic transaction cost"
              ? "insufficient funds for gas fee please top up"
              : extractErrorMessage(error)}{" "}
          </div>
        </div>,
        {
          duration: 5000,
          className: "toast_error",
        }
      );
    }
  };
  // ==
  // ==
  const processSell = async () => {
    try {
      if (!fromAmount) {
        toast.error("You must provide an amount to purchase");
        return;
      }
      setOpenWallet(false);
      setConfirmBuyTransactionModal(true);
      setWriteState({
        loading: true,
      });
      const decrypted = decrypt(privateKey);
      const signer = new ethers.Wallet(decrypted, provider);
      const routerAddress = "0x1641aDe952320BEC5330B7e54D5A032FB5006de3"; // Replace with the actual DEX router address
      const routerAbi = [
        {
          inputs: [
            {
              internalType: "uint256",
              name: "amountIn",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "amountOutMin",
              type: "uint256",
            },
            {
              internalType: "address[]",
              name: "path",
              type: "address[]",
            },
            {
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "deadline",
              type: "uint256",
            },
          ],
          name: "swapExactTokensForETH",
          outputs: [
            {
              internalType: "uint256[]",
              name: "amounts",
              type: "uint256[]",
            },
          ],
          stateMutability: "nonpayable",
          type: "function",
        },
      ];
      const contract = new ethers.Contract(routerAddress, routerAbi, signer);
      // Swap Parameters

      const path = [
        selectedFrom?.tokenAddress,
        "0x7e38B6d9c6074F259F213BBA2Bbf6B74d96fcE6A",
      ]; // Replace with actual token addresses
      const getAmountOutMin = await getAmountsOut({
        amount: fromAmount,
        path,
      });
      // console.log(getAmountOutMin, "exactly");

      const recipient = wallet_address; // Address to receive tokens
      const deadline = Math.floor(Date.now() / 1000) + 60 * 20; // 20 minutes from now
      const ethAmount = ethers.utils.parseEther(fromAmount.toString()); // Amount of ETH to swap

      const tx = await contract.swapExactTokensForETH(
        ethAmount,
        getAmountOutMin,
        path,
        recipient,
        deadline
      );

      //console.log("Transaction submitted:", tx.hash);

      const receipt = await tx.wait();
      dispatch(triggerBalanceChange());
      setWriteState({
        loading: false,
        success: true,
      });
      toast.success(
        <div className="toast_success_div">
          <div className="toast_success_div_title">Success!</div>
          <div className="toast_success_div_para">Sell Successful!!!</div>
        </div>,
        {
          duration: 5000,
          className: "toast_success",
        }
      );
      setConfirmBuyTransactionModal(false);
      const hash = tx?.hash;
      // console.log("Transaction confirmed:", receipt);
      //submit the hash

      const res = await INITIATE_SWAP({ txHash: hash, pair: "EGAX-USDC" });
      //console.log(res, "sender spree");
    } catch (error) {
      // console.log(error.message);
      setWriteState({
        loading: false,
        error: error.message,
      });
      //console.log("Error in Transaction: ", error.message);
      // console.log("Transaction Error:", error);
      // console.log("Error in Transaction: ", error.message);
      // console.log("Transaction Error:", extractErrorMessage(error));

      toast.error(
        <div className="toast_success_div">
          <div className="toast_error_div_title">Error!!</div>
          <div className="toast_success_div_para">
            {extractErrorMessage(error) ==
            "insufficient funds for intrinsic transaction cost"
              ? "insufficient funds for gas fee please top up"
              : extractErrorMessage(error)}{" "}
          </div>
        </div>,
        {
          duration: 5000,
          className: "toast_error",
        }
      );
    }
  };

  return (
    <div className="convertArea" style={{ width: "-webkit-fill-available" }}>
      <div className="WalletDetailPageDiv_header">
        <div className="WalletDetailPageDiv_header_back" onClick={back}>
          <ArrowLeft01Icon size={24} />
        </div>
        convert tokens
        <div className="WalletDetailPageDiv_header_info">
          <InformationCircleIcon size={20} />
        </div>
      </div>
      <div
        className="bg-neutral-50 rounded-lg w-[45rem] px-4 py-4"
        style={{ width: "-webkit-fill-available" }}
      >
        <div className="">
          <p className="text-neutral-400 text-sm">Convert Your EGAX to USDC</p>
        </div>

        <div className="relative flex flex-col gap-[9px] dropdown pt-4 text-sm">
          <p>Convert From</p>
          <div className="relative">
            <button
              onClick={() => {
                setIsFromDropdownOpen((prev) => !prev);
                setIsToDropdownOpen(false);
              }}
              className="w-[18rem] py-4 px-4 rounded-md border border-neutral-200 bg-neutral-100 text-black flex items-center
                 justify-between outline-none"
              style={{ width: "100%" }}
            >
              <div className="flex items-center gap-3 text-sm font-semibold">
                {selectedFrom?.img ? (
                  <img
                    src={selectedFrom.img}
                    alt={selectedFrom.label}
                    className="w-6 h-6 rounded-full"
                  />
                ) : (
                  <div className="w-6 h-6 bg-neutral-300 rounded-full"></div>
                )}
                {selectedFrom?.label || "Select a Token"}
              </div>
              <span>
                <ArrowDown01Icon />
              </span>
            </button>
            {isFromDropdownOpen && (
              <ul className="absolute mt-2 w-full bg-neutral-100 border border-neutral-200 rounded-md shadow-lg z-50 max-h-40 overflow-y-auto">
                {options?.map((option) => (
                  <li
                    key={option.value}
                    onClick={() => handleSelectFrom(option)}
                    className="flex items-center gap-3 px-4 py-2 hover:bg-neutral-200 cursor-pointer font-semibold"
                  >
                    <img
                      src={option.img}
                      alt={option.label}
                      className="w-6 h-6 rounded-full"
                    />
                    {option.label}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="flex flex-col gap-[9px] pt-2">
            <div className="flex justify-between">
              <p>You send</p>

              <p className="text-xs">
                {parseFloat(
                  Assets.find((dd) => dd.tokenSymbol === selectedFrom?.value)
                    ?.balance || 0
                )?.toFixed(3)}
                {` ${selectedFrom?.value || ""}`}
              </p>
            </div>
            <div
              className="w-[18rem] border border-neutral-200 bg-neutral-100 rounded h-[3.5rem] p-3 py-8 outline-none text-sm flex items-center"
              style={{ width: "100%" }}
            >
              <input
                type="number"
                placeholder="Enter an amount"
                style={{
                  width: "100%",
                  // marginLeft: "3em",
                  // borderLeft: "solid 1px grey",
                  // paddingLeft: "5px",
                }}
                className="text-sm font-semibold"
                onChange={handleFromChange}
                value={fromAmount}
              />
              {/* <button
                className="flex items-center gap-2"
                style={{ width: "max-content" }}
              >
                Max
              </button>{" "} */}
            </div>
          </div>
        </div>
        <div className="relative toggle_div">
          <TbArrowsRightLeft
            className=" left-[20.5rem] text-2xl text-neutral-500 hover:bg-neutral-600 rounded"
            // style={{ margin: "auto" }}
            onClick={toggle}
          />
        </div>
        <div className="relative flex flex-col gap-[9px] dropdown text-sm">
          <p>To </p>
          <div className="relative drop_toks_div_bridg">
            <button
              onClick={() => {
                setIsToDropdownOpen((prev) => !prev);
                setIsFromDropdownOpen(false);
              }}
              className="w-[18rem] py-4 px-4 rounded-md border border-neutral-200 bg-neutral-100 text-black flex items-center
                 justify-between"
              style={{ width: "100%" }}
            >
              <div className="flex items-center gap-3 text-sm font-semibold">
                {selectedTo?.img ? (
                  <img
                    src={selectedTo.img}
                    alt={selectedTo.label}
                    className="w-6 h-6 rounded-full "
                  />
                ) : (
                  <div className="w-6 h-6 bg-neutral-300 rounded-full"></div>
                )}
                {selectedTo?.label || "Select a Token"}
              </div>
              <span>
                <ArrowDown01Icon />
              </span>
            </button>
            {isToDropdownOpen && (
              <ul className="absolute mt-2 w-full bg-neutral-100 border border-neutral-200 rounded-md shadow-lg z-50 max-h-40 overflow-y-auto ">
                {options?.map((option) => (
                  <li
                    key={option.value}
                    onClick={() => handleSelectTo(option)}
                    className="flex items-center gap-3 px-4 py-2 hover:bg-neutral-200 cursor-pointer font-semibold"
                  >
                    <img
                      src={option.img}
                      alt={option.label}
                      className="w-6 h-6 rounded-full"
                    />
                    {option.label}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="flex flex-col gap-[9px]">
            <div className="flex justify-between">
              <p>You receive</p>
              <p className="text-xs">
                {parseFloat(
                  Assets.find((dd) => dd?.tokenSymbol === selectedTo?.value)
                    ?.balance || 0
                )?.toFixed(3)}
                {` ${selectedTo?.value || ""}`}
              </p>
            </div>
            <button
              className="w-[18rem] border border-neutral-200 bg-neutral-100 rounded h-[3.5rem] p-3 py-8 outline-none text-sm flex items-center "
              style={{ width: "100%" }}
            >
              {/* <button
                className="flex items-center gap-2"
                style={{ width: "max-content" }}
              >
                <img
                  src="/img/usdcicon.png"
                  alt=""
                  className="w-6 h-6 rounded-full"
                />{" "}
                <span className="text-sm font-semibold">
                  {" "}
                  {selectedTo.value}
                </span>
              </button>{" "} */}
              {amountOutLoading ? (
                <CustomLoader
                  width="20px"
                  height="20px"
                  padding="10px"
                  borderSize="2px"
                  borderColor="#6059fd #c7c6d5 #c7c6d5"
                />
              ) : (
                <input
                  type="number"
                  value={toAmount || ""}
                  style={{
                    width: "100%",
                    // marginLeft: "3em",
                    // borderLeft: "solid 1px grey",
                    // paddingLeft: "5px",
                  }}
                  disabled
                  className="text-sm font-semibold"
                />
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="convert_div_btn_div">
        <div className="convert_div_btn_div_1">
          <h6 className="text-neutral-500 text-sm" style={{ fontSize: "12px" }}>
            Includes a 0.3% conversion fee
          </h6>
        </div>

        {selectedFrom?.value === "EGAX" ? (
          <button
            className="product_detail_cont_div5_7_btn"
            onClick={() => {
              setOpenWallet(true);
              setConfirmBuyTransactionModal(false);
              setTransactionType("buy");
            }}
            disabled={writeState.loading}
          >
            {writeState.loading ? (
              <>
                <ClipLoader color="#ffffff" size={18} /> Buying...
              </>
            ) : (
              "Convert Now"
            )}
          </button>
        ) : (
          <>
            {hasAllowance ? (
              <button
                className="convert_div_btn_div_1_btn"
                onClick={() => {
                  setOpenWallet(true);
                  setConfirmBuyTransactionModal(false);
                  setTransactionType("sell");
                }}
                disabled={writeState.loading}
                // style={{
                //   display: "flex",
                //   alignItems: "center",
                //   justifyContent: "center",
                // }}
              >
                {writeState.loading ? (
                  <>
                    <ClipLoader color="#ffffff" size={18} /> Converting...
                  </>
                ) : (
                  "Convert"
                )}
              </button>
            ) : (
              <button
                className="product_detail_cont_div5_7_btn"
                onClick={() => {
                  setOpenWallet(true);
                  setConfirmBuyTransactionModal(false);
                  setTransactionType("approve");
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                disabled={loading}
              >
                {loading ? (
                  <>
                    {" "}
                    <ClipLoader color="#fff" size={24} /> Approving...
                  </>
                ) : (
                  "   Approve Spending"
                )}
              </button>
            )}
          </>
        )}
      </div>
      <Toaster />
      {confirmBuyTransactionModal && (
        <div className="confrimBuySellDiv">
          <div className="confrimBuySellDiv_container">
            <div className="confrimBuySellDiv_title">
              Confirm Convert
              <Cancel01Icon
                size={20}
                className="confrimBuySellDiv_title_icon"
                onClick={() => {
                  setConfirmBuyTransactionModal(false);
                }}
              />
            </div>
            <div className="confrimBuySellDiv_body">
              <div className="confrimBuySellDiv_body1">
                <div className="confrimBuySellDiv_body1_cont1">
                  <div className="confrimBuySellDiv_body1_cont1_area1">
                    {parseFloat(fromAmount)?.toFixed(8)}
                  </div>
                  <div className="confrimBuySellDiv_body1_cont1_area2">
                    {selectedFrom.value}
                    {/* {activeBtn === "buy"
                      ? app.BASE_TOKEN
                      : ticker.split("-")[1]} */}
                  </div>
                </div>
                <div className="confrimBuySellDiv_body1_cont1">
                  <div className="confrimBuySellDiv_body1_cont1_area1">
                    {parseFloat(toAmount)?.toFixed(8)}
                  </div>
                  <div className="confrimBuySellDiv_body1_cont1_area2">
                    {selectedTo.value}
                  </div>
                </div>
              </div>
              <div className="confrimBuySellDiv_body2">
                <div className="swap_details_div_body">
                  <div className="swap_details_div_body_cont1">
                    <div className="swap_details_div_body_cont1_div1">
                      Price
                    </div>
                    <div className="swap_details_div_body_cont1_div2">
                      {/* {activeBtn === "buy"
                        ? parseFloat(
                            parseFloat(amountOut) / parseFloat(amount) || 0
                          )?.toFixed(8)
                        : parseFloat(
                            parseFloat(1) * parseFloat(amountOut) || 0
                          )?.toFixed(8)}{" "}
                      {activeBtn === "buy"
                        ? ticker.split("-")[1]
                        : app.BASE_TOKEN}{" "}
                      /{" "}
                      {activeBtn === "buy"
                        ? app.BASE_TOKEN
                        : ticker.split("-")[1]}{" "} */}
                    </div>
                  </div>
                  <div className="swap_details_div_body_cont1">
                    <div className="swap_details_div_body_cont1_div1">
                      Minimum received
                    </div>
                    <div className="swap_details_div_body_cont1_div2">
                      {amountOutLoading ? (
                        <>
                          {" "}
                          <CustomLoader
                            width="15px"
                            height="15px"
                            padding="0px"
                            borderSize="2px"
                            containerWidth={"max-content"}
                            margin={"0px 5px 0px 10px"}
                            borderColor="#6059fd #c7c6d5 #c7c6d5"
                          />
                        </>
                      ) : (
                        <> {parseFloat(toAmount || 0)?.toFixed(8)} </>
                      )}

                      {selectedTo.value}
                    </div>
                  </div>
                  <div className="swap_details_div_body_cont1">
                    <div className="swap_details_div_body_cont1_div1">
                      Slippage Tolerance
                    </div>
                    <div className="swap_details_div_body_cont1_div2">
                      0.50%
                    </div>
                  </div>
                  <div className="swap_details_div_body_cont1">
                    <div className="swap_details_div_body_cont1_div1">
                      Trading fee
                    </div>
                    <div className="swap_details_div_body_cont1_div2">
                      0.30%
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="product_detail_cont_div5_7_btn"
                onClick={() => {
                  setOpenWallet(true);
                  setConfirmBuyTransactionModal(false);
                  setTransactionType("buy");
                }}
                disabled={writeState.loading}
              >
                {writeState.loading ? (
                  <>
                    <ClipLoader color="#ffffff" size={18} /> Buying...
                  </>
                ) : (
                  "Confirm Convert"
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      {openWallet && transactionType === "approve" && (
        <WalletPop
          closePop={() => {
            setOpenWallet(false);
            setConfirmBuyTransactionModal(true);
          }}
          transaction={true}
          transactionType={transactionType}
          transact={setAlllowanceFunc}
          loading={loading}
          tokenName={selectedFrom?.value}
        />
      )}

      {openWallet && transactionType === "buy" && (
        <WalletPop
          closePop={() => {
            setOpenWallet(false);
            setConfirmBuyTransactionModal(true);
          }}
          transaction={true}
          transactionType={transactionType}
          transact={co_ordinatePurchase}
          loading={writeState.loading}
          tokenName={selectedFrom.value}
        />
      )}
      {openWallet && transactionType === "sell" && (
        <WalletPop
          closePop={() => {
            setOpenWallet(false);
            setConfirmBuyTransactionModal(true);
          }}
          transaction={true}
          transactionType={transactionType}
          transact={processSell}
          loading={writeState.loading}
          tokenName={selectedFrom?.value}
        />
      )}
    </div>
  );
};

export default Convert;
