import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GET_TRADE_DATA } from "../../../services/exchange";

export const fetchTradesData = createAsyncThunk(
  "tradesData/fetchTradesData",
  async (ticker, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState().tradesData;
      const hasCachedData = !!state.tradesData[ticker];

      // Always fetch fresh data
      const data = await GET_TRADE_DATA({ ticker, page: "1", limit: "10" });
      const freshTrades = data.trades || [];

      // If we have cached data, do silent update
      if (hasCachedData) {
        dispatch(
          silentTradesDataUpdate({
            ticker,
            tradesData: freshTrades,
          })
        );
        // Return empty to prevent loading state change
        return { ticker, tradesData: [] };
      }

      // If no cached data exists, return normally
      return { ticker, tradesData: freshTrades };
    } catch (error) {
      console.error("Error fetching trades data:", error);
      return rejectWithValue({
        ticker,
        error: error.message,
      });
    }
  }
);

const tradesDataSlice = createSlice({
  name: "tradesData",
  initialState: {
    tradesData: {},
    loading: {},
    error: {},
    lastUpdated: {},
  },
  reducers: {
    silentTradesDataUpdate: (state, action) => {
      const { ticker, tradesData } = action.payload;
      state.tradesData[ticker] = tradesData;
      state.lastUpdated[ticker] = Date.now();
      // No loading state change - silent update
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTradesData.pending, (state, action) => {
        const ticker = action.meta.arg;
        const hasData = !!state.tradesData[ticker];

        // Only show loading if no data exists
        if (!hasData) {
          state.loading[ticker] = true;
        }
        state.error[ticker] = null;
      })
      .addCase(fetchTradesData.fulfilled, (state, action) => {
        const { ticker, tradesData } = action.payload;
        // Only update if we got actual data (not empty array from silent update)
        if (tradesData.length > 0) {
          state.tradesData[ticker] = tradesData;
          state.loading[ticker] = false;
          state.lastUpdated[ticker] = Date.now();
        }
      })
      .addCase(fetchTradesData.rejected, (state, action) => {
        const { ticker, error } = action.payload;
        state.loading[ticker] = false;
        state.error[ticker] = error;
      });
  },
});

export const { silentTradesDataUpdate } = tradesDataSlice.actions;
export default tradesDataSlice.reducer;
