export const walletSplliter = ({ address }) => {
  if (!address) {
    return "";
  }
  const result = `${address?.slice(0, 4)}...${address?.slice(
    37,
    address.length
  )}`;

  return result;
};
