import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  latestBuy: null,
  latestSell: null,
  latestTrade: null, // This is for mobile (latest trade of any type)
};

const tradeSlice = createSlice({
  name: "trades",
  initialState,
  reducers: {
    setLatestTrade: (state, action) => {
      const trades = action.payload;

      if (!Array.isArray(trades) || trades.length === 0) return;

      // Sort trades by createdAt (latest first)
      const sortedTrades = trades.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      // Set the latest trade for mobile (most recent trade of any type)
      state.latestTrade = sortedTrades[0];

      // Find the latest BUY and SELL trades based on createdAt
      state.latestBuy =
        sortedTrades.find((trade) => trade.swapType === "BUY") ||
        state.latestBuy;
      state.latestSell =
        sortedTrades.find((trade) => trade.swapType === "SELL") ||
        state.latestSell;
    },
  },
});

export const { setLatestTrade } = tradeSlice.actions;
export default tradeSlice.reducer;
