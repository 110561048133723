import apiInstance from "../utils/axiosInstance";
import { BUY_USDC_ROUTE } from "./routes";

export const PURCHASE_USDC = async (payload) => {
  try {
    const res = await apiInstance.post(BUY_USDC_ROUTE, payload);
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
