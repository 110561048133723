// export const BASE_URL = "http://192.168.1.25:2017";
// export const BASE_URL = "http://localhost:2017";
export const EXCHANGE_URL = "https://backtest.egomart.org";
// export const EXCHANGE_URL = "http://localhost:4024";
export const BASE_URL = "https://listing.egomart.org";

export const createWalletRoute = `${BASE_URL}/public/signup`;

/**
 * @param {string} walletAddress
 */
export const userExistRoute = `${BASE_URL}/public/check-user`;

/**
 * @body {string} key
 */

export const importWalletRoute = `${BASE_URL}/public/import-wallet`;
export const LIST_ALL_ASSET_ROUTE = `${BASE_URL}/public/get-all-assets-new`;
export const NATIVE_TOKEN_BALANCE_ROUTE = `${BASE_URL}/user/check-native-token-balance`;
export const TRANSFER_TOKEN_ROUTE = `${BASE_URL}/user/transfer-token`;
export const CREATE_BUSINESS_ROUTE = `${BASE_URL}/business/create-business-bio`;
export const LIST_ON_BOT_MARKETPLACE_ROUTE = `${BASE_URL}/business/manufacture-and-list-on-bot`;
export const EDIT_LISTED_PRODUCT_ROUTE = `${BASE_URL}/business/update-product-info`;
export const GET_BUSINESS_INFO_ROUTE = `${BASE_URL}/business/get-all-data-for-user`;
export const ALL_BUSINESS_ROUTE = `${BASE_URL}/public/fetch-all-businesses`;
export const PUBLIC_BUSINESS_INFO_ROUTE = `${BASE_URL}/public/fetch-business-by-id`;
export const BUY_FROM_EXCHANGE_ROUTE = `${EXCHANGE_URL}/exchange/bot-order`;
export const DEPOSIT_TO_EXCHANGE_ROUTE = `${EXCHANGE_URL}/exchange/deposit-token`;
export const WITHDRAW_FROM_EXCHANGE_ROUTE = `${EXCHANGE_URL}/exchange/withdraw-token`;
export const CHECK_BALANCE_IN_EXCHANGE_ROUTE = `${EXCHANGE_URL}/exchange/check-balance`;
export const PURCHASE_PRODUCT_ROUTE = `${BASE_URL}/product/purchase-product-on-egomart`;
export const CHECK_PRODUCT_ALLOWANCE_ROUTE = `${BASE_URL}/product/check-allowance
`;
export const MATCHING_ENGINE_ROUTE = `${BASE_URL}/business/matching-engine`;
export const SET_PRODUCT_ALLOWANCE_ROUTE = `${BASE_URL}/product/set-allowance
`;
export const STAKE_PRODUCT_ROUTE = `${BASE_URL}/product/stake-purchased-product
`;
export const BRIDGE_ROUTE = `${BASE_URL}/bridge/initalize-bridge
`;
export const BRIDGE2_ROUTE = `${BASE_URL}/bridge/initalize-egc-bridge
`;
export const GET_ALL_TRADE_DATA_ROUTE = `${BASE_URL}/business/get-all-trades`;
export const GET_24_HR_STAT_ROUTE = `${BASE_URL}/web3/get-all-24-stats`;
export const GET_CHART_DATA_ROUTE = `${BASE_URL}/public/market-chart-new`;
export const GET_ALL_BUSINESS_STAT_ROUTE = `${BASE_URL}/web3/get-all-business-stats`;
export const GET_USER_ORDERS_ROUTE = `${BASE_URL}/product/get-all-my-orders`;
export const GET_ORDER_BY_BUSINESS_ID = `${BASE_URL}/business/get-all-orders-by-id?`;
export const FETCH_NETWORK_PROVIDER_ROUTES = `${BASE_URL}/public/get-all-providers?`;
export const GET_ALL_ORDER_ROUTES = `${BASE_URL}/business/get-all-orders`;
export const BUY_USDC_ROUTE = "/paystack/process/checkout";
export const INITIATE_SWAP_ROUTES = `${BASE_URL}/swap/initiate-swap`;
export const GET_TRADE_DATA_ROUTE = `${BASE_URL}/public/get-all-trades-by-pair`;
export const GET_LATEST_TRADE_ROUTE = `${BASE_URL}/web3/get-latest-trades`;
export const GET_ORDER_DELIVERY_DETAILS_ROUTE = `${BASE_URL}/business/get-order-info`;
export const UPDATE_ORDER_STATUS_ROUTE = `${BASE_URL}/business/update-order-status?`;
export const TRADE_LEADERBOARD_ROUTE = `${BASE_URL}/web3/get-trades-positions`;
export const TRIGGER_NEW_BUSINESS_ROUTE = `${BASE_URL}/public/test-event`;
export const GET_ALL_PRODUCT_BY_CATEGORY_ROUTE = `${BASE_URL}/public/get-all-products?`;
export const GET_PRODUCT_DETAIL_BY_ID_ROUTE = `${BASE_URL}/public/get-product-by-id`;
export const GET_BUSINESS_WITH_PRODUCT_ROUTE = `${BASE_URL}/public/get-businesses-and-product-count`;
export const ALL_PRODUCTS_ROUTE = `${BASE_URL}/public/get-all-products`;
