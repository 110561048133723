import axios from "axios";
import { BASE_URL } from "../services/routes";
import { store } from "../Redux/app/store";

const apiInstance = axios.create({
  baseURL: BASE_URL,
});

apiInstance.interceptors.request.use((config) => {
  const state = store.getState();
  const token = state.user.privateKey || "";
  config.headers = config.headers || {};
  config.headers["x-token"] = token;
  return config;
});

export default apiInstance;
