import React, { useState } from "react";
import { ArrowLeft01Icon, InformationCircleIcon } from "hugeicons-react";
import WalletSend from "../WalletSend/WalletSend";
import WalletModel from "./WalletModel";

const SendModal = ({
  Assets,
  toggleSendModal,
  selectedAsset,
  setSelectedAsset,
}) => {
  // const [selectedAsset, setSelectedAsset] = useState(null);
  const [sendModal, setSendModal] = useState(false);
  if (sendModal) {
    //console.log(selectedAsset, "abbba");

    return (
      <WalletSend
        selectedToken={selectedAsset}
        back={() => setSendModal(false)}
      />
    );
  }

  return (
    <div className="SendModal_div">
      <div className="WalletDetailPageDiv_header">
        <div
          className="WalletDetailPageDiv_header_back"
          onClick={toggleSendModal}
        >
          <ArrowLeft01Icon size={24} />
        </div>
        Select Asset
        <div className="WalletDetailPageDiv_header_info">
          <InformationCircleIcon size={20} />
        </div>
      </div>
      <WalletModel
        setSelectedAsset={setSelectedAsset}
        selectedAsset={selectedAsset}
        handleOnClick={() => {
          setSelectedAsset(data);
          // setSendModal(true);
        }}
      />

      {/* <div className="SendModal_div_body">
        {Assets?.map((data) => {
          //console.log(data, "ad");
          return (
            <div
              className="WalletPop_div_cont_walletHome_div2_body_asset"
              onClick={() => {
                setSelectedAsset(data);
                setSendModal(true);
              }}
            >
              <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont1">
                <img
                  src={data?.img || data?.image || "/img/art.png"}
                  alt=""
                  className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_img"
                />
                <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_area">
                  <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_area_title">
                    {data?.tokenAName}
                  </div>
                  <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_area_price">
                    {parseFloat(data?.currentPrice)?.toFixed(4)}
                  </div>
                </div>
              </div>
              <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont2">
                <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_area_title">
                  {parseFloat(data?.balance)?.toFixed(4)}
                </div>
                <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_area_price">
                  ${parseFloat(data.balance * 0.545)?.toFixed(2)}
                </div>
              </div>
            </div>
          );
        })}
      </div> */}
    </div>
  );
};

export default SendModal;
