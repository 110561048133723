import React from "react";
import "./index.css";
const Nodata = ({ message }) => {
  return (
    <div className="NodataDiv">
      <img src="/img/noDataIcon.webp" alt="" className="NodataDiv_img" />
      <div className="NodataDiv_txt">{message}</div>
    </div>
  );
};

export default Nodata;
