import { useEffect, useRef } from "react";
import { Camera02Icon, Tick01Icon } from "hugeicons-react";
const UploadWidget = ({ event, img }) => {
  const cloudinaryRef = useRef();
  const widgetRef = useRef();

  useEffect(() => {
    cloudinaryRef.current = window.cloudinary;
    widgetRef.current = cloudinaryRef.current?.createUploadWidget(
      {
        cloudName: "itechsuite",
        uploadPreset: "torqtech_preset",
      },
      event
    );
  }, []);

  return (
    <>
      <div
        className="seemore_div_cont_upload_div1_cont"
        onClick={() => widgetRef.current?.open()}
      >
        {img ? (
          <img
            src={img}
            alt=""
            className="seemore_div_cont_upload_div1_cont_icon"
          />
        ) : (
          <Camera02Icon className="seemore_div_cont_upload_div1_cont_icon" />
        )}
      </div>
    </>
  );
};

export default UploadWidget;
