import React, { useState, useEffect } from "react";
import Blockies from "react-blockies";
import { useSelector } from "react-redux";
import { Camera02Icon, ArrowLeft01Icon } from "hugeicons-react";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  GET_ORDER_BY_PRODUCT_BUSINESS_ID,
  PUBLIC_BUSINESS_INFO,
} from "../../../services/business";
import Nodata from "../../../Components/Nodata/Nodata";
import ReactTimeAgo from "react-time-ago";
import UploadWidget from "../../Cloudinary/UploadWidget";
import RwaOrderDetails from "./RwaOrderDetails";
import ListProduct from "../ListProduct";
import CustomLoader from "../../../Components/CustomLoader/CustomLoader";

const RwaProductDetails = () => {
  const { prodId, businessId } = useParams();

  const navigate = useNavigate();
  const { wallet_address, business_info } = useSelector((state) => state.user);
  const [listModal, setListModal] = useState(false);
  const [orders, setOrders] = useState([]);
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [businessProducts, setBusinessProducts] = useState(null);
  const [businessProductsLoading, setBusinessProductsLoading] = useState(false);
  const [orderModal, setOrderModal] = useState(null);

  const toggleListModal = () => {
    setListModal(!listModal);
  };

  //// console.log(prodId);

  const fetchBusinessProducts = async () => {
    setBusinessProductsLoading(true);
    try {
      const res = await PUBLIC_BUSINESS_INFO({
        id: business_info?.businesses?.businessName,
      });
      // console.log(res);

      const filteredProducts = res?.products?.filter(
        (product) => product?.productId === prodId
      );

      setBusinessProducts(filteredProducts[0] || null);
      setBusinessProductsLoading(false);
    } catch (error) {
      console.error("Error fetching business products:", error);
      setBusinessProductsLoading(false);
    }
  };
  useEffect(() => {
    fetchBusinessProducts();
  }, [business_info]);

  const fetchOrders = async () => {
    setOrdersLoading(true);
    const res = await GET_ORDER_BY_PRODUCT_BUSINESS_ID(prodId, businessId);
    //// console.log(res);
    if (res?.success) {
      setOrdersLoading(false);
      setOrders(res?.data?.orders);
    }
    setOrdersLoading(false);
  };
  useEffect(() => {
    fetchOrders();
  }, []);

  // console.log(businessProducts);

  // console.log(orders);

  return (
    <div className="RwaProductDetails_div">
      <div className="ListModal_cont_area_back_button">
        {" "}
        <div
          className="ListModal_cont_area_back_button_div1"
          onClick={() => {
            navigate("/business/products");
          }}
        >
          <ArrowLeft01Icon />
          Back
        </div>
      </div>
      <div className="RwaProductDetails_div_div1">
        <div className="RwaProductDetails_div_div1_cont1">
          {businessProductsLoading ? (
            <div
              style={{
                background: "#ededf3",
                height: "100px",
                width: "100px",
                padding: "10px",
                marginRight: "10px",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CustomLoader
                containerWidth={"max-content"}
                width={"30px"}
                height={"30px"}
              />
            </div>
          ) : (
            <img
              src={businessProducts?.images}
              alt=""
              className="RwaProductDetails_div_div1_img"
            />
          )}

          <div className="RwaProductDetails_div_div1_cont2">
            <div className="RwaProductDetails_div_div1_cont2_title">
              {" "}
              {businessProducts?.name || "_ _"}
            </div>
            <div className="RwaProductDetails_div_div1_cont2_title_Span_title">
              <span className="RwaProductDetails_div_div1_cont2_title_Span">
                Brand:
              </span>{" "}
              {businessProducts?.brand || "_ _"}
            </div>
          </div>
        </div>
        <div className="RwaProductDetails_div_div1_cont2">
          {businessProductsLoading ? (
            <button className="RwaProductDetails_div_div1_cont2_btn" disabled>
              Edit Product
            </button>
          ) : (
            <button
              className="RwaProductDetails_div_div1_cont2_btn"
              onClick={toggleListModal}
            >
              Edit Product
            </button>
          )}
        </div>
      </div>
      <div className="RwaProductDetails_div_div2">
        <div className="RwaBusinessDetails_div_area_1_cont1_div3">
          <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1">
            <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_title">
              Business Owner
            </div>
            <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_value">
              <Blockies
                seed={
                  businessProducts?.personnel
                    ? businessProducts?.personnel
                    : "0xXXXX"
                }
                size={5}
                scale={4}
                className="blockies_icon"
              />{" "}
              {businessProducts?.personnel ? (
                <>
                  {" "}
                  {`${businessProducts?.personnel?.slice(
                    0,
                    4
                  )}...${businessProducts?.personnel?.slice(37, 42)}`}
                </>
              ) : (
                <>0xXXXX</>
              )}
            </div>
          </div>
          <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1">
            <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_title">
              Category
            </div>
            <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_value">
              {businessProducts?.category || "_ _"}
            </div>
          </div>
          <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1">
            <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_title">
              Quantity
            </div>
            <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_value">
              {businessProducts?.quantity || 0}
            </div>
          </div>
          <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1">
            <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_title">
              Price
            </div>
            <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_value">
              {parseFloat(businessProducts?.userAmount || 0)?.toFixed(2)}
            </div>
          </div>

          <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1">
            <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_title">
              Created
            </div>
            <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_value">
              <ReactTimeAgo
                date={businessProducts?.createdAt || 0}
                locale="en-US"
              />
            </div>
          </div>
        </div>
        <div
          className="RwaProductDetails_div_div2_details"
          dangerouslySetInnerHTML={{ __html: businessProducts?.details }}
        ></div>
      </div>
      <div className="RwaProductDetails_div_div3">
        <div className="ExchangeOrder_div_area2_title">
          {orders?.length || 0} Orders
          <div className="ExchangeOrder_div_area2_title_filter_div"></div>
        </div>

        {ordersLoading ? (
          <div style={{ background: "#fff", height: "300px" }}>
            <CustomLoader containerWidth={"100%"} margin={"10px 0px "} />
          </div>
        ) : (
          <div className="RwaProductDetails_div_div3_body">
            {orders?.length === 0 ? (
              <Nodata message={"No order found"} />
            ) : (
              <table className="TableCompWithDiv_cont_table">
                <thead className="TableCompWithDiv_cont_head">
                  <tr className="TableCompWithDiv_cont_head_titles">
                    <th className="TableCompWithDiv_cont_head_titles_div TableCompWithDiv_cont_head_titles_div_first">
                      Product
                    </th>
                    <th className="TableCompWithDiv_cont_head_titles_div">
                      Price
                    </th>
                    <th className="TableCompWithDiv_cont_head_titles_div">
                      Quantity
                    </th>
                    <th className="TableCompWithDiv_cont_head_titles_div">
                      Total
                    </th>
                    <th className="TableCompWithDiv_cont_head_titles_div">
                      Status
                    </th>
                    <th className="TableCompWithDiv_cont_head_titles_div">
                      Time
                    </th>
                    <th className="TableCompWithDiv_cont_head_titles_div ">
                      Order Id
                    </th>
                    <th className="TableCompWithDiv_cont_head_titles_div  TableCompWithDiv_cont_head_titles_div_last"></th>
                  </tr>
                </thead>

                <tbody className="tableBody">
                  {orders
                    ?.sort(
                      (b, a) => new Date(a.createdAt) - new Date(b.createdAt)
                    )
                    ?.map((data) => (
                      <tr
                        className="tableBody_row "
                        onClick={() => setOrderModal(data)}
                      >
                        <td className="tableBody_row_data tableBody_row_data_first  ">
                          <div className="tableBody_row_data_product_div">
                            <img
                              src={data.Get_product_image}
                              alt=""
                              className="tableBody_row_data_product_div_img"
                            />
                            <div className="tableBody_row_data_product_div_cont">
                              <div className="tableBody_row_data_product_div_cont_1">
                                {data?.item_name}
                              </div>
                              <div className="tableBody_row_data_product_div_cont_2">
                                <span className="tableBody_row_data_product_div_cont_2_span">
                                  {" "}
                                  Business:
                                </span>{" "}
                                {business_info?.businesses?.businessName}
                              </div>
                            </div>
                          </div>
                        </td>

                        <td className="tableBody_row_data">
                          <div className="tableBody_row_data_product_price">
                            <div className="tableBody_row_data_product_price_div1">
                              {parseFloat(data?.amount || 0)?.toFixed(2)} USDC
                            </div>
                            <span className="tableBody_row_data_product_price_span">
                              =$ {parseFloat(data?.amount || 0)?.toFixed(2)}
                            </span>
                          </div>
                        </td>
                        <td className="tableBody_row_data">{data?.qty}</td>
                        <td className="tableBody_row_data">
                          {" "}
                          <div className="tableBody_row_data_product_price">
                            <div className="tableBody_row_data_product_price_div1">
                              {parseFloat(
                                parseFloat(data?.qty) * parseFloat(data?.amount)
                              )?.toFixed(2)}{" "}
                              USDC
                            </div>
                            <span className="tableBody_row_data_product_price_span">
                              =${" "}
                              {parseFloat(
                                parseFloat(data?.qty) * parseFloat(data?.amount)
                              )?.toFixed(2)}{" "}
                            </span>
                          </div>
                        </td>
                        <td className="tableBody_row_data">
                          <div
                            className={
                              data?.status === "INIT"
                                ? "tableBody_row_data_product_Status"
                                : "tableBody_row_data_product_Status_delivered"
                            }
                          >
                            {data?.status === "INIT"
                              ? "Processing"
                              : data?.status}{" "}
                          </div>
                        </td>
                        <td className="tableBody_row_data">
                          {" "}
                          <ReactTimeAgo date={data?.createdAt} locale="en-US" />
                        </td>
                        <td className="tableBody_row_data ">
                          {data?.order_id}
                        </td>
                        <td className="tableBody_row_data tableBody_row_data_last">
                          ...
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>
      {listModal && (
        <ListProduct
          toggleListModal={toggleListModal}
          type={"edit"}
          productId={prodId}
          businessProducts={businessProducts}
        />
      )}

      {orderModal && (
        <RwaOrderDetails
          back={() => setOrderModal(null)}
          payload={orderModal}
          setPayload={setOrderModal}
          business_info={business_info}
        />
      )}
    </div>
  );
};

export default RwaProductDetails;
