import apiInstance from "../utils/axiosInstance";
import exchangeInstance from "../utils/exchangeInstance";
import {
  ALL_BUSINESS_ROUTE,
  BUY_FROM_EXCHANGE_ROUTE,
  CHECK_BALANCE_IN_EXCHANGE_ROUTE,
  CREATE_BUSINESS_ROUTE,
  DEPOSIT_TO_EXCHANGE_ROUTE,
  GET_BUSINESS_INFO_ROUTE,
  LIST_ON_BOT_MARKETPLACE_ROUTE,
  MATCHING_ENGINE_ROUTE,
  PUBLIC_BUSINESS_INFO_ROUTE,
  WITHDRAW_FROM_EXCHANGE_ROUTE,
  GET_TRADE_DATA_ROUTE,
  GET_LATEST_TRADE_ROUTE,
  TRADE_LEADERBOARD_ROUTE,
} from "./routes";

export const EXCHANGE_MARKET_ORDER = async (payload) => {
  try {
    const res = await apiInstance.post(MATCHING_ENGINE_ROUTE, payload);
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
export const DEPOSIT_TO_EXCHANGE = async (payload) => {
  try {
    const res = await exchangeInstance.post(DEPOSIT_TO_EXCHANGE_ROUTE, payload);
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
export const WITHDRAW_FROM_EXCHANGE = async (payload) => {
  try {
    const res = await exchangeInstance.post(
      WITHDRAW_FROM_EXCHANGE_ROUTE,
      payload
    );
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
export const CHECK_BALANCE_IN_EXCHANGE = async (payload) => {
  try {
    const res = await exchangeInstance.post(
      CHECK_BALANCE_IN_EXCHANGE_ROUTE,
      payload
    );
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
export const GET_TRADE_DATA = async ({ ticker, page, limit }) => {
  try {
    const res = await apiInstance.get(
      `${GET_TRADE_DATA_ROUTE}/${ticker}?page=${page}&limit=${limit}`
    );
    // console.log(res, "tradesesrererere");
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
export const GET_LATEST_TRADE = async () => {
  try {
    const res = await apiInstance.get(`${GET_LATEST_TRADE_ROUTE}`);
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
export const GET_TRADE_LEADERBOARD = async () => {
  try {
    const res = await apiInstance.get(`${TRADE_LEADERBOARD_ROUTE}`);
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
