import React, { useEffect, useRef, useState } from "react";
import { Slider } from "antd";
import { Select } from "antd";
import { ConfigProvider } from "antd";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { Toaster, toast } from "react-hot-toast";
import { EXCHANGE_MARKET_ORDER } from "../../../services/exchange";
import { Alert02Icon, Cancel01Icon } from "hugeicons-react";
import { extractErrorMessage } from "./helper";
import { setActiveProvider } from "../../../Redux/feautures/assets/assetSlice";
import { app } from "../../../utils/config";
import {
  _all_amount,
  _all_prices,
  _all_prices2,
  _buyManager,
  getAmountsOut,
  getBalanceFromAddress,
} from "./helper";
import { getPrice } from "../ExchangeTrade/Liquidity/getPrice";
import { numberWithCommas } from "../../../assets/js/numberWithCommas";
import { ethers } from "ethers";
import { decrypt } from "../../../helper/encryption";
import { INITIATE_SWAP } from "../../../services/business";
import { CHECK_ALLOWANCE, SET_ALLOWANCE } from "../../../services/product";
import WalletPop from "../../../OnchainWallet/WalletPop/WalletPop";
import {
  triggerBalanceChange,
  updateBalancesAfterTrade,
} from "../../../Redux/feautures/assets/assetSlice";
import { checkApproval, setApproval } from "../../../services/etherUtils";
import { getAmountOutFn } from "../ExchangeTrade/Liquidity/getPrice";
import CustomLoader from "../../../Components/CustomLoader/CustomLoader";
import routerAbi from "../ExchangeTrade/Liquidity/dhiveRouterAbi.json";

const BuySell = ({
  activeBtn,
  toggleActiveBtn,
  marketPrice,
  balances,
  onChangeCallback,
  flight,
  symbol,
  tokenAddress,
  ticker,
  businessStatus,
  data,
  baseToken,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orders } = useSelector((state) => state.orders);
  const { trades } = useSelector((state) => state.trades);
  const { activeProvider, balanceState: balancer } = useSelector(
    (state) => state.assets
  );
  const Assets = useSelector(
    (state) => state.assets.tokensByNetwork[activeProvider?.network] || []
  );
  const balanceState = useSelector(
    (state) =>
      state.assets.balanceState.byNetwork[activeProvider?.network] || {}
  );
  const { privateKey, wallet_address } = useSelector((state) => state.user);
  const [processing, setProcessing] = useState(false);
  const [price, setPrice] = useState(0);
  const [amount, setAmount] = useState("");
  const [hasAllowance, setHasAllowance] = useState(null);
  const [slippage, setSlippage] = useState(0.5);
  const [loading, setLoading] = useState(false);
  const [transactionType, setTransactionType] = useState(null);
  const [openWallet, setOpenWallet] = useState(false);
  const [tokenPriceInEgax, setTokenPriceInEgax] = useState(0);
  const [amountOut, setAmountOut] = useState("");
  const [amountOutLoading, setAmountOutLoading] = useState(false);
  const [switchNetworkPop, setSwitchNetworkPop] = useState(false);
  const [switchLoading, setSwitchLoading] = useState(false);
  const [switched, setSwitched] = useState(false);
  const [previousNetwork, setPreviousNetwork] = useState(null);
  const [confirmBuyTransactionModal, setConfirmBuyTransactionModal] =
    useState(false);
  const [writeState, setWriteState] = useState({
    loading: false,
    error: null,
    success: false,
  });
  console.log("====================================");
  console.log(tokenAddress);
  console.log("====================================");
  const scanUrl = [
    { EGOCHAIN: "https://egoscan.io/address" },
    { BASE: "https://basescan.org/address" },
  ];
  const baseTokens = {
    WEGAX: "0x7e38B6d9c6074F259F213BBA2Bbf6B74d96fcE6A",
    DhiveBase: "0x6Db1e1b97e6727D93FdfEbE68CB9Dab094fA8e52",
  };
  console.log(data);

  const paths =
    activeBtn == "buy"
      ? [
          data?.network === "EGOCHAIN"
            ? baseTokens.WEGAX
            : baseTokens.DhiveBase,
          tokenAddress,
        ]
      : [
          tokenAddress,
          data?.network === "EGOCHAIN"
            ? baseTokens.WEGAX
            : baseTokens.DhiveBase,
        ];

  const RouterAddress =
    data?.network === "EGOCHAIN"
      ? "0x1641aDe952320BEC5330B7e54D5A032FB5006de3"
      : data?.network === "BASE"
      ? "0xaaa3b1F1bd7BCc97fD1917c18ADE665C5D31F066"
      : "";

  const marks = {
    0: "0%",
    25: "25%",
    50: "50%",
    75: "75%",
    100: "100%",
  };
  const provider = new ethers.providers.JsonRpcProvider(
    data?.network === "EGOCHAIN"
      ? "https://mainnet.egochain.org"
      : data?.network === "BASE"
      ? "https://base-rpc.publicnode.com"
      : "https://mainnet.egochain.org"
  );

  const slippagePercentage = ethers.BigNumber.from(Math.floor(0.5 * 100));

  useEffect(() => {
    console.log(
      parseFloat(
        balancer.byNetwork[`${data?.network} NETWORK`]?.tokens[
          data?.contractAddress.toLowerCase()
        ]?.balance
      ),

      "cheeeeeeee"
    );
    onChangeCallback();
  }, [activeBtn]);

  // ====
  // ====
  // ====
  const sliderChange = (value) => {
    activeBtn === "buy"
      ? setAmount(
          parseFloat(
            ((value === 100 ? 99.99 : value) / 100) *
              Assets.find((value) => value?.tokenBName === baseToken)
                ?.balance || 0
          )?.toFixed(4)
        )
      : setAmount(
          parseFloat(
            ((value === 100 ? 99.99 : value) / 100) *
              Assets.find((value) => value?.tokenB === tokenAddress)?.balance ||
              0
          )?.toFixed(4)
        );

    if (value === 0) {
      setAmountOut(0);
    }
  };
  //  ===
  //  ===
  //  ===
  const handleAmountChange = (event) => {
    setAmount(event.target.value);
    if (event.target.value == "") {
      setAmountOut("");
    }
  };

  const processBuy = async () => {
    console.log("====================================");
    console.log(paths);
    console.log("====================================");
    try {
      if (!amount || !amountOut) {
        toast.error("You must provide an amount to purchase");
        return;
      }
      setOpenWallet(false);
      setConfirmBuyTransactionModal(true);
      setWriteState({
        loading: true,
      });
      const decrypted = decrypt(privateKey);
      const signer = new ethers.Wallet(decrypted, provider);
      const routerAddress = RouterAddress; // Replace with the actual DEX router address
      const contract = new ethers.Contract(routerAddress, routerAbi, signer);
      const adjustedSlippage = Math.max(slippage * 1.5, 0.5); // Increase slippage by 50% or set min 0.5%
      const slippageBN = ethers.BigNumber.from(10000).sub(
        Math.floor(adjustedSlippage * 100)
      );
      // Swap Parameters

      const path = paths; // Replace with actual token addresses
      const recipient = wallet_address; // Address to receive tokens
      const deadline = Math.floor(Date.now() / 1000) + 60 * 20; // 20 minutes from now
      const ethAmount = ethers.utils.parseEther(amount.toString()); // Amount of ETH to swap
      const AmountsOut = ethers.utils.parseEther(amountOut.toString());
      const amountOutMin = AmountsOut.mul(slippageBN).div(10000);
      console.log("====================================");
      console.log(amountOutMin, AmountsOut);
      console.log("====================================");
      if (data?.network == "EGOCHAIN") {
        const tx = await contract.swapExactETHForTokens(
          amountOutMin,
          path,
          recipient,
          deadline,
          {
            value: ethAmount,
            gasLimit: 300000,
          }
        );

        console.log("====================================");
        console.log(tx);
        console.log("====================================");

        const receipt = await tx.wait();
        console.log("====================================");
        console.log(receipt);
        console.log("====================================");
        // dispatch(triggerBalanceChange());
        dispatch(
          updateBalancesAfterTrade({
            networkName: "EGOCHAIN NETWORK",
            walletAddress: wallet_address,
            tokenAddresses: [path[1]], // Addresses of tokens involved in trade
          })
        );
        setWriteState({
          loading: false,
          success: true,
        });
        toast.success(
          <div className="toast_success_div">
            <div className="toast_success_div_title">Success!</div>
            <div className="toast_success_div_para">Buy Successful!!!</div>
          </div>,
          {
            duration: 5000,
            className: "toast_success",
          }
        );
        setConfirmBuyTransactionModal(false);
        // // console.log(receipt, tx.hash, "receipt");
        const hash = tx?.hash;

        const res = await INITIATE_SWAP({ txHash: hash, pair: ticker });
        // // console.log(res, "sender spree");
        return;
      }
      if (data?.network == "BASE") {
        const tx = await contract.swapExactTokensForTokens(
          ethAmount,
          amountOutMin,
          path,
          recipient,
          deadline,
          {
            gasLimit: 300000,
          }
        );

        console.log("====================================");
        console.log(tx);
        console.log("====================================");

        const receipt = await tx.wait();
        console.log("====================================");
        console.log(receipt);
        console.log("====================================");
        // dispatch(triggerBalanceChange());
        dispatch(
          updateBalancesAfterTrade({
            networkName: `BASE NETWORK`,
            walletAddress: wallet_address,
            tokenAddresses: [path[0], path[1]], // Addresses of tokens involved in trade
          })
        );
        setWriteState({
          loading: false,
          success: true,
        });
        toast.success(
          <div className="toast_success_div">
            <div className="toast_success_div_title">Success!</div>
            <div className="toast_success_div_para">Buy Successful!!!</div>
          </div>,
          {
            duration: 5000,
            className: "toast_success",
          }
        );
        setConfirmBuyTransactionModal(false);
        // // console.log(receipt, tx.hash, "receipt");
        const hash = tx?.hash;
        console.log("====================================");
        console.log(hash, ticker);
        console.log("====================================");
        const res = await INITIATE_SWAP({ txHash: hash, pair: ticker });
        console.log(res, "sender spree");
        return;
      }
    } catch (error) {
      setWriteState({
        loading: false,
        error: error.message,
      });
      // // console.log("Transaction Error:", error);
      // // console.log("Error in Transaction: ", error.message);
      // // console.log("Transaction Error:", extractErrorMessage(error));

      toast.error(
        <div className="toast_success_div">
          <div className="toast_error_div_title">Error!!</div>
          <div className="toast_success_div_para">
            {extractErrorMessage(error) ==
            "insufficient funds for intrinsic transaction cost"
              ? "insufficient funds for gas fee please top up"
              : extractErrorMessage(error)}{" "}
          </div>
        </div>,
        {
          duration: 5000,
          className: "toast_error",
        }
      );
    }
  };
  // ==
  // ==
  const processSell = async () => {
    try {
      if (!amount) {
        toast.error("You must provide an amount to purchase");
        return;
      }
      setOpenWallet(false);
      setConfirmBuyTransactionModal(true);
      setWriteState({
        loading: true,
      });
      const decrypted = decrypt(privateKey);
      const signer = new ethers.Wallet(decrypted, provider);
      const routerAddress = RouterAddress; // Replace with the actual DEX router address

      const contract = new ethers.Contract(routerAddress, routerAbi, signer);
      const adjustedSlippage = Math.max(slippage * 1.5, 0.5); // Increase slippage by 50% or set min 0.5%
      const slippageBN = ethers.BigNumber.from(10000).sub(
        Math.floor(adjustedSlippage * 100)
      );
      // Swap Parameters

      const recipient = wallet_address; // Address to receive tokens
      const deadline = Math.floor(Date.now() / 1000) + 60 * 20; // 20 minutes from now
      const ethAmount = ethers.utils.parseEther(amount.toString()); // Amount of ETH to swap
      const AmountsOut = ethers.utils.parseEther(amountOut.toString());
      const amountOutMin = AmountsOut.mul(slippageBN).div(10000);
      console.log("====================================");
      console.log(amountOutMin, AmountsOut);
      console.log("====================================");
      if (data?.network == "EGOCHAIN") {
        const tx = await contract.swapExactTokensForETH(
          ethAmount,
          amountOutMin,
          paths,
          recipient,
          deadline
        );

        //// // console.log("Transaction submitted:", tx.hash);

        const receipt = await tx.wait();
        console.log("====================================");
        console.log(receipt);
        console.log("====================================");
        // dispatch(triggerBalanceChange());
        dispatch(
          updateBalancesAfterTrade({
            networkName: `EGOCHAIN NETWORK`,
            walletAddress: wallet_address,
            tokenAddresses: [paths[0]], // Addresses of tokens involved in trade
          })
        );
        setWriteState({
          loading: false,
          success: true,
        });
        toast.success(
          <div className="toast_success_div">
            <div className="toast_success_div_title">Success!</div>
            <div className="toast_success_div_para">Sell Successful!!!</div>
          </div>,
          {
            duration: 5000,
            className: "toast_success",
          }
        );
        setConfirmBuyTransactionModal(false);
        const hash = tx?.hash;
        // console.log("Transaction confirmed:", receipt);
        //submit the hash

        const res = await INITIATE_SWAP({ txHash: hash, pair: ticker });
        console.log(res, "sender spree");
        return;
      }
      if (data?.network == "BASE") {
        const tx = await contract.swapTokensForExactTokens(
          AmountsOut,
          ethAmount,
          paths,
          recipient,
          deadline,
          {
            gasLimit: 300000,
          }
        );

        console.log("====================================");
        console.log(tx);
        console.log("====================================");
        const receipt = await tx.wait();
        console.log("====================================");
        console.log(receipt);
        console.log("====================================");
        // dispatch(triggerBalanceChange());
        dispatch(
          updateBalancesAfterTrade({
            networkName: `BASE NETWORK`,
            walletAddress: wallet_address,
            tokenAddresses: [paths[0], paths[1]], // Addresses of tokens involved in trade
          })
        );
        setWriteState({
          loading: false,
          success: true,
        });
        toast.success(
          <div className="toast_success_div">
            <div className="toast_success_div_title">Success!</div>
            <div className="toast_success_div_para">Sell Successful!!!</div>
          </div>,
          {
            duration: 5000,
            className: "toast_success",
          }
        );
        setConfirmBuyTransactionModal(false);
        const hash = tx?.hash;
        // console.log("Transaction confirmed:", receipt);
        //submit the hash

        const res = await INITIATE_SWAP({ txHash: hash, pair: ticker });
        console.log(res, "sender spree");
        return;
      }
    } catch (error) {
      // // console.log(error.message);
      setWriteState({
        loading: false,
        error: error.message,
      });
      //// // console.log("Error in Transaction: ", error.message);
      // // console.log("Transaction Error:", error);
      // // console.log("Error in Transaction: ", error.message);
      // // console.log("Transaction Error:", extractErrorMessage(error));

      toast.error(
        <div className="toast_success_div">
          <div className="toast_error_div_title">Error!!</div>
          <div className="toast_success_div_para">
            {extractErrorMessage(error) ==
            "insufficient funds for intrinsic transaction cost"
              ? "insufficient funds for gas fee please top up"
              : extractErrorMessage(error)}{" "}
          </div>
        </div>,
        {
          duration: 5000,
          className: "toast_error",
        }
      );
    }
  };

  const setAlllowanceFunc = async () => {
    setOpenWallet(false);
    setConfirmBuyTransactionModal(true);
    setLoading(true);

    try {
      for (const address of paths) {
        const response = await setApproval({
          privateKey,
          tokenAddress: address,
          spender: RouterAddress,
          prov: provider,
        });

        console.log(`Setting allowance for ${address}:`, response);
        const receipt = await response.wait();
        console.log(`Transaction receipt for ${address}:`, receipt);
      }

      toast.success(
        <div className="toast_success_div">
          <div className="toast_success_div_title">Allowance Success!</div>
          <div className="toast_success_div_para">
            You have successfully set allowances for all tokens.
          </div>
        </div>,
        { duration: 5000, className: "toast_success" }
      );

      setTransactionType(null);
      setOpenWallet(false);
      setHasAllowance(true);
      setLoading(false);
    } catch (error) {
      toast.error(
        <div className="toast_success_div">
          <div className="toast_error_div_title">
            {error.message === "Insufficient funds for intrinsic transaction"
              ? "Insufficient balance for fees!!"
              : "Error!!"}
          </div>
          <div className="toast_success_div_para">
            {error.message === "Insufficient funds for intrinsic transaction"
              ? "Insufficient USDC balance, top up your USDC in your wallet to continue"
              : error.message}
          </div>
        </div>,
        { duration: 5000, className: "toast_error" }
      );
    }
  };
  const checkAllowanceFunction = async () => {
    let allApproved = true; // Assume all are approved
    if (data?.network == "BASE") {
      for (const address of paths) {
        const response = await checkApproval({
          owner: wallet_address,
          spender: RouterAddress,
          tokenAddress: address,
          prov: provider,
        });

        console.log(`Allowance for ${address}:`, response);

        if (parseFloat(response) == 0) {
          allApproved = false; // If any token has zero allowance, set to false
          setHasAllowance(false);
          return;
        }
        setHasAllowance(true);
      }
      return;
    }

    if (data?.network == "EGOCHAIN") {
      const response = await checkApproval({
        owner: wallet_address,
        spender: RouterAddress,
        tokenAddress,
        prov: provider,
      });

      console.log(`Allowance for ${tokenAddress}:`, response);

      if (parseFloat(response) == 0) {
        setHasAllowance(false);
        return;
      }
      setHasAllowance(true);
      return;
    }

    // setHasAllowance(allApproved); // Only set to true if both have allowance
  };
  // useEffect(() => {
  //   checkAllowanceFunction();
  // }, []);
  useEffect(() => {
    if (amount) {
      checkAllowanceFunction();
    }
  }, [amount]);

  const getTokenPrices = async () => {
    // setMarketCapLoader(true);
    const res = await getPrice(tokenAddress, wallet_address);

    // // console.log("====================================");
    // // console.log(res);
    // // console.log("====================================");

    if (res.error) {
      console.error("Error fetching liquidity details:", res.error);
      // setMarketCapLoader(false);
    } else {
      setTokenPriceInEgax(res?.tokenPriceInEgax);
      // setTokenPriceInUSD(res?.tokenPriceInUsd);
    }
    // setMarketCapLoader(false);
  };

  useEffect(() => {
    if (!tokenAddress) {
      return;
    }
    getTokenPrices();
  }, [tokenAddress]);

  const toggleButon = (e) => {
    toggleActiveBtn(e);
    setAmount("");
    setAmountOut("");
  };

  const getAmountOutFunc = async () => {
    setAmountOutLoading(true);
    try {
      const res = await getAmountOutFn(
        ethers.utils.parseEther(amount.toString()),
        paths,
        RouterAddress,
        provider
      );
      console.log(res, "exactly");
      if (res) {
        setAmountOutLoading(false);
        // // console.log(res.toString(), "exactly");
        // // console.log(res.toString(), "exactly");
        const splitValue = res.toString().split(",");
        // // console.log(ethers.utils.formatEther(splitValue[1]));
        setAmountOut(ethers.utils.formatEther(splitValue[1]));

        return;
      }
      setAmountOutLoading(false);
      setAmountOut("");
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    // // console.log(amount);
    if (amount === "") {
      setAmountOut("");
      return;
    }
    getAmountOutFunc();
  }, [amount]);

  const initBuy = () => {
    setConfirmBuyTransactionModal(true);
  };

  const initSell = () => {
    setConfirmBuyTransactionModal(true);
  };

  const parsedAmountOut =
    amountOut && !isNaN(amountOut) && amountOut.trim() !== ""
      ? ethers.utils.parseEther(amountOut)
      : ethers.BigNumber.from(0);
  const amountOutMin = ethers.utils.formatEther(
    parsedAmountOut.mul(10000 - slippagePercentage).div(10000)
  );

  const splitProvider = activeProvider?.network?.split(" ");

  useEffect(() => {
    setPreviousNetwork(splitProvider[0]);
    if (!data) {
      return;
    }
    if (splitProvider[0] !== data?.network) {
      setSwitchNetworkPop(true);
    } else {
      setSwitchNetworkPop(false);
    }
  }, [data]);

  const networkSwitch = () => {
    try {
      setSwitchLoading(true);
      setTimeout(() => {
        setSwitched(true);
        setSwitchLoading(false);
        dispatch(setActiveProvider(`${data?.network} NETWORK`));
        toast.success(
          <div className="toast_success_div">
            <div className="toast_success_div_title">Netowrk Switched!</div>
            <div className="toast_success_div_para">
              You switched network from {splitProvider[0]} NETWORK to{" "}
              {data?.network} NETWORK .
            </div>
          </div>,
          { duration: 5000, className: "toast_success" }
        );
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="buy_modal_div_div1_cont1">
        <div className="buy_modal_div_div1_cont1_btns">
          <div
            id="buy"
            className={
              activeBtn === "buy"
                ? "buy_modal_div_div1_cont1_btns_btn1 buy_btn"
                : "buy_modal_div_div1_cont1_btns_btn1_buy"
            }
            onClick={toggleButon}
          >
            <div className="buy_modal_div_div1_cont1_btns_btn1_Span">Buy</div>
          </div>
          <div
            id="sell"
            className={
              activeBtn === "sell"
                ? "buy_modal_div_div1_cont1_btns_btn1 sell_btn"
                : "buy_modal_div_div1_cont1_btns_btn1_sell"
            }
            onClick={toggleButon}
          >
            <div className="buy_modal_div_div1_cont1_btns_btn1_Span">Sell</div>
          </div>
        </div>
        <div className="buy_modal_div_div1_cont1_body">
          <div className="buy_modal_div_div1_cont1_body_cont1_head1">
            <div className="buy_modal_div_div1_cont1_body_cont1_head1_txt1">
              Avbl
            </div>

            <div className="buy_modal_div_div1_cont1_body_cont1_head1_txt2">
              {flight ? (
                <ClipLoader color="#ffffff" size={18} />
              ) : (
                (() => {
                  if (activeBtn === "sell") {
                    if (!data) {
                      return "_ _";
                    }
                    if (data?.network === "EGOCHAIN") {
                      return (
                        <>
                          {numberWithCommas(
                            parseFloat(
                              balancer.byNetwork[`${data?.network} NETWORK`]
                                ?.tokens[data?.contractAddress.toLowerCase()]
                                ?.balance || 0
                            )
                          )}
                          {ticker.split("-")[1]}
                        </>
                      );
                    }
                    if (data?.network === "BASE") {
                      return (
                        <>
                          {numberWithCommas(
                            parseFloat(
                              balancer.byNetwork[`${data?.network} NETWORK`]
                                ?.tokens[data?.contractAddress.toLowerCase()]
                                ?.balance
                            )
                          )}{" "}
                          {ticker.split("-")[1]}
                        </>
                      );
                    }
                    return ticker.split("-")[1];
                  }
                  // ===
                  // ===
                  // ===
                  if (activeBtn === "buy") {
                    if (!data) {
                      return "_ _";
                    }
                    if (data?.network === "EGOCHAIN") {
                      return (
                        <>
                          {numberWithCommas(
                            parseFloat(
                              balancer.byNetwork[`${data?.network} NETWORK`]
                                ?.native?.balance || 0
                            )
                          )}{" "}
                          {ticker.split("-")[0]}
                        </>
                      );
                    }
                    if (data?.network === "BASE") {
                      return (
                        <>
                          {numberWithCommas(
                            parseFloat(
                              balancer.byNetwork[`${data?.network} NETWORK`]
                                ?.tokens[
                                Assets.find(
                                  (a) => a.ticker === "DHV-DHV"
                                )?.tokenB.toLowerCase()
                              ]?.balance || 0
                            )
                          )}{" "}
                          {baseToken}
                        </>
                      );
                    }
                    return ticker.split("-")[1];
                  }

                  return (
                    parseFloat(
                      balancer?.byNetwork[`${data?.network} NETWORK`]?.native
                        ?.balance
                    ) || 0.0
                  );
                })()
              )}
            </div>
          </div>
          <div className="buy_modal_div_div1_cont1_body_1">
            <label htmlFor="" className="buy_modal_div_div1_cont1_body_1_label">
              <p className="buy_modal_div_div1_cont1_body_para">
                <div className="buy_modal_div_div1_cont1_body_para1">
                  Amount
                </div>
                <div className="buy_modal_div_div1_cont1_body_para1">
                  {!data
                    ? "_ _"
                    : activeBtn === "buy"
                    ? baseToken
                    : ticker.split("-")[1]}
                </div>
              </p>
              <input
                type="number"
                autocapitalize="off"
                autocorrect="off"
                autocomplete="off"
                spellcheck="false"
                className="buy_modal_div_div1_cont1_body_1_label_input"
                onChange={handleAmountChange}
                value={amount}
              />
            </label>
            <div className="amount_slider">
              <ConfigProvider
                theme={{
                  components: {
                    Slider: {
                      dotActiveBorderColor: "#6059fd",
                      handleActiveColor: "#6059fd",
                      handleActiveOutlineColor: "#6059fd",
                      handleColor: "#6059fd",
                      trackBg: "#6059fd",
                      trackHoverBg: "#6059fd",
                    },
                  },
                }}
              >
                <Slider
                  marks={marks}
                  defaultValue={0}
                  className="amount_slider_input"
                  onChange={sliderChange}
                />
              </ConfigProvider>
            </div>

            <label htmlFor="" className="buy_modal_div_div1_cont1_body_1_label">
              <p className="buy_modal_div_div1_cont1_body_para">
                <div className="buy_modal_div_div1_cont1_body_para1">
                  Receive
                </div>
                <div className="buy_modal_div_div1_cont1_body_para1">
                  {!data
                    ? "_ _"
                    : activeBtn === "buy"
                    ? ticker.split("-")[1]
                    : baseToken}
                </div>
              </p>

              {amountOutLoading ? (
                <CustomLoader
                  width="20px"
                  height="20px"
                  padding="10px"
                  borderSize="2px"
                  borderColor="#6059fd #c7c6d5 #c7c6d5"
                />
              ) : (
                <input
                  type="number"
                  autocapitalize="off"
                  autocorrect="off"
                  autocomplete="off"
                  spellcheck="false"
                  className="buy_modal_div_div1_cont1_body_1_label_input"
                  onChange={handleAmountChange}
                  value={parseFloat(amountOut)?.toFixed(8)}
                  readOnly
                />
              )}
            </label>
          </div>
          {businessStatus === "UNVERIFIED" ? (
            <span className="warningBusinessTrade">
              <Alert02Icon className="warningBusinessTrade_icon" />
              <div className="warningBusinessTradetxt">
                This business is unverified. Only interact with businesses you
                trust.
              </div>
            </span>
          ) : null}

          <div className="buy_modal_div_div1_cont1_body_button_div">
            {!data ? (
              <button
                className="product_detail_cont_div5_7_btn"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                disabled
              >
                Fetching data...
              </button>
            ) : (
              <>
                {!wallet_address ? (
                  <button
                    className="product_detail_cont_div5_7_btn"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => navigate("/wallet")}
                  >
                    Create wallet
                  </button>
                ) : (
                  <>
                    {splitProvider[0] !== data?.network ? (
                      <button
                        className="product_detail_cont_div5_7_btn"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        disabled={switchLoading}
                        onClick={networkSwitch}
                      >
                        {switchLoading ? (
                          <>
                            <ClipLoader color="#ffffff" size={18} />{" "}
                            Switching...
                          </>
                        ) : (
                          "Switch Network"
                        )}
                      </button>
                    ) : (
                      <>
                        {amount === "" || amount == 0 ? (
                          <button
                            className="product_detail_cont_div5_7_btn"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            disabled
                          >
                            Input amount
                          </button>
                        ) : (
                          <>
                            {Assets.find((value) =>
                              activeBtn == "buy"
                                ? value?.tokenBName === baseToken
                                : value?.tokenB === tokenAddress
                            )?.balance < parseFloat(amount) ? (
                              <button
                                className="product_detail_cont_div5_7_btn"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                disabled
                              >
                                Insufficient balance
                              </button>
                            ) : (
                              <>
                                {" "}
                                {amountOutLoading ? (
                                  <button
                                    className="product_detail_cont_div5_7_btn"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                    disabled
                                  >
                                    <CustomLoader
                                      width="20px"
                                      height="20px"
                                      padding="0px"
                                      borderSize="2px"
                                      containerWidth={"max-content"}
                                      margin={"0px 5px 0px 0px"}
                                      borderColor="#fff #c7c6d5 #c7c6d5"
                                    />{" "}
                                    Fetching price...
                                  </button>
                                ) : (
                                  <>
                                    {" "}
                                    {activeBtn === "buy" ? (
                                      <button
                                        onClick={initBuy}
                                        disabled={writeState.loading}
                                        className="ProductDetailPage_div_body_div2_div7_btn"
                                      >
                                        {writeState.loading ? (
                                          <ClipLoader
                                            color="#ffffff"
                                            size={18}
                                          />
                                        ) : (
                                          "Buy"
                                        )}
                                      </button>
                                    ) : (
                                      <button
                                        onClick={initSell}
                                        disabled={writeState.loading}
                                        className="ProductDetailPage_div_body_div2_div7_btn_sell"
                                      >
                                        {writeState.loading ? (
                                          <ClipLoader
                                            color="#ffffff"
                                            size={18}
                                          />
                                        ) : (
                                          "Sell"
                                        )}
                                      </button>
                                    )}
                                  </>
                                )}
                              </>
                            )}{" "}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <div className="swap_details_div">
            <div className="swap_details_div_1">
              1{" "}
              {!data
                ? "_ _"
                : activeBtn === "buy"
                ? baseToken
                : ticker.split("-")[1]}{" "}
              ={" "}
              {amountOutLoading ? (
                <CustomLoader
                  width="15px"
                  height="15px"
                  padding="0px"
                  borderSize="2px"
                  containerWidth={"max-content"}
                  margin={"0px 5px 0px 10px"}
                  borderColor="#6059fd #c7c6d5 #c7c6d5"
                />
              ) : (
                <>
                  {activeBtn === "buy"
                    ? numberWithCommas(
                        parseFloat(
                          parseFloat(amountOut) / parseFloat(amount) || 0
                        )?.toFixed(8)
                      )
                    : numberWithCommas(
                        parseFloat(
                          parseFloat(amountOut) / parseFloat(amount) || 0
                        )?.toFixed(8)
                      )}
                </>
              )}{" "}
              {!data
                ? "_ _"
                : activeBtn === "buy"
                ? ticker.split("-")[1]
                : baseToken}
            </div>
            <div className="swap_details_div_body">
              <div className="swap_details_div_body_cont1">
                <div className="swap_details_div_body_cont1_div1">
                  Minimum received
                </div>
                <div className="swap_details_div_body_cont1_div2">
                  {amountOutLoading ? (
                    <>
                      {" "}
                      <CustomLoader
                        width="15px"
                        height="15px"
                        padding="0px"
                        borderSize="2px"
                        containerWidth={"max-content"}
                        margin={"0px 5px 0px 10px"}
                        borderColor="#6059fd #c7c6d5 #c7c6d5"
                      />
                    </>
                  ) : (
                    <>
                      {" "}
                      {numberWithCommas(
                        parseFloat(amountOutMin || 0)?.toFixed(8)
                      )}{" "}
                    </>
                  )}

                  {!data
                    ? "_ _"
                    : activeBtn === "buy"
                    ? ticker.split("-")[1]
                    : baseToken}
                </div>
              </div>
              <div className="swap_details_div_body_cont1">
                <div className="swap_details_div_body_cont1_div1">
                  Slippage Tolerance
                </div>
                <div className="swap_details_div_body_cont1_div2">0.50%</div>
              </div>
              <div className="swap_details_div_body_cont1">
                <div className="swap_details_div_body_cont1_div1">
                  Trading fee
                </div>
                <div className="swap_details_div_body_cont1_div2">0.30%</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
      {confirmBuyTransactionModal && (
        <div className="confrimBuySellDiv">
          <div className="confrimBuySellDiv_container">
            <div className="confrimBuySellDiv_title">
              Confirm {activeBtn == "buy" ? "Buy" : "Sell"}{" "}
              <Cancel01Icon
                size={20}
                className="confrimBuySellDiv_title_icon"
                onClick={() => {
                  setConfirmBuyTransactionModal(false);
                }}
              />
            </div>
            <div className="confrimBuySellDiv_body">
              <div className="confrimBuySellDiv_body1">
                <div className="confrimBuySellDiv_body1_cont1">
                  <div className="confrimBuySellDiv_body1_cont1_area1">
                    {parseFloat(amount)?.toFixed(8)}
                  </div>
                  <div className="confrimBuySellDiv_body1_cont1_area2">
                    {activeBtn === "buy" ? baseToken : ticker.split("-")[1]}
                  </div>
                </div>
                <div className="confrimBuySellDiv_body1_cont1">
                  <div className="confrimBuySellDiv_body1_cont1_area1">
                    {parseFloat(amountOut)?.toFixed(8)}
                  </div>
                  <div className="confrimBuySellDiv_body1_cont1_area2">
                    {activeBtn === "buy" ? ticker.split("-")[1] : baseToken}
                  </div>
                </div>
              </div>
              <div className="confrimBuySellDiv_body2">
                <div className="swap_details_div_body">
                  <div className="swap_details_div_body_cont1">
                    <div className="swap_details_div_body_cont1_div1">
                      Price
                    </div>
                    <div className="swap_details_div_body_cont1_div2">
                      {activeBtn === "buy"
                        ? parseFloat(
                            parseFloat(amountOut) / parseFloat(amount) || 0
                          )?.toFixed(8)
                        : parseFloat(
                            parseFloat(1) * parseFloat(amountOut) || 0
                          )?.toFixed(8)}{" "}
                      {activeBtn === "buy" ? ticker.split("-")[1] : baseToken} /{" "}
                      {activeBtn === "buy" ? baseToken : ticker.split("-")[1]}{" "}
                    </div>
                  </div>
                  <div className="swap_details_div_body_cont1">
                    <div className="swap_details_div_body_cont1_div1">
                      Minimum received
                    </div>
                    <div className="swap_details_div_body_cont1_div2">
                      {amountOutLoading ? (
                        <>
                          {" "}
                          <CustomLoader
                            width="15px"
                            height="15px"
                            padding="0px"
                            borderSize="2px"
                            containerWidth={"max-content"}
                            margin={"0px 5px 0px 10px"}
                            borderColor="#6059fd #c7c6d5 #c7c6d5"
                          />
                        </>
                      ) : (
                        <> {parseFloat(amountOutMin || 0)?.toFixed(8)} </>
                      )}

                      {activeBtn === "buy" ? ticker.split("-")[1] : baseToken}
                    </div>
                  </div>
                  <div className="swap_details_div_body_cont1">
                    <div className="swap_details_div_body_cont1_div1">
                      Slippage Tolerance
                    </div>
                    <div className="swap_details_div_body_cont1_div2">
                      0.50%
                    </div>
                  </div>
                  <div className="swap_details_div_body_cont1">
                    <div className="swap_details_div_body_cont1_div1">
                      Trading fee
                    </div>
                    <div className="swap_details_div_body_cont1_div2">
                      0.30%
                    </div>
                  </div>
                </div>
              </div>
              {activeBtn === "buy" ? (
                <>
                  {data?.network === "EGOCHAIN" ? (
                    <button
                      className="product_detail_cont_div5_7_btn"
                      onClick={() => {
                        setOpenWallet(true);
                        setConfirmBuyTransactionModal(false);
                        setTransactionType("buy");
                      }}
                      disabled={writeState.loading}
                    >
                      {writeState.loading ? (
                        <>
                          <ClipLoader color="#ffffff" size={18} /> Buying...
                        </>
                      ) : (
                        "Confirm Buy"
                      )}
                    </button>
                  ) : (
                    <>
                      {" "}
                      {hasAllowance ? (
                        <button
                          className="product_detail_cont_div5_7_btn"
                          onClick={() => {
                            setOpenWallet(true);
                            setConfirmBuyTransactionModal(false);
                            setTransactionType("buy");
                          }}
                          disabled={writeState.loading}
                        >
                          {writeState.loading ? (
                            <>
                              <ClipLoader color="#ffffff" size={18} /> Buying...
                            </>
                          ) : (
                            "Confirm Buy"
                          )}
                        </button>
                      ) : (
                        <button
                          className="product_detail_cont_div5_7_btn"
                          onClick={() => {
                            setOpenWallet(true);
                            setConfirmBuyTransactionModal(false);
                            setTransactionType("approve");
                          }}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          disabled={loading}
                        >
                          {loading ? (
                            <>
                              {" "}
                              <ClipLoader color="#fff" size={24} /> Approving...
                            </>
                          ) : (
                            "   Approve Spending"
                          )}
                        </button>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {" "}
                  {hasAllowance ? (
                    <button
                      className="ProductDetailPage_div_body_div2_div7_btn_sell"
                      onClick={() => {
                        setOpenWallet(true);
                        setConfirmBuyTransactionModal(false);
                        setTransactionType("sell");
                      }}
                      disabled={writeState.loading}
                    >
                      {writeState.loading ? (
                        <>
                          <ClipLoader color="#ffffff" size={18} /> Selling...
                        </>
                      ) : (
                        "Confirm Sell"
                      )}
                    </button>
                  ) : (
                    <button
                      className="product_detail_cont_div5_7_btn"
                      onClick={() => {
                        setOpenWallet(true);
                        setConfirmBuyTransactionModal(false);
                        setTransactionType("approve");
                      }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          {" "}
                          <ClipLoader color="#fff" size={24} /> Approving...
                        </>
                      ) : (
                        "   Approve Spending"
                      )}
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {openWallet && transactionType === "approve" && (
        <WalletPop
          closePop={() => {
            setOpenWallet(false);
            setConfirmBuyTransactionModal(true);
          }}
          transaction={true}
          transactionType={transactionType}
          transact={setAlllowanceFunc}
          loading={loading}
          tokenName={symbol}
        />
      )}
      {openWallet && transactionType === "buy" && (
        <WalletPop
          closePop={() => {
            setOpenWallet(false);
            setConfirmBuyTransactionModal(true);
          }}
          transaction={true}
          transactionType={transactionType}
          transact={processBuy}
          loading={writeState.loading}
          tokenName={symbol}
        />
      )}
      {openWallet && transactionType === "sell" && (
        <WalletPop
          closePop={() => {
            setOpenWallet(false);
            setConfirmBuyTransactionModal(true);
          }}
          transaction={true}
          transactionType={transactionType}
          transact={processSell}
          loading={writeState.loading}
          tokenName={symbol}
        />
      )}

      {switchNetworkPop && (
        <div className="SwitchNetworkDiv">
          {" "}
          <div className="confrimBuySellDiv">
            <div className="confrimBuySellDiv_container">
              <div className="confrimBuySellDiv_title">Switch Network</div>
              <div className="confrimBuySellDiv_body">
                <div className="swtichNetworkModalBody_title">
                  Current Network {splitProvider[0]} Network
                </div>

                <div className="swtichNetworkModalBody">
                  <div className="swtichNetworkModalBody_area1">
                    <div
                      className={
                        switched
                          ? "swtichNetworkModalBody_area1_cont1 switchAnimateRight"
                          : "swtichNetworkModalBody_area1_cont1"
                      }
                    >
                      {previousNetwork == "BASE" && (
                        <img
                          src="/img/base_logo.png"
                          alt=""
                          className="swtichNetworkModalBody_area1_cont1_img"
                        />
                      )}
                      {previousNetwork == "BINANCE" && (
                        <img
                          src="/img/bnb.png"
                          alt=""
                          className="swtichNetworkModalBody_area1_cont1_img"
                        />
                      )}
                      {previousNetwork == "EGOCHAIN" && (
                        <img
                          src="/img/egax_logo.png"
                          alt=""
                          className="swtichNetworkModalBody_area1_cont1_img"
                        />
                      )}
                    </div>
                    <div className="swtichNetworkModalBody_area1_cont2">
                      <img src="/img/switchIcon.svg" alt="" />
                    </div>
                    <div
                      c
                      className={
                        switched
                          ? "swtichNetworkModalBody_area1_cont1 switchAnimateLeft"
                          : "swtichNetworkModalBody_area1_cont1"
                      }
                    >
                      {data?.network == "BASE" && (
                        <img
                          src="/img/base_logo.png"
                          alt=""
                          className="swtichNetworkModalBody_area1_cont1_img"
                        />
                      )}
                      {data?.network == "BINANCE" && (
                        <img
                          src="/img/bnb.png"
                          alt=""
                          className="swtichNetworkModalBody_area1_cont1_img"
                        />
                      )}
                      {data?.network == "EGOCHAIN" && (
                        <img
                          src="/img/egax_logo.png"
                          alt=""
                          className="swtichNetworkModalBody_area1_cont1_img"
                        />
                      )}
                    </div>
                  </div>
                  <div className="swtichNetworkModalBody_area2">
                    You are on the wrong network please switch your network to
                    continue!!
                  </div>
                </div>
                {switched ? (
                  <button
                    className="product_detail_cont_div5_7_btn"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => setSwitchNetworkPop(false)}
                  >
                    Continue
                  </button>
                ) : (
                  <button
                    className="product_detail_cont_div5_7_btn"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    disabled={switchLoading}
                    // onClick={() => {
                    //   setSwitchLoading((prev) => !prev);
                    // }}
                    onClick={networkSwitch}
                  >
                    {switchLoading ? (
                      <>
                        <ClipLoader color="#ffffff" size={18} /> Switching...
                      </>
                    ) : (
                      "Switch Network"
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BuySell;
