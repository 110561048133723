import React, { useState, useEffect } from "react";
import {
  ArrowLeft01Icon,
  InformationCircleIcon,
  SecurityLockIcon,
  ViewOffSlashIcon,
} from "hugeicons-react";
import { useSelector } from "react-redux";
import { decrypt } from "../../../../helper/encryption";
import "./index.css";
const BackupWallet = ({ back }) => {
  const { mnemonic } = useSelector((state) => state.user);
  const [hidden, setHidden] = useState(true);
  const [tempMnemonic, setTempMnemonic] = useState([]);
  useEffect(() => {
    const decrypted_mnemonic = decrypt(mnemonic);
    //console.log(decrypted_mnemonic);
    const toArr = decrypted_mnemonic?.split(" ");
    setTempMnemonic(toArr);
  }, []);
  return (
    <div className="SendModal_div">
      <div className="WalletDetailPageDiv_header">
        <div className="WalletDetailPageDiv_header_back" onClick={back}>
          <ArrowLeft01Icon size={24} />
        </div>
        Backup Wallet
        <div className="WalletDetailPageDiv_header_info">
          <InformationCircleIcon size={20} />
        </div>
      </div>
      <div className="walletBackupBody">
        <div className="walletBackupBody_div1">
          <div className="walletBackupBody_div1_title">
            Note down seed phrase
          </div>
          <div className="walletBackupBody_div1_para">
            You can write down the words in order and keep them stored safely
          </div>
        </div>
        <div className="walletBackupBody_div2">
          {tempMnemonic?.map((data, index) => (
            <div className="walletBackupBody_div2_cont1">
              <div className="walletBackupBody_div2_cont1_numb">
                {" "}
                {index + 1}
              </div>
              <div className="walletBackupBody_div2_cont1_value">{data}</div>
            </div>
          ))}
          {hidden && (
            <div
              className="walletBackupBody_div2_blur_div"
              onClick={() => setHidden(false)}
            >
              <div className="walletBackupBody_div2_blur_div_cont">
                <ViewOffSlashIcon
                  size={16}
                  className="walletBackupBody_div2_blur_div_cont_icon"
                />{" "}
                <div className="walletBackupBody_div2_blur_div_txt">
                  View the seed phrase{" "}
                  <div className="walletBackupBody_div2_blur_div_txt_para">
                    Make sure no one is looking at your screen
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {!hidden && (
          <div
            className="walletBackupBody_div3"
            onClick={() => setHidden(true)}
          >
            Hide phrase{" "}
            <ViewOffSlashIcon
              size={16}
              className="walletBackupBody_div2_blur_div_cont_icon"
            />{" "}
          </div>
        )}
      </div>
    </div>
  );
};

export default BackupWallet;
