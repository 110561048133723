import React, { useEffect, useState } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { ArrowLeft02Icon } from "hugeicons-react";
import { useSelector } from "react-redux";
import { decrypt, encrypt } from "../../../helper/encryption";

const CreateWalletPage3 = ({ prevStep }) => {
  const { mnemonic } = useSelector((state) => state.user);
  const [tempMnemonic, setTempMnemonic] = useState([]);

  const navigate = useNavigate();

  const phrase = [
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
    { id: 7 },
    { id: 8 },
    { id: 9 },
    { id: 10 },
    { id: 11 },
    { id: 12 },
  ];

  useEffect(() => {
    const decrypted_mnemonic = decrypt(mnemonic);
    //console.log(decrypted_mnemonic);

    const toArr = decrypted_mnemonic?.split(" ");
    setTempMnemonic(toArr);
  }, []);
  return (
    <div className="onchainWalletDiv_container">
      <div className="CreateWalletPage1_area_div1">
        <div className="CreateWalletPage1_area_div1_cont1" onClick={prevStep}>
          <ArrowLeft02Icon />
        </div>
        <div className="CreateWalletPage1_area_div1_cont2">
          <div className="CreateWalletPage1_area_div1_cont2_circle1 active"></div>
          <div className="CreateWalletPage1_area_div1_cont2_circle1 active"></div>
          <div className="CreateWalletPage1_area_div1_cont2_circle1 active"></div>
        </div>
      </div>
      <div className="CreateWalletPage3_area">
        <div className="CreateWalletPage3_area_title">
          Secret Recovery Phrase
        </div>
        <div className="CreateWalletPage3_area_para">
          This phrase is the ONLY way to recover your wallet. Do NOT share it
          with anyone!
        </div>

        <div className="CreateWalletPage3_area_div">
          {tempMnemonic?.map((data, index) => (
            <div className="CreateWalletPage3_area_div_cont">
              <div className="CreateWalletPage3_area_div_cont_div">
                {index + 1}.
              </div>
              <input
                type="text"
                className="CreateWalletPage3_area_div_input"
                value={data}
                disabled
              />
            </div>
          ))}
        </div>

        <div className="radioDiv">
          <input type="checkbox" name="" id="" className="radioDiv_checkbox" />{" "}
          I have saved my secret phrase
        </div>

        <button
          className="onchainWalletDiv_container_div2_btn1"
          onClick={() => {
            navigate("/");
          }}
        >
          Back home
        </button>
      </div>
    </div>
  );
};

export default CreateWalletPage3;
