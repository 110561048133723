import React, { useState, useEffect } from "react";
import "./index.css";
import ProductSlide from "./ProductSlide";
import ProductDisplayCarousel from "../../ProductDisplay/ProductDisplayCarousel";
import { Link, useParams } from "react-router-dom";
import {
  GET_PRODUCT_DETAIL_BY_ID,
  GET_ALL_PRODUCT_BY_CATEGORY,
} from "../../../../services/market";
import CustomLoader from "../../../../Components/CustomLoader/CustomLoader";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchProductsByCategory } from "../../../../Redux/feautures/products/productSlice";
import ClipLoader from "react-spinners/ClipLoader";
import toast, { Toaster } from "react-hot-toast";
import { Cancel01Icon } from "hugeicons-react";
import WalletPop from "../../../../OnchainWallet/WalletPop/WalletPop";
import { CHECK_ALLOWANCE } from "../../../../services/product";
import { PURCHASE_PRODUCT } from "../../../../services/product";
import { SET_ALLOWANCE } from "../../../../services/product";
import { STAKE_PRODUCT } from "../../../../services/product";
const OPTIONS = { slidesToScroll: "auto" };

const ProductDetail = () => {
  const { id, category } = useParams();
  const dispatch = useDispatch();
  const { categoryProducts, loading: categoryProdLoading } = useSelector(
    (state) => state.products
  );
  // ======
  // ======
  // ======
  // ======
  // ======
  const { privateKey, wallet_address } = useSelector((state) => state.user);
  const [openWallet, setOpenWallet] = useState(false);
  const [transactionType, setTransactionType] = useState(null);
  const [hasAllowance, setHasAllowance] = useState(null);
  const [deliveryLoading, setDeliveryLoading] = useState(false);
  const [allowanceLoading, setAllowanceLoading] = useState(false);
  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const [deliveryModal, setDeliveryModal] = useState(false);
  const [confirmPurchaseModal, setConfirmPurchaseModal] = useState(false);
  const [checkAllowanceLoading, setCheckAllowanceLoading] = useState(false);
  const [deliveryDetails, setDeliveryDetails] = useState({
    fullname: "",
    phoneNo: "",
    country: "",
    state: "",
    city: "",
    zipcode: "",
  });
  const [orderId, setOrderId] = useState("");
  // ======
  // ======
  // ======
  // ======
  // ======
  const [quantity, setQuantity] = useState(1);
  const [payload, setPayload] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState({
    decrease: false,
    increase: false,
  });
  const quantityLimit = payload?.quantity || 0;
  const handleDecrease = () => {
    setQuantity((prev) => (prev > 1 ? prev - 1 : 1));
  };

  const handleIncrease = () => {
    setQuantity((prev) => (prev < quantityLimit ? prev + 1 : quantityLimit));
  };
  const handleInputChange = (e) => {
    let value = e.target.value;

    if (!isNaN(value) && value !== "") {
      value = Math.min(Math.max(value, 1), quantityLimit);
      setQuantity(value);
    } else if (value === "") {
      setQuantity(1);
    }
  };

  const fetchProductById = async () => {
    setLoading(true);
    const res = await GET_PRODUCT_DETAIL_BY_ID(id);
    // console.log("====================================");
    // console.log(res);
    // console.log("====================================");
    setLoading(false);
    setPayload(res?.data[0]);
  };
  useEffect(() => {
    fetchProductById();
  }, [id]);

  // console.log("====================================");
  // console.log(id);
  // console.log("====================================");

  function formatImages(images) {
    if (Array.isArray(images)) {
      return images;
    }

    if (typeof images === "string") {
      // Repeat the single image to create an array of 4 images
      return new Array(4).fill(images);
    }

    return []; // Return an empty array if no valid images exist
  }

  const images = formatImages(payload?.images);
  // console.log("====================================");
  // console.log(images);
  // console.log("====================================");

  const specArray = payload?.specifications
    ? payload?.specifications
        ?.split(",")
        ?.map((spec) => {
          const [key, value] = spec.split(":").map((s) => s.trim());
          return key && value ? { key, value } : null;
        })
        .filter(Boolean) // Remove any invalid/null entries
    : [];

  const page = 1;
  const limit = 10;
  const key = `${category}_page${page}_limit${limit}`;
  const products = categoryProducts[key]?.data || [];

  useEffect(() => {
    if (!products.length) {
      dispatch(fetchProductsByCategory({ category, page, limit }));
    }
  }, [dispatch, category, page, limit, products.length]);
  // console.log("====================================");
  // console.log(category);
  // console.log(products);
  // console.log("====================================");

  // =====
  // =====
  // =====
  // =====
  // =====
  // =====
  // =====
  // =====
  // =====
  // console.log("====================================");
  // console.log(privateKey);
  // console.log("====================================");
  const checkAllowanceFunction = async () => {
    setCheckAllowanceLoading(true);
    const body = {
      key: privateKey,
    };
    const response = await CHECK_ALLOWANCE(body);
    // console.log(response);
    if (response.balance === 0) {
      setCheckAllowanceLoading(false);
      setHasAllowance(false);
      return;
    }
    if (response.balance > 0) {
      setCheckAllowanceLoading(false);
      setHasAllowance(true);
      return;
    }
  };

  useEffect(() => {
    checkAllowanceFunction();
  }, [privateKey]);

  const setAlllowanceFunc = async () => {
    setAllowanceLoading(true);
    setOpenWallet(false);
    setConfirmPurchaseModal(true);
    const body = {
      key: privateKey,
    };
    const response = await SET_ALLOWANCE(body);
    //// console.log("====================================");
    //// console.log(response);
    //// console.log("====================================");
    if (response.success === false) {
      setAllowanceLoading(false);
      toast.error(
        <div className="toast_success_div">
          <div className="toast_error_div_title">
            {response.message === "Insufficient funds for intrinsic transaction"
              ? "   Insufficient balance for fees!!"
              : "Error!!"}
          </div>
          <div className="toast_success_div_para">
            {response.message === "Insufficient funds for intrinsic transaction"
              ? "Insufficient USDC balance, top up your USDC in your wallet to continue"
              : response.message}
          </div>
        </div>,
        {
          duration: 5000,
          className: "toast_error",
        }
      );
      return;
    }

    toast.success(
      <div className="toast_success_div">
        <div className="toast_success_div_title">Allowance Success!</div>
        <div className="toast_success_div_para">
          {" "}
          You have successfully set your allowance.
        </div>
      </div>,
      {
        duration: 5000,
        className: "toast_success",
      }
    );
    setOpenWallet(false);
    setConfirmPurchaseModal(true);
    setHasAllowance(true);
    setAllowanceLoading(false);
  };

  const purchaseProduct = async () => {
    setPurchaseLoading(true);
    setOpenWallet(false);
    setConfirmPurchaseModal(true);
    const body = {
      key: privateKey,
      productId: parseInt(payload?.productId),
      qty: quantity,
    };
    const res = await PURCHASE_PRODUCT(body);
    //// console.log(res);
    if (res.success === false) {
      setPurchaseLoading(false);
      toast.error(
        <div className="toast_success_div">
          <div className="toast_error_div_title">Error!!</div>
          <div className="toast_success_div_para">{res.message}</div>
        </div>,
        {
          duration: 5000,
          className: "toast_error",
        }
      );
      return;
    }
    toast.success(
      <div className="toast_success_div">
        <div className="toast_success_div_title">Purchase Success!</div>
        <div className="toast_success_div_para">
          {" "}
          You have successfully purchased your product.
        </div>
      </div>,
      {
        duration: 5000,
        className: "toast_success",
      }
    );
    setPurchaseLoading(false);
    setConfirmPurchaseModal(false);
    setOpenWallet(false);
    setOrderId(res.orderId);
    setDeliveryModal(true);
  };

  const stakeProduct = async () => {
    setDeliveryLoading(true);
    const body = {
      orderId: orderId,
      fullname: deliveryDetails.fullname,
      phoneNo: deliveryDetails.phoneNo,
      country: deliveryDetails.country,
      state: deliveryDetails.state,
      city: deliveryDetails.city,
      zipcode: deliveryDetails.zipcode,
      deliveryType: "PICKUP",
    };
    //// console.log(body);
    const res = await STAKE_PRODUCT(body);
    //// console.log(res);
    if (res.success === false) {
      setDeliveryLoading(false);
      toast.error(
        <div className="toast_success_div">
          <div className="toast_error_div_title">Error!!</div>
          <div className="toast_success_div_para">{res.message}</div>
        </div>,
        {
          duration: 5000,
          className: "toast_error",
        }
      );
      return;
    }
    setDeliveryLoading(false);
    // setDeliveryModal(false);
    toast.success(
      <div className="toast_success_div">
        <div className="toast_success_div_title"> Success!</div>
        <div className="toast_success_div_para">
          {" "}
          You have successfully submitted your delivery info.
        </div>
      </div>,
      {
        duration: 5000,
        className: "toast_success",
      }
    );
    setTimeout(() => {
      navigate("/myOrders");
    }, 5000);
  };

  const handleDeliveryChange = (e) => {
    const { name, value } = e.target;
    setDeliveryDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const toggleConfirmPurchase = () => {
    setConfirmPurchaseModal((prev) => !prev);
  };

  // console.log(payload);

  return (
    <>
      {loading ? (
        <div className="ComponentLoadingDiv">
          <CustomLoader
            borderSize={"4px"}
            height={"45px"}
            width={"45px"}
            containerHeight={"max-content"}
          />
          <p className="loadMoreBusinessDiv_txt" style={{ fontWeight: "600" }}>
            Loading product detail...
          </p>
        </div>
      ) : (
        <div className="ProductDetailPage">
          <div className="container">
            <div className="ProductDetailPage_conts">
              <div className="ProductDetailPage_area">
                <div className="ProductDetailPage_area_div1">
                  <ProductSlide images={images} />
                </div>
                <div className="ProductDetailPage_area_div2">
                  <div className="ProductDetailPage_area_div2_div1">
                    <div className="ProductDetailPage_area_div2_div1_title">
                      {payload?.name}
                    </div>
                  </div>

                  <div className="ProductDetailPage_area_div2_div2_price">
                    <div className="ProductDetailPage_area_div2_div2_price_div1">
                      {parseFloat(payload?.userAmount).toFixed(2)}
                      <span className="ProductDetailPage_area_div2_div2_price_span">
                        {" "}
                        usdc
                      </span>{" "}
                    </div>{" "}
                    <div className="ProductDetailPage_area_div2_div2_price_div2">
                      ${parseFloat(payload?.userAmount).toFixed(2)}
                    </div>{" "}
                  </div>

                  <div className="ProductDetailPage_area_div2_div3">
                    <div className="product_detail_cont_div4_cont1">
                      <div className="product_detail_cont_div4_cont1_title">
                        Quantity:
                      </div>
                      <div className="product_detail_cont_div4_cont1_val">
                        {" "}
                        {payload?.quantity || 0}
                      </div>
                    </div>
                    <div className="product_detail_cont_div4_cont2">
                      <button
                        onClick={handleDecrease}
                        className={`product_detail_cont_div4_cont2_btn1 ${
                          disabled.decrease ? "disabled" : ""
                        }`}
                        disabled={disabled.decrease}
                      >
                        -
                      </button>
                      <input
                        onChange={handleInputChange}
                        type="number"
                        value={quantity}
                        className="product_detail_cont_div4_cont2_input"
                      />
                      <button
                        onClick={handleIncrease}
                        className={`product_detail_cont_div4_cont2_btn2 ${
                          disabled.increase ? "disabled" : ""
                        }`}
                        disabled={disabled.increase}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="ProductDetailPage_area_div2_div3">
                    <div className="product_detail_cont_div4_cont1">
                      <div className="product_detail_cont_div4_cont1_title">
                        Brand:
                      </div>
                      <div className="product_detail_cont_div4_cont1_val">
                        {" "}
                        {payload?.brand}
                      </div>
                    </div>
                    <div className="product_detail_cont_div4_cont1">
                      <div className="product_detail_cont_div4_cont1_title">
                        Business:
                      </div>
                      <div
                        className="product_detail_cont_div4_cont1_val"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        <Link
                          to={`/market/business/all/products/${payload?.businessName}`}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          <img
                            src={payload?.busImg}
                            alt=""
                            className="marketPlace_area_3_div1_body_cont_stats_body_quantity_img"
                            style={{ marginRight: "5px" }}
                          />
                          {payload?.symbol}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="ProductDetailPage_area_div2_div3">
                    <div className="product_detail_cont_div4_cont1">
                      <div className="product_detail_cont_div4_cont1_title">
                        Category:
                      </div>
                      <div
                        className="product_detail_cont_div4_cont1_val"
                        style={{
                          color: "#6057fd",
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                      >
                        <Link
                          to={`/market/category/all/products/${payload?.category}`}
                          style={{
                            color: "#6057fd",
                            cursor: "pointer",
                            userSelect: "none",
                          }}
                        >
                          {payload?.category}
                        </Link>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="ProductDetailPage_area_div2_div3_spec_div">
                    <div className="ProductDetailPage_area_div2_div3_spec_div_title">
                      Specifications
                    </div>

                    {specArray?.length === 0 ? (
                      <>No specifications</>
                    ) : (
                      <>
                        {" "}
                        {specArray.map((spec, index) => (
                          <div
                            key={index}
                            className="ProductDetailPage_area_div2_div3_spec_div_body_cont"
                          >
                            <div className="ProductDetailPage_area_div2_div3_spec_div_body_cont_div1">
                              {spec.key}:
                            </div>
                            <div className="ProductDetailPage_area_div2_div3_spec_div_body_cont_divVal">
                              {spec.value}
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                  <div className="ProductDetailPage_area_div2_div3_return_policy">
                    <div className="ProductDetailPage_area_div2_div3_return_policy_title">
                      Return policy
                    </div>
                    <div className="ProductDetailPage_area_div2_div3_return_policy_body">
                      No return policy
                    </div>
                  </div>
                  <div className="ProductDetailPage_area_div2_div3_amounts">
                    <div className="product_detail_cont_div5_6_div1">
                      <div className="product_detail_cont_div5_6_div1_title">
                        Quantity
                      </div>
                      <div className="product_detail_cont_div5_6_div1_value">
                        {quantity}
                      </div>
                    </div>
                    <div className="product_detail_cont_div5_6_div1">
                      <div className="product_detail_cont_div5_6_div1_title">
                        Unit Amount
                      </div>
                      <div className="product_detail_cont_div5_6_div1_value">
                        <div className="product_detail_cont_div5_6_div1_value_div1">
                          {parseFloat(payload?.userAmount).toFixed(2)}
                          <span className="product_detail_cont_div5_6_div1_value_div1_span">
                            {" "}
                            USDC
                          </span>{" "}
                        </div>{" "}
                        <span className="product_detail_cont_div5_6_div1_value_span">
                          =$ {parseFloat(payload?.userAmount).toFixed(2)}
                        </span>
                      </div>
                    </div>
                    <div className="product_detail_cont_div5_6_div1">
                      <div className="product_detail_cont_div5_6_div1_title">
                        Total
                      </div>
                      <div className="product_detail_cont_div5_6_div1_value">
                        <div className="product_detail_cont_div5_6_div1_value_div1">
                          {parseFloat(
                            parseFloat(payload?.userAmount) * quantity
                          ).toFixed(2)}
                          <span className="product_detail_cont_div5_6_div1_value_div1_span">
                            {" "}
                            USDC
                          </span>{" "}
                        </div>{" "}
                        <span className="product_detail_cont_div5_6_div1_value_span">
                          =${" "}
                          {parseFloat(
                            parseFloat(payload?.userAmount) * quantity
                          ).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="ProductDetailPage_area_div2_div3_amounts_btn_div">
                    <button
                      className="product_detail_cont_div5_7_btn"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={toggleConfirmPurchase}
                    >
                      Purchase
                    </button>
                  </div>
                </div>
              </div>

              <div className="ProductDetailPage_area_div2_div3_prodDetails">
                <div className="ProductDetailPage_area_div2_div3_prodDetails_title">
                  Product details
                </div>
                <div
                  className="ProductDetailPage_area_div2_div3_prodDetails_body"
                  dangerouslySetInnerHTML={{ __html: payload?.details }}
                ></div>
              </div>

              {categoryProdLoading && !products?.length ? (
                <div
                  className="loadMoreBusinessDiv"
                  style={{ padding: "2em 10px" }}
                >
                  <CustomLoader
                    borderSize={"3px"}
                    height={"35px"}
                    width={"35px"}
                  />
                  <p className="loadMoreBusinessDiv_txt">Loading products...</p>
                </div>
              ) : (
                <ProductDisplayCarousel
                  slides={products}
                  options={OPTIONS}
                  title={"Similar Products"}
                  seeAll={`/market/category/all/products/${category}`}
                />
              )}
            </div>
          </div>

          {/* ====== */}
          {/* ====== */}
          {/* ====== */}
          {/* ====== */}
          {/* ====== */}
          {/* ====== */}
          {confirmPurchaseModal && (
            <div className="confrimBuySellDiv">
              <div className="confrimBuySellDiv_container">
                <div className="confrimBuySellDiv_title">
                  Confirm Purchase
                  <Cancel01Icon
                    size={20}
                    className="confrimBuySellDiv_title_icon"
                    onClick={() => {
                      setConfirmPurchaseModal(false);
                    }}
                  />
                </div>
                <div className="confrimBuySellDiv_body">
                  <div
                    className="orderReceiptDiv_cont_body_cont1_body"
                    style={{ marginBottom: "1em" }}
                  >
                    <div className="orderReceiptDiv_cont_body_cont1_body_div1">
                      <img
                        src={images[0]}
                        alt=""
                        className="tableBody_row_data_product_div_img"
                      />
                      <div className="orderReceiptDiv_cont_body_cont1_body_div1_area">
                        <div className="tableBody_row_data_product_div_cont_1">
                          {payload?.name}
                        </div>
                        <div className="tableBody_row_data_product_div_cont_2">
                          <span className="tableBody_row_data_product_div_cont_2_span">
                            {" "}
                            Brand:
                          </span>{" "}
                          {payload?.brand}
                        </div>
                      </div>
                    </div>
                    {/* ==== */}
                    {/* ==== */}
                    {/* ==== */}
                    {/* ==== */}
                    {/* ==== */}
                    <div className="orderReceiptDiv_cont_body_cont1_body_div2">
                      <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1">
                        <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_title">
                          Brand
                        </div>
                        <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_value">
                          {payload?.brand}
                        </div>
                      </div>
                      <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1">
                        <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_title">
                          Price
                        </div>
                        <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_value">
                          <div className="tableBody_row_data_product_price_div1">
                            {parseFloat(payload?.userAmount).toFixed(2)}USDC
                          </div>
                          <span className="tableBody_row_data_product_price_span">
                            =$ {parseFloat(payload?.userAmount).toFixed(2)}
                          </span>
                        </div>
                      </div>
                      <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1">
                        <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_title">
                          Quantity
                        </div>
                        <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_value">
                          {quantity}
                        </div>
                      </div>
                      <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1">
                        <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_title">
                          Total
                        </div>
                        <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_value">
                          <div className="tableBody_row_data_product_price_div1">
                            {parseFloat(
                              parseFloat(payload?.userAmount) * quantity
                            ).toFixed(2)}{" "}
                            USDC
                          </div>
                          <span className="tableBody_row_data_product_price_span">
                            =${" "}
                            {parseFloat(
                              parseFloat(payload?.userAmount) * quantity
                            ).toFixed(2)}
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* ==== */}
                    {/* ==== */}
                  </div>
                  {checkAllowanceLoading ? (
                    <button
                      className="product_detail_cont_div5_7_btn"
                      disabled={disabled}
                    >
                      <ClipLoader color="#ffffff" size={18} /> Checking
                      allowance...
                    </button>
                  ) : (
                    <>
                      {" "}
                      {hasAllowance ? (
                        <button
                          className="product_detail_cont_div5_7_btn"
                          onClick={() => {
                            setOpenWallet(true);
                            setConfirmPurchaseModal(false);
                            setTransactionType("purchase");
                          }}
                          disabled={purchaseLoading}
                        >
                          {purchaseLoading ? (
                            <>
                              <ClipLoader color="#ffffff" size={18} />{" "}
                              Purchasing...
                            </>
                          ) : (
                            "Purchase"
                          )}
                        </button>
                      ) : (
                        <button
                          className="product_detail_cont_div5_7_btn"
                          onClick={() => {
                            setOpenWallet(true);
                            setConfirmPurchaseModal(false);
                            setTransactionType("approve");
                          }}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          disabled={allowanceLoading}
                        >
                          {allowanceLoading ? (
                            <>
                              {" "}
                              <ClipLoader color="#fff" size={24} /> Approving...
                            </>
                          ) : (
                            "   Approve Spending"
                          )}
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
          {/* ====== */}
          {/* ====== */}
          {/* ====== */}
          {/* ====== */}
          {/* ====== */}
          {/* ====== */}
          {/* ====== */}
          {/* ====== */}
          {/* ====== */}
          {/* ====== */}
          {/* ====== */}
          {/* ====== */}
          {openWallet && transactionType === "approve" && (
            <WalletPop
              closePop={() => {
                setOpenWallet(false);
                setConfirmPurchaseModal(true);
              }}
              transaction={true}
              transactionType={transactionType}
              transact={setAlllowanceFunc}
              loading={allowanceLoading}
              tokenName={payload?.name}
            />
          )}
          {openWallet && transactionType === "purchase" && (
            <WalletPop
              closePop={() => {
                setOpenWallet(false);
                setConfirmPurchaseModal(true);
              }}
              transaction={true}
              transactionType={transactionType}
              transact={purchaseProduct}
              loading={purchaseLoading}
              tokenName={payload?.name}
            />
          )}
          {/* ====== */}
          {/* ====== */}
          {/* ====== */}
          {/* ====== */}
          {/* ====== */}
          {/* ====== */}
          {/* ====== */}
          {/* ====== */}
          {/* ====== */}
          {/* ====== */}
          {/* ====== */}
          {/* ====== */}

          {deliveryModal && (
            <div className="depositModal">
              <div className="WalletPop_div_cont" style={{ padding: "1em" }}>
                <div className="deliveryModal_cont_title">Delivery details</div>
                <div className="deliveryModal_cont_body">
                  <div className="deliveryModal_cont_body_cont1">
                    <div className="ListModal_cont_area_body_div2_title">
                      Full name<span className="asteriks">*</span>
                    </div>
                    <input
                      type="text"
                      placeholder="name"
                      className="ListModal_cont_area_body_div2_input"
                      id="fullname"
                      name="fullname"
                      value={deliveryDetails.fullname}
                      onChange={handleDeliveryChange}
                    />
                  </div>
                  <div className="deliveryModal_cont_body_cont1">
                    <div className="ListModal_cont_area_body_div2_title">
                      Phone no<span className="asteriks">*</span>
                    </div>
                    <input
                      type="text"
                      placeholder="+1"
                      className="ListModal_cont_area_body_div2_input"
                      id="phoneNo"
                      name="phoneNo"
                      value={deliveryDetails.phoneNo}
                      onChange={handleDeliveryChange}
                    />
                  </div>
                  <div className="deliveryModal_cont_body_cont1">
                    <div className="ListModal_cont_area_body_div2_title">
                      Country <span className="asteriks">*</span>
                    </div>
                    <input
                      type="text"
                      placeholder="country of residence"
                      className="ListModal_cont_area_body_div2_input"
                      id="country"
                      name="country"
                      value={deliveryDetails.country}
                      onChange={handleDeliveryChange}
                    />
                  </div>
                  <div className="deliveryModal_cont_body_cont1">
                    <div className="ListModal_cont_area_body_div2_title">
                      State <span className="asteriks">*</span>
                    </div>
                    <input
                      type="text"
                      placeholder="state"
                      className="ListModal_cont_area_body_div2_input"
                      id="state"
                      name="state"
                      value={deliveryDetails.state}
                      onChange={handleDeliveryChange}
                    />
                  </div>
                  <div className="deliveryModal_cont_body_cont1">
                    <div className="ListModal_cont_area_body_div2_title">
                      City <span className="asteriks">*</span>
                    </div>
                    <input
                      type="text"
                      placeholder="city"
                      className="ListModal_cont_area_body_div2_input"
                      id="city"
                      name="city"
                      value={deliveryDetails.city}
                      onChange={handleDeliveryChange}
                    />
                  </div>
                  <div className="deliveryModal_cont_body_cont1">
                    <div className="ListModal_cont_area_body_div2_title">
                      Zip code <span className="asteriks">*</span>
                    </div>
                    <input
                      type="number"
                      placeholder="zipcode"
                      className="ListModal_cont_area_body_div2_input"
                      id="zipcode"
                      name="zipcode"
                      value={deliveryDetails.zipcode}
                      onChange={handleDeliveryChange}
                    />
                  </div>
                  <button
                    class="rwaBusinessDiv_area_div1_cont2_btn2"
                    onClick={stakeProduct}
                  >
                    {deliveryLoading ? (
                      <>
                        {" "}
                        <ClipLoader color="#fff" size={24} /> Submitting...
                      </>
                    ) : (
                      " Submit"
                    )}
                  </button>
                </div>
              </div>
            </div>
          )}

          <Toaster />
        </div>
      )}
    </>
  );
};

export default ProductDetail;
