import React, { useState } from "react";
import { ArrowLeft02Icon } from "hugeicons-react";
import "./index.css";
import { useDispatch } from "react-redux";
import { decrypt, encrypt } from "../../../helper/encryption";
import toast, { Toaster } from "react-hot-toast";
import {
  setPassword,
  setWalletDetails,
} from "../../../Redux/feautures/user/userSlice";
import { CREATE_ACCOUNT, IMPORT_WALLET } from "../../../services/auth";
import ClipLoader from "react-spinners/ClipLoader";

const CreateWalletPage2 = ({ prevStep, nextStep }) => {
  const dispatch = useDispatch();
  const [passwords, setPasswords] = useState({
    password: "",
    confirm: "",
  });
  const [loading, setLoading] = useState(false);

  const handlePasswordOnChange = (e) => {
    const { value, id } = e.target;

    setPasswords({ ...passwords, [id]: value });
  };

  const savePassword = async () => {
    //basic validation
    if (!passwords.password || !passwords.confirm) {
      return;
    }

    if (passwords.password != passwords.confirm) {
      return;
    }

    const encrypted_password = encrypt(passwords.password);

    await dispatch(setPassword(encrypted_password));

    //call the create account api
    setLoading(true);
    const res = await CREATE_ACCOUNT();
    //console.log(res, "rsult");

    if (!res?.success) {
      setLoading(false);
      //console.log("Failed to proceed, wallet connection failed!!!");
      toast.error(
        <div className="toast_success_div">
          <div className="toast_error_div_title">Error!!</div>
          <div className="toast_success_div_para">
            Failed to proceed, wallet connection failed!!!
          </div>
        </div>,
        {
          duration: 5000,
          className: "toast_error",
        }
      );
      return;
    }
    setLoading(false);
    //prepare payload
    toast.success(
      <div className="toast_success_div">
        <div className="toast_success_div_title"> Success!</div>
        <div className="toast_success_div_para">
          {" "}
          You have successfully created your wallet.
        </div>
      </div>,
      {
        duration: 5000,
        className: "toast_success",
      }
    );
    const toSAve = {
      wallet: res.wallet,
      mnemonic: res?.credential.mnemonic,
      privateKey: res?.credential.privateKey,
    };

    //save the wallet data
    dispatch(setWalletDetails(toSAve));
    setTimeout(() => {
      nextStep();
    }, 2000);

    // //console.log(encrypt(passwords.password));
    //save encrypted version to store
  };

  return (
    <div className="onchainWalletDiv_container">
      <div className="CreateWalletPage1_area_div1">
        <div className="CreateWalletPage1_area_div1_cont1" onClick={prevStep}>
          <ArrowLeft02Icon />
        </div>
        <div className="CreateWalletPage1_area_div1_cont2">
          <div className="CreateWalletPage1_area_div1_cont2_circle1 active"></div>
          <div className="CreateWalletPage1_area_div1_cont2_circle1 active"></div>
          <div className="CreateWalletPage1_area_div1_cont2_circle1"></div>
        </div>
      </div>
      <div className="CreateWalletPage2_area">
        <div className="CreateWalletPage2_area_title">Create a password</div>
        <div className="CreateWalletPage2_area_para">
          You will use this to unlock your wallet
        </div>
        <input
          type="password"
          className="CreateWalletPage2_area_input"
          placeholder="Password"
          id="password"
          value={passwords.password}
          onChange={handlePasswordOnChange}
        />
        <input
          type="password"
          className="CreateWalletPage2_area_input"
          placeholder="Confirm Password"
          id="confirm"
          value={passwords.confirm}
          onChange={handlePasswordOnChange}
        />
        <div className="CreateWalletPage2_area_criteria">
          <div className="CreateWalletPage2_area_criteria_txt">
            *Must be 8 characters long
          </div>
          <div className="CreateWalletPage2_area_criteria_txt">
            *Must contain a capital letter
          </div>
          <div className="CreateWalletPage2_area_criteria_txt">
            *Must contain a number
          </div>
          <div className="CreateWalletPage2_area_criteria_txt">
            *Must contain a special character
          </div>
        </div>
        <button
          className="onchainWalletDiv_container_div2_btn1"
          // onClick={nextStep}
          onClick={savePassword}
          disabled={loading}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading ? (
            <>
              {" "}
              <ClipLoader color="#fff" size={24} /> Creating...
            </>
          ) : (
            " Continue"
          )}
        </button>
      </div>
      <Toaster />
    </div>
  );
};

export default CreateWalletPage2;
