import React from "react";
import "./index.css";

const LegalPolicy = () => {
  return (
    <div className="LegalPolicy" Div>
      <div className="container" style={{ maxWidth: "1200px" }}>
        <div className="LegalPolicy_area">
          <div className="LegalPolicy_area_cont1">
            <div className="LegalPolicy_area_cont1_title">Legal Disclaimer</div>
            <div className="LegalPolicy_area_cont1_link">
              Last Updated: January 28, 2025
            </div>
          </div>
          <div className="LegalPolicy_area_cont_body">
            <div className="LegalPolicy_area_cont_body_cont1">
              <div className="LegalPolicy_area_cont_body_cont1_title">
                Introduction
              </div>
              <div className="LegalPolicy_area_cont_body_cont1_para">
                This Legal Disclaimer governs your use of the website located at
                <a href="/" style={{ color: "#6058fd" }}>
                  https://dhive.org
                </a>{" "}
                (the "Website") and the services offered therein (collectively,
                "Our Services"). By accessing or using the Website, you ("User"
                or "You") agree to be bound by this Legal Disclaimer and the
                Terms of Use referenced herein. If you do not agree to these
                terms, you must not use the Website or Our Services. This
                agreement is entered into between You and DHIVE Network
                (referred to as "Protocol," "We," "Us," or "Our").
              </div>
            </div>
            {/* ==== */}
            {/* ==== */}
            {/* ==== */}
            {/* ==== */}
            <div className="LegalPolicy_area_cont_body_cont1">
              <div className="LegalPolicy_area_cont_body_cont1_title">
                User Representations and Warranties
              </div>
              <div className="LegalPolicy_area_cont_body_cont1_para">
                By using the Website, You represent and warrant that:
                <br />
                (a) You are of legal age to form a binding contract in your
                jurisdiction;
                <br />
                (b) You have not been previously suspended or removed from using
                Our Services;
                <br />
                (c) You have the full power and authority to enter into this
                agreement without violating any other agreement to which You are
                a party.
                <br />
                If You are accessing or using Our Services on behalf of a legal
                entity, You further represent and warrant that:
                <br />
                (a) The entity is duly organized and validly existing under the
                laws of its jurisdiction;
                <br />
                (b) You are authorized to act on behalf of such an entity.
              </div>
            </div>
            {/* ==== */}
            {/* ==== */}
            {/* ==== */}
            {/* ==== */}
            <div className="LegalPolicy_area_cont_body_cont1">
              <div className="LegalPolicy_area_cont_body_cont1_title">
                No Professional Advice
              </div>
              <div className="LegalPolicy_area_cont_body_cont1_para">
                The information provided on the Website is for informational
                purposes only and does not constitute investment, financial,
                legal, regulatory, tax, or accounting advice. Nothing on the
                Website should be construed as a recommendation, endorsement, or
                suggestion by Us, Our affiliates, or any third party to engage
                in any investment strategy, transaction, or activity. You are
                solely responsible for consulting with qualified professionals
                regarding Your specific circumstances and decisions.
              </div>
            </div>
            {/* ==== */}
            {/* ==== */}
            {/* ==== */}
            {/* ==== */}
            <div className="LegalPolicy_area_cont_body_cont1">
              <div className="LegalPolicy_area_cont_body_cont1_title">
                Usage Restrictions
              </div>
              <div className="LegalPolicy_area_cont_body_cont1_para">
                By accessing or using the Website or Our Services, You confirm
                and agree that:
                <br />
                (a) You are not located in, or a resident of, any jurisdiction
                subject to comprehensive sanctions or restrictions, including
                but not limited to Afghanistan, Belarus, China, Cuba, Iran,
                North Korea, Russia, Syria, or any other jurisdiction designated
                by the Office of Foreign Assets Control (OFAC);
                <br />
                (b) Your access to and use of the Website and Our Services is
                lawful under the laws of Your country of residence.
              </div>
            </div>
            {/* ==== */}
            {/* ==== */}
            {/* ==== */}
            {/* ==== */}
            <div className="LegalPolicy_area_cont_body_cont1">
              <div className="LegalPolicy_area_cont_body_cont1_title">
                Assumption of Risk
              </div>
              <div className="LegalPolicy_area_cont_body_cont1_para">
                You acknowledge and agree that the use of the Website and Our
                Services involves inherent risks, including but not limited to:
                <br />
                (a) User errors, such as sending payments to incorrect
                addresses;
                <br />
                (b) Software malfunctions, including issues related to malware
                or cryptographic libraries;
                <br />
                (c) Technical failures, such as hardware wallet malfunctions;
                <br />
                (d) Security breaches, including unauthorized access to wallets;
                <br />
                (e) Actions or omissions of third parties, such as bankruptcy,
                fraud, or cyberattacks. We are not responsible for any losses,
                damages, or claims arising from such events.
              </div>
            </div>
            {/* ==== */}
            {/* ==== */}
            {/* ==== */}
            {/* ==== */}
            <div className="LegalPolicy_area_cont_body_cont1">
              <div className="LegalPolicy_area_cont_body_cont1_title">
                Investment Risks
              </div>
              <div className="LegalPolicy_area_cont_body_cont1_para">
                Cryptocurrency investments involve significant risk, including
                the potential loss of principal and extreme price volatility.
                The information provided on the Website does not guarantee the
                preservation or appreciation of invested amounts. You are solely
                responsible for understanding the risks, conducting Your own due
                diligence, and making informed decisions regarding Your use of
                the Website and Our Services.
              </div>
            </div>
            {/* ==== */}
            {/* ==== */}
            {/* ==== */}
            {/* ==== */}
            <div className="LegalPolicy_area_cont_body_cont1">
              <div className="LegalPolicy_area_cont_body_cont1_title">
                Tax Compliance
              </div>
              <div className="LegalPolicy_area_cont_body_cont1_para">
                You are solely responsible for determining and complying with
                any tax obligations arising from Your use of the Website or Our
                Services. We do not provide tax advice and are not responsible
                for any tax-related consequences of Your actions.
              </div>
            </div>
            {/* ==== */}
            {/* ==== */}
            {/* ==== */}
            {/* ==== */}
            <div className="LegalPolicy_area_cont_body_cont1">
              <div className="LegalPolicy_area_cont_body_cont1_title">
                No Warranties
              </div>
              <div className="LegalPolicy_area_cont_body_cont1_para">
                The Website and Our Services are provided on an "as is" and "as
                available" basis, without any warranties of any kind, either
                express or implied, including but not limited to implied
                warranties of merchantability, fitness for a particular purpose,
                non-infringement, or uninterrupted or error-free operation. We
                do not guarantee the availability, accessibility, or
                functionality of the Website, and reserve the right to modify or
                discontinue any aspect of the Website without prior notice.
              </div>
            </div>
            {/* ==== */}
            {/* ==== */}
            {/* ==== */}
            {/* ==== */}
            <div className="LegalPolicy_area_cont_body_cont1">
              <div className="LegalPolicy_area_cont_body_cont1_title">
                Security Disclaimer
              </div>
              <div className="LegalPolicy_area_cont_body_cont1_para">
                The Website is not guaranteed to be secure or free from bugs,
                viruses, or other harmful components. You are responsible for
                implementing appropriate security measures to protect Your data
                and assets.
              </div>
            </div>
            {/* ==== */}
            {/* ==== */}
            {/* ==== */}
            {/* ==== */}
            <div className="LegalPolicy_area_cont_body_cont1">
              <div className="LegalPolicy_area_cont_body_cont1_title">
                Limitation of Liability
              </div>
              <div className="LegalPolicy_area_cont_body_cont1_para">
                To the fullest extent permitted by applicable law, We shall not
                be liable for any indirect, special, incidental, punitive, or
                consequential damages, including but not limited to loss of
                profits, business interruption, loss of goodwill, or loss of
                data, arising out of or in connection with Your use of the
                Website or Our Services.
              </div>
            </div>
            {/* ==== */}
            {/* ==== */}
            {/* ==== */}
            {/* ==== */}
            <div className="LegalPolicy_area_cont_body_cont1">
              <div className="LegalPolicy_area_cont_body_cont1_title">
                Arbitration
              </div>
              <div className="LegalPolicy_area_cont_body_cont1_para">
                Any dispute, controversy, or claim arising out of or relating to
                Your use of the Website, Our Services, or these Terms of Use
                shall be resolved exclusively through binding arbitration
                administered by the Judicial Arbitration and Mediation Services
                (JAMS) under its Comprehensive Arbitration Rules and Procedures.
                Arbitration shall be conducted on an individual basis, and class
                actions or representative proceedings are expressly prohibited.
                If the prohibition on class arbitration is deemed unenforceable,
                the remaining provisions of this arbitration agreement shall
                remain in full force and effect.
              </div>
            </div>
            {/* ==== */}
            {/* ==== */}
            {/* ==== */}
            {/* ==== */}
            <div className="LegalPolicy_area_cont_body_cont1">
              <div className="LegalPolicy_area_cont_body_cont1_title">
                Amendments
              </div>
              <div className="LegalPolicy_area_cont_body_cont1_para">
                We reserve the right to modify or update this Legal Disclaimer
                and the Terms of Use at any time, in Our sole discretion,
                without prior notice. Any changes will be effective immediately
                upon posting on the Website. Your continued use of the Website
                following the posting of revised terms constitutes Your
                acceptance of such changes.
              </div>
            </div>
            {/* ==== */}
            {/* ==== */}
            {/* ==== */}
            {/* ==== */}
            <div className="LegalPolicy_area_cont_body_cont1">
              <div className="LegalPolicy_area_cont_body_cont1_title">
                Third-Party Services and Content
              </div>
              <div className="LegalPolicy_area_cont_body_cont1_para">
                The Website may include links to third-party services or content
                ("Third-Party Content"). We do not control, endorse, or assume
                responsibility for any Third-Party Content, including its
                accuracy, completeness, or legality. Your interactions with
                third parties are solely at Your own risk.
              </div>
            </div>
            {/* ==== */}
            {/* ==== */}
            {/* ==== */}
            {/* ==== */}
            <div className="LegalPolicy_area_cont_body_cont1">
              <div className="LegalPolicy_area_cont_body_cont1_title">
                Intellectual Property Rights
              </div>
              <div className="LegalPolicy_area_cont_body_cont1_para">
                The Website and all its content, features, and functionality,
                including but not limited to text, graphics, logos, images, and
                software, are owned by the Protocol, its licensors, or
                affiliates, and are protected by United States and international
                intellectual property laws. You are granted a limited,
                revocable, non-exclusive license to access and use the Website
                for personal, non-commercial purposes. Any unauthorized use of
                the Website or its content is strictly prohibited.
              </div>
            </div>
            {/* ==== */}
            {/* ==== */}
            {/* ==== */}
            {/* ==== */}
            <div className="LegalPolicy_area_cont_body_cont1">
              <div className="LegalPolicy_area_cont_body_cont1_title">
                Contact Information
              </div>
              <div className="LegalPolicy_area_cont_body_cont1_para">
                For questions or concerns regarding this Legal Disclaimer,
                please contact Us at [insert contact information]. By using the
                Website, You acknowledge that You have read, understood, and
                agreed to this Legal Disclaimer and the Terms of Use.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LegalPolicy;
