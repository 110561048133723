import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addBalanceListener,
  removeBalanceListener,
  fetchTokenBalance,
  initializeBalanceTracking,
} from "../../../Redux/feautures/assets/assetSlice";
import { ethers } from "ethers";

const TokenList2 = ({ selectedChain, hideLowBal, setSelectedAsset }) => {
  const dispatch = useDispatch();
  const walletAddress = useSelector((state) => state.user.wallet_address);

  // Safely get tokens with fallback to empty array
  const nativeTokens = useSelector(
    (state) => state.assets.nativeTokensByNetwork[selectedChain?.network] || []
  );
  const tokens = useSelector(
    (state) => state.assets.tokensByNetwork[selectedChain?.network] || []
  );
  const balanceState = useSelector(
    (state) => state.assets.balanceState.byNetwork[selectedChain?.network] || {}
  );

  useEffect(() => {
    if (selectedChain?.network && walletAddress) {
      dispatch(
        initializeBalanceTracking({
          networkName: selectedChain.webRPC,
          walletAddress,
        })
      );
    }
  }, [selectedChain?.network, walletAddress, dispatch]);

  return (
    <div className="WalletPop_div_cont_walletHome_div2_body">
      {/* Native Tokens */}
      {nativeTokens.map((data) => {
        if (!data?.id) return null;
        return (
          <TokenBalanceItem
            key={`native-${data.id}`}
            data={data}
            selectedChain={selectedChain}
            balanceState={balanceState?.native}
            setSelectedAsset={setSelectedAsset}
            isNative={true}
          />
        );
      })}

      {/* ERC20 Tokens */}
      {tokens.map((data) => {
        if (!data?.id || !data?.tokenB) return null;
        return (
          <TokenBalanceItem
            key={`token-${data.id}`}
            data={data}
            selectedChain={selectedChain}
            balanceState={balanceState?.tokens?.[data.tokenB?.toLowerCase()]}
            setSelectedAsset={setSelectedAsset}
            hideLowBal={hideLowBal}
            isNative={false}
          />
        );
      })}
    </div>
  );
};

const TokenBalanceItem = ({
  data,
  selectedChain,
  balanceState,
  setSelectedAsset,
  hideLowBal,
  isNative,
}) => {
  const dispatch = useDispatch();
  const walletAddress = useSelector((state) => state.user.wallet_address);

  // Safely get token address with fallback
  const tokenAddress = isNative ? "native" : data?.tokenB?.toLowerCase();

  // Track if this is the initial load
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (!selectedChain?.network || !walletAddress || !tokenAddress) return;

    const fetchInitialBalance = async () => {
      try {
        dispatch(
          fetchTokenBalance({
            networkName: selectedChain.network,
            walletAddress,
            tokenAddress,
            isNative,
            tokenData: data,
            silentUpdate: !initialLoad, // Silent update after initial load
          })
        );
      } catch (error) {
        console.error("Balance fetch error:", error);
      } finally {
        if (initialLoad) setInitialLoad(false);
      }
    };

    fetchInitialBalance();

    // Setup WebSocket listener for real-time updates
    const wsProvider = new ethers.providers.WebSocketProvider(
      selectedChain?.webRPC
    );
    const transferFilter = {
      topics: [
        ethers.utils.id("Transfer(address,address,uint256)"),
        null,
        ethers.utils.hexZeroPad(walletAddress, 32),
      ],
    };

    wsProvider.on(transferFilter, (log) => {
      if (log.address.toLowerCase() === tokenAddress?.toLowerCase()) {
        dispatch(
          fetchTokenBalance({
            networkName: selectedChain.network,
            walletAddress,
            tokenAddress,
            isNative,
            tokenData: data,
            silentUpdate: true, // Silent background update
          })
        );
      }
    });

    return () => {
      wsProvider.removeAllListeners();
    };
  }, [selectedChain?.network, walletAddress, tokenAddress, dispatch]);

  // Skip if balance is 0 and hideLowBal is true
  if (hideLowBal && parseFloat(balanceState?.balance || 0) <= 0) {
    return null;
  }

  // Determine what to display
  const showLoading = initialLoad && balanceState?.status === "loading";
  const displayBalance = balanceState?.hasValue
    ? parseFloat(balanceState.balance || 0).toFixed(4)
    : showLoading
    ? "Loading..."
    : "0";

  const tokenSymbol = isNative ? selectedChain?.baseToken : data?.tokenBName;

  const tokenValue = parseFloat(
    (balanceState?.balance || 0) * (data?.currentPrice || 0)
  ).toFixed(4);

  const assImg = data?.img || data?.image || "/img/art.png";

  return (
    <div
      className="WalletPop_div_cont_walletHome_div2_body_asset"
      onClick={() =>
        setSelectedAsset({ ...data, balance: balanceState?.balance })
      }
    >
      <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont1">
        <img
          src={isNative ? selectedChain?.image || "/img/art.png" : assImg}
          alt=""
          className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_img"
        />
        <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_area">
          <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_area_title">
            {tokenSymbol}
          </div>
          {!isNative && (
            <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_area_price">
              {parseFloat(data?.currentPrice || 0)}
            </div>
          )}
        </div>
      </div>
      <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont2">
        <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_area_title">
          {displayBalance}
        </div>
        <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_area_price">
          {tokenValue}
        </div>
      </div>
    </div>
  );
};

export default TokenList2;
