import React, { useState, useEffect } from "react";
import {
  Briefcase01Icon,
  ChartLineData02Icon,
  UnavailableIcon,
} from "hugeicons-react";
import "./index.css";
import { Camera02Icon, ArrowLeft01Icon } from "hugeicons-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Blockies from "react-blockies";
import {
  InformationCircleIcon,
  CheckmarkBadge01Icon,
  ShoppingCart01Icon,
} from "hugeicons-react";

import { PUBLIC_BUSINESS_INFO } from "../../../../services/business";
import ClipLoader from "react-spinners/ClipLoader";
import Nodata from "../../../../Components/Nodata/Nodata";

const SearchResults = () => {
  // const { businessName } = useParams();
  const businessName = "Next-Gen Electronics";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const previewLength = 350;
  const [businessInfo, setBusinessInfo] = useState(null);

  const fetchBusinessInfo = async () => {
    setLoading(true);
    const res = await PUBLIC_BUSINESS_INFO({ id: businessName });
    setLoading(false);
    if (!res.success) {
      return;
    }
    setBusinessInfo(res);
  };

  useEffect(() => {
    fetchBusinessInfo();
  }, [businessName]);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  // console.log("====================================");
  // console.log(businessInfo?.products);
  // console.log("====================================");
  return (
    <div className="BusinessProducts_div" style={{ paddingTop: "1em" }}>
      <div className="container">
        <div
          className="RwaBusinessDetails_div_area"
          style={{ paddingTop: "0px" }}
        >
          <div
            className="ListModal_cont_area_back_button"
            style={{ paddingTop: "0px" }}
          >
            {" "}
            <div
              className="ListModal_cont_area_back_button_div1"
              onClick={() => {
                navigate("/market");
              }}
              style={{ marginBottom: "1em" }}
            >
              <ArrowLeft01Icon />
              Back
            </div>
          </div>

          <div className="businessProd_body">
            <div className="businessProd_body_2">
              <div className="businessProd_body_2_cont1">
                <div className="businessProd_body_2_cont1_div1">
                  <div className="businessProd_body_2_cont1_div1_title">
                    Price
                  </div>
                  <div className="businessProd_body_2_cont1_div1_body">
                    <input type="radio" name="" id="" />{" "}
                    <span className="businessProd_body_2_cont1_div1_body_span">
                      {" "}
                      High - Low
                    </span>{" "}
                  </div>
                  <div className="businessProd_body_2_cont1_div1_body">
                    <input type="radio" name="" id="" />{" "}
                    <span className="businessProd_body_2_cont1_div1_body_span">
                      {" "}
                      Low - High
                    </span>{" "}
                  </div>
                </div>
                <div className="businessProd_body_2_cont1_div1">
                  <div className="businessProd_body_2_cont1_div1_title">
                    Status
                  </div>
                  <div className="businessProd_body_2_cont1_div1_body">
                    <input type="radio" name="" id="" />{" "}
                    <span className="businessProd_body_2_cont1_div1_body_span">
                      {" "}
                      Sold Out
                    </span>{" "}
                  </div>
                  <div className="businessProd_body_2_cont1_div1_body">
                    <input type="radio" name="" id="" />{" "}
                    <span className="businessProd_body_2_cont1_div1_body_span">
                      {" "}
                      In Stock
                    </span>{" "}
                  </div>
                </div>
                <div className="businessProd_body_2_cont1_div1">
                  <div className="businessProd_body_2_cont1_div1_title">
                    Time
                  </div>
                  <div className="businessProd_body_2_cont1_div1_body">
                    <input type="radio" name="" id="" />{" "}
                    <span className="businessProd_body_2_cont1_div1_body_span">
                      {" "}
                      New
                    </span>{" "}
                  </div>
                </div>
              </div>{" "}
              <div className="businessProd_body_2_cont2">
                <div
                  className="businessProd_body_1"
                  style={{ marginBottom: "3em" }}
                >
                  <div className="marketPlace_area_2_title">
                    Search result for iphone
                  </div>
                </div>
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "400px",
                      width: "100%",
                    }}
                  >
                    <ClipLoader color="#6059fd" size={42} />
                  </div>
                ) : (
                  <>
                    {businessInfo?.products?.length === 0 ||
                    businessInfo?.products?.length === undefined ? (
                      <Nodata message="No products found" />
                    ) : (
                      <div className="businessProd_body_2_cont2_body">
                        {businessInfo?.products?.map((data, index) => {
                          return (
                            <div
                              className="businessProd_body_2_cont2_body_card"
                              key={index}
                              onClick={() => navigate("/market/detail")}
                            >
                              <img
                                src={data.images}
                                alt=""
                                className="marketPlace_area_3_div1_body_cont_img"
                              />
                              <div className="marketPlace_area_3_div1_body_cont_stats_body">
                                <div className="marketPlace_area_3_div1_body_cont_stats_body_title">
                                  {data.name}
                                </div>
                                <div className="marketPlace_area_3_div1_body_cont_stats_body_amount">
                                  <div className="marketPlace_area_3_div1_body_cont_stats_body_amount_cont">
                                    {parseFloat(data.userAmount).toFixed(2)}
                                    <span className="marketPlace_area_3_div1_body_cont_stats_body_amount_cont_span">
                                      USDC
                                    </span>{" "}
                                  </div>
                                  <span className="marketPlace_area_3_div1_body_cont_stats_body_amount_span">
                                    =$
                                    {parseFloat(data.userAmount).toFixed(2)}
                                  </span>
                                </div>
                                <div className="marketPlace_area_3_div1_body_cont_stats_body_quantity">
                                  <img
                                    src={businessInfo?.businesses?.image}
                                    alt=""
                                    className="marketPlace_area_3_div1_body_cont_stats_body_quantity_img"
                                  />
                                  <div className="marketPlace_area_3_div1_body_cont_stats_body_quantity_div2">
                                    <div className="marketPlace_area_3_div1_body_cont_stats_body_quantity_title">
                                      Quantity
                                    </div>
                                    <div className="marketPlace_area_3_div1_body_cont_stats_body_quantity_amount">
                                      {data.quantity}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </>
                )}
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResults;
