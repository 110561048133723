import React, { useState, useEffect } from "react";
import {
  LineChart,
  AreaChart,
  Line,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";
import FetchData from "./FetchData";
import "./index.css";
import AnimatedNumber from "react-awesome-animated-number";
import "react-awesome-animated-number/dist/index.css";
import { GET_CHART_DATA } from "../../../../services/business";
import CustomLoader from "../../../../Components/CustomLoader/CustomLoader";
import { formatDecimal } from "../../../../assets/js/formatDecimal";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const date = payload[0].payload.date;
    const price = `${formatDecimal(payload[0]?.value)}egax`;
    return (
      <div className="customChartToolTip">
        <p
          style={{ margin: 0, fontSize: "14px" }}
          className="customChartToolTip_para1"
        >{`Date: ${date}`}</p>
        <p
          style={{ margin: 0, fontWeight: "bold", fontSize: "16px" }}
          className="customChartToolTip_para2"
        >{`Price: ${price}`}</p>
      </div>
    );
  }
  return null;
};

const Chart = ({ ticker, baseToken, businessData }) => {
  const [data, setData] = useState([]);
  const [activeInterval, setActiveInterval] = useState("1Y");
  const [isLoading, setIsLoading] = useState(false);
  const [hoveredPrice, setHoveredPrice] = useState(null);
  const [percentageChange, setPercentageChange] = useState(0);

  // const timeframes = {
  //   "5m": 5 * 60 * 1000,
  //   "1h": 60 * 60 * 1000,
  //   // "1d":
  //   "7d": 7 * 24 * 60 * 60 * 1000,
  //   "1y": 365 * 24 * 60 * 60 * 1000,
  // };
  // const intervals = [
  //   { label: "5m", value: 0.0104167 }, // 15 minutes
  //   { label: "1h", value: 0.0416667 }, // 1 hour
  //   { label: "7d", value: 1 }, // 1 day
  //   { label: "1W", value: 7 }, // 1 week
  //   { label: "1Y", value: 365 }, // 1 year
  // ];
  const intervals = [
    { label: "5m", value: 0.0104167 }, // 15 minutes
    { label: "1h", value: 0.0416667 }, // 1 hour
    { label: "7d", value: 1 }, // 1 day
    { label: "1W", value: 7 }, // 1 week
    { label: "1Y", value: 365 }, // 1 year
  ];

  const fetchChartData = async () => {
    setIsLoading(true);
    // const days = intervals.find((i) => i.label === activeInterval)?.value;
    // const data = await FetchData(days);
    //// console.log(days, "ss");
    const data = await GET_CHART_DATA({
      ticker,
      timeframe: 365,
      page: "1",
      limit: "100",
    });
    console.log(data, "symbollic");
    setData(data);
    setIsLoading(false);
    setHoveredPrice(null); // Reset on new interval
    setPercentageChange(null);
  };

  useEffect(() => {
    if (ticker) {
      fetchChartData();
    }
  }, [ticker]);

  const getMinPrice = () => Math.min(...data?.map((d) => d.value));
  const getMaxPrice = () => Math.max(...data?.map((d) => d.value));

  const handleMouseHover = (hoveredData) => {
    if (hoveredData) {
      const firstPrice = data[0]?.value || 1;
      const price = hoveredData.value;
      const change = price - firstPrice;
      const percentage = (change / firstPrice) * 100;
      setHoveredPrice(formatDecimal(price));
      setPercentageChange(percentage?.toFixed(8));
    }
  };
  // // console.log(data[data?.length - 1]?.value);

  return (
    <div className="chart-container">
      <div className="chart_container_div1">
        <div className="chart-header">
          <span style={{ fontSize: "24px", color: "#000", fontWeight: "600" }}>
            <AnimatedNumber
              value={
                hoveredPrice ||
                formatDecimal(data[data?.length - 1]?.value || 0) ||
                0
              }
              hasComma={true}
              size={24}
              duration={500}
            />
            {!businessData ? "_ _" : baseToken}
          </span>
          <span
            style={{
              fontSize: "14px",
              color: percentageChange >= 0 ? "green" : "red",
              marginLeft: "5px",
            }}
          >
            {percentageChange >= 0 ? `↑` : `↓`} {Math.abs(percentageChange)}%
          </span>
        </div>
        <div className="range-selector">
          {intervals?.map((interval) => (
            <button
              key={interval.label}
              className={`range-btn ${
                activeInterval === interval.label ? "active" : ""
              }`}
              onClick={() => setActiveInterval(interval.label)}
              disabled={interval.label == "1Y" ? false : true}
            >
              {interval.label}
            </button>
          ))}
        </div>
      </div>

      {isLoading ? (
        <CustomLoader />
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={data}
            onMouseMove={(e) => {
              if (e?.activePayload) {
                handleMouseHover(e.activePayload[0].payload);
              }
            }}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="1%" stopColor="#6059fd" stopOpacity={0.3} />
                <stop offset="100%" stopColor="#6059fd" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="date"
              // tickFormatter={(dateStr) => {
              //   const date = new Date(dateStr);
              //   if (isNaN(date.getTime())) return "";

              //   switch (activeInterval) {
              //     case "15m":
              //     case "1H":
              //       return date.toLocaleTimeString("en-US", {
              //         hour: "2-digit",
              //         minute: "2-digit",
              //       });
              //     case "1D":
              //     case "1W":
              //       return date.toLocaleDateString("en-US", {
              //         month: "short",
              //         day: "numeric",
              //       });
              //     case "1Y":
              //       return date.toLocaleDateString("en-US", {
              //         month: "short",
              //       });
              //     default:
              //       return date.toLocaleDateString();
              //   }
              // }}
              stroke="#fff"
              tick={{ fontSize: 12, fill: "#999" }}
              // interval="preserveStartEnd"
            />
            <YAxis
              domain={[getMinPrice() * 0.9, getMaxPrice() * 1.1]}
              hide={true}
            />
            <CartesianGrid
              stroke="#4d4d4d"
              strokeWidth={0.3}
              opacity={0.3}
              horizontal={true}
              vertical={false}
              strokeDasharray="3 3"
            />
            <Tooltip
              content={<CustomTooltip />}
              cursor={{ stroke: "#dbdbe6", strokeWidth: 1 }}
            />
            {/* <Line
              type="monotone"
              dataKey="value"
              stroke="#6059fd"
              strokeWidth={2.5}
              dot={false}
            /> */}
            <Area
              type="monotone"
              dataKey="value"
              stroke="#6059fd"
              fillOpacity={1}
              fill="url(#colorUv)"
              strokeWidth={2.5}
              dot={false}
              isAnimationActive={true}
              animationDuration={1000}
            />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default Chart;
