import React, { useEffect, useState } from "react";
import Blockies from "react-blockies";
import {
  ArrowLeft01Icon,
  InformationCircleIcon,
  ViewIcon,
  ViewOffSlashIcon,
} from "hugeicons-react";
import {
  Copy01Icon,
  Settings02Icon,
  ArrowUp02Icon,
  ArrowDown02Icon,
  ArrowDown01Icon,
  InvoiceIcon,
  CreditCardIcon,
  Link04Icon,
} from "hugeicons-react";
import "./index.css";
import WalletAssetDetail from "../WalletAssetDetail/WalletAssetDetail";
import SendModal from "./SendModal";
import ReceiveModal from "./ReceiveModal";
import { LIST_ALL_ASSET } from "../../../services/wallet";
import { useSelector } from "react-redux";
import Bridge from "../Bridge/Bridge";
import toast, { Toaster } from "react-hot-toast";
import WalletChains from "./WalletChains";
import WalletSettings from "../WalletSettings/WalletSettings";
import WalletModel from "./WalletModel";
import WalletBuy from "../WalletBuy/WalletBuy";
import TokenList from "./TokenList";

const WalletHome = ({ closePop }) => {
  const { wallet_address } = useSelector((state) => state.user);
  const {
    Assets,
    providers,
    tokensByNetwork,
    nativeTokensByNetwork,
    activeProvider: selectedChain,
  } = useSelector((state) => state.assets);
  const [allEgochainSum, setAllEgochainSum] = useState(0);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [sendModal, setSendModal] = useState(false);
  const [receiveModal, setReceiveModal] = useState(false);
  const [bridge, setBridge] = useState(false);
  const [hideLowBal, setHideLowBal] = useState(false);
  const [settings, setSettings] = useState(false);
  const [balanceView, setBalanceView] = useState(null);
  const [buy, setBuy] = useState(false);
  // const [selectedChain, setSelectedChain] = useState(chains[0]);
  const [chainModal, setChainModal] = useState(false);
  const egax_price = 0.54;

  useEffect(() => {
    let totalSum = 0;
    let egaxBalance = 0;
    let egaxCurrentPrice = 0;
    Assets.forEach((item) => {
      const balance = parseFloat(item.balance);
      const currentPrice = parseFloat(item.currentPrice);

      if (item.tokenBName === "EGAX") {
        egaxBalance = balance;
        egaxCurrentPrice = currentPrice;
      } else {
        totalSum += balance * currentPrice;
      }
    });
    const total = totalSum * egax_price;
    const egaxCalc = egaxBalance * egaxCurrentPrice;
    setAllEgochainSum(total + egaxCalc);
  }, [Assets]);

  const toggleSendModal = () => {
    //console.log("toggled...");
    setSendModal(!sendModal);
  };
  const toggleChainModal = () => {
    setChainModal(!chainModal);
  };
  const toggleReceiveModal = () => {
    setReceiveModal(!receiveModal);
  };

  const toggleBridge = () => {
    setBridge(!bridge);
  };
  const toggleSettings = () => {
    setSettings(!settings);
  };
  const toggleBuy = () => {
    setBuy(!buy);
  };
  const toggleLowBal = () => setHideLowBal((prev) => !prev);

  const copyText = () => {
    const textToCopy = wallet_address;
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          toast.success("Address copied", {
            style: { fontSize: "12px" },
          });
        })
        .catch((err) => {
          toast.error("Failed to copy link: ", err, {
            style: { fontSize: "12px" },
          });
        });
    } else {
      // Fallback method for older browsers
      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        toast.success("Address copied", {
          style: { fontSize: "12px" },
        });
      } catch (err) {
        toast.error("Failed to copy link: ", err, {
          style: { fontSize: "12px" },
        });
      }
      document.body.removeChild(textArea);
    }
  };

  useEffect(() => {
    const balanceView = localStorage.getItem("balanceView");
    setBalanceView(balanceView);
    // console.log(balanceView);
  }, [localStorage.getItem("balanceView")]);

  const hideBalance = () => {
    localStorage.setItem("balanceView", "hidden");
    setBalanceView("hidden");
  };
  const viewBalance = () => {
    localStorage.setItem("balanceView", "visible");
    setBalanceView("visible");
  };

  // console.log(Assets);
  if (selectedAsset)
    return (
      <WalletAssetDetail
        selectedAsset={selectedAsset}
        back={() => setSelectedAsset(null)}
      />
    );
  if (sendModal)
    return (
      <SendModal
        Assets={Assets}
        toggleSendModal={toggleSendModal}
        setSelectedAsset={setSelectedAsset}
        selectedAsset={selectedAsset}
      />
    );
  if (chainModal)
    return (
      <WalletChains
        chains={providers}
        toggleChainModal={toggleChainModal}
        // setSelectedChain={setSelectedChain}
      />
    );
  if (receiveModal)
    return (
      <ReceiveModal
        setSelectedAsset={setSelectedAsset}
        selectedAsset={selectedAsset}
        Assets={Assets}
        toggleReceiveModal={toggleReceiveModal}
      />
    );
  if (bridge) return <Bridge back={toggleBridge} />;
  if (settings) return <WalletSettings back={toggleSettings} />;
  if (buy) return <WalletBuy back={toggleBuy} />;

  return (
    <div className="WalletPop_div_cont_walletHome">
      <div className="WalletPop_div_cont_walletHome_div1">
        <div className="WalletPop_div_cont_walletHome_div1_cont1">
          <div className="WalletPop_div_cont_walletHome_div1_cont1_area1">
            <Blockies
              seed={wallet_address ? wallet_address : "0xXXXX"}
              size={9}
              scale={4}
              className="blockies_icon"
            />{" "}
            <div className="WalletPop_div_cont_walletHome_div1_cont1_area1_cont">
              <div className="WalletPop_div_cont_walletHome_div1_cont1_area1_cont_title">
                Wallet
              </div>
              <div
                className="WalletPop_div_cont_walletHome_div1_cont1_area1_cont_addr"
                onClick={copyText}
              >
                {wallet_address ? (
                  <>
                    {" "}
                    {`${wallet_address?.slice(0, 4)}...${wallet_address?.slice(
                      37,
                      42
                    )}`}
                  </>
                ) : (
                  <>0xXXXX</>
                )}{" "}
                <Copy01Icon className="WalletPop_div_cont_walletHome_div1_cont1_area1_cont_addr_icon" />
              </div>
            </div>
          </div>
          <div className="WalletPop_div_cont_walletHome_div1_cont1_area2">
            <Settings02Icon
              className="WalletPop_div_cont_walletHome_div1_cont1_area2_icon"
              onClick={toggleSettings}
            />
            <div
              className="WalletPop_div_cont_walletHome_div1_cont1_area2_div2"
              onClick={toggleChainModal}
            >
              <div className="WalletPop_div_cont_walletHome_div1_cont1_area2_div2_cont1">
                <img
                  src={selectedChain?.img || selectedChain?.image}
                  alt=""
                  className="WalletPop_div_cont_walletHome_div1_cont1_area2_div2_cont1_img"
                />
                <span className="WalletPop_div_cont_walletHome_div1_cont1_area2_div2_cont1_span">
                  {selectedChain?.network?.slice(0, 3)}
                </span>
              </div>
              <ArrowDown01Icon className="WalletPop_div_cont_walletHome_div1_cont1_area2_div2_icon" />
            </div>
          </div>
        </div>
        <div className="WalletPop_div_cont_walletHome_div1_cont2">
          <div className="WalletPop_div_cont_walletHome_div1_cont2_area1">
            {balanceView === "hidden" ? (
              "**********"
            ) : (
              <>${parseFloat(allEgochainSum || 0)?.toFixed(5)}</>
            )}
            {balanceView === "hidden" ? (
              <div onClick={viewBalance} className="hideBalDiv">
                <ViewOffSlashIcon
                  className="WalletPop_div_cont_walletHome_div1_cont2_area1_icon"
                  size={20}
                />
              </div>
            ) : (
              <div onClick={hideBalance} className="hideBalDiv">
                <ViewIcon
                  className="WalletPop_div_cont_walletHome_div1_cont2_area1_icon"
                  size={20}
                />
              </div>
            )}
          </div>
          <div className="WalletPop_div_cont_walletHome_div1_cont2_area2">
            <div
              className="WalletPop_div_cont_walletHome_div1_cont2_area2_cont1"
              onClick={toggleSendModal}
            >
              <div className="WalletPop_div_cont_walletHome_div1_cont2_area2_cont1_icon_div">
                <ArrowUp02Icon className="WalletPop_div_cont_walletHome_div1_cont2_area2_cont1_icon" />
              </div>
              Send
            </div>
            <div
              className="WalletPop_div_cont_walletHome_div1_cont2_area2_cont1"
              onClick={toggleReceiveModal}
            >
              <div className="WalletPop_div_cont_walletHome_div1_cont2_area2_cont1_icon_div">
                <ArrowDown02Icon className="WalletPop_div_cont_walletHome_div1_cont2_area2_cont1_icon" />
              </div>
              Receive
            </div>
            <div
              className="WalletPop_div_cont_walletHome_div1_cont2_area2_cont1"
              onClick={toggleBuy}
            >
              <div className="WalletPop_div_cont_walletHome_div1_cont2_area2_cont1_icon_div">
                <CreditCardIcon className="WalletPop_div_cont_walletHome_div1_cont2_area2_cont1_icon" />
              </div>
              Buy
            </div>
            <div
              className="WalletPop_div_cont_walletHome_div1_cont2_area2_cont1"
              onClick={toggleBridge}
            >
              <div className="WalletPop_div_cont_walletHome_div1_cont2_area2_cont1_icon_div">
                <Link04Icon className="WalletPop_div_cont_walletHome_div1_cont2_area2_cont1_icon" />
              </div>
              Bridge
            </div>
          </div>
        </div>
      </div>
      <div className="WalletPop_div_cont_walletHome_div2">
        <div className="WalletPop_div_cont_walletHome_div2_title">
          Assets{" "}
          <span className="WalletPop_div_cont_walletHome_div2_title_Span">
            Hide 0 balances
            <>
              <label class="switch">
                <input
                  type="checkbox"
                  onChange={toggleLowBal}
                  checked={hideLowBal}
                />
                <span class="slider"></span>
              </label>
            </>
          </span>
        </div>

        <TokenList
          hideLowBal={hideLowBal}
          selectedChain={selectedChain}
          setSelectedAsset={setSelectedAsset}
        />
        <button
          className="WalletPop_div_cont_walletHome_div2_btn"
          onClick={closePop}
        >
          Close
        </button>
      </div>
      <Toaster />
    </div>
  );
};

export default WalletHome;
