import apiInstance from "../utils/axiosInstance";
import {
  PURCHASE_PRODUCT_ROUTE,
  CHECK_PRODUCT_ALLOWANCE_ROUTE,
  SET_PRODUCT_ALLOWANCE_ROUTE,
  STAKE_PRODUCT_ROUTE,
  GET_USER_ORDERS_ROUTE,
  GET_ORDER_DELIVERY_DETAILS_ROUTE,
  ALL_PRODUCTS_ROUTE,
} from "./routes";

export const PURCHASE_PRODUCT = async ({ key, productId, qty }) => {
  try {
    const res = await apiInstance.post(PURCHASE_PRODUCT_ROUTE, {
      key,
      productId,
      qty,
    });
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
export const GET_USER_ORDERS = async () => {
  try {
    const res = await apiInstance.get(GET_USER_ORDERS_ROUTE);
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
export const GET_ORDER_DELIVERY_DETAILS = async ({ id }) => {
  try {
    const res = await apiInstance.get(
      `${GET_ORDER_DELIVERY_DETAILS_ROUTE}/${id}`
    );
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
export const STAKE_PRODUCT = async (payload) => {
  try {
    const res = await apiInstance.post(STAKE_PRODUCT_ROUTE, payload);
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
export const CHECK_ALLOWANCE = async (payload) => {
  try {
    const res = await apiInstance.post(CHECK_PRODUCT_ALLOWANCE_ROUTE, payload);
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
export const FETCH_PRODUCTS = async (payload) => {
  try {
    const res = await apiInstance.get(ALL_PRODUCTS_ROUTE, payload);
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
export const SET_ALLOWANCE = async (payload) => {
  try {
    const res = await apiInstance.post(SET_PRODUCT_ALLOWANCE_ROUTE, payload);
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
