import React, { useState } from "react";
import { ArrowLeft01Icon, InformationCircleIcon } from "hugeicons-react";
import toast, { Toaster } from "react-hot-toast";

import "./index.css";
import { useSelector, useDispatch } from "react-redux";
import { TRANSFER_TOKEN } from "../../../services/wallet";
import sendTransactionWithPrivateKey from "../../sendNativeToken";
import { decrypt } from "../../../helper/encryption";
import sendTokenWithPrivateKey from "../../sendToken";
import ClipLoader from "react-spinners/ClipLoader";
import { triggerBalanceChange } from "../../../Redux/feautures/assets/assetSlice";

const WalletSend = ({ selectedToken, back }) => {
  const dispatch = useDispatch();
  const { activeProvider: provider } = useSelector((state) => state.assets);
  // const address = "0x0E7F93dba708d2ec5d6Eb91612f873598393e252";

  const { privateKey, wallet_address: address } = useSelector(
    (state) => state.user
  );
  const { activeProvider: selectedChain } = useSelector(
    (state) => state.assets
  );
  const [confirmSend, setConfirmSend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [payload, setPayload] = useState({
    receiver: "",
    amount: "",
  });

  const handleOnchange = (e) => {
    const { id, value } = e.target;
    setPayload({ ...payload, [id]: value });
  };
  const ToggleConfirmSend = () => {
    setConfirmSend(!confirmSend);
  };
  const processSend = async () => {
    //console.log("processing...");
  };

  // use async calls for api calls
  const transferFunction = async () => {
    if (selectedToken?.tokenB == "0x0000000000000000000000000000000000000000") {
      //use native token function
      setLoading(true);
      const res = await sendTransactionWithPrivateKey({
        amount: payload.amount,
        to: payload.receiver,
        privateKey: decrypt(privateKey),
        rpcUrl: provider.rpc,
        // rpcUrl: "https://mainnet.egochain.org",
      });
      setLoading(false);
      //console.log(res, "abiiiii");

      if (!res.success) {
        // setError(true);
        toast.error(
          <div className="toast_success_div">
            <div className="toast_error_div_title">Error!!</div>
            <div className="toast_success_div_para">Sending failed!!</div>
          </div>,
          {
            duration: 5000,
            className: "toast_error",
          }
        );
        return;
      }
      // setSuccess(true);

      toast.success(
        <div className="toast_success_div">
          <div className="toast_success_div_title">Success!</div>
          <div className="toast_success_div_para">Sending success!!</div>
        </div>,
        {
          duration: 5000,
          className: "toast_success",
        }
      );
      back();
      dispatch(triggerBalanceChange());
      return;
    }

    setLoading(true);
    const res = await sendTokenWithPrivateKey({
      amount: payload.amount,
      to: payload.receiver,
      privateKey: decrypt(privateKey),
      rpcUrl: "https://mainnet.egochain.org",
      tokenAddress: selectedToken?.tokenB,
    });

    setLoading(false);

    if (!res.success) {
      toast.error(
        <div className="toast_success_div">
          <div className="toast_error_div_title">Error!!</div>
          <div className="toast_success_div_para">Failed to send token</div>
        </div>,
        {
          duration: 5000,
          className: "toast_error",
        }
      );
      return;
    }
    toast.success(
      <div className="toast_success_div">
        <div className="toast_success_div_title">Success!</div>
        <div className="toast_success_div_para">{`Token send successful ${res.hash}`}</div>
      </div>,
      {
        duration: 5000,
        className: "toast_success",
      }
    );
    dispatch(triggerBalanceChange());
    back();
  };

  return (
    <>
      {/* Success Message */}
      {success && (
        <div className="Modal_parent_div">
          <div className="Modal_child_div Success_div">
            <p>Successful.</p>
            <button onClick={() => setSuccess(false)} className="Close_btn">
              Close
            </button>
          </div>
        </div>
      )}
      {/* Error Message */}
      {error && (
        <div className="Modal_parent_div">
          <div className="Modal_child_div Error_div">
            <p>Confirmation failed. Please try again.</p>

            <button onClick={() => setError(false)} className="Close_btn">
              Close
            </button>
          </div>
        </div>
      )}{" "}
      {confirmSend === false ? (
        <div className="WalletDetailPageDiv">
          <div className="WalletDetailPageDiv_header">
            <div className="WalletDetailPageDiv_header_back" onClick={back}>
              <ArrowLeft01Icon size={24} />
            </div>

            <div className="WalletDetailPageDiv_header_info">
              <InformationCircleIcon size={20} />
            </div>
          </div>
          <div className="sendDetailBody">
            <div className="sendDetailBody_cont1">
              <div className="sendDetailBody_cont1_title">Address</div>
              <input
                type="text"
                onChange={handleOnchange}
                id="receiver"
                placeholder="0xxx"
                className="sendDetailBody_cont1_input"
              />
            </div>
            <div className="sendDetailBody_cont1">
              <div className="sendDetailBody_cont1_title">Amount</div>
              <div className="sendDetailBody_cont1_div2">
                <input
                  type="text"
                  onChange={handleOnchange}
                  id="amount"
                  placeholder={`${
                    selectedToken?.tokenType === "native"
                      ? selectedChain?.baseToken
                      : selectedToken?.tokenBName
                  } Amount`}
                  className="sendDetailBody_cont1_input2"
                />
                <button className="sendDetailBody_cont1_div2_max">Max</button>
              </div>
              <span className="sendDetailBody_cont1_span">
                {/* ≈${parseFloat(50.56 * 0.545)?.toFixed(2)} */}
              </span>
            </div>
            <button
              className="sendDetailBody_btn"
              id=""
              onClick={() => {
                if (payload.amount === "" || payload.receiver === "") {
                  toast.error("Some fields empty!!!");

                  return;
                }
                ToggleConfirmSend();
              }}
            >
              Next
            </button>
          </div>
        </div>
      ) : (
        <div className="WalletDetailPageDiv">
          <div className="WalletDetailPageDiv_header">
            <div
              className="WalletDetailPageDiv_header_back"
              onClick={() => setConfirmSend(false)}
            >
              <ArrowLeft01Icon size={24} />
            </div>

            <div className="WalletDetailPageDiv_header_info">
              <InformationCircleIcon size={20} />
            </div>
          </div>
          <div className="confirmTransferBody">
            <div className="confirmTransferBody_div1">
              <div className="confirmTransferBody_div1_cont1">
                {payload.amount} {selectedToken.tokenSymbol}
              </div>{" "}
              <div className="confirmTransferBody_div1_cont2">
                ≈${selectedToken?.balance}
              </div>{" "}
            </div>
            <div className="confirmTransferBody_div2">
              <div className="confirmTransferBody_div2_cont1">
                <div className="confirmTransferBody_div2_cont1_div">
                  <div className="confirmTransferBody_div2_cont1_div_title">
                    Asset
                  </div>
                  <div className="confirmTransferBody_div2_cont1_div_para">
                    {selectedToken.name} {`(${selectedToken.tokenSymbol})`}
                  </div>
                </div>
                <div className="confirmTransferBody_div2_cont1_div">
                  <div className="confirmTransferBody_div2_cont1_div_title">
                    Wallet
                  </div>
                  <div className="confirmTransferBody_div2_cont1_div_para">
                    {`${address.slice(0, 4)}...${address.slice(37, 42)}`}
                  </div>
                </div>
                <div className="confirmTransferBody_div2_cont1_div">
                  <div className="confirmTransferBody_div2_cont1_div_title">
                    To
                  </div>
                  <div className="confirmTransferBody_div2_cont1_div_para">
                    {`${payload?.receiver.slice(
                      0,
                      4
                    )}...${payload.receiver.slice(37, 42)}`}
                  </div>
                </div>
              </div>
              <div className="confirmTransferBody_div2_cont1">
                <div className="confirmTransferBody_div2_cont1_div">
                  <div className="confirmTransferBody_div2_cont1_div_title">
                    Est Network fee
                  </div>
                  <div className="confirmTransferBody_div2_cont1_div_para">
                    0.001 ${provider?.baseToken}
                  </div>
                </div>
              </div>
            </div>
            <button
              disabled={loading}
              onClick={transferFunction}
              className="sendDetailBody_btn"
            >
              {loading ? (
                <>
                  Loading... <ClipLoader color="#ffffff" size={18} />
                </>
              ) : (
                "Confirm"
              )}
            </button>
          </div>
          <Toaster />
        </div>
      )}
    </>
  );
};

export default WalletSend;
