import React from "react";
import "./index.css";
import Blockies from "react-blockies";
import {
  Copy01Icon,
  Settings02Icon,
  ArrowUp02Icon,
  ArrowDown02Icon,
  InvoiceIcon,
  CreditCardIcon,
  Link04Icon,
} from "hugeicons-react";
import { useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";

const TransactionModal = ({
  transactionType,
  closePop,
  transact,
  loading,
  tokenName = "JVP",
}) => {
  const { wallet_address } = useSelector((state) => state.user);
  //console.log("====================================");
  //console.log(loading);
  //console.log("====================================");
  return (
    <div className="transactionModalDiv">
      <div className="WalletPop_div_cont_walletHome_div1_cont1">
        <div className="WalletPop_div_cont_walletHome_div1_cont1_area1">
          <Blockies
            seed={wallet_address ? wallet_address : "0xXXXX"}
            size={9}
            scale={4}
            className="blockies_icon"
          />{" "}
          <div className="WalletPop_div_cont_walletHome_div1_cont1_area1_cont">
            <div className="WalletPop_div_cont_walletHome_div1_cont1_area1_cont_title">
              Wallet
            </div>
            <div className="WalletPop_div_cont_walletHome_div1_cont1_area1_cont_addr">
              {wallet_address ? (
                <>
                  {" "}
                  {`${wallet_address?.slice(0, 4)}...${wallet_address?.slice(
                    37,
                    42
                  )}`}
                </>
              ) : (
                <>0xXXXX</>
              )}{" "}
              <Copy01Icon className="WalletPop_div_cont_walletHome_div1_cont1_area1_cont_addr_icon" />
            </div>
          </div>
        </div>
        <div className="WalletPop_div_cont_walletHome_div1_cont1_area2">
          <Settings02Icon className="WalletPop_div_cont_walletHome_div1_cont1_area2_icon" />
        </div>
      </div>
      <div className="transactionModalBody">
        <div className="transactionModalBody_title">
          {transactionType == "approve"
            ? "Spending cap request"
            : " Transaction request"}
        </div>
        <div className="transactionModalBody_area">
          <div className="transactionModalBody_area_1">
            {transactionType == "approve" && (
              <div className="transactionModalBody_area_spender">
                <div className="transactionModalBody_area_spender_cont1">
                  Spender
                </div>
                <div className="transactionModalBody_area_spender_cont2">
                  <div className="transactionModalBody_area_spender_cont2_div">
                    0xD5a...2F2d
                  </div>
                </div>
              </div>
            )}
            <div className="transactionModalBody_area_cont1">
              <div className="transactionModalBody_area_cont1_div1">
                Request from
              </div>
              <div className="transactionModalBody_area_cont1_div2">
                dhive.com
              </div>
            </div>
            {transactionType == "approve" ? (
              <div className="transactionModalBody_area_cont1">
                <div className="transactionModalBody_area_cont1_div1">
                  Signin with
                </div>
                <div className="transactionModalBody_area_cont1_div2">
                  <Blockies
                    seed={wallet_address ? wallet_address : "0xXXXX"}
                    size={4}
                    scale={5}
                    className="blockies_icon"
                  />{" "}
                  {wallet_address ? (
                    <>
                      {" "}
                      {`${wallet_address?.slice(
                        0,
                        4
                      )}...${wallet_address?.slice(37, 42)}`}
                    </>
                  ) : (
                    <>0xXXXX</>
                  )}{" "}
                </div>
              </div>
            ) : (
              <div className="transactionModalBody_area_cont1">
                <div className="transactionModalBody_area_cont1_div1">
                  Interacting with
                </div>
                <div className="transactionModalBody_area_cont1_div2">
                  <div className="transactionModalBody_area_spender_cont2_div">
                    0xD5a...2F2d
                  </div>
                </div>
              </div>
            )}
          </div>
          {transactionType == "approve" && (
            <div className="transactionModalBody_area_2">
              <div className="transactionModalBody_area_2_area_cont1">
                <div className="transactionModalBody_area_2_area_cont1_title">
                  Account Balance
                </div>
                <div className="transactionModalBody_area_2_area_cont1_title">
                  0 {tokenName}
                </div>
              </div>
              <div className="transactionModalBody_area_2_area_cont1">
                <div className="transactionModalBody_area_2_area_cont1_title">
                  Spending cap
                </div>
                <div className="transactionModalBody_area_2_area_cont1_title">
                  Unlimited {tokenName}
                </div>
              </div>
            </div>
          )}
          <div className="transactionModalBody_area_3">
            <div className="transactionModalBody_area_3_cont1">
              <div className="transactionModalBody_area_3_cont1_title">
                Network fee
              </div>
              <div className="transactionModalBody_area_3_cont1_value">
                0.01 EGAX $0.04
              </div>
            </div>
            <div className="transactionModalBody_area_3_cont1">
              <div className="transactionModalBody_area_3_cont1_title">
                Speed
              </div>
              <div className="transactionModalBody_area_3_cont1_value">
                {" <15sec "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="transactionModalBody_btns">
        <button className="transactionModalBody_btns_btn1" onClick={closePop}>
          Cancel
        </button>
        <button
          className="transactionModalBody_btns_btn2"
          onClick={transact}
          disabled={loading}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading ? (
            <>
              {" "}
              <ClipLoader color="#fff" size={17} /> Confirming
            </>
          ) : (
            "Confirm"
          )}
        </button>
      </div>
    </div>
  );
};

export default TransactionModal;
