import React, { useState } from "react";
import "./index.css";
import ImporttWalletPage3 from "./ImportWalletPages/ImportWalletPage3/ImportWalletPage3";
import ImportWalletPage2 from "./ImportWalletPages/ImportWalletPage2/ImportWalletPage2";
import { useNavigate } from "react-router-dom";

const ImportWallet = () => {
  const navigate = useNavigate();
  const [nextStep, setNextStep] = useState(1);

  return (
    <div className="onchainWalletDiv">
      {/* {nextStep === 1 && <HomeContainer nextStep={() => setNextStep(2)} />} */}
      {nextStep === 1 && (
        <ImporttWalletPage3
          nextStep={() => setNextStep(2)}
          prevStep={() => navigate("/wallet")}
        />
      )}
      {nextStep === 2 && (
        <ImportWalletPage2
          nextStep={() => setNextStep(3)}
          prevStep={() => setNextStep(2)}
        />
      )}

      {/* {nextStep === 3 && (
        <CreateWalletPage2
          nextStep={() => setNextStep(4)}
          prevStep={() => setNextStep(2)}
        />
      )}
      {nextStep === 4 && <CreateWalletPage3 prevStep={() => setNextStep(3)} />} */}
    </div>
  );
};

export default ImportWallet;
