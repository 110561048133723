import React, { useEffect, useState, useRef } from "react";
import "./index.css";
import { Link } from "react-router-dom";
import Blockies from "react-blockies";
import WalletPop from "../../OnchainWallet/WalletPop/WalletPop";
import { CheckmarkBadge01Icon, GiftIcon } from "hugeicons-react";
import { useDispatch, useSelector } from "react-redux";
import { NATIVE_TOKEN_BALANCE } from "../../services/wallet";
import HowItWorks from "../LegalPolicy/HowItWorks";
import { Search01Icon, Menu01Icon } from "hugeicons-react";
import { ArrowDown01Icon } from "hugeicons-react";
import { ElectricPlugsIcon } from "hugeicons-react";
import { useNavigate } from "react-router-dom";
import { updateBusinessChain } from "../../Redux/feautures/businesses/businessSlice";
import { app } from "../../utils/config";
import ClipLoader from "react-spinners/ClipLoader";
import { categories } from "../../BusinessCreation/RWABusiness/ListProduct";

const ExchangeHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { products } = useSelector((state) => state.products);
  const { wallet_address } = useSelector((state) => state.user);
  const { business_info } = useSelector((state) => state.user);
  const { businesses, businessChain } = useSelector(
    (state) => state.businesses
  );
  const latestBuy = useSelector((state) => state.trades.latestBuy);
  const latestSell = useSelector((state) => state.trades.latestSell);
  const latestTrade = useSelector((state) => state.trades.latestTrade); // Mobile view
  const { nativeToken, Assets, loading, activeProvider } = useSelector(
    (state) => state.assets
  );
  const [balanceView, setBalanceView] = useState(null);
  const [howItworks, setHowItworks] = useState(false);

  const [walletOpen, setWalletOpen] = useState(false);
  const [categoryPop, setCategoryPop] = useState(false);
  const [inputPop, setInputPop] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedChain, setSelectedChain] = useState("all");
  const [chainsPopDiv, setChainsPopDiv] = useState(false);
  const [searchArr, setSearchArr] = useState([]);
  const inputRef = useRef(null);
  const popRef = useRef(null);

  const [classNameShake, setClassNameShake] = useState(
    "ExchangeHeader_div_area_1_btns_buy"
  );
  const [classNameShakeSell, setClassNameShakeSell] = useState(
    "ExchangeHeader_div_area_1_btns_sell"
  );
  // // console.log(kfkfkf);

  const [latestBuyTrade, setLatestBuyTrade] = useState(null);
  const [latestSellTrade, setLatestSellTrade] = useState(null);
  const [latestTradeData, setLatestTradeData] = useState(null);

  // Effect for BUY trades
  useEffect(() => {
    if (latestBuy) {
      setLatestBuyTrade(latestBuy); // Store latest BUY trade
      setClassNameShake("ExchangeHeader_div_area_1_btns_buy_shake");

      // Reset only the BUY animation after 500ms
      const timeout = setTimeout(() => {
        setClassNameShake("ExchangeHeader_div_area_1_btns_buy");
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [latestBuy]); // Runs only when latestBuy updates

  // Effect for SELL trades
  useEffect(() => {
    if (latestSell) {
      setLatestSellTrade(latestSell); // Store latest SELL trade
      setClassNameShakeSell("ExchangeHeader_div_area_1_btns_sell_shake");

      // Reset only the SELL animation after 500ms
      const timeout = setTimeout(() => {
        setClassNameShakeSell("ExchangeHeader_div_area_1_btns_sell");
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [latestSell]); // Runs only when latestSell updates

  useEffect(() => {
    if (!latestTrade) return;

    setLatestTradeData(latestTrade); // Store latest trade for mobile

    if (latestTrade.swapType === "BUY") {
      setClassNameShake("ExchangeHeader_div_area_1_btns_buy_shake");
    } else if (latestTrade.swapType === "SELL") {
      setClassNameShakeSell("ExchangeHeader_div_area_1_btns_sell_shake");
    }

    // Reset animation classes after 500ms
    const timeout = setTimeout(() => {
      setClassNameShake("");
      setClassNameShakeSell("");
    }, 500);

    return () => clearTimeout(timeout);
  }, [latestTrade]);

  const toggleWalletOpen = () => {
    setWalletOpen(!walletOpen);
  };
  const toggleHowItworks = () => {
    setHowItworks(!howItworks);
  };

  useEffect(() => {
    const balanceView = localStorage.getItem("balanceView");
    setBalanceView(balanceView);
    // console.log(balanceView);
  }, [localStorage.getItem("balanceView")]);

  const url = window.location.pathname;
  const path = url.split("/");

  const toggleCategoryPop = () => {
    setCategoryPop((prev) => !prev);
  };
  // Handle input changes
  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
    setInputPop(e.target.value.trim() !== ""); // Open only if there's input
  };

  // Search function
  function searchProducts(query, products, limit = 5) {
    // Convert query to lowercase for case-insensitive search
    query = query.toLowerCase();

    // Filter products based on the query
    const filteredProducts = products?.filter((product) => {
      // Check if any field contains the query
      for (const key in product) {
        if (
          typeof product[key] === "string" &&
          product[key].toLowerCase().includes(query)
        ) {
          return true; // Include this product in the results
        }
      }
      return false; // Exclude this product
    });

    // Limit the results to the specified limit
    return filteredProducts?.slice(0, limit);
  }

  // Handle click outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target) &&
        popRef.current &&
        !popRef.current.contains(event.target)
      ) {
        setInputPop(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const data = searchProducts(searchValue, products);
    // console.log(data, "amamr");
    setSearchArr(data);
  }, [searchValue]);
  console.log("====================================");
  console.log(activeProvider);
  console.log("====================================");

  useEffect(() => {
    dispatch(updateBusinessChain(selectedChain));
  }, [selectedChain]);

  console.log("====================================");
  console.log(businessChain);
  console.log("====================================");
  console.log(path[1], "path");

  return (
    <div className="ExchangeHeader_div">
      {path[1] === "market" && <div className="mobile_padding"></div>}

      {path[1] === "market" ? null : (
        <div className="mobileLatestTradesDiv">
          {latestTradeData && (
            <button
              className={
                latestTradeData.swapType === "BUY"
                  ? `ExchangeHeader_div_area_1_btns_buy ${classNameShake}`
                  : `ExchangeHeader_div_area_1_btns_sell ${classNameShakeSell}`
              }
            >
              <div className="ExchangeHeader_div_area_1_btns_buy_div1">
                <Blockies
                  seed={latestTradeData?.user || "0xXXXX"}
                  size={5}
                  scale={4}
                  className="blockies_icon rm_head_clss"
                />
                {latestTradeData?.user ? (
                  <>
                    {`${latestTradeData?.user?.slice(
                      0,
                      4
                    )}...${latestTrade?.user?.slice(38, 42)}`}
                  </>
                ) : (
                  <>0xXXXX</>
                )}
              </div>
              {latestTradeData.swapType === "BUY" ? "bought" : "sold"}{" "}
              <span className="rm_head_clss" style={{ marginLeft: "5px" }}>
                {parseFloat(
                  latestTradeData.swapType === "BUY"
                    ? latestTradeData.amountIn || 0
                    : latestTradeData.amountOut || 0
                ).toFixed(2)}{" "}
                EGAX of{" "}
              </span>
              <div className="ExchangeHeader_div_area_1_btns_buy_div2">
                {latestTradeData?.ticker?.split("-")[1] || "N/A"}
              </div>
            </button>
          )}
        </div>
      )}

      <div className="container_fluid">
        <div className="ExchangeHeader_div_area">
          <div className="ExchangeHeader_div_area_1">
            <div className="ExchangeHeader_div_area_1_cont1">
              <Link
                to={path[1] === "market" ? "/market" : "/"}
                className="ExchangeHeader_div_area_1_div1"
              >
                <img
                  src="/White_Dhive_Icon.svg"
                  alt=""
                  className="ExchangeHeader_div_area_1_div1_img_mobi"
                />
                <img
                  src="/White Dhive.svg"
                  alt=""
                  className="ExchangeHeader_div_area_1_div1_img"
                />
              </Link>
              <div className="ExchangeHeader_div_area_1_cont2">
                <div
                  className={
                    path[1] === "market"
                      ? "ExchangeHeader_div_area_1_cont2_div1"
                      : "ExchangeHeader_div_area_1_cont2_div2"
                  }
                  onClick={() => {
                    navigate("/market");
                    localStorage.setItem("welcomeMarket", "true");
                  }}
                >
                  rNfts Market
                </div>
                <div
                  className={
                    path[1] === "market"
                      ? "ExchangeHeader_div_area_1_cont2_div2"
                      : "ExchangeHeader_div_area_1_cont2_div1"
                  }
                  onClick={() => {
                    navigate("/");
                    localStorage.setItem("welcomeExchange", "true");
                  }}
                >
                  Exchange
                </div>
              </div>
            </div>

            {/* ===== */}
            {/* =====d */}
            {/* =====d */}
            {/* =====d */}
            {path[1] === "market" ? null : (
              <div className="ExchangeHeader_div_area_1_btns">
                <button
                  className={`ExchangeHeader_div_area_1_btns_buy ${classNameShake}`}
                >
                  <div className="ExchangeHeader_div_area_1_btns_buy_div1">
                    <Blockies
                      seed={latestBuyTrade?.user || "0xXXXX"}
                      size={5}
                      scale={4}
                      className="blockies_icon rm_head_clss"
                    />
                    {latestBuyTrade?.user ? (
                      <>
                        {`${latestBuyTrade?.user?.slice(
                          0,
                          2
                        )}...${latestBuyTrade?.user?.slice(40, 42)}`}
                      </>
                    ) : (
                      <>0xXXXX</>
                    )}
                  </div>
                  bought{" "}
                  <span className="rm_head_clss" style={{ marginLeft: "5px" }}>
                    {parseFloat(latestBuyTrade?.amountIn || 0)?.toFixed(2)} EGAX
                    of{" "}
                  </span>
                  <div className="ExchangeHeader_div_area_1_btns_buy_div2">
                    {latestBuyTrade?.ticker?.split("-")[1] || "N/A"}
                  </div>
                </button>

                <button
                  className={`ExchangeHeader_div_area_1_btns_sell ${classNameShakeSell}`}
                >
                  <div className="ExchangeHeader_div_area_1_btns_buy_div1">
                    <Blockies
                      seed={latestSellTrade?.user || "0xXXXX"}
                      size={5}
                      scale={4}
                      className="blockies_icon rm_head_clss"
                    />
                    {latestSellTrade?.user ? (
                      <>
                        {`${latestSellTrade?.user?.slice(
                          0,
                          2
                        )}...${latestSellTrade?.user?.slice(40, 42)}`}
                      </>
                    ) : (
                      <>0xXXXX</>
                    )}
                  </div>
                  sold{" "}
                  <span className="rm_head_clss" style={{ marginLeft: "5px" }}>
                    {parseFloat(latestSellTrade?.amountOut || 0)?.toFixed(2)}{" "}
                    EGAX of{" "}
                  </span>
                  <div className="ExchangeHeader_div_area_1_btns_buy_div2">
                    {latestSellTrade?.ticker?.split("-")[1] || "N/A"}
                  </div>
                </button>
              </div>
            )}
          </div>

          {/* ===== */}
          {/* =====d */}
          {/* =====d */}
          {/* =====d */}
          <div className="ExchangeHeader_div_area_1_cont2_mobi">
            <div
              className={
                path[1] === "market"
                  ? "ExchangeHeader_div_area_1_cont2_div1"
                  : "ExchangeHeader_div_area_1_cont2_div2"
              }
              onClick={() => {
                navigate("/market");
                localStorage.setItem("welcomeMarket", "true");
              }}
            >
              rNfts Market
            </div>
            <div
              className={
                path[1] === "market"
                  ? "ExchangeHeader_div_area_1_cont2_div2"
                  : "ExchangeHeader_div_area_1_cont2_div1"
              }
              onClick={() => {
                navigate("/");
                localStorage.setItem("welcomeExchange", "true");
              }}
            >
              Exchange
            </div>
          </div>
          {path[1] === "market" && (
            <div className="exchange_sort_div">
              <div className="exchange_sort_div1" onClick={toggleCategoryPop}>
                <Menu01Icon className="exchange_sort_div1_icon" /> Categories
                {categoryPop && (
                  <div className="categoryPopDiv">
                    {" "}
                    {categories.map((data, index) => {
                      return (
                        <Link
                          to={`/market/category/all/products/${data.label}`}
                          className="categoryPopDiv_cont"
                        >
                          <ElectricPlugsIcon className="categoryPopDiv_cont_icon" />
                          <div className="categoryPopDiv_cont_txt">
                            {data.value}
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                )}
              </div>

              <div className="ExchangeHeader_search_div" ref={inputRef}>
                <Search01Icon
                  className="ExchangeHeader_search_div_search_icon"
                  // onClick={() => navigate("/market/search/results")}
                />
                <input
                  type="search"
                  name="search"
                  id="search"
                  placeholder="Search..."
                  className="ExchangeHeader_search_input"
                  value={searchValue}
                  onChange={handleInputChange}
                />
                {inputPop && (
                  <div className="inputPopDiv" ref={popRef}>
                    {searchArr?.length >= 1 ? (
                      searchArr?.map((s_data, index) => {
                        return (
                          <div
                            key={index}
                            className="inputPopDiv_cont"
                            onClick={() =>
                              navigate(
                                `/market/detail/${s_data?.id}/${s_data?.category}`
                              )
                            }
                          >
                            {s_data?.name}
                          </div>
                        );
                      })
                    ) : (
                      <> No product Found!</>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="ExchangeHeader_div_area_2">
            {path[1] === "market" && (
              <div className="mobile_sort_div">
                <Search01Icon
                  className="mobile_sort_div_icon"
                  // onClick={() => navigate("/market/search/results")}
                />
              </div>
            )}
            {path[1] === "market" ? null : (
              <Link to="/tradeevent" className="eventMobileLink">
                <span className="span_new">New</span>
                <GiftIcon className="ExchangeHeader_div_area_2_links_div_link1_icon" />
              </Link>
            )}
            <div className="ExchangeHeader_div_area_2_links_div">
              {path[1] === "market" ? null : (
                <>
                  <Link
                    to="/tradeevent"
                    className="ExchangeHeader_div_area_2_links_div_link1"
                  >
                    Event{" "}
                    <div>
                      <GiftIcon className="ExchangeHeader_div_area_2_links_div_link1_icon" />
                      <span className="span_new">New</span>
                    </div>
                  </Link>
                  <div
                    className="ExchangeHeader_div_area_2_links_div_link1"
                    onClick={toggleHowItworks}
                    style={{ cursor: "pointer" }}
                  >
                    How it works
                  </div>
                </>
              )}
              {path[1] === "market" && (
                <Link
                  to="/market/myOrders"
                  className="ExchangeHeader_div_area_2_links_div_link1"
                >
                  My Orders
                </Link>
              )}

              {business_info ? (
                <Link
                  to="/business"
                  className="ExchangeHeader_div_area_2_links_div_btn_link"
                >
                  <button className="ExchangeHeader_div_area_2_links_div_btn">
                    Manage
                  </button>
                </Link>
              ) : (
                <Link
                  to="/business/create"
                  className="ExchangeHeader_div_area_2_links_div_btn_link"
                >
                  <button className="ExchangeHeader_div_area_2_links_div_btn">
                    Tokenize
                  </button>
                </Link>
              )}
            </div>

            {path[1] === "" ? (
              <div className="headerToggleChainDiv">
                <div
                  className="headerToggleChainDiv_control"
                  onClick={() => setChainsPopDiv((prev) => !prev)}
                >
                  {selectedChain === "all" && (
                    <>
                      {" "}
                      <img
                        src="/img/dhiveCoinIcon.png"
                        alt=""
                        className="headerToggleChainDiv_control_img"
                      />
                      <img
                        src="/img/egax_logo.png"
                        alt=""
                        className="headerToggleChainDiv_control_img2"
                      />
                      <img
                        src="/img/baseLogo.png"
                        alt=""
                        className="headerToggleChainDiv_control_img2"
                      />
                    </>
                  )}
                  {selectedChain === "BASE" && (
                    <>
                      <img
                        src="/img/baseLogo.png"
                        alt=""
                        className="headerToggleChainDiv_control_img"
                      />
                      <p
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                          marginLeft: "2px",
                        }}
                        className="headerToggleChainDiv_contro_p"
                      >
                        Base
                      </p>
                    </>
                  )}
                  {selectedChain === "DHIVE" && (
                    <>
                      <img
                        src="/img/dhiveCoinIcon.png"
                        alt=""
                        className="headerToggleChainDiv_control_img"
                      />
                      <p
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                          marginLeft: "2px",
                        }}
                        className="headerToggleChainDiv_contro_p"
                      >
                        Dhiv
                      </p>
                    </>
                  )}
                  {selectedChain === "EGOCHAIN" && (
                    <>
                      <img
                        src="/img/egax_logo.png"
                        alt=""
                        className="headerToggleChainDiv_control_img"
                      />
                      <p
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                          marginLeft: "2px",
                        }}
                        className="headerToggleChainDiv_contro_p"
                      >
                        Ego
                      </p>
                    </>
                  )}

                  <ArrowDown01Icon className="headerToggleChainDiv_control_icon" />
                </div>
                {chainsPopDiv && (
                  <div className="headerToggleChainDiv_dropDown_div">
                    <div
                      className="headerToggleChainDiv_dropDown_div_cont1"
                      onClick={() => {
                        setSelectedChain("all");
                        setChainsPopDiv(false);
                      }}
                    >
                      <div className="headerToggleChainDiv_dropDown_div_cont1_div1">
                        {" "}
                        <img
                          src="/img/dhiveCoinIcon.png"
                          alt=""
                          className="headerToggleChainDiv_control_img"
                        />
                        <img
                          src="/img/egax_logo.png"
                          alt=""
                          className="headerToggleChainDiv_control_img2"
                        />
                        <img
                          src="/img/baseLogo.png"
                          alt=""
                          className="headerToggleChainDiv_control_img2"
                        />
                      </div>
                      <div className="headerToggleChainDiv_dropDown_div_cont1_txt">
                        All
                      </div>
                    </div>
                    <div
                      className="headerToggleChainDiv_dropDown_div_cont1"
                      onClick={() => {
                        setSelectedChain("DHIVE");
                        setChainsPopDiv(false);
                      }}
                    >
                      <div className="headerToggleChainDiv_dropDown_div_cont1_div1">
                        {" "}
                        <img
                          src="/img/dhiveCoinIcon.png"
                          alt=""
                          className="headerToggleChainDiv_control_img"
                        />
                      </div>
                      <div className="headerToggleChainDiv_dropDown_div_cont1_txt">
                        Dhive
                      </div>
                    </div>
                    <div
                      className="headerToggleChainDiv_dropDown_div_cont1"
                      onClick={() => {
                        setSelectedChain("EGOCHAIN");
                        setChainsPopDiv(false);
                      }}
                    >
                      <div className="headerToggleChainDiv_dropDown_div_cont1_div1">
                        <img
                          src="/img/egax_logo.png"
                          alt=""
                          className="headerToggleChainDiv_control_img"
                        />
                      </div>
                      <div className="headerToggleChainDiv_dropDown_div_cont1_txt">
                        Egochain
                      </div>
                    </div>
                    <div
                      className="headerToggleChainDiv_dropDown_div_cont1"
                      onClick={() => {
                        setSelectedChain("BASE");
                        setChainsPopDiv(false);
                      }}
                    >
                      <div className="headerToggleChainDiv_dropDown_div_cont1_div1">
                        <img
                          src="/img/baseLogo.png"
                          alt=""
                          className="headerToggleChainDiv_control_img"
                        />
                      </div>
                      <div className="headerToggleChainDiv_dropDown_div_cont1_txt">
                        Base
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : null}

            {wallet_address != null && (
              <div
                className="ExchangeHeader_div_area_2_address_div"
                onClick={toggleWalletOpen}
              >
                <div className="ExchangeHeader_div_area_2_address_div_cont2">
                  <img
                    src={
                      activeProvider
                        ? activeProvider?.img || activeProvider?.image
                        : ""
                    }
                    alt=""
                    className="address_header_img"
                  />
                  <span className="address_header_span">
                    {wallet_address ? (
                      <>
                        {" "}
                        {`${wallet_address?.slice(
                          0,
                          4
                        )}...${wallet_address?.slice(37, 42)}`}
                      </>
                    ) : (
                      <>0xXXXX</>
                    )}
                  </span>
                </div>
              </div>
            )}
            {wallet_address == null && (
              <Link to="/wallet">
                <button className="ExchangeHeader_div_area_2_btn">
                  Create Wallet
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
      {walletOpen && <WalletPop closePop={toggleWalletOpen} />}
      {howItworks && <HowItWorks close={toggleHowItworks} />}
    </div>
  );
};

export default ExchangeHeader;
