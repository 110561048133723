import React, { useState, useEffect } from "react";
import { Camera02Icon, ArrowLeft01Icon, Cancel01Icon } from "hugeicons-react";
import html2canvas from "html2canvas";
import ReactTimeAgo from "react-time-ago";
import { GET_ORDER_DELIVERY_DETAILS } from "../../../services/product";

import { UPDATE_ORDER_STATUS } from "../../../services/business";
import CustomLoader from "../../../Components/CustomLoader/CustomLoader";

const RwaOrderDetails = ({ back, payload, business_info, setPayload }) => {
  const [deliveryDetails, setDeliveryDetails] = useState(null);
  const [deliveryDetailsLoading, setDeliveryDetailsLoading] = useState(false);

  const fetchDeliveryDetails = async () => {
    setDeliveryDetailsLoading(true);
    const res = await GET_ORDER_DELIVERY_DETAILS({ id: payload?.id });
    // console.log(res);
    if (res?.status == "success") {
      setDeliveryDetailsLoading(false);
      setDeliveryDetails(res?.data?.delivery);
      return;
    }
    setDeliveryDetailsLoading(false);
  };

  useEffect(() => {
    fetchDeliveryDetails();
  }, [payload]);

  const updateOrderStatus = async () => {
    const res = await UPDATE_ORDER_STATUS(
      payload?.productId,
      business_info?.businesses?.businessID,
      payload?.order_id,
      "DELIVERED"
    );
    // console.log(res);
    if (res.success === true) {
      setPayload(res?.data?.order);
    }
  };
  // // console.log(payload, "payload");
  // // console.log(deliveryDetails, "deliveryDetails");
  // // console.log(business_info, "business_info");
  return (
    <div className="orderReceiptDiv">
      <div className="orderReceiptDiv_back_div" onClick={back}></div>
      <div className="orderReceiptDiv_cont">
        <div className="orderReceiptDiv_cont_header">
          <div className="orderReceiptDiv_cont_header_title">Order details</div>
          <Cancel01Icon
            className="orderReceiptDiv_cont_header_icon"
            onClick={back}
          />
        </div>
        <div className="orderReceiptDiv_cont_body" id="capture">
          <div className="orderReceiptDiv_cont_body_cont1">
            <div className="orderReceiptDiv_cont_body_cont1_body">
              <div className="orderReceiptDiv_cont_body_cont1_body_div1">
                <img
                  src={payload?.Get_product_image}
                  alt=""
                  className="tableBody_row_data_product_div_img"
                />
                <div className="orderReceiptDiv_cont_body_cont1_body_div1_area">
                  <div className="tableBody_row_data_product_div_cont_1">
                    {payload?.item_name}
                  </div>
                  <div className="tableBody_row_data_product_div_cont_2">
                    <span className="tableBody_row_data_product_div_cont_2_span">
                      {" "}
                      Business:
                    </span>{" "}
                    {business_info?.businesses?.businessName}
                  </div>
                  <div className="tableBody_row_data_product_div_cont_2">
                    <span className="tableBody_row_data_product_div_cont_2_span">
                      {" "}
                      Price:
                    </span>{" "}
                    <div className="tableBody_row_data_product_div_cont_2_amount">
                      {parseFloat(payload?.amount)?.toFixed(2)}USDC
                      <span className="tableBody_row_data_product_price_span">
                        =$ {parseFloat(payload?.amount)?.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/* ==== */}
              {/* ==== */}
              {/* ==== */}
              {/* ==== */}
              {/* ==== */}
              <div className="orderReceiptDiv_cont_body_cont1_body_div2">
                <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1">
                  <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_title">
                    Quantity
                  </div>
                  <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_value">
                    {payload?.qty}
                  </div>
                </div>
                <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1">
                  <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_title">
                    Total
                  </div>
                  <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_value">
                    <div className="tableBody_row_data_product_price_div1">
                      {parseFloat(
                        parseFloat(payload?.qty) * parseFloat(payload?.amount)
                      )?.toFixed(2)}{" "}
                      USDC
                    </div>
                    <span className="tableBody_row_data_product_price_span">
                      =${" "}
                      {parseFloat(
                        parseFloat(payload?.qty) * parseFloat(payload?.amount)
                      )?.toFixed(2)}{" "}
                    </span>
                  </div>
                </div>
                <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1">
                  <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_title">
                    Ordered
                  </div>
                  <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_value">
                    <ReactTimeAgo date={payload?.createdAt} locale="en-US" />
                  </div>
                </div>
                <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1">
                  <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_title">
                    Order Status
                  </div>
                  <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_value">
                    <div
                      className={
                        payload?.status === "INIT"
                          ? "tableBody_row_data_product_Status"
                          : "tableBody_row_data_product_Status_delivered"
                      }
                    >
                      {payload?.status === "INIT"
                        ? "Pending..."
                        : payload?.status}{" "}
                    </div>
                  </div>
                </div>
              </div>
              {/* ==== */}
              {/* ==== */}
              {/* ==== */}
              {/* ==== */}
              {/* ==== */}
              <div className="orderReceiptDiv_cont_body_cont1_body_div3">
                <div className="orderReceiptDiv_cont_body_cont1_body_div3_cont1">
                  <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_title">
                    Customer Name
                  </div>
                  <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_value">
                    {deliveryDetailsLoading ? (
                      <CustomLoader
                        containerWidth={"max-content"}
                        margin={"0px 0px"}
                        width={"20px"}
                        height={"20px"}
                        borderSize={"3px"}
                        padding={"0px"}
                      />
                    ) : (
                      <> {deliveryDetails?.fullname}</>
                    )}
                  </div>
                </div>
                <div className="orderReceiptDiv_cont_body_cont1_body_div3_cont1">
                  <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_title">
                    Phone No
                  </div>
                  <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_value">
                    {deliveryDetailsLoading ? (
                      <CustomLoader
                        containerWidth={"max-content"}
                        margin={"0px 0px"}
                        width={"20px"}
                        height={"20px"}
                        borderSize={"3px"}
                        padding={"0px"}
                      />
                    ) : (
                      <> {deliveryDetails?.phoneNo}</>
                    )}{" "}
                  </div>
                </div>
                <div className="orderReceiptDiv_cont_body_cont1_body_div3_cont1">
                  <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_title">
                    Country
                  </div>
                  <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_value">
                    {deliveryDetailsLoading ? (
                      <CustomLoader
                        containerWidth={"max-content"}
                        margin={"0px 0px"}
                        width={"20px"}
                        height={"20px"}
                        borderSize={"3px"}
                        padding={"0px"}
                      />
                    ) : (
                      <> {deliveryDetails?.country || "_ _"}</>
                    )}{" "}
                  </div>
                </div>
                <div className="orderReceiptDiv_cont_body_cont1_body_div3_cont1">
                  <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_title">
                    State
                  </div>
                  <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_value">
                    {deliveryDetailsLoading ? (
                      <CustomLoader
                        containerWidth={"max-content"}
                        margin={"0px 0px"}
                        width={"20px"}
                        height={"20px"}
                        borderSize={"3px"}
                        padding={"0px"}
                      />
                    ) : (
                      <> {deliveryDetails?.state}</>
                    )}{" "}
                  </div>
                </div>
                <div className="orderReceiptDiv_cont_body_cont1_body_div3_cont1">
                  <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_title">
                    City
                  </div>
                  <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_value">
                    {deliveryDetailsLoading ? (
                      <CustomLoader
                        containerWidth={"max-content"}
                        margin={"0px 0px"}
                        width={"20px"}
                        height={"20px"}
                        borderSize={"3px"}
                        padding={"0px"}
                      />
                    ) : (
                      <> {deliveryDetails?.city}</>
                    )}{" "}
                  </div>
                </div>
                {/* <div className="orderReceiptDiv_cont_body_cont1_body_div3_cont1">
                  <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_title">
                    Zip code
                  </div>
                  <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_value">
                    {deliveryDetails?.zipcode || "_ _"}
                  </div>
                </div> */}
              </div>
              {/* ==== */}
              {/* ==== */}
              {/* ==== */}
              {/* ==== */}
              {/* ==== */}
            </div>
          </div>
        </div>
        <div className="orderReceiptDiv_cont_button_div">
          {payload?.status === "DELIVERED" ? (
            <button className="product_detail_cont_div5_7_btn" disabled>
              Order delivered
            </button>
          ) : (
            <button
              className="product_detail_cont_div5_7_btn"
              onClick={updateOrderStatus}
            >
              Update order as shipped
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default RwaOrderDetails;
