import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
const EGAX_USD_Price = 0.45;
const PoolInfo = ({
  egaxPoolVal,
  tokenPoolVal,
  toTalegaxPoolVal,
  toTaltokenPoolVal,
  loading,
  data,
  userShareOfPool,
  TVL,
  baseToken,
}) => {
  const totalPoolLiquidityInDollar = TVL * EGAX_USD_Price;

  return (
    <div className="LiquidityDiv_cont_area2">
      <div className="LiquidityDiv_cont_area2_cont1">
        <PoolInfoItem
          title="Pool Liquidity"
          value={`$${parseFloat(totalPoolLiquidityInDollar || 0)?.toFixed(4)}`}
          loading={loading}
        />
        <PoolInfoItem
          title={`Pooled ${data?.tokenSymbol || "_ _"}`}
          value={parseFloat(toTaltokenPoolVal)?.toFixed(4)}
          img={data?.image || "/img/art.png"}
          loading={loading}
        />
        <PoolInfoItem
          title={`Pooled ${baseToken}`}
          value={parseFloat(toTalegaxPoolVal)?.toFixed(4)}
          img={
            baseToken === "EGAX"
              ? "/img/egax_logo.png"
              : baseToken === "DHV"
              ? "/img/dhiveCoinIcon.png"
              : "/img/art.png"
          }
          loading={loading}
        />
      </div>
      <div className="LiquidityDiv_cont_area2_cont2">
        <span className="LiquidityDiv_cont_area2_cont2_span">
          My LP Token Balances
        </span>
        <PoolInfoItem
          title={`Pooled ${data?.tokenSymbol || "_ _"}`}
          value={parseFloat(tokenPoolVal)?.toFixed(4)}
          loading={loading}
        />
        <PoolInfoItem
          title={`Pooled ${baseToken}`}
          value={parseFloat(egaxPoolVal)?.toFixed(4)}
          loading={loading}
        />
        <PoolInfoItem
          title="Share of pool"
          value={userShareOfPool}
          loading={loading}
        />
      </div>
    </div>
  );
};

const PoolInfoItem = ({ title, value, img, loading }) => (
  <div className="LiquidityDiv_cont_area2_cont1_div1">
    <div className="LiquidityDiv_cont_area2_cont1_div1_title">
      {title}{" "}
      {img && (
        <img
          src={img}
          alt=""
          className="LiquidityDiv_cont_area2_cont1_div1_title_img"
        />
      )}
    </div>
    <div className="LiquidityDiv_cont_area2_cont1_div1_para">
      {" "}
      {loading ? <ClipLoader color="#605afd" size={18} /> : value}
    </div>
  </div>
);

export default PoolInfo;
