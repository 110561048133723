import React from "react";
import Blockies from "react-blockies";
import "./index.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SideBar from "./SideBar/SideBar";
import { Outlet } from "react-router-dom";
import NavBar from "./NavBar/NavBar";
const RwaBusiness = () => {
  return (
    <div className="rwaBusinessDiv">
      <div className="container">
        <div className="rwaBusinessDiv_area">
          <div className="rwaBusinessDiv_area_side">
            {/* <Link to="/" className="rwaBusinessDiv_area_side_link">
              <img
                src="/egomart_logo.png"
                alt=""
                className="rwaBusinessDiv_area_side_link_img"
              />
              Egomart
            </Link> */}
            <SideBar />
          </div>
          <div className="rwaBusinessDiv_area_div">
            {/* <div className="rwaBusinessDiv_area_div_cont1">
              <NavBar />
            </div> */}
            <div className="rwaBusinessDiv_body">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RwaBusiness;
