import React, { useState } from "react";
import { ArrowLeft01Icon, InformationCircleIcon } from "hugeicons-react";
import { ViewIcon, PaintBoardIcon } from "hugeicons-react";
import toast, { Toaster } from "react-hot-toast";
import { Notification03Icon } from "hugeicons-react";
import { LanguageCircleIcon } from "hugeicons-react";
import {
  ArrowRight01Icon,
  LockPasswordIcon,
  DollarCircleIcon,
  SecurityLockIcon,
} from "hugeicons-react";
import "./index.css";
import BackupWallet from "./BackupWallet/BackupWallet";
const WalletSettings = ({ back }) => {
  const [backup, setBackup] = useState(false);
  if (backup) {
    return <BackupWallet back={() => setBackup(false)} />;
  }

  return (
    <div className="SendModal_div">
      <div className="WalletDetailPageDiv_header">
        <div className="WalletDetailPageDiv_header_back" onClick={back}>
          <ArrowLeft01Icon size={24} />
        </div>
        Settings
        <div className="WalletDetailPageDiv_header_info">
          <InformationCircleIcon size={20} />
        </div>
      </div>
      <div className="walletSettingsBody">
        <div className="header_menu_links_div">
          <div className="header_menu_links_div_title"> Wallet security</div>
          <div
            className="header_menu_links_div_cont1
            "
            onClick={() => setBackup(true)}
          >
            <div className="header_menu_links_div_cont1_div1">
              <SecurityLockIcon
                size={18}
                className="HeaderMenuDiv_div2_cont1_iocn2"
              />{" "}
              Backup
            </div>
            <div className="header_menu_links_div_cont1_div2">
              <ArrowRight01Icon
                size={18}
                className="HeaderMenuDiv_header_cont1_icon"
              />
            </div>
          </div>
          <div
            className="header_menu_links_div_cont1
            "
          >
            <div className="header_menu_links_div_cont1_div1">
              <LockPasswordIcon
                size={18}
                className="HeaderMenuDiv_div2_cont1_iocn2"
              />{" "}
              Password
            </div>
            <div className="header_menu_links_div_cont1_div2">
              <ArrowRight01Icon
                size={18}
                className="HeaderMenuDiv_header_cont1_icon"
              />
            </div>
          </div>

          {/* ====== */}
          {/* ====== */}
          {/* ====== */}
          {/* ====== */}
          {/* ====== */}
          <div className="header_menu_links_div_title">Preferences</div>
          <div
            className="header_menu_links_div_cont1
            "
          >
            <div className="header_menu_links_div_cont1_div1">
              <DollarCircleIcon
                size={18}
                className="HeaderMenuDiv_div2_cont1_iocn2"
              />{" "}
              Currency
            </div>
            <div className="header_menu_links_div_cont1_div2">
              <div className="header_menu_links_div_cont1_div2_txt">USD</div>
              {/* <ArrowRight01Icon
                size={18}
                className="HeaderMenuDiv_header_cont1_icon"
              /> */}
            </div>
          </div>
          <div
            className="header_menu_links_div_cont1
            "
          >
            <div className="header_menu_links_div_cont1_div1">
              <LanguageCircleIcon
                size={18}
                className="HeaderMenuDiv_div2_cont1_iocn2"
              />{" "}
              Language
            </div>
            <div className="header_menu_links_div_cont1_div2">
              <div className="header_menu_links_div_cont1_div2_txt">
                English
              </div>
              {/* <ArrowRight01Icon
                size={18}
                className="HeaderMenuDiv_header_cont1_icon"
              /> */}
            </div>
          </div>
          <div
            className="header_menu_links_div_cont1
            "
          >
            <div className="header_menu_links_div_cont1_div1">
              <Notification03Icon
                size={18}
                className="HeaderMenuDiv_div2_cont1_iocn2"
              />{" "}
              Notifications
            </div>
            <div className="header_menu_links_div_cont1_div2">
              <div className="header_menu_links_div_cont1_div2_txt">
                Disabled
              </div>
              {/* <ArrowRight01Icon
                size={18}
                className="HeaderMenuDiv_header_cont1_icon"
              /> */}
            </div>
          </div>
          <div
            className="header_menu_links_div_cont1
            "
          >
            <div className="header_menu_links_div_cont1_div1">
              <PaintBoardIcon
                size={18}
                className="HeaderMenuDiv_div2_cont1_iocn2"
              />{" "}
              Appearance
            </div>
            <div className="header_menu_links_div_cont1_div2">
              <div className="header_menu_links_div_cont1_div2_txt">Light</div>
              {/* <ArrowRight01Icon
                size={18}
                className="HeaderMenuDiv_header_cont1_icon"
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletSettings;
