import React, { useState, useEffect } from "react";
import {
  Home01Icon,
  ProductLoadingIcon,
  ChartLineData01Icon,
} from "hugeicons-react";
import "./index.css";
import { Link } from "react-router-dom";

const SideBar = () => {
  const [activeTab, setActiveTab] = useState("business");
  const toggleActiveTab = (e) => {
    setActiveTab(e.currentTarget.id);
  };

  const currentPath = window.location.pathname;

  const path = currentPath.split("/");

  // console.log("====================================");
  // console.log(currentPath);
  // console.log(path);
  // console.log("====================================");

  useEffect(() => {
    if (path[2] == "products") {
      setActiveTab("product");
    }
  }, [path]);
  return (
    <div className="sideBar">
      <Link
        to="/business"
        className={
          activeTab === "business" ? "sideBar_cont1_active" : "sideBar_cont1"
        }
        id="business"
        onClick={toggleActiveTab}
      >
        <Home01Icon className="sideBar_cont1_icon" size={20} />
        Home
      </Link>
      <Link
        to="/business/products"
        className={
          activeTab === "product" ? "sideBar_cont1_active" : "sideBar_cont1"
        }
        id="product"
        onClick={toggleActiveTab}
      >
        <ProductLoadingIcon className="sideBar_cont1_icon" size={20} /> Products
      </Link>
      {/* <div className="sideBar_cont1">
        <ChartLineData01Icon className="sideBar_cont1_icon" size={20} /> Trades
      </div> */}
    </div>
  );
};

export default SideBar;
