// src/utils/ethersUtils.js
import { ethers } from "ethers";
import { CONTRACT_ADDRESS, CONTRACT_ABI } from "../constants/index";

import { store } from "../Redux/app/store";
import { decrypt } from "../helper/encryption";

const provider = new ethers.providers.JsonRpcProvider(
  `https://mainnet.egochain.org`
);

// Read from a contract function
export const readContract = async (functionName, args = []) => {
  try {
    const decrypted = decrypt(privateKey);

    const signer = new ethers.Wallet(decrypted, provider);

    // eate a contract instance
    const contract = new ethers.Contract(
      CONTRACT_ADDRESS,
      CONTRACT_ABI,
      signer
    );

    const result = await contract[functionName](...args);
    return result;
  } catch (error) {
    console.error("Error reading from contract:", error);
    throw error;
  }
};

export const writeContractEther = async ({
  functionName,
  args = [],
  value, // Optional, only processed if provided
  overrides = {},
  privateKey,
  setState,
  abi = CONTRACT_ABI,
  contractAddress = CONTRACT_ADDRESS,
}) => {
  try {
    if (setState) setState({ loading: true, error: null, success: false });

    const decrypted = decrypt(privateKey);
    const signer = new ethers.Wallet(decrypted, provider);

    // Create a contract instance
    const contract = new ethers.Contract(contractAddress, abi, signer);

    // Construct transaction overrides
    const txOverrides = { ...overrides };

    // Add value only if it's provided and valid
    if (value !== undefined && value !== null) {
      txOverrides.value = ethers.utils.parseEther(value.toString());
    }

    const tx = await contract[functionName](...args, txOverrides);
    await tx.wait(); // Wait for the transaction to be mined

    if (setState) setState({ loading: false, error: null, success: true });

    return tx;
  } catch (error) {
    console.error("Error writing to contract:", error);
    if (setState) setState({ loading: false, error, success: false });

    throw error;
  }
};

export const setApproval = async ({
  tokenAddress, // ERC-20 contract address
  spender, // Address allowed to spend tokens
  privateKey, // User's private key
  setState, // Optional state handler
  prov = provider,
}) => {
  try {
    if (setState) setState({ loading: true, error: null, success: false });

    const decrypted = decrypt(privateKey); // Ensure decryption if stored encrypted
    const signer = new ethers.Wallet(decrypted, prov);

    // Create an instance of the ERC-20 contract
    const tokenContract = new ethers.Contract(
      tokenAddress,
      [
        "function approve(address spender, uint256 amount) public returns (bool)",
      ],
      signer
    );

    // Convert the amount to Wei (if using ERC-20 decimals)
    const formattedAmount = ethers.utils.parseEther(
      "100000000000000000000000000".toString()
    ); // Adjust decimals if needed

    // Send the approval transaction
    const tx = await tokenContract.approve(spender, formattedAmount);
    await tx.wait(); // Wait for confirmation

    if (setState) setState({ loading: false, error: null, success: true });

    //console.log("Approval successful:", tx);
    return tx;
  } catch (error) {
    console.error("Approval failed:", error);
    if (setState) setState({ loading: false, error, success: false });

    throw error;
  }
};

export const checkApproval = async ({
  tokenAddress,
  owner,
  spender,
  prov = provider,
}) => {
  //console.log("====================================");
  //console.log(tokenAddress, owner, spender, prov);
  //console.log("====================================");
  try {
    // Create an instance of the ERC-20 contract
    const tokenContract = new ethers.Contract(
      tokenAddress,
      [
        "function allowance(address owner, address spender) public view returns (uint256)",
      ],
      prov
    );

    // Query the current allowance
    const allowance = await tokenContract.allowance(owner, spender);
    // Convert the result to a human-readable format (assuming 18 decimals)
    return ethers.utils.formatEther(allowance);
  } catch (error) {
    console.error("Error checking allowance:", error);
    throw error;
  }
};
