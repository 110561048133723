import React from "react";
import "./index.css";
import { Link, useNavigate } from "react-router-dom";

const HomeContainer = ({ nextStep }) => {
  const navigate = useNavigate();
  return (
    <div className="onchainWalletDiv_container">
      <div className="onchainWalletDiv_container_div1">
        <img
          src="/White Dhive.svg"
          alt=""
          className="onchainWalletDiv_container_div1_title_img"
        />
        <div
          className="onchainWalletDiv_container_div1_title
              "
        >
          Web3 Wallet
        </div>
        <div className="onchainWalletDiv_container_div1_para">
          Your Interchain Gateway
        </div>
      </div>
      <div className="onchainWalletDiv_container_div2">
        <button
          className="onchainWalletDiv_container_div2_btn1"
          onClick={nextStep}
        >
          Create a new wallet
        </button>
        <button
          onClick={() => navigate("/import")}
          className="onchainWalletDiv_container_div2_btn2"
        >
          Import an existing wallet
        </button>
        <Link to="/" className="onchainWalletDiv_container_div2_link">
          Go back home{" "}
        </Link>
      </div>
    </div>
  );
};

export default HomeContainer;
