import React, { useState, useEffect } from "react";
import "./index.css";
import { TbArrowsRightLeft } from "react-icons/tb";
import { BRIDGE, BRIDGE2 } from "../../../services/wallet";
import { socketEvents } from "../../../Sockets/events";
import { useSocket } from "../../../Sockets/contextProvider/SocketContext";
import { useSelector } from "react-redux";
import {
  ArrowDown01Icon,
  ArrowLeft01Icon,
  InformationCircleIcon,
} from "hugeicons-react";
import ClipLoader from "react-spinners/ClipLoader";
import toast, { Toaster } from "react-hot-toast";

// Configuration for tokens, networks, and variants
const tokensConfig = {
  USDC: {
    icon: "/img/usdcicon.png",
    networks: {
      "BINANCE NETWORK": {
        label: "Binance Network",
        icon: "/img/bnb.png",
        contractAddress: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d", // Default contract address
        variants: [], // No variants for USDC on Binance
      },
      "EGOCHAIN NETWORK": {
        label: "Egochain",
        icon: "/img/egax_logo.png",
        contractAddress: "0xF5121E85b239F650063637B8Df45DcEd2c88976c", // Default contract address
        variants: [
          // {
          //   label: "USDC",
          //   symbol: "USDC",
          //   contractAddress: "0xEGC_BASE_VARIANT_1_CONTRACT_ADDRESS", // Variant contract address
          // },
        ], // No variants for USDC on Egochain
      },
    },
    bridgeFee: 0.005, // 0.5%
  },
  EGC: {
    icon: "/img/egoras_logo_icon.svg",
    networks: {
      "BASE NETWORK": {
        label: "Base",
        icon: "/img/base.png",
        contractAddress: "0x586dF0DB6F3A30EDEdc4b1A434D0449168458Ee5", // Default contract address
        variants: [
          {
            label: "EGC",
            symbol: "EGC",
            contractAddress: "0x586dF0DB6F3A30EDEdc4b1A434D0449168458Ee5", // Variant contract address
          },
        ],
      },
      "EGOCHAIN NETWORK": {
        label: "Egochain",
        icon: "/img/egax_logo.png",
        contractAddress: "0x1cb26D61Cc54Fc029eb07f657C817F26Ba5636eE", // Default contract address
        variants: [
          {
            label: "EGC (Egoras Automobile)",
            symbol: "EGC",
            contractAddress: "0x1cb26D61Cc54Fc029eb07f657C817F26Ba5636eE", // Variant contract address
          },
          {
            label: "EgoMiniBus (EGC)",
            symbol: "EGC-OLD",
            contractAddress: "0x16eB0dd82942F6e42b9B21f7EE15DDC735831Db9", // Variant contract address
          },
        ],
      },
    },
    bridgeFee: 0.01, // 1%
  },
};

// Dropdown component for networks and variants
const NetworkDropdown = ({
  options,
  selected,
  onSelect,
  isOpen,
  toggleDropdown,
  label = "Network",
}) => (
  <div className="relative">
    <button
      onClick={toggleDropdown}
      className="w-full py-4 px-4 rounded-md border border-neutral-200 bg-neutral-100 text-black flex items-center justify-between outline-none"
    >
      <div className="flex items-center gap-3 text-sm font-semibold">
        {selected?.icon ? (
          <img
            src={selected.icon}
            alt={selected.label}
            className="w-6 h-6 rounded-full"
          />
        ) : (
          <div className="w-6 h-6 bg-neutral-300 rounded-full"></div>
        )}
        {selected?.label || `Select a ${label}`}
      </div>
      <span>
        <ArrowDown01Icon />
      </span>
    </button>
    {isOpen && (
      <ul className="absolute mt-2 w-full bg-neutral-100 border border-neutral-200 rounded-md shadow-lg z-50 max-h-40 overflow-y-auto">
        {options.map((option) => (
          <li
            key={option.value}
            onClick={() => onSelect(option)}
            className="flex items-center gap-3 px-4 py-2 hover:bg-neutral-200 cursor-pointer font-semibold"
          >
            {option.icon && (
              <img
                src={option.icon}
                alt={option.label}
                className="w-6 h-6 rounded-full"
              />
            )}
            {option.label}
          </li>
        ))}
      </ul>
    )}
  </div>
);

// Input component for bridge amount
const AmountInput = ({ value, onChange, disabled = false }) => (
  <div className="w-full border border-neutral-200 bg-neutral-100 rounded h-[3.5rem] p-3 py-8 outline-none text-sm flex items-center">
    <input
      type="number"
      placeholder="Enter an amount"
      style={{
        width: "100%",
        marginLeft: "3em",
        borderLeft: "solid 1px grey",
        paddingLeft: "5px",
      }}
      className="text-sm font-semibold"
      onChange={onChange}
      value={value}
      disabled={disabled}
    />
  </div>
);

// Main Bridge component
const Bridge = ({ back }) => {
  const socket = useSocket();
  const { wallet_address } = useSelector((state) => state.user);
  const [selectedToken, setSelectedToken] = useState("USDC"); // Default token
  const [selectedFrom, setSelectedFrom] = useState(null);
  const [selectedTo, setSelectedTo] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null); // For variants
  const [selectedFromVariant, setSelectedFromVariant] = useState(null); // For variants
  const [selectedToVariant, setSelectedTOVariant] = useState(null); // For variants
  const [bridgeAmount, setBridgeAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [isTokenDropdownOpen, setIsTokenDropdownOpen] = useState(false);
  const [isFromDropdownOpen, setIsFromDropdownOpen] = useState(false);
  const [isToDropdownOpen, setIsToDropdownOpen] = useState(false);
  const [isFromVariantDropdownOpen, setIsFromVariantDropdownOpen] =
    useState(false);
  const [isToVariantDropdownOpen, setIsToVariantDropdownOpen] = useState(false);

  // Token options for dropdown
  const tokenOptions = Object.keys(tokensConfig).map((token) => ({
    value: token,
    label: token,
    icon: tokensConfig[token].icon,
  }));

  // Network options for dropdown (exclude specified network)
  const networkOptions = (token, excludeNetwork = null) =>
    Object.entries(tokensConfig[token].networks)
      .filter(([value]) => value !== excludeNetwork) // Exclude the specified network
      .map(([value, { label, icon }]) => ({
        value,
        label,
        icon,
      }));

  // Variant options for dropdown
  const variantOptions = (token, network) => {
    const networkConfig = tokensConfig[token].networks[network];
    return networkConfig.variants.map((variant) => ({
      value: variant.label,
      label: variant.label,
      symbol: variant.symbol,
      contractAddress: variant.contractAddress,
    }));
  };

  // Handle bridge transaction

  //   {
  //     "amount": 0.1,
  //     "networkOut": "BASE NETWORK",
  //     "networkIn": "EGOCHAIN NETWORK",
  //     "symbolIn": "EGC", // "EGC" for BASE NETWORK
  //     "symbolOut": "EGC" // "EGC-OLD" for MINIBUS || "EGC" for Automobile
  // }
  const bridgeFunds = async () => {
    if (!bridgeAmount || parseFloat(bridgeAmount) <= 0) {
      toast.error("Please enter a valid amount.");
      return;
    }
    setLoading(true);
    // console.log(selectedToken);

    if (selectedToken === "USDC") {
      // Payload for USDC bridging
      const payload = {
        amount: parseFloat(bridgeAmount),
        networkOut: selectedTo.value,
        networkIn: selectedFrom.value,
        symbol: selectedToken,
      };

      const res = await BRIDGE(payload);
      // console.log(res, "manana");
      setLoading(true);

      if (res?.success === false) {
        setLoading(false);
        toast.error(res?.message || "Bridge transaction failed.");
      }
      return;
    }

    // console.log(selectedToVariant, selectedFromVariant, "oppp");

    const payload = {
      amount: parseFloat(bridgeAmount),
      networkOut: selectedTo.value,
      networkIn: selectedFrom.value,
      symbolIn: selectedFromVariant.symbol, // "EGC" for BASE NETWORK
      symbolOut: selectedToVariant.symbol, // "EGC-OLD" for MINIBUS || "EGC" for Automobile
    };
    // console.log(payload);

    const res = await BRIDGE2(payload);
    setLoading(true);

    if (res?.success === false) {
      setLoading(false);
      toast.error(res?.message || "Bridge transaction failed.");
    }
  };

  // Socket listener for bridge events
  useEffect(() => {
    const listener = (data) => {
      if (data?.success === false) {
        setLoading(false);
        toast.error(data?.message || "Bridge transaction failed.");
      } else if (
        data?.success === true &&
        data?.message === "Fund transfer to user was successful."
      ) {
        setLoading(false);
        toast.success("Successfully bridged tokens!");
        setTimeout(() => window.location.reload(), 5000);
      }
    };
    socket.on(`${wallet_address}${socketEvents.BRIDGE_LISTENER}`, listener);
    return () =>
      socket.off(`${wallet_address}${socketEvents.BRIDGE_LISTENER}`, listener);
  }, [socket, wallet_address]);

  // Toggle "From" and "To" networks
  const toggleNetworks = () => {
    const temp = selectedFrom;
    // console.log("temp", temp);
    setSelectedFrom(selectedTo);
    setSelectedTo(temp);
  };

  return (
    <div className="bridgeArea" style={{ width: "-webkit-fill-available" }}>
      <div className="WalletDetailPageDiv_header">
        <div className="WalletDetailPageDiv_header_back" onClick={back}>
          <ArrowLeft01Icon size={24} />
        </div>
        Bridge tokens
        <div className="WalletDetailPageDiv_header_info">
          <InformationCircleIcon size={20} />
        </div>
      </div>
      <div
        className="bg-neutral-50 rounded-lg w-[45rem] px-4 py-4"
        style={{ width: "-webkit-fill-available" }}
      >
        {/* Token Selection */}
        <div className="relative flex flex-col gap-[9px] dropdown pt-4 text-sm">
          <p>Select Token</p>
          <NetworkDropdown
            options={tokenOptions}
            selected={{
              value: selectedToken,
              label: selectedToken,
              icon: tokensConfig[selectedToken].icon,
            }}
            onSelect={(option) => {
              setSelectedToken(option.value);
              setSelectedFrom(null);
              setSelectedTo(null);
              setSelectedVariant(null);
              setIsTokenDropdownOpen((prev) => !prev);
            }}
            isOpen={isTokenDropdownOpen}
            toggleDropdown={() => setIsTokenDropdownOpen((prev) => !prev)}
          />
        </div>

        {/* From Network */}
        <div className="relative flex flex-col gap-[9px] dropdown pt-4 text-sm">
          <p>From this network</p>
          <NetworkDropdown
            options={networkOptions(selectedToken, selectedTo?.value)} // Exclude the "To" network
            selected={selectedFrom}
            onSelect={(option) => {
              setSelectedFrom(option);
              setSelectedVariant(null);
              setIsFromDropdownOpen((prev) => !prev);
            }}
            isOpen={isFromDropdownOpen}
            toggleDropdown={() => {
              setIsFromDropdownOpen((prev) => !prev);
              setIsToDropdownOpen(false);
            }}
          />
        </div>

        {/* Variant Selection (for "From" network) */}
        {selectedFrom?.value &&
          tokensConfig[selectedToken].networks[selectedFrom.value].variants
            .length > 0 && (
            <div className="relative flex flex-col gap-[9px] dropdown text-sm">
              <p>Select Variant (From)</p>
              <NetworkDropdown
                options={variantOptions(selectedToken, selectedFrom.value)}
                // selected={{
                //   value: selectedVariant?.label,
                //   label: selectedVariant?.label,
                // }}
                selected={{
                  value: selectedFromVariant?.symbol,
                  label: selectedFromVariant?.label,
                }}
                onSelect={(option) => {
                  setSelectedFromVariant(option);
                  setIsFromVariantDropdownOpen((prev) => !prev);
                }}
                isOpen={isFromVariantDropdownOpen}
                toggleDropdown={() =>
                  setIsFromVariantDropdownOpen((prev) => !prev)
                }
                label="Variant"
              />
            </div>
          )}

        {/* Amount Input */}
        <div className="flex flex-col gap-[9px] pt-2">
          <p>You send</p>
          <AmountInput
            value={bridgeAmount}
            onChange={(e) => setBridgeAmount(e.target.value)}
          />
        </div>

        {/* Toggle Button */}
        <div className="relative toggle_div">
          <TbArrowsRightLeft
            className="left-[20.5rem] text-2xl text-neutral-500 hover:bg-neutral-600 rounded"
            onClick={toggleNetworks}
          />
        </div>

        {/* To Network */}
        <div className="relative flex flex-col gap-[9px] dropdown text-sm">
          <p>To this network</p>
          <NetworkDropdown
            options={networkOptions(selectedToken, selectedFrom?.value)} // Exclude the "From" network
            selected={selectedTo}
            onSelect={(option) => {
              setSelectedTo(option);
              setSelectedVariant(null);
              setIsToDropdownOpen((prev) => !prev);
            }}
            isOpen={isToDropdownOpen}
            toggleDropdown={() => {
              setIsToDropdownOpen((prev) => !prev);
              setIsFromDropdownOpen(false);
            }}
          />
        </div>

        {/* Variant Selection (for "To" network) */}
        {selectedTo?.value &&
          tokensConfig[selectedToken].networks[selectedTo.value].variants
            .length > 0 && (
            <div className="relative flex flex-col gap-[9px] dropdown text-sm">
              <p>Select Variant (To)</p>
              <NetworkDropdown
                options={variantOptions(selectedToken, selectedTo.value)}
                // selected={{
                //   value: selectedVariant?.label,
                //   label: selectedVariant?.label,
                // }}
                selected={{
                  value: selectedToVariant?.symbol,
                  label: selectedToVariant?.label,
                }}
                onSelect={(option) => {
                  // selectedVariant(option);
                  setSelectedTOVariant(option);

                  setIsToVariantDropdownOpen((prev) => !prev);
                }}
                isOpen={isToVariantDropdownOpen}
                toggleDropdown={() =>
                  setIsToVariantDropdownOpen((prev) => !prev)
                }
                label="Variant"
              />
            </div>
          )}

        {/* Amount Output */}
        <div className="flex flex-col gap-[9px]">
          <p>You receive</p>
          <AmountInput
            value={
              bridgeAmount
                ? parseFloat(bridgeAmount) -
                  parseFloat(bridgeAmount) *
                    tokensConfig[selectedToken].bridgeFee
                : ""
            }
            disabled
          />
        </div>
      </div>

      {/* Bridge Button and Fees */}
      <div className="bridge_div_btn_div">
        <div className="bridge_div_btn_div_1">
          <p className="text-sm text-neutral-500" style={{ fontSize: "12px" }}>
            Bridge fees
          </p>
          <button
            className="text-sm font-semibold"
            style={{ fontSize: "12px", marginTop: "5px", marginBottom: "5px" }}
          >
            {bridgeAmount
              ? parseFloat(bridgeAmount) * tokensConfig[selectedToken].bridgeFee
              : 0}{" "}
            <span style={{ fontSize: "12px" }}>{selectedToken}</span>
          </button>
          <h6 className="text-neutral-500 text-sm" style={{ fontSize: "12px" }}>
            Includes a {tokensConfig[selectedToken].bridgeFee * 100}% bridging
            fee
          </h6>
        </div>
        <button
          className="bridge_div_btn_div_1_btn"
          onClick={bridgeFunds}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          disabled={loading}
        >
          {loading ? <ClipLoader color="#fff" size={24} /> : "Bridge"}
        </button>
      </div>
      <Toaster />
    </div>
  );
};

export default Bridge;
