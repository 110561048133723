import { useEffect, useState } from "react";
import "./App.css";
import { Outlet, useLocation } from "react-router-dom";
import ExchangeHeader from "./Exchange/ExchangeHeader/ExchangeHeader";
import ExchangeFooter from "./Exchange/ExchangeFooter/ExchangeFooter";
import { FETCH_NETWORK_PROVIDERS, LIST_ALL_ASSET } from "./services/wallet";
import { useDispatch, useSelector } from "react-redux";
import {
  setAssets,
  setAssetsPending,
  setProviders,
} from "./Redux/feautures/assets/assetSlice";
import { fetchTokenBalances } from "./OnchainWallet/WalletPop/utils/multiBalance";
import { fetchProductsByCategory } from "./Redux/feautures/products/productSlice";

import {
  resetBusiness,
  setBusinessInfo,
} from "./Redux/feautures/user/userSlice";

import {
  ALL_BUSINESS_STAT,
  GET_24_STAT,
  GET_BUSINESS_INFO,
  GET_TRADES_DATA,
  SHOW_ALL_BUSINESS,
} from "./services/business";

import { GET_LATEST_TRADE } from "./services/exchange";
import { populateBusinesses } from "./Redux/feautures/businesses/businessSlice";
import { bridge_providers } from "./OnchainWallet/dummy/Bridge-Provider";
import { socketEvents } from "./Sockets/events";
import { useSocket } from "./Sockets/contextProvider/SocketContext";

// import useTransactionListener from "./OnchainWallet/WalletPop/services/useTransactionListener";
// import { updateTrade } from "./Redux/feautures/trades/tradeSlice";
import { setLatestTrade } from "./Redux/feautures/trades/tradeSlice";
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
function App() {
  const dispatch = useDispatch();
  const socket = useSocket();
  // const categoryProducts = useSelector(
  //   (state) => state.products.categoryProducts
  // );
  const { wallet_address } = useSelector((state) => state.user);
  const { businesses } = useSelector((state) => state.businesses);
  const { block } = useSelector((state) => state.wallTrans);
  const { activeProvider } = useSelector((state) => state.assets);
  const { Assets, isSuccess, balanceChange } = useSelector(
    (state) => state.assets
  );

  // // Listen for new transactions
  // useTransactionListener(wallet_address, (transaction) => {
  //   console.log(transaction, "new transaction");
  //   dispatch(setNewBlock(transaction));

  //   // setTransaction((prev) => [transaction, ...prev]); // Add new transaction to the list
  // });

  // const providerUrl = "wss://egoscan.io/socket/v2/websocket?vsn=2.0.0";
  //update the asset record

  // useEffect(() => {
  //   socket.on("connect", () => {
  //     console.log("COnnection established");
  //   });
  // }, []);

  const updateAsset = async () => {
    dispatch(setAssetsPending());

    const res = await LIST_ALL_ASSET();

    console.log(res, "respi");
    if (!res?.success) return;
    if (res?.assets.length >= 1) {
      dispatch(setAssets(res?.assets));
      // if (!wallet_address) return;
      // console.log(activeProvider, "activeProvider");
      // const balances = await fetchTokenBalances(
      //   wallet_address,
      //   activeProvider.network === "EGOCHAIN NETWORK"
      //     ? res?.assets?.map((asset) => {
      //         // console.log(asset, "coool");
      //         return asset?.tokenB;
      //       })
      //     : res?.assets
      //         .filter((asset) => asset?.tokenBName === "USDC")
      //         ?.map((a) => activeProvider?.contractAddress),
      //   activeProvider.rpc
      // );

      // console.log(balances, "shakina");

      // if ((await balances).length <= 0) return;

      //dispatch balances
      // dispatch(updateBalances(balances));
      // getStats();
    }
  };

  const fetchBusinessINfo = async () => {
    const res = await GET_BUSINESS_INFO({ address: wallet_address });

    if (!res.success) {
      dispatch(setBusinessInfo(null));

      // do nothing ...
      return;
    }

    //save to redux

    dispatch(setBusinessInfo(res.data));
  };

  const fetchAllBusiness = async () => {
    const res = await SHOW_ALL_BUSINESS();
    // console.log(res, "res");
    if (!res?.success) {
      return;
    }

    dispatch(populateBusinesses(res?.newBusiness));
  };

  const fetchProviders = async () => {
    const res = await FETCH_NETWORK_PROVIDERS();

    // console.log(res, "igniter");
    if (!res.success) {
      return;
    }

    const providers = bridge_providers;
    dispatch(setProviders(res.assets));
  };
  const allBusinessStats = async () => {
    if (isSuccess === false) return;

    const res = await ALL_BUSINESS_STAT();
    console.log(res, "stats");

    if (!res?.success) {
      return;
    }
  };

  useEffect(() => {
    const eventKey = `${socketEvents.ALL_TRADE_EVENT}`;

    const handleTradeEvent = (tradeData) => {
      // console.log(tradeData, "TRADE SOCKET DATA");

      if (!tradeData) {
        // console.log("Trade socket emission failed!!!");
        return;
      }

      // console.log("Trade socket emission successful!!!");

      // Ensure tradeData is an array before dispatching
      const tradesArray = Array.isArray(tradeData) ? tradeData : [tradeData];

      // Dispatch trade update to Redux store
      dispatch(setLatestTrade(tradesArray));
    };

    socket.on(eventKey, handleTradeEvent);

    return () => {
      socket.off(eventKey, handleTradeEvent);
    };
  }, [socket, dispatch]);

  const fetchLatestTrades = async () => {
    try {
      // console.log("Fetching latest trades...");
      const res = await GET_LATEST_TRADE();
      // console.log("API Response:", res);
      if (!res?.data) {
        // console.log("No data received!");
        return;
      }
      let trades = [
        ...(res.data.buy ? [res.data.buy] : []),
        ...(res.data.sell ? [res.data.sell] : []),
      ];
      dispatch(setLatestTrade(trades));
    } catch (error) {
      console.error("Error fetching latest trades:", error);
    }
  };
  useEffect(() => {
    fetchLatestTrades();
  }, []);

  useEffect(() => {
    fetchProviders();
  }, []);
  useEffect(() => {
    allBusinessStats();
  }, []);

  useEffect(() => {
    fetchAllBusiness();
  }, []);

  useEffect(() => {
    fetchBusinessINfo();
  }, []);

  useEffect(() => {
    // dispatch(resetBusiness()); //remove this line
    updateAsset();
  }, [balanceChange, activeProvider]);

  // useEffect(() => {
  //   if (!Object.keys(categoryProducts).length) {
  //     dispatch(fetchProductsByCategory({ category: null, page: 1, limit: 10 }));
  //   } else {
  //     dispatch(
  //       fetchProductsByCategory({
  //         category: null,
  //         page: 1,
  //         limit: 10,
  //         forceUpdate: true,
  //       })
  //     );
  //   }
  // }, [dispatch, categoryProducts]);

  // useEffect(() => {
  // }, []);

  return (
    <div className="App">
      <ExchangeHeader />
      <ScrollToTop />
      <Outlet />
      <ExchangeFooter />
    </div>
  );
}

export default App;
