import React from "react";
import { Camera02Icon, ArrowLeft01Icon, Cancel01Icon } from "hugeicons-react";
import html2canvas from "html2canvas";
import ReactTimeAgo from "react-time-ago";

const OrderReceipt = ({ back, payload }) => {
  const downloadImage = () => {
    const element = document.getElementById("capture"); // The div you want to capture
    html2canvas(element).then((canvas) => {
      const link = document.createElement("a");
      link.download = "Dhive-Receipt.png"; // The name of the downloaded file
      link.href = canvas.toDataURL("image/png"); // Convert the canvas to a data URL
      link.click(); // Trigger the download
    });
  };

  //console.log(payload, "payload");
  return (
    <div className="orderReceiptDiv">
      <div className="orderReceiptDiv_back_div" onClick={back}></div>
      <div className="orderReceiptDiv_cont">
        <div className="orderReceiptDiv_cont_header">
          <div className="orderReceiptDiv_cont_header_title">Order receipt</div>
          <Cancel01Icon
            className="orderReceiptDiv_cont_header_icon"
            onClick={back}
          />
        </div>
        <div className="orderReceiptDiv_cont_body" id="capture">
          <div className="orderReceiptDiv_cont_body_cont1">
            <div className="orderReceiptDiv_cont_body_cont1_title">
              <img
                src="/White Dhive.svg"
                alt=""
                className="orderReceiptDiv_cont_body_cont1_title_img"
              />
            </div>
            <div className="orderReceiptDiv_cont_body_cont1_body">
              <div className="orderReceiptDiv_cont_body_cont1_body_div1">
                <img
                  src={payload?.Get_product_image}
                  alt=""
                  className="tableBody_row_data_product_div_img"
                />
                <div className="orderReceiptDiv_cont_body_cont1_body_div1_area">
                  <div className="tableBody_row_data_product_div_cont_1">
                    {payload?.item_name}
                  </div>
                  <div className="tableBody_row_data_product_div_cont_2">
                    <span className="tableBody_row_data_product_div_cont_2_span">
                      {" "}
                      Business:
                    </span>{" "}
                    _ _
                  </div>
                  <div className="tableBody_row_data_product_div_cont_2">
                    <span className="tableBody_row_data_product_div_cont_2_span">
                      {" "}
                      Price:
                    </span>{" "}
                    <div className="tableBody_row_data_product_div_cont_2_amount">
                      {parseFloat(payload?.amount)?.toFixed(2)}USDC
                      <span className="tableBody_row_data_product_price_span">
                        =$ {parseFloat(payload?.amount)?.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/* ==== */}
              {/* ==== */}
              {/* ==== */}
              {/* ==== */}
              {/* ==== */}
              <div className="orderReceiptDiv_cont_body_cont1_body_div2">
                <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1">
                  <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_title">
                    Price
                  </div>
                  <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_value">
                    <div className="tableBody_row_data_product_price_div1">
                      {parseFloat(payload?.amount)?.toFixed(2)}USDC
                    </div>
                    <span className="tableBody_row_data_product_price_span">
                      =$ {parseFloat(payload?.amount)?.toFixed(2)}
                    </span>
                  </div>
                </div>
                <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1">
                  <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_title">
                    Quantity
                  </div>
                  <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_value">
                    {payload?.qty}
                  </div>
                </div>
                <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1">
                  <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_title">
                    Total
                  </div>
                  <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_value">
                    <div className="tableBody_row_data_product_price_div1">
                      {parseFloat(
                        parseFloat(payload?.qty) * parseFloat(payload?.amount)
                      )?.toFixed(2)}{" "}
                      USDC
                    </div>
                    <span className="tableBody_row_data_product_price_span">
                      =${" "}
                      {parseFloat(
                        parseFloat(payload?.qty) * parseFloat(payload?.amount)
                      )?.toFixed(2)}
                    </span>
                  </div>
                </div>
                <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1">
                  <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_title">
                    Ordered
                  </div>
                  <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_value">
                    <ReactTimeAgo date={payload?.createdAt} locale="en-US" />
                  </div>
                </div>
                <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1">
                  <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_title">
                    Order Status
                  </div>
                  <div className="orderReceiptDiv_cont_body_cont1_body_div2_cont1_value">
                    <div
                      className={
                        payload?.status === "INIT"
                          ? "tableBody_row_data_product_Status"
                          : "tableBody_row_data_product_Status_delivered"
                      }
                    >
                      {payload?.status === "INIT"
                        ? "Processing"
                        : payload?.status}{" "}
                    </div>
                  </div>
                </div>
              </div>
              {/* ==== */}
              {/* ==== */}
              {/* ==== */}
              {/* ==== */}
              {/* ==== */}
              {/* <div className="orderReceiptDiv_cont_body_cont1_body_div3">
                <div className="orderReceiptDiv_cont_body_cont1_body_div3_cont1">
                  <div className="orderReceiptDiv_cont_body_cont1_body_div3_cont1_title">
                    Customer Name
                  </div>
                  <div className="orderReceiptDiv_cont_body_cont1_body_div3_cont1_value">
                    John Doe
                  </div>
                </div>
                <div className="orderReceiptDiv_cont_body_cont1_body_div3_cont1">
                  <div className="orderReceiptDiv_cont_body_cont1_body_div3_cont1_title">
                    Phone
                  </div>
                  <div className="orderReceiptDiv_cont_body_cont1_body_div3_cont1_value">
                    +234 823 453 2321
                  </div>
                </div>
              </div> */}
              {/* ==== */}
              {/* ==== */}
              {/* ==== */}
              {/* ==== */}
              {/* ==== */}
            </div>
          </div>
        </div>
        <div className="orderReceiptDiv_cont_button_div">
          <button
            className="orderReceiptDiv_cont_button"
            onClick={downloadImage}
          >
            Save Receipt
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderReceipt;
