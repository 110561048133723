import React, { useState } from "react";
import { ArrowLeft01Icon, InformationCircleIcon } from "hugeicons-react";
import WalletReceive from "../WalletReceive/WalletReceive";
import WalletModel from "./WalletModel";

const ReceiveModal = ({
  Assets,
  toggleReceiveModal,
  selectedAsset,
  setSelectedAsset,
}) => {
  // const [selectedAsset, setSelectedAsset] = useState(null);
  const [receiveModal, setReceiveModal] = useState(false);
  if (receiveModal) {
    return (
      <WalletReceive
        selectedToken={selectedAsset}
        back={() => setReceiveModal(false)}
      />
    );
  }
  return (
    <div className="SendModal_div">
      <div className="WalletDetailPageDiv_header">
        <div
          className="WalletDetailPageDiv_header_back"
          onClick={toggleReceiveModal}
        >
          <ArrowLeft01Icon size={24} />
        </div>
        Select Asset
        <div className="WalletDetailPageDiv_header_info">
          <InformationCircleIcon size={20} />
        </div>
      </div>
      <div className="SendModal_div_body">
        <WalletModel
          setSelectedAsset={setSelectedAsset}
          handleOnClick={() => {
            // setSelectedAsset(data);
            setReceiveModal(true);
          }}
        />

        {/* {Assets?.map((data) => (
          <div
            className="WalletPop_div_cont_walletHome_div2_body_asset"
            onClick={() => {
              setSelectedAsset(data);
              setReceiveModal(true);
            }}
          >
            <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont1">
              <img
                src={data?.img || data?.image || "/img/art.png"}
                alt=""
                className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_img"
              />
              <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_area">
                <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_area_title">
                  {data?.tokenAName}
                </div>
                <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_area_price">
                  {parseFloat(data?.currentPrice)?.toFixed(4)}
                </div>
              </div>
            </div>
            <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont2">
              <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_area_title">
                {parseFloat(data?.balance)?.toFixed(4)}
              </div>
              <div className="WalletPop_div_cont_walletHome_div2_body_asset_cont1_area_price">
                ${parseFloat(data.balance * 0.545)?.toFixed(2)}
              </div>
            </div>
          </div>
        ))} */}
      </div>
    </div>
  );
};

export default ReceiveModal;
