import React, { useState, useEffect } from "react";
import { ArrowLeft01Icon, InformationCircleIcon } from "hugeicons-react";
import "./index.css";
import { PURCHASE_USDC } from "../../../services/PaystackPurchase";
import { useSocket } from "../../../Sockets/contextProvider/SocketContext";
import { socketEvents } from "../../../Sockets/events";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { numberWithCommas } from "../../../assets/js/numberWithCommas";

const WalletBuy = ({ back }) => {
  const { wallet_address } = useSelector((state) => state.user);
  const socket = useSocket();
  const [loading, setLoading] = useState(false);
  const [payUrl, setPayUrl] = useState("");
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState("");
  const [amountUSDC, setAmountUSDC] = useState("");
  const [buyModal, setBuyModal] = useState(false);

  const emailChange = (e) => {
    setEmail(e.target.value);
  };
  const amountChange = (e) => {
    setAmount(e.target.value);
    if (e.target.value == "") {
      setAmountUSDC("");
      return;
    }
    setAmountUSDC(parseFloat(e.target.value) / 1800);
  };
  const amountUSDCChange = (e) => {
    setAmountUSDC(e.target.value);
    if (e.target.value == "") {
      setAmount("");
      return;
    }
    setAmount(parseFloat(e.target.value) * 1800);
  };
  const initializeEgaxPurchase = async () => {
    setLoading(true);
    const payload = { email: email, amount: amount };
    //console.log(payload);
    const res = await PURCHASE_USDC({
      email: payload.email,
      amount: payload.amount,
    });
    //console.log("====================================");
    //console.log(res);
    if (!res.success) {
      setLoading(false);
      toast.error("USDC purchase failed");
      return;
    }
    setLoading(false);
    setBuyModal(true);
    setPayUrl(res?.data?.data?.authorization_url);
    // window.open(res?.data?.data?.authorization_url, "_blank");
    //console.log("====================================");
  };

  useEffect(() => {
    socket.on(`${wallet_address}${socketEvents.USDC_PURCHASE}`, (data) => {
      //console.log("purchase event started:", data);
      if (data?.success == false) {
        //    setLoading(false);
        toast.error(
          <div className="toast_success_div">
            <div className="toast_error_div_title">Error!!</div>
            <div className="toast_success_div_para">{data?.message}</div>
          </div>,
          {
            duration: 5000,
            className: "toast_error",
          }
        );

        return;
      }
      if (data?.success == true) {
        setLoading(false);
        toast.success(
          <div className="toast_success_div">
            <div className="toast_success_div_title"> Success Purchasing!</div>
            <div className="toast_success_div_para"> {data?.message}</div>
          </div>,
          {
            duration: 5000,
            className: "toast_success",
          }
        );
        setTimeout(() => {
          window.location.reload();
        }, 5000);

        return;
      }
    });
    return () => {
      socket.off(`${wallet_address}${socketEvents.USDC_PURCHASE}`); // Cleanup listener
    };
  }, [socket]);

  if (buyModal) {
    return (
      <div className="buy_div">
        <div className="WalletDetailPageDiv_header">
          <div
            className="WalletDetailPageDiv_header_back"
            onClick={() => setBuyModal(false)}
          >
            <ArrowLeft01Icon size={24} />
          </div>
          Complete purchase
          <div className="WalletDetailPageDiv_header_info">
            <InformationCircleIcon size={20} />
          </div>
        </div>

        <div className="buy_egax_modal_info">
          <iframe
            src={payUrl}
            style={{ width: "100%", height: "100%", border: "none" }}
            title="Paystack Checkout"
          ></iframe>
        </div>
      </div>
    );
  }
  return (
    <div className="buy_div">
      <div className="WalletDetailPageDiv_header">
        <div className="WalletDetailPageDiv_header_back" onClick={back}>
          <ArrowLeft01Icon size={24} />
        </div>
        Purchase USDC
        <div className="WalletDetailPageDiv_header_info">
          <InformationCircleIcon size={20} />
        </div>
      </div>

      <div className="buy_egax_modal_info">
        <div className="buy_egax_modal_info_txt">
          Fill out the information below to initiate the USDC purchase with your
          naira.
        </div>
        <div className="buy_egax_modal_info_body">
          <div className="buy_egax_modal_info_body_1">
            <div className="buy_egax_modal_info_body_1_head">Email address</div>
            <input
              type="email"
              className="buy_egax_modal_info_body_1_input"
              placeholder="@gmail.com"
              onChange={emailChange}
              value={email}
            />
          </div>
          <div className="buy_egax_modal_info_body_1">
            <div className="buy_egax_modal_info_body_1_head">Naira amount</div>
            <div className="buy_egax_modal_info_body_1_input_div">
              <div className="buy_egax_modal_info_body_1_input_div_ticker">
                ₦
              </div>
              <input
                onChange={amountChange}
                value={amount}
                placeholder="0.00"
                className="buy_egax_modal_info_body_1_input_2"
                type="number"
              />
            </div>
          </div>

          <div className="buy_usdc_input_div">
            <div className="buy_usdc_input_div_title">USDC amount</div>
            <div className="buy_usdc_input_div_area">
              <div className="buy_usdc_input_div_area_img_div">
                <img
                  src="/img/usdcicon.png"
                  alt=""
                  className="buy_usdc_input_div_area_img"
                />
              </div>
              <input
                value={amountUSDC}
                placeholder="0.00"
                className="buy_usdc_input"
                type="number"
                // readOnly
                onChange={amountUSDCChange}
              />
            </div>
          </div>

          <div className="buy_egax_modal_info_body_detail_div">
            <div className="TokenListDetailBody_warning_div">
              <InformationCircleIcon size={15} />
              <div className="TokenListDetailBody_warning_div_span">
                This purchase is solely meant for <b> Nigerians </b> only!
              </div>
            </div>
          </div>
          <div className="buy_egax_modal_info_body_button_div">
            {email === "" ? (
              <button
                className="buy_egax_modal_info_body_button"
                disabled={true}
              >
                Input email address
              </button>
            ) : amount < 10000 ? (
              <button
                className="buy_egax_modal_info_body_button"
                disabled={true}
              >
                Minimum amount ₦10,000.00
              </button>
            ) : (
              <button
                className="buy_egax_modal_info_body_button"
                onClick={initializeEgaxPurchase}
                // onClick={toggleBuyModal}
              >
                Proceed to Purchase
              </button>
            )}
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default WalletBuy;
